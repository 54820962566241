import React, { useState, useContext } from 'react';
import { 
    TextField, Button, FormControl, InputLabel, Select, MenuItem,
    RadioGroup, FormControlLabel, Radio, Typography, Box, Rating,
    Chip, Alert
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { db, auth } from '../../config/firebaseConfig';
import { collection, addDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { es } from 'date-fns/locale';
import { AuthContext } from '../../contexts/AuthContext';
import { format, addMonths } from 'date-fns';

const difficultyLevels = [
    { value: 1, label: 'Principiante' },
    { value: 2, label: 'Intermedio' },
    { value: 3, label: 'Avanzado' },
    { value: 4, label: 'Experto' },
];

const diasSemana = [
    { value: 1, label: 'Lunes' },
    { value: 2, label: 'Martes' },
    { value: 3, label: 'Miércoles' },
    { value: 4, label: 'Jueves' },
    { value: 5, label: 'Viernes' },
    { value: 6, label: 'Sábado' },
    { value: 0, label: 'Domingo' },
];

const CreateGroupClassForm = ({ onSubmit, onCancel }) => {
    const { userDetails } = useContext(AuthContext);
    const [error, setError] = useState(null);
    
    const [classData, setClassData] = useState({
        nombreClase: '',
        descripcion: '',
        configuracionTemporal: {
            tipo: 'puntual',
            duracionMinutos: 60,
            fechaInicio: null,
            horaInicio: null,
            fechaFinPeriodicidad: null,
            diasSemana: [],
            frecuencia: 'semanal'
        },
        capacidadMaxima: 10,
        dificultad: 1,
        politicaCancelacion: 'flexible',
        categorias: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            setClassData(prev => ({
                ...prev,
                [parent]: {
                    ...prev[parent],
                    [child]: value
                }
            }));
        } else {
            setClassData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleTimeChange = (newTime) => {
        setClassData(prev => ({
            ...prev,
            configuracionTemporal: {
                ...prev.configuracionTemporal,
                horaInicio: newTime
            }
        }));
    };

    const handleDateChange = (newDate, field) => {
        setClassData(prev => ({
            ...prev,
            configuracionTemporal: {
                ...prev.configuracionTemporal,
                [field]: newDate
            }
        }));
    };

    const handleDiasSemanaChange = (event) => {
        const { value } = event.target;
        setClassData(prev => ({
            ...prev,
            configuracionTemporal: {
                ...prev.configuracionTemporal,
                diasSemana: typeof value === 'string' ? value.split(',').map(Number) : value
            }
        }));
    };

    const validateForm = () => {
        if (!classData.nombreClase) return "El nombre de la clase es obligatorio";
        if (!classData.configuracionTemporal.horaInicio) return "La hora es obligatoria";
        if (classData.configuracionTemporal.tipo === 'puntual' && !classData.configuracionTemporal.fechaInicio) {
            return "La fecha es obligatoria para clases puntuales";
        }
        if (classData.configuracionTemporal.tipo === 'periodica') {
            if (classData.configuracionTemporal.diasSemana.length === 0) {
                return "Selecciona al menos un día de la semana";
            }
            if (!classData.configuracionTemporal.fechaInicio || !classData.configuracionTemporal.fechaFinPeriodicidad) {
                return "Las fechas de inicio y fin son obligatorias para clases periódicas";
            }
        }
        return null;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            const user = auth.currentUser;
            if (!user) throw new Error('No hay usuario autenticado');

            const IDGC = `GC${Date.now()}`;
            const horaInicio = classData.configuracionTemporal.horaInicio;

            // Crear el documento principal de la clase
            const nuevaClase = {
                IDGC,
                IDBO: userDetails.IDBO,
                IDPT: userDetails.IDPT,
                nombreClase: classData.nombreClase,
                descripcion: classData.descripcion,
                configuracionTemporal: {
                    ...classData.configuracionTemporal,
                    horaInicio: format(horaInicio, 'HH:mm'),
                },
                capacidadMaxima: parseInt(classData.capacidadMaxima),
                dificultad: difficultyLevels.find(level => level.value === classData.dificultad)?.label,
                politicaCancelacion: classData.politicaCancelacion === 'flexible' ? 'posible_cancelar' : 'se_cobra_igual',
                categorias: classData.categorias.split(',').map(cat => cat.trim()),
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                estado: 'activa'
            };

            // Crear la clase en la nueva colección
            const docRef = await addDoc(collection(db, 'ClasesProgramadas'), nuevaClase);

            // Si es periódica, generar todas las sesiones
            if (classData.configuracionTemporal.tipo === 'periodica') {
                const sesionesRef = collection(db, 'ClasesProgramadas', docRef.id, 'sesiones');
                const sesiones = generarSesiones(nuevaClase);
                
                // Crear todas las sesiones
                await Promise.all(sesiones.map(sesion => 
                    addDoc(sesionesRef, {
                        ...sesion,
                        alumnosInscritos: [],
                        aforoActual: 0,
                        estado: 'activa'
                    })
                ));
            }

            onSubmit(nuevaClase);
        } catch (error) {
            console.error('Error al crear la clase:', error);
            setError('Error al crear la clase: ' + error.message);
        }
    };

    const generarSesiones = (claseData) => {
        const sesiones = [];
        const {
            fechaInicio,
            fechaFinPeriodicidad,
            diasSemana,
            horaInicio,
            duracionMinutos,
            frecuencia
        } = claseData.configuracionTemporal;

        let fechaActual = new Date(fechaInicio);
        const fechaFin = new Date(fechaFinPeriodicidad);
        const [horas, minutos] = horaInicio.split(':');

        while (fechaActual <= fechaFin) {
            if (diasSemana.includes(fechaActual.getDay())) {
                const fechaSesion = new Date(fechaActual);
                fechaSesion.setHours(parseInt(horas), parseInt(minutos), 0, 0);

                sesiones.push({
                    fechaInicio: fechaSesion,
                    fechaFin: new Date(fechaSesion.getTime() + duracionMinutos * 60000),
                    estado: 'activa'
                });
            }

            // Avanzar según la frecuencia
            if (frecuencia === 'semanal') {
                fechaActual.setDate(fechaActual.getDate() + 1);
            } else if (frecuencia === 'quincenal') {
                fechaActual.setDate(fechaActual.getDate() + (diasSemana.includes(fechaActual.getDay()) ? 14 : 1));
            } else if (frecuencia === 'mensual') {
                fechaActual.setMonth(fechaActual.getMonth() + 1);
            }
        }

        return sesiones;
    };

    const textFieldStyle = {
        marginBottom: 2,
        '& .MuiOutlinedInput-root': {
            borderRadius: 4,
        },
        '& .MuiInputLabel-root': {
            background: 'white',
            padding: '0 4px',
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <form onSubmit={handleSubmit}>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <TextField
                    fullWidth
                    margin="normal"
                    name="nombreClase"
                    label="Nombre de la clase"
                    value={classData.nombreClase}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    name="descripcion"
                    label="Descripción de la clase"
                    multiline
                    rows={4}
                    value={classData.descripcion}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />

                <FormControl fullWidth margin="normal" sx={textFieldStyle}>
                    <InputLabel>Tipo de clase</InputLabel>
                    <Select
                        name="configuracionTemporal.tipo"
                        value={classData.configuracionTemporal.tipo}
                        onChange={handleChange}
                        required
                    >
                        <MenuItem value="puntual">Puntual</MenuItem>
                        <MenuItem value="periodica">Periódica</MenuItem>
                    </Select>
                </FormControl>

                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TimePicker
                        label="Hora de inicio"
                        value={classData.configuracionTemporal.horaInicio}
                        onChange={(newTime) => handleTimeChange(newTime)}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                fullWidth 
                                margin="normal"
                                required 
                                sx={textFieldStyle}
                            />
                        )}
                    />
                    <TextField
                        fullWidth
                        name="configuracionTemporal.duracionMinutos"
                        label="Duración (minutos)"
                        type="number"
                        value={classData.configuracionTemporal.duracionMinutos}
                        onChange={handleChange}
                        required
                        sx={textFieldStyle}
                    />
                </Box>

                {classData.configuracionTemporal.tipo === 'puntual' ? (
                    <DatePicker
                        label="Fecha de la clase"
                        value={classData.configuracionTemporal.fechaInicio}
                        onChange={(newDate) => handleDateChange(newDate, 'fechaInicio')}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                fullWidth 
                                margin="normal"
                                required 
                                sx={textFieldStyle}
                            />
                        )}
                    />
                ) : (
                    <>
                        <FormControl fullWidth margin="normal" sx={textFieldStyle}>
                            <InputLabel>Días de la semana</InputLabel>
                            <Select
                                multiple
                                value={classData.configuracionTemporal.diasSemana}
                                onChange={handleDiasSemanaChange}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip 
                                                key={value} 
                                                label={diasSemana.find(dia => dia.value === value)?.label} 
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {diasSemana.map((dia) => (
                                    <MenuItem key={dia.value} value={dia.value}>
                                        {dia.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <DatePicker
                                label="Fecha de inicio"
                                value={classData.configuracionTemporal.fechaInicio}
                                onChange={(newDate) => handleDateChange(newDate, 'fechaInicio')}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        fullWidth 
                                        margin="normal"
                                        required 
                                        sx={textFieldStyle}
                                    />
                                )}
                            />
                            <DatePicker
                                label="Fecha de fin"
                                value={classData.configuracionTemporal.fechaFinPeriodicidad}
                                onChange={(newDate) => handleDateChange(newDate, 'fechaFinPeriodicidad')}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        fullWidth 
                                        margin="normal"
                                        required 
                                        sx={textFieldStyle}
                                    />
                                )}
                            />
                        </Box>

                        <FormControl fullWidth margin="normal" sx={textFieldStyle}>
                            <InputLabel>Frecuencia</InputLabel>
                            <Select
                                name="configuracionTemporal.frecuencia"
                                value={classData.configuracionTemporal.frecuencia}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="semanal">Semanal</MenuItem>
                                <MenuItem value="quincenal">Quincenal</MenuItem>
                                <MenuItem value="mensual">Mensual</MenuItem>
                            </Select>
                        </FormControl>
                    </>
                )}

                <TextField
                    fullWidth
                    margin="normal"
                    name="capacidadMaxima"
                    label="Capacidad máxima"
                    type="number"
                    value={classData.capacidadMaxima}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />

                <Box sx={{ mt: 4, mb: 2 }}>
                    <Typography gutterBottom>Nivel de Dificultad</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Rating
                            name="dificultad"
                            value={classData.dificultad}
                            max={4}
                            onChange={(event, newValue) => {
                                handleChange({ target: { name: 'dificultad', value: newValue } });
                            }}
                            icon={<FitnessCenterIcon fontSize="inherit" />}
                            emptyIcon={<FitnessCenterIcon fontSize="inherit" />}
                            sx={{ fontSize: '2rem' }}
                        />
                        <Typography variant="body2">
                            {difficultyLevels.find(level => level.value === classData.dificultad)?.label}
                        </Typography>
                    </Box>
                </Box>

                <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <Typography component="legend">Política de cancelación</Typography>
                    <RadioGroup
                        name="politicaCancelacion"
                        value={classData.politicaCancelacion}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="flexible" control={<Radio />} label="Se puede cancelar" />
                        <FormControlLabel value="strict" control={<Radio />} label="Se cobra igualmente" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    name="categorias"
                    label="Categorías"
                    value={classData.categorias}
                    onChange={handleChange}
                    placeholder="Cardio, Fuerza, Flexibilidad (separadas por comas)"
                    sx={textFieldStyle}
                />

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button 
                        onClick={onCancel} 
                        sx={{ 
                            color: '#FF5722',
                            borderRadius: '25px',
                            padding: '10px 20px'
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        sx={{ 
                            backgroundColor: '#FF5722',
                            color: 'white',
                            borderRadius: '25px',
                            padding: '10px 20px',
                            '&:hover': {
                                backgroundColor: '#E64A19'
                            }
                        }}
                    >
                        Crear Clase
                    </Button>
                </Box>
            </form>
        </LocalizationProvider>
    );
};

export default CreateGroupClassForm;
