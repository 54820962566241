import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    IconButton,
    Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import deleteClientService from '../../../services/deleteClient';
import initializeClientBonos from '../../../services/initializeClientBonos';
import { useAuth } from '../../../contexts/AuthContext';
import { getFirestore, doc, updateDoc, getDoc, collection, getDocs } from 'firebase/firestore';

const ManagementView = ({ clientId }) => {
    const navigate = useNavigate();
    const { userDetails } = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientState, setClientState] = useState('Pendiente');
    const [bonos, setBonos] = useState(0);
    const db = getFirestore();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                let clientDocRef;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
                } else {
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
                }
                
                const clientDoc = await getDoc(clientDocRef);
                if (clientDoc.exists()) {
                    const data = clientDoc.data();
                    setClientState(data.estado || 'Pendiente');
                    setBonos(data.bonos || 0);
                }
            } catch (error) {
                console.error('Error al obtener los datos del cliente:', error);
            }
        };

        fetchClientData();
    }, [clientId, userDetails, db]);

    useEffect(() => {
        const initializeBonos = async () => {
            try {
                const result = await initializeClientBonos(userDetails);
                if (!result.success) {
                    console.error('Error al inicializar bonos:', result.error);
                } else if (result.updatedClients > 0) {
                    setSnackbar({
                        open: true,
                        message: result.message,
                        severity: 'info'
                    });
                }
            } catch (error) {
                console.error('Error al ejecutar la inicialización de bonos:', error);
            }
        };

        // Ejecutar la inicialización solo una vez cuando el componente se monta
        initializeBonos();
    }, [userDetails]);

    const handleStateChange = async (event) => {
        const newState = event.target.value;
        setClientState(newState);
        
        try {
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
            } else {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
            }
            
            await updateDoc(clientDocRef, {
                estado: newState
            });

            setSnackbar({
                open: true,
                message: 'Estado del cliente actualizado correctamente',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error al actualizar el estado del cliente:', error);
            setSnackbar({
                open: true,
                message: 'Error al actualizar el estado del cliente',
                severity: 'error'
            });
        }
    };

    const handleBonosChange = async (newValue) => {
        // Asegurarse de que el valor no sea negativo
        if (newValue < 0) return;
        
        try {
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
            } else {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
            }
            
            await updateDoc(clientDocRef, {
                bonos: newValue
            });

            setBonos(newValue);
            setSnackbar({
                open: true,
                message: 'Bonos actualizados correctamente',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error al actualizar los bonos:', error);
            setSnackbar({
                open: true,
                message: 'Error al actualizar los bonos',
                severity: 'error'
            });
        }
    };

    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        try {
            await deleteClientService(userDetails, clientId);
            setSnackbar({
                open: true,
                message: 'Cliente eliminado correctamente',
                severity: 'success'
            });
            // Esperar un momento para que el usuario vea el mensaje de éxito
            setTimeout(() => {
                navigate('/clients');
            }, 1500);
        } catch (error) {
            console.error('Error al eliminar el cliente:', error);
            setSnackbar({
                open: true,
                message: `Error al eliminar el cliente: ${error.message}`,
                severity: 'error'
            });
            setOpenDialog(false);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2, mb: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
                    Gestión del Cliente
                </Typography>

                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Estado del Cliente
                    </Typography>
                    <FormControl fullWidth sx={{ maxWidth: 300 }}>
                        <InputLabel id="client-state-label">Estado</InputLabel>
                        <Select
                            labelId="client-state-label"
                            id="client-state"
                            value={clientState}
                            label="Estado"
                            onChange={handleStateChange}
                        >
                            <MenuItem value="Pendiente">Pendiente</MenuItem>
                            <MenuItem value="Activo">Activo</MenuItem>
                            <MenuItem value="Inactivo">Inactivo</MenuItem>
                            <MenuItem value="Perdido">Perdido</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Gestión de Bonos
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, maxWidth: 300 }}>
                        <Tooltip title="Reducir bonos">
                            <IconButton 
                                onClick={() => handleBonosChange(bonos - 1)}
                                disabled={bonos <= 0}
                                color="primary"
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                        
                        <TextField
                            label="Bonos"
                            type="number"
                            value={bonos}
                            onChange={(e) => handleBonosChange(parseInt(e.target.value) || 0)}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            sx={{ width: '100px' }}
                        />
                        
                        <Tooltip title="Añadir bonos">
                            <IconButton 
                                onClick={() => handleBonosChange(bonos + 1)}
                                color="primary"
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                <Box sx={{ 
                    p: 3, 
                    bgcolor: 'background.paper', 
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'error.main',
                }}>
                    <Typography variant="h6" gutterBottom color="error">
                        Zona de Peligro
                    </Typography>
                    <Typography variant="body1" paragraph color="error">
                        Las acciones en esta sección son irreversibles. Por favor, procede con precaución.
                    </Typography>
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        disabled={loading}
                        sx={{
                            '&:hover': {
                                bgcolor: 'error.light',
                                color: 'white'
                            }
                        }}
                    >
                        {loading ? 'Eliminando...' : 'Eliminar Cliente'}
                    </Button>
                </Box>
            </Paper>

            {/* Diálogo de confirmación */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Estás seguro de que quieres eliminar este cliente?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta acción eliminará al cliente de tu lista y actualizará su perfil. 
                        Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleCloseDialog} 
                        disabled={loading}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDelete} 
                        color="error" 
                        variant="contained"
                        disabled={loading}
                        autoFocus
                    >
                        {loading ? 'Eliminando...' : 'Eliminar'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ManagementView; 