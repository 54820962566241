import React, { useState, useContext, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    IconButton,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    InputAdornment,
    Paper,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import BookIcon from '@mui/icons-material/Book';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import FilterListIcon from '@mui/icons-material/FilterList';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import { AuthContext } from '../../contexts/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../config/firebaseConfig';
import { doc, collection, addDoc, getDocs, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { deleteObject } from 'firebase/storage';
import { useSnackbar } from 'notistack';

const ContentTool = () => {
    const { userDetails } = useContext(AuthContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editingContent, setEditingContent] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [contenidos, setContenidos] = useState([]);
    const [loadingContenidos, setLoadingContenidos] = useState(true);
    const [selectedTipo, setSelectedTipo] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        tipo: '',
        precio: 0,
        esGratis: true,
        file: null
    });

    // Tipos de contenido disponibles
    const tiposContenido = [
        "Video Tutorial",
        "E-book",
        "Infografía",
        "Guía PDF",
        "Plan de Entrenamiento",
        "Plan Nutricional",
        "Webinar Grabado",
        "Otro"
    ];

    const getFileType = (file) => {
        if (file.type.startsWith('video/')) return 'video';
        if (file.type.startsWith('image/')) return 'imagen';
        if (file.type === 'application/pdf') return 'pdf';
        return 'otro';
    };

    useEffect(() => {
        fetchContenidos();
    }, [userDetails]);

    const fetchContenidos = async () => {
        if (!userDetails) return;

        try {
            let contenidosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                contenidosRef = collection(db, 'userDetails', userDetails.uid, 'contenidos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                contenidosRef = collection(db, 'Centros', userDetails.IDBO, 'contenidos');
            }

            const contenidosSnapshot = await getDocs(contenidosRef);
            const contenidosData = contenidosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setContenidos(contenidosData);
        } catch (error) {
            console.error("Error al cargar los contenidos:", error);
            enqueueSnackbar('Error al cargar los contenidos', { variant: 'error' });
        } finally {
            setLoadingContenidos(false);
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const maxSize = 200 * 1024 * 1024; // 200MB
            if (file.size > maxSize) {
                enqueueSnackbar('El archivo es demasiado grande. Máximo 200MB permitido.', {
                    variant: 'warning'
                });
                return;
            }
            setFormData(prev => ({
                ...prev,
                file: file
            }));
        }
    };

    const handleSubmit = async () => {
        if (!formData.titulo || !formData.tipo || !formData.file) {
            enqueueSnackbar('Por favor, completa todos los campos obligatorios', {
                variant: 'warning'
            });
            return;
        }

        setIsLoading(true);
        try {
            const storage = getStorage();
            const fileName = `${Date.now()}_${formData.file.name}`;
            const storageRefPath = `contenidos/${fileName}`;
            const storageRef = ref(storage, storageRefPath);
            
            await uploadBytes(storageRef, formData.file);
            const fileUrl = await getDownloadURL(storageRef);

            const fileType = getFileType(formData.file);
            const contenidoData = {
                titulo: formData.titulo,
                descripcion: formData.descripcion,
                tipo: formData.tipo,
                precio: formData.precio,
                esGratis: formData.esGratis,
                fileUrl: fileUrl,
                filePath: storageRefPath,
                fileType: fileType,
                fecha: serverTimestamp(),
                autor: userDetails?.display_name || 'Anónimo',
                descargas: 0
            };

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const contenidosRef = collection(db, 'userDetails', userDetails.uid, 'contenidos');
                await addDoc(contenidosRef, contenidoData);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                const contenidosRef = collection(db, 'Centros', userDetails.IDBO, 'contenidos');
                await addDoc(contenidosRef, contenidoData);
            }

            enqueueSnackbar('Contenido subido exitosamente', { variant: 'success' });
            setOpenDialog(false);
            setFormData({
                titulo: '',
                descripcion: '',
                tipo: '',
                precio: 0,
                esGratis: true,
                file: null
            });
            await fetchContenidos();
        } catch (error) {
            console.error("Error al subir el contenido:", error);
            enqueueSnackbar(`Error al subir el contenido: ${error.message}`, { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteContent = async (contenido) => {
        if (!window.confirm('¿Estás seguro de que quieres eliminar este contenido?')) {
            return;
        }

        try {
            const storage = getStorage();
            const fileRef = ref(storage, contenido.filePath);
            await deleteObject(fileRef);

            let contenidoRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                contenidoRef = doc(db, 'userDetails', userDetails.uid, 'contenidos', contenido.id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                contenidoRef = doc(db, 'Centros', userDetails.IDBO, 'contenidos', contenido.id);
            }

            await deleteDoc(contenidoRef);
            await fetchContenidos();
            
            enqueueSnackbar('Contenido eliminado con éxito', { variant: 'success' });
        } catch (error) {
            console.error("Error al eliminar el contenido:", error);
            enqueueSnackbar(`Error al eliminar el contenido: ${error.message}`, { variant: 'error' });
        }
    };

    const handleEditContent = async (updatedData) => {
        if (!editingContent) return;

        setIsLoading(true);
        try {
            let contenidoRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                contenidoRef = doc(db, 'userDetails', userDetails.uid, 'contenidos', editingContent.id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                contenidoRef = doc(db, 'Centros', userDetails.IDBO, 'contenidos', editingContent.id);
            }

            await updateDoc(contenidoRef, {
                titulo: updatedData.titulo,
                descripcion: updatedData.descripcion,
                tipo: updatedData.tipo,
                precio: updatedData.precio,
                esGratis: updatedData.esGratis,
                fecha_actualizacion: serverTimestamp()
            });

            enqueueSnackbar('Contenido actualizado con éxito', { variant: 'success' });
            setOpenEditDialog(false);
            setEditingContent(null);
            await fetchContenidos();
        } catch (error) {
            console.error("Error al actualizar el contenido:", error);
            enqueueSnackbar(`Error al actualizar el contenido: ${error.message}`, { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const filteredContenidos = contenidos.filter(contenido => {
        const matchesSearch = contenido.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            contenido.descripcion.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesTipo = !selectedTipo || contenido.tipo === selectedTipo;
        return matchesSearch && matchesTipo;
    });

    const getContentIcon = (fileType) => {
        switch (fileType) {
            case 'video':
                return <VideoLibraryIcon sx={{ fontSize: 40 }} />;
            case 'pdf':
                return <PictureAsPdfIcon sx={{ fontSize: 40 }} />;
            case 'imagen':
                return <ImageIcon sx={{ fontSize: 40 }} />;
            default:
                return <BookIcon sx={{ fontSize: 40 }} />;
        }
    };

    return (
        <Box>
            {/* Encabezado */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Biblioteca de Contenidos
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Gestiona y comparte contenido educativo con tus clientes
                </Typography>
                
                <Alert severity="info" sx={{ mt: 2 }}>
                    Sube contenido de calidad para tus clientes: videos tutoriales, e-books, guías de ejercicios y más.
                </Alert>
            </Box>

            {/* Barra de acciones */}
            <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Buscar contenidos..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            startIcon={<FilterListIcon />}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            Filtrar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setOpenDialog(true)}
                        >
                            Nuevo Contenido
                        </Button>
                    </Grid>

                    {showFilters && (
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                                <InputLabel>Tipo de Contenido</InputLabel>
                                <Select
                                    value={selectedTipo}
                                    onChange={(e) => setSelectedTipo(e.target.value)}
                                    label="Tipo de Contenido"
                                >
                                    <MenuItem value="">Todos los tipos</MenuItem>
                                    {tiposContenido.map((tipo) => (
                                        <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Paper>

            {/* Grid de contenidos */}
            {loadingContenidos ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : filteredContenidos.length > 0 ? (
                <Grid container spacing={3}>
                    {filteredContenidos.map((contenido) => (
                        <Grid item xs={12} sm={6} md={4} key={contenido.id}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ 
                                    p: 2, 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'space-between',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                }}>
                                    {getContentIcon(contenido.fileType)}
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setEditingContent(contenido);
                                                setOpenEditDialog(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDeleteContent(contenido)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>

                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography variant="h6" gutterBottom>
                                        {contenido.titulo}
                                    </Typography>
                                    <Chip 
                                        label={contenido.tipo}
                                        size="small"
                                        sx={{ mb: 1 }}
                                    />
                                    <Typography variant="body2" color="text.secondary" paragraph>
                                        {contenido.descripcion}
                                    </Typography>
                                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="caption" color="text.secondary">
                                            {contenido.fecha?.toDate?.() ? 
                                                new Date(contenido.fecha.toDate()).toLocaleDateString() : 
                                                'Fecha no disponible'}
                                        </Typography>
                                        <Chip 
                                            label={contenido.esGratis ? 'Gratis' : `${contenido.precio}€`}
                                            color={contenido.esGratis ? 'success' : 'primary'}
                                            size="small"
                                        />
                                    </Box>
                                </CardContent>

                                <Box sx={{ p: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        href={contenido.fileUrl}
                                        target="_blank"
                                    >
                                        Descargar
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        No se encontraron contenidos
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {searchTerm || selectedTipo ? 
                            'Prueba con otros criterios de búsqueda' : 
                            'Comienza subiendo tu primer contenido'}
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenDialog(true)}
                        sx={{ mt: 2 }}
                    >
                        Subir Contenido
                    </Button>
                </Box>
            )}

            {/* Modal para nuevo contenido */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Subir Nuevo Contenido</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            label="Título"
                            variant="outlined"
                            value={formData.titulo}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                titulo: e.target.value
                            }))}
                        />

                        <TextField
                            fullWidth
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={formData.descripcion}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                descripcion: e.target.value
                            }))}
                        />

                        <FormControl fullWidth>
                            <InputLabel>Tipo de Contenido</InputLabel>
                            <Select
                                value={formData.tipo}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    tipo: e.target.value
                                }))}
                                label="Tipo de Contenido"
                            >
                                {tiposContenido.map((tipo) => (
                                    <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<UploadFileIcon />}
                            >
                                {formData.file ? 'Archivo seleccionado' : 'Subir Archivo'}
                                <input
                                    type="file"
                                    hidden
                                    accept="video/*,image/*,application/pdf"
                                    onChange={handleFileUpload}
                                />
                            </Button>
                            {formData.file && (
                                <Typography variant="body2" color="text.secondary">
                                    {formData.file.name}
                                </Typography>
                            )}
                        </Box>

                        <FormControl component="fieldset">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Button
                                    variant={formData.esGratis ? "contained" : "outlined"}
                                    onClick={() => setFormData(prev => ({ ...prev, esGratis: true, precio: 0 }))}
                                >
                                    Gratis
                                </Button>
                                <Button
                                    variant={!formData.esGratis ? "contained" : "outlined"}
                                    onClick={() => setFormData(prev => ({ ...prev, esGratis: false }))}
                                    endIcon={
                                        <Chip 
                                            label="BETA" 
                                            size="small" 
                                            color="warning"
                                            sx={{ height: 20 }}
                                        />
                                    }
                                >
                                    De Pago
                                </Button>
                            </Box>
                            {!formData.esGratis && (
                                <Alert severity="warning" sx={{ mt: 1, fontSize: '0.875rem' }}>
                                    La funcionalidad de pago está en fase beta. Por el momento, todos los contenidos serán accesibles de forma gratuita para los clientes.
                                </Alert>
                            )}
                        </FormControl>

                        {!formData.esGratis && (
                            <TextField
                                fullWidth
                                label="Precio (€)"
                                type="number"
                                variant="outlined"
                                value={formData.precio}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    precio: parseFloat(e.target.value) || 0
                                }))}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={() => setOpenDialog(false)} disabled={isLoading}>
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <CircularProgress size={20} color="inherit" />
                                Subiendo...
                            </Box>
                        ) : (
                            'Subir'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal para editar contenido */}
            <Dialog 
                open={openEditDialog} 
                onClose={() => {
                    setOpenEditDialog(false);
                    setEditingContent(null);
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Editar Contenido</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            label="Título"
                            variant="outlined"
                            value={editingContent?.titulo || ''}
                            onChange={(e) => setEditingContent(prev => ({
                                ...prev,
                                titulo: e.target.value
                            }))}
                        />

                        <TextField
                            fullWidth
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={editingContent?.descripcion || ''}
                            onChange={(e) => setEditingContent(prev => ({
                                ...prev,
                                descripcion: e.target.value
                            }))}
                        />

                        <FormControl fullWidth>
                            <InputLabel>Tipo de Contenido</InputLabel>
                            <Select
                                value={editingContent?.tipo || ''}
                                onChange={(e) => setEditingContent(prev => ({
                                    ...prev,
                                    tipo: e.target.value
                                }))}
                                label="Tipo de Contenido"
                            >
                                {tiposContenido.map((tipo) => (
                                    <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl component="fieldset">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Button
                                    variant={editingContent?.esGratis ? "contained" : "outlined"}
                                    onClick={() => setEditingContent(prev => ({ ...prev, esGratis: true, precio: 0 }))}
                                >
                                    Gratis
                                </Button>
                                <Button
                                    variant={!editingContent?.esGratis ? "contained" : "outlined"}
                                    onClick={() => setEditingContent(prev => ({ ...prev, esGratis: false }))}
                                    endIcon={
                                        <Chip 
                                            label="BETA" 
                                            size="small" 
                                            color="warning"
                                            sx={{ height: 20 }}
                                        />
                                    }
                                >
                                    De Pago
                                </Button>
                            </Box>
                            {!editingContent?.esGratis && (
                                <Alert severity="warning" sx={{ mt: 1, fontSize: '0.875rem' }}>
                                    La funcionalidad de pago está en fase beta. Por el momento, todos los contenidos serán accesibles de forma gratuita para los clientes.
                                </Alert>
                            )}
                        </FormControl>

                        {!editingContent?.esGratis && (
                            <TextField
                                fullWidth
                                label="Precio (€)"
                                type="number"
                                variant="outlined"
                                value={editingContent?.precio || 0}
                                onChange={(e) => setEditingContent(prev => ({
                                    ...prev,
                                    precio: parseFloat(e.target.value) || 0
                                }))}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button 
                        onClick={() => {
                            setOpenEditDialog(false);
                            setEditingContent(null);
                        }}
                        disabled={isLoading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleEditContent(editingContent)}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <CircularProgress size={20} color="inherit" />
                                Actualizando...
                            </Box>
                        ) : (
                            'Guardar Cambios'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ContentTool; 