import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from './components/pages/Home';
import ClientList from './components/clients/ClientList';
import ClientDetails from './components/clients/ClientDetails';
import ClientDetailsV2 from './components/client-v2/ClientDetailsV2';
import AddClient from './components/clients/AddClient';
import Messages from './components/pages/Messages';
import Chat from './components/pages/Chat';
import Notifications from './components/pages/Notifications';
import Settings from './components/pages/Settings';
import PrivateRoute from './components/common/PrivateRoute';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import PotentialClients from './components/clients/PotentialClients';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Logout from './components/auth/Logout';
import Classes from './components/pages/Classes';
import AssistanceButton from './components/common/AssistanceButton';
import Facturacion from './components/pages/Facturacion';
import IA from './components/pages/IA';
import SubscriptionConfirmation from './components/pages/SubscriptionConfirmation';
import { useAuth } from './contexts/AuthContext';
import RestrictedAccess from './components/common/RestrictedAccess';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import StripeConnectReturn from './components/billing/StripeConnectReturn';
import StripeConnectRefresh from './components/billing/StripeConnectRefresh';
import { StripeProvider } from './contexts/StripeContext';
import { SnackbarProvider } from 'notistack';
import TutorialButton from './components/common/TutorialButton';
import Recursos from './components/pages/Recursos';
import Admin from './components/pages/Admin';
import { AuthProvider } from './contexts/AuthContext';
import Generar from './components/pages/Generar';
import Chatbot from './components/pages/Chatbot';
import Tools from './components/pages/Tools';
import FormToolPage from './components/tools/FormToolPage';
import ContentToolPage from './components/tools/ContentToolPage';

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    const isSubscriptionConfirmation = location.pathname === '/subscription-confirmation' && sessionId;
    
    // Logs para depuración
    console.log('Ruta actual:', location.pathname);
    console.log('Session ID:', sessionId);
    console.log('¿Es página de confirmación de suscripción?', isSubscriptionConfirmation);
    
    const isAuthPage = ['/login', '/register', '/logout'].includes(location.pathname);

    if (currentUser) {
      if (isSubscriptionConfirmation) {
        // Permitir permanecer en la página de confirmación sin redirigir
        return;
      }
      if (location.pathname === '/' || isAuthPage) {
        navigate('/home');
      }
    } else if (!isAuthPage && !isSubscriptionConfirmation) {
      navigate('/login');
    }
  }, [currentUser, navigate, location]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {location.pathname !== '/login' && 
       location.pathname !== '/register' && 
       location.pathname !== '/logout' && 
       <Navbar />}
      <Box component="main" sx={{ 
        flexGrow: 1, 
        p: 3, 
        display: 'flex', 
        flexDirection: 'column',
        minHeight: 'calc(100vh - 64px)' // Altura total menos la altura del header si existe
      }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/clients" element={<PrivateRoute><ClientList /></PrivateRoute>} />
          <Route path="/clients/:id" element={<PrivateRoute><ClientDetails /></PrivateRoute>} />
          <Route path="/client-v2/:id" element={<PrivateRoute><ClientDetailsV2 /></PrivateRoute>} />
          <Route path="/clients/add" element={<PrivateRoute><AddClient /></PrivateRoute>} />
          <Route path="/messages" element={<PrivateRoute><Messages /></PrivateRoute>} />
          <Route path="/chat/:chatId" element={<PrivateRoute><Chat /></PrivateRoute>} />
          <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
          <Route path="/potential-clients" element={<PrivateRoute><PotentialClients /></PrivateRoute>} />
          <Route path="/classes" element={<PrivateRoute><Classes /></PrivateRoute>} />
          <Route path="/facturacion" element={<PrivateRoute><Facturacion /></PrivateRoute>} />
          <Route path="/ia" element={<IA />} />
          <Route path="/chatbot" element={<PrivateRoute><Chatbot /></PrivateRoute>} />
          <Route path="/tools" element={<PrivateRoute><Tools /></PrivateRoute>} />
          <Route path="/tools/forms" element={<PrivateRoute><FormToolPage /></PrivateRoute>} />
          <Route path="/tools/content" element={<PrivateRoute><ContentToolPage /></PrivateRoute>} />
          <Route 
            path="/subscription-confirmation" 
            element={
              <PrivateRoute>
                <SubscriptionConfirmation />
              </PrivateRoute>
            } 
          />
          <Route path="/facturacion/stripe/return/:userId" element={<StripeConnectReturn />} />
          <Route path="/facturacion/stripe/refresh/:userId" element={<StripeConnectRefresh />} />
          <Route path="/recursos" element={<Recursos />} />
          <Route path="/generar" element={<PrivateRoute><Generar /></PrivateRoute>} />
          <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
        </Routes>
        <Footer />
      </Box>
    </Box>
  );
};

const App = () => {
  const { userDetails, isLoadingAccess } = useAuth();

  return (
    <AuthProvider>
      <Router>
        <SnackbarProvider maxSnack={3}>
          <StripeProvider>
            <div className="App">
              <AssistanceButton />
              <TutorialButton />
              <AppContent />
              {!isLoadingAccess && userDetails && !userDetails.dashboardAccess && <RestrictedAccess />}
            </div>
          </StripeProvider>
        </SnackbarProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;
