import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Tabs,
    Tab,
    Paper,
    CircularProgress,
    IconButton,
    Tooltip,
    Avatar,
    Typography,
    Chip,
    Divider,
    Menu,
    MenuItem,
    Snackbar,
    Alert
} from '@mui/material';
import { 
    Person as PersonIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    FitnessCenter as FitnessCenterIcon,
    ArrowBack as ArrowBackIcon,
    Restaurant as RestaurantIcon,
    Star as StarIcon,
    Timeline as TimelineIcon,
    Circle as CircleIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    CalendarMonth as CalendarMonthIcon,
    CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';

// Importar las vistas
import SummaryView from './views/SummaryView';
import ProfileView from './views/ProfileView';
import RoutineView from './views/RoutineView';
import ManagementView from './views/ManagementView';
import DietView from './views/DietView';
import ToolsView from './views/ToolsView';
import ProgressView from './views/ProgressView';
import AgendaView from './views/AgendaView';

const ClientDetailsV2 = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(null);
    const [error, setError] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    
    // Nuevo estado para el menú de estados
    const [stateMenuAnchor, setStateMenuAnchor] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        const fetchClientDetails = async () => {
            if (!userDetails || !id) {
                setError("Información de usuario o ID de cliente no disponible");
                setLoading(false);
                return;
            }

            try {
                let clientDocRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
                }

                const clientDoc = await getDoc(clientDocRef);

                if (!clientDoc.exists()) {
                    throw new Error("Cliente no encontrado");
                }

                const clientData = clientDoc.data();
                setClient({
                    ...clientData,
                    id: id
                });
            } catch (error) {
                console.error("Error al obtener detalles del cliente:", error);
                setError("Error al cargar los detalles del cliente");
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
    }, [id, userDetails, db]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleGoBack = () => {
        navigate('/clients');
    };

    // Función para manejar el cambio de estado
    const handleStateChange = async (newState) => {
        try {
            let clientDocRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
            } else {
                clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
            }
            
            await updateDoc(clientDocRef, {
                estado: newState
            });

            setClient(prev => ({
                ...prev,
                estado: newState
            }));

            setSnackbar({
                open: true,
                message: 'Estado actualizado correctamente',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            setSnackbar({
                open: true,
                message: 'Error al actualizar el estado',
                severity: 'error'
            });
        } finally {
            setStateMenuAnchor(null);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const tabs = [
        { label: 'Resumen', icon: <DashboardIcon /> },
        { label: 'Perfil', icon: <PersonIcon /> },
        { label: 'Agenda', icon: <CalendarMonthIcon /> },
        { label: 'Rutina', icon: <FitnessCenterIcon /> },
        { label: 'Dieta', icon: <RestaurantIcon /> },
        { label: 'Progreso', icon: <TimelineIcon /> },
        { label: 'Objetivos Diarios', icon: <CheckCircleIcon /> },
        { label: 'Gestión', icon: <SettingsIcon /> }
    ];

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {/* Header con información del cliente */}
            <Paper 
                elevation={0} 
                sx={{ 
                    borderRadius: 0,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    mb: 2,
                    p: 2
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Tooltip title="Volver a la lista de clientes">
                        <IconButton onClick={handleGoBack}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                    
                    <Avatar
                        src={client?.photo_url}
                        alt={`${client?.nombre} ${client?.apellidos}`}
                        sx={{ 
                            width: 56, 
                            height: 56,
                            border: '2px solid',
                            borderColor: 'primary.main'
                        }}
                    />
                    
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" component="h1">
                            {client?.nombre} {client?.apellidos}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Chip
                                label={client?.plan === 'Pro' ? 'Cliente Pro' : 'Cliente Free'}
                                color={client?.plan === 'Pro' ? 'primary' : 'default'}
                                size="small"
                                sx={{
                                    background: client?.plan === 'Pro' ? 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)' : undefined,
                                    color: client?.plan === 'Pro' ? 'white' : undefined,
                                }}
                            />
                            <Chip
                                icon={<CircleIcon sx={{ fontSize: 12 }} />}
                                deleteIcon={<KeyboardArrowDownIcon />}
                                onDelete={() => {}}
                                onClick={(e) => setStateMenuAnchor(e.currentTarget)}
                                label={client?.estado || 'Pendiente'}
                                size="small"
                                sx={{
                                    backgroundColor: (() => {
                                        switch(client?.estado) {
                                            case 'Activo':
                                                return '#4CAF50';
                                            case 'Inactivo':
                                                return '#FFA726';
                                            case 'Perdido':
                                                return '#EF5350';
                                            default:
                                                return '#9E9E9E';
                                        }
                                    })(),
                                    color: 'white',
                                    cursor: 'pointer',
                                    '& .MuiChip-icon': {
                                        color: 'white'
                                    },
                                    '& .MuiChip-deleteIcon': {
                                        color: 'white'
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Paper>

            {/* Menú de estados */}
            <Menu
                anchorEl={stateMenuAnchor}
                open={Boolean(stateMenuAnchor)}
                onClose={() => setStateMenuAnchor(null)}
            >
                <MenuItem onClick={() => handleStateChange('Pendiente')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircleIcon sx={{ fontSize: 12, color: '#9E9E9E' }} />
                        Pendiente
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => handleStateChange('Activo')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircleIcon sx={{ fontSize: 12, color: '#4CAF50' }} />
                        Activo
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => handleStateChange('Inactivo')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircleIcon sx={{ fontSize: 12, color: '#FFA726' }} />
                        Inactivo
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => handleStateChange('Perdido')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircleIcon sx={{ fontSize: 12, color: '#EF5350' }} />
                        Perdido
                    </Box>
                </MenuItem>
            </Menu>

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>

            {/* Tabs */}
            <Paper elevation={0} sx={{ borderRadius: 0 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                icon={tab.icon}
                                iconPosition="start"
                                sx={{
                                    minHeight: 48,
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    fontWeight: 'medium'
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>
            </Paper>

            <Box sx={{ mt: 2 }}>
                {currentTab === 0 && <SummaryView clientId={id} />}
                {currentTab === 1 && <ProfileView clientId={id} />}
                {currentTab === 2 && <AgendaView clientId={id} />}
                {currentTab === 3 && <RoutineView clientId={id} />}
                {currentTab === 4 && <DietView clientId={id} />}
                {currentTab === 5 && <ProgressView clientId={id} />}
                {currentTab === 6 && <ToolsView clientId={id} />}
                {currentTab === 7 && <ManagementView clientId={id} />}
            </Box>
        </Box>
    );
};

export default ClientDetailsV2; 