import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Badge,
    Avatar,
    Card,
    CardContent,
    CardActions,
    Stack,
    keyframes,
    Snackbar,
    Alert
} from '@mui/material';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Add as AddIcon,
    Assignment as AssignmentIcon,
    Email as EmailIcon,
    FitnessCenter as FitnessCenterIcon,
    Restaurant as RestaurantIcon,
    Check as CheckIcon,
    Close as CloseIcon,
    CalendarMonth as CalendarMonthIcon,
    Info as InfoIcon,
    MoreVert as MoreVertIcon,
    Delete as DeleteIcon,
    SquareFoot as SquareFootIcon,
    Event as EventIcon,
    Description as DescriptionIcon,
    Scale as ScaleIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, where, orderBy, getDocs, addDoc, updateDoc, doc, Timestamp, serverTimestamp, deleteDoc, limit, getDoc } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, getDay, isSameMonth, isToday, isSameDay, parseISO, isWithinInterval, differenceInDays } from 'date-fns';
import { es } from 'date-fns/locale';
import FormResponses from '../tools/FormTool/FormResponses';

// Colores para diferentes tipos de actividades
const activityColors = {
    formulario: '#8E24AA', // Morado
    mensaje: '#1E88E5',    // Azul
    rutina: '#43A047',     // Verde
    dieta: '#FB8C00',       // Naranja
    pesaje: '#FF4081'       // Rosa
};

// Iconos para diferentes tipos de actividades
const activityIcons = {
    formulario: <AssignmentIcon />,
    mensaje: <EmailIcon />,
    rutina: <FitnessCenterIcon />,
    dieta: <RestaurantIcon />,
    pesaje: <ScaleIcon />
};

// Animación para el banner de "en construcción"
const pulseAnimation = keyframes`
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`;

const AgendaView = ({ clientId }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [newActivity, setNewActivity] = useState({
        tipo: 'mensaje',
        titulo: '',
        descripcion: '',
        fecha: '',
        completado: false,
        fechaFin: '',
        rutina_id: '',
        dieta_id: '',
        formulario_id: ''
    });
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [activityToDelete, setActivityToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [rutinas, setRutinas] = useState([]);
    const [dietas, setDietas] = useState([]);
    const [formularios, setFormularios] = useState([]);
    const [openFormResponsesDialog, setOpenFormResponsesDialog] = useState(false);
    const [selectedFormActivity, setSelectedFormActivity] = useState(null);
    const [openRoutineProgressDialog, setOpenRoutineProgressDialog] = useState(false);
    const [selectedRoutineActivity, setSelectedRoutineActivity] = useState(null);
    const [openDietProgressDialog, setOpenDietProgressDialog] = useState(false);
    const [selectedDietActivity, setSelectedDietActivity] = useState(null);

    // Cargar actividades del cliente desde Firestore
    useEffect(() => {
        const fetchActivities = async () => {
            setLoading(true);
            try {
                let activitiesRef;
                
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    activitiesRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'actividades');
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    activitiesRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'actividades');
                }

                const activityQuery = query(
                    activitiesRef,
                    orderBy('fecha', 'desc')
                );

                const activitySnapshot = await getDocs(activityQuery);
                const activityList = activitySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    fecha: doc.data().fecha?.toDate() || new Date(),
                    fechaFin: doc.data().fechaFin?.toDate ? doc.data().fechaFin.toDate() : doc.data().fechaFin ? new Date(doc.data().fechaFin) : null
                }));

                // Para cada actividad de tipo rutina o dieta, verificar días completados
                const activityListWithCompletionStatus = await Promise.all(
                    activityList.map(async (activity) => {
                        if (activity.tipo === 'rutina') {
                            // Obtener detalles de la rutina para saber cuántos días y ejercicios tiene
                            const rutinaRef = doc(db, 'routines', activity.rutina_id);
                            const rutinaSnap = await getDoc(rutinaRef);
                            const rutinaData = rutinaSnap.data();
                            const totalDays = rutinaData?.days?.length || 0;
                            
                            // Consultar historial de ejercicios para esta rutina
                            const historialRef = collection(db, 'historial_ejercicios');
                            const historialQuery = query(
                                historialRef,
                                where('clientId', '==', clientId),
                                where('routineId', '==', activity.rutina_id)
                            );
                            
                            const historialSnapshot = await getDocs(historialQuery);
                            
                            // Crear objeto para rastrear días completados
                            const diasCompletados = {};
                            
                            // Agrupar registros por día
                            const registrosPorDia = {};
                            historialSnapshot.forEach(doc => {
                                const data = doc.data();
                                if (data.dayIndex !== undefined) {
                                    if (!registrosPorDia[data.dayIndex]) {
                                        registrosPorDia[data.dayIndex] = [];
                                    }
                                    registrosPorDia[data.dayIndex].push(data);
                                }
                            });
                            
                            // Evaluar cada día según nuestras condiciones
                            for (let dayIndex = 0; dayIndex < totalDays; dayIndex++) {
                                const registrosDia = registrosPorDia[dayIndex] || [];
                                
                                // Condición 1: Día marcado explícitamente como completado
                                const diaCompletadoExplicitamente = registrosDia.some(
                                    registro => registro.workoutCompleted === true
                                );
                                
                                // Condición 2: Día con suficientes ejercicios completados
                                const totalEjerciciosDia = rutinaData?.days?.[dayIndex]?.exercises?.length || 0;
                                const ejerciciosCompletados = registrosDia.length;
                                const diaCompletadoParcialmente = totalEjerciciosDia > 0 && 
                                                                 ejerciciosCompletados >= (totalEjerciciosDia * 0.75);
                                
                                // Si cumple alguna condición, marcar como completado
                                if (diaCompletadoExplicitamente || diaCompletadoParcialmente) {
                                    // Encontrar el registro más reciente para este día
                                    const registrosMasRecientes = [...registrosDia].sort(
                                        (a, b) => new Date(b.hora) - new Date(a.hora)
                                    );
                                    
                                    const ultimoRegistro = registrosMasRecientes[0];
                                    
                                    diasCompletados[dayIndex] = {
                                        completado: true,
                                        completado_tipo: diaCompletadoExplicitamente ? 'completo' : 'parcial',
                                        fecha_completado: ultimoRegistro.hora,
                                        feedback: ultimoRegistro.feedback || '',
                                        ejercicios_completados: ejerciciosCompletados,
                                        total_ejercicios: totalEjerciciosDia,
                                        porcentaje_completado: Math.round((ejerciciosCompletados / totalEjerciciosDia) * 100)
                                    };
                                }
                            }
                            
                            // Calcular estadísticas generales de la rutina
                            const diasCompletadosCount = Object.keys(diasCompletados).length;
                            const porcentajeRutinaCompletada = totalDays > 0 
                                ? Math.round((diasCompletadosCount / totalDays) * 100) 
                                : 0;
                            
                            return {
                                ...activity,
                                dias_completados: diasCompletados,
                                tiene_dias_completados: diasCompletadosCount > 0,
                                estadisticas_rutina: {
                                    total_dias: totalDays,
                                    dias_completados: diasCompletadosCount,
                                    porcentaje_completado: porcentajeRutinaCompletada
                                }
                            };
                        } else if (activity.tipo === 'dieta') {
                            // Obtener detalles de la dieta para saber las comidas programadas
                            const dietaRef = doc(db, 'diets', activity.dieta_id);
                            const dietaSnap = await getDoc(dietaRef);
                            const dietaData = dietaSnap.data();
                            
                            // Calcular el rango de días desde el inicio hasta el fin de la dieta
                            const startDate = activity.fecha;
                            const endDate = activity.fechaFin || activity.fecha;
                            const days = eachDayOfInterval({ start: startDate, end: endDate });
                            
                            // Crear objeto para rastrear días completados
                            const diasCompletados = {};
                            
                            // Para cada día en el rango
                            for (let i = 0; i < days.length; i++) {
                                const day = days[i];
                                const fecha = format(day, 'yyyy-MM-dd');
                                const docId = `${clientId}_${fecha}`;
                                
                                try {
                                    const historialRef = doc(db, 'historial_comidas', docId);
                                    const historialSnap = await getDoc(historialRef);
                                    
                                    if (historialSnap.exists()) {
                                        const historialData = historialSnap.data();
                                        const meals = historialData.meals || [];
                                        
                                        // Contar comidas registradas y completadas
                                        const totalMealsRegistered = meals.length;
                                        const completedMeals = meals.filter(meal => meal.completed || meal.photo_url).length;
                                        
                                        // Calcular el total de comidas esperadas por día (desde la dieta)
                                        const expectedMeals = dietaData?.meals_per_day || 5; // Default a 5 si no está especificado
                                        
                                        // Calcular porcentaje de completado
                                        const porcentajeCompletado = Math.round((completedMeals / expectedMeals) * 100);
                                        
                                        // Guardar el día en el objeto diasCompletados siempre que haya al menos una comida registrada
                                        if (totalMealsRegistered > 0) {
                                            const dayIndex = i; // Usamos el índice directamente
                                            
                                            // Determinar tipo de completado basado en el porcentaje
                                            let completadoTipo = 'pendiente';
                                            if (completedMeals >= expectedMeals) {
                                                completadoTipo = 'completo';
                                            } else if (completedMeals >= (expectedMeals * 0.75)) {
                                                completadoTipo = 'parcial';
                                            } else if (completedMeals > 0) {
                                                completadoTipo = 'iniciado';
                                            }
                                            
                                            diasCompletados[dayIndex] = {
                                                completado: completedMeals > 0,  // Marcar como completado si hay al menos una comida completada
                                                completado_tipo: completadoTipo,
                                                fecha_completado: historialData.metadata?.last_updated || historialData.metadata?.created_at,
                                                comidas_completadas: completedMeals,
                                                total_comidas: expectedMeals,
                                                porcentaje_completado: porcentajeCompletado,
                                                comidas: meals.map(meal => ({
                                                    tipo: meal.type,
                                                    nombre: meal.name,
                                                    descripcion: meal.description,
                                                    notas: meal.notes,
                                                    hora: meal.time,
                                                    tiene_foto: !!meal.photo_url
                                                }))
                                            };
                                        }
                                    } else {
                                        // Reemplazar línea con console.log por un comentario
                                        // console.log('❌ No hay registro para día:', fecha, docId);
                                    }
                                } catch (error) {
                                    console.error('Error al verificar día de dieta:', error);
                                }
                            }
                            
                            // Calcular estadísticas generales de la dieta
                            const totalDays = days.length;
                            const diasCompletadosCount = Object.keys(diasCompletados).length;
                            const porcentajeDietaCompletada = totalDays > 0 
                                ? Math.round((diasCompletadosCount / totalDays) * 100) 
                                : 0;
                            
                            // Reemplazar bloque de console.log por un comentario
                            // console.log('🔍 Estadísticas finales dieta:', {
                            //     total_dias: totalDays,
                            //     dias_completados: diasCompletadosCount,
                            //     porcentaje_completado: porcentajeDietaCompletada,
                            //     dias_completados_obj: diasCompletados
                            // });
                            
                            return {
                                ...activity,
                                dias_completados: diasCompletados,
                                tiene_dias_completados: diasCompletadosCount > 0,
                                estadisticas_dieta: {
                                    total_dias: totalDays,
                                    dias_completados: diasCompletadosCount,
                                    porcentaje_completado: porcentajeDietaCompletada
                                }
                            };
                        }
                        return activity;
                    })
                );

                setActivities(activityListWithCompletionStatus);
                
                // Cargar rutinas y dietas
                await fetchRutinasYDietas();
                // Cargar formularios
                await fetchFormularios();
            } catch (err) {
                console.error('Error al cargar actividades:', err);
                setError('No se pudieron cargar las actividades. Por favor, inténtalo de nuevo.');
            } finally {
                setLoading(false);
            }
        };

        if (clientId && userDetails) {
            fetchActivities();
        }
    }, [clientId, userDetails, db]);

    // Añadimos una función para cargar las rutinas y dietas existentes
    const fetchRutinasYDietas = async () => {
        try {
            // Obtener rutinas existentes siguiendo el mismo patrón que en RoutineView
            const routinesRef = collection(db, 'routines');
            const rutinasQuery = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId)
            );
            const rutinasSnapshot = await getDocs(rutinasQuery);
            const rutinasData = rutinasSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setRutinas(rutinasData);
            // Reemplazar línea con console.log por un comentario
            // console.log('Rutinas cargadas:', rutinasData);

            // Obtener dietas existentes siguiendo el mismo patrón que en DietView
            const dietsRef = collection(db, 'diets');
            const dietasQuery = query(
                dietsRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc')
            );
            const dietasSnapshot = await getDocs(dietasQuery);
            const dietasData = dietasSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setDietas(dietasData);
            // Reemplazar línea con console.log por un comentario
            // console.log('Dietas cargadas:', dietasData);
        } catch (error) {
            console.error("Error al cargar rutinas y dietas:", error);
            setError("Error al cargar rutinas y dietas");
        }
    };

    // Añadimos una función para cargar los formularios existentes
    const fetchFormularios = async () => {
        try {
            // Determinar la referencia correcta a la colección de formularios
            let formulariosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                formulariosRef = collection(db, 'userDetails', userDetails.uid, 'form_templates');
            } else {
                formulariosRef = collection(db, 'Centros', userDetails.IDBO, 'form_templates');
            }

            const formulariosQuery = query(
                formulariosRef,
                orderBy('createdAt', 'desc')
            );

            const formulariosSnapshot = await getDocs(formulariosQuery);
            const formulariosData = formulariosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setFormularios(formulariosData);
            // Reemplazar línea con console.log por un comentario
            // console.log('Formularios cargados:', formulariosData);
        } catch (error) {
            console.error("Error al cargar formularios:", error);
            setError("Error al cargar formularios");
        }
    };

    // Cambiar al mes anterior
    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    // Cambiar al mes siguiente
    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    // Cambiar al mes actual
    const goToToday = () => {
        setCurrentMonth(new Date());
    };

    // Modificar la función handleAddActivity para seleccionar la dieta más reciente por defecto
    const handleAddActivity = (date) => {
        setSelectedActivity(null);
        
        // Preparar el objeto de nueva actividad
        const newActivityObj = {
            tipo: 'mensaje',
            titulo: '',
            descripcion: '',
            fecha: format(date, 'yyyy-MM-dd'),
            completado: false,
            fechaFin: '',
            rutina_id: '',
            dieta_id: '',
            formulario_id: ''
        };
        
        setNewActivity(newActivityObj);
        setOpenDialog(true);
    };

    // Añadir una función para manejar el cambio de tipo de actividad
    const handleTipoChange = (e) => {
        const nuevoTipo = e.target.value;
        
        // Actualizar el tipo de actividad
        const updatedActivity = {
            ...newActivity,
            tipo: nuevoTipo
        };
        
        // Si el nuevo tipo es 'dieta' y hay dietas disponibles, seleccionar la más reciente
        if (nuevoTipo === 'dieta' && dietas.length > 0) {
            // Las dietas ya vienen ordenadas por createdAt desc, así que la primera es la más reciente
            const dietaMasReciente = dietas[0];
            updatedActivity.dieta_id = dietaMasReciente.id;
            updatedActivity.titulo = `Plan de Alimentación`;
        } else if (nuevoTipo === 'rutina' && rutinas.length > 0) {
            // Si el nuevo tipo es 'rutina' y hay rutinas disponibles, seleccionar la más reciente
            const rutinaMasReciente = rutinas[0];
            updatedActivity.rutina_id = rutinaMasReciente.id;
            updatedActivity.titulo = `${rutinaMasReciente.name || 'Sin nombre'}`;
        } else if (nuevoTipo === 'formulario' && formularios.length > 0) {
            // Si el nuevo tipo es 'formulario' y hay formularios disponibles, seleccionar el más reciente
            const formularioMasReciente = formularios[0];
            updatedActivity.formulario_id = formularioMasReciente.id;
            updatedActivity.titulo = `Formulario: ${formularioMasReciente.name || formularioMasReciente.title || 'Sin nombre'}`;
        } else {
            // Para otros tipos, reiniciar el título
            updatedActivity.titulo = '';
        }
        
        setNewActivity(updatedActivity);
    };

    // Cerrar diálogo
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedActivity(null);
    };

    // Modificamos handleSubmitActivity para manejar eventos de varios días
    const handleSubmitActivity = async () => {
        try {
            if (!newActivity.titulo.trim()) {
                setSnackbar({
                    open: true,
                    message: 'El título no puede estar vacío',
                    severity: 'error'
                });
                return;
            }

            const fechaActividad = newActivity.fecha ? new Date(newActivity.fecha) : new Date();
            
            const activityData = {
                tipo: newActivity.tipo,
                titulo: newActivity.titulo,
                descripcion: newActivity.descripcion,
                fecha: fechaActividad,
                completado: false,
                createdAt: serverTimestamp(),
                trainer_id: userDetails.IDPT
            };

            // Si es una rutina o dieta, añadimos los campos adicionales
            if (newActivity.tipo === 'rutina') {
                activityData.rutina_id = newActivity.rutina_id;
                if (newActivity.fechaFin) {
                    activityData.fechaFin = new Date(newActivity.fechaFin);
                }
                
                // Buscar la rutina seleccionada para obtener más detalles
                const selectedRutina = rutinas.find(r => r.id === newActivity.rutina_id);
                if (selectedRutina) {
                    activityData.rutina_details = {
                        name: selectedRutina.name,
                        days: selectedRutina.days?.length || 0
                    };
                    
                    // Crear un mapa de días de la semana configurados
                    const dayWeekMapping = {};
                    const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
                    
                    // Inicializar todos los días como falsos
                    diasSemana.forEach(day => {
                        dayWeekMapping[day] = false;
                    });
                    
                    // Marcar como verdaderos los días configurados en la rutina
                    if (selectedRutina.days && selectedRutina.days.length > 0) {
                        selectedRutina.days.forEach(day => {
                            if (day.weekDays && day.weekDays.length > 0) {
                                day.weekDays.forEach(weekDay => {
                                    dayWeekMapping[weekDay] = true;
                                });
                            }
                        });
                    }
                    
                    // Guardar el mapa de días en la actividad
                    activityData.dayWeekMapping = dayWeekMapping;
                    
                    // Verificar si hay al menos un día configurado
                    const hasDaysConfigured = Object.values(dayWeekMapping).some(value => value === true);
                    
                    // Si no hay días configurados, mostrar todos los días (configuración por defecto)
                    if (!hasDaysConfigured) {
                        diasSemana.forEach(day => {
                            dayWeekMapping[day] = true;
                        });
                    }
                }
            } else if (newActivity.tipo === 'dieta') {
                activityData.dieta_id = newActivity.dieta_id;
                if (newActivity.fechaFin) {
                    activityData.fechaFin = new Date(newActivity.fechaFin);
                }
                
                // Para las dietas, simplemente guardamos el ID y un título genérico
                activityData.dieta_details = {
                    nombre: "Plan de Alimentación"
                };
                
                // Asegurarnos de que el título sea "Plan de Alimentación"
                activityData.titulo = "Plan de Alimentación";
            } else if (newActivity.tipo === 'formulario') {
                activityData.formulario_id = newActivity.formulario_id;
                if (newActivity.fechaFin) {
                    activityData.fechaFin = new Date(newActivity.fechaFin);
                }
                
                // Buscar el formulario seleccionado para obtener más detalles
                const selectedFormulario = formularios.find(f => f.id === newActivity.formulario_id);
                if (selectedFormulario) {
                    activityData.formulario_details = {
                        name: selectedFormulario.name || selectedFormulario.title,
                        questions: selectedFormulario.questions?.length || 0
                    };
                }
            } else if (newActivity.tipo === 'pesaje') {
                // Para actividades de tipo pesaje/medición, añadir detalles específicos
                activityData.measurement_details = {
                    programmed: true,
                    notes: newActivity.descripcion,
                    data: {}  // Este objeto estará vacío hasta que el cliente lo complete
                };
            } else if (newActivity.tipo === 'mensaje') {
                // Para actividades de tipo mensaje/recordatorio, añadir detalles específicos
                activityData.reminder_details = {
                    priority: newActivity.descripcion ? 'high' : 'normal',
                    read: false
                };
            }

            // Determinar la referencia correcta a la colección de actividades
            let activitiesRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                activitiesRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'actividades');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('ID del Centro (IDBO) no disponible');
                }
                activitiesRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'actividades');
            } else {
                // Fallback a la ruta que estábamos usando antes
                activitiesRef = collection(db, `clients/${clientId}/activities`);
            }

            let activityId;
            if (selectedActivity) {
                // Actualizar actividad existente
                const activityRef = doc(db, activitiesRef.path, selectedActivity.id);
                await updateDoc(activityRef, activityData);
                activityId = selectedActivity.id;
            } else {
                // Crear nueva actividad
                const docRef = await addDoc(activitiesRef, activityData);
                activityId = docRef.id;
            }

            // Si es un formulario, crear o actualizar el documento en la colección forms
            if (newActivity.tipo === 'formulario') {
                const selectedFormulario = formularios.find(f => f.id === newActivity.formulario_id);
                if (selectedFormulario) {
                    const formsRef = collection(db, 'forms');
                    
                    // Crear un nuevo documento en forms con la información del formulario
                    await addDoc(formsRef, {
                        activityId: activityId,
                        formId: selectedFormulario.id,
                        title: selectedFormulario.title || selectedFormulario.name,
                        description: selectedFormulario.description,
                        questions: selectedFormulario.questions,
                        frequency: selectedFormulario.frequency || 'once',
                        selectedDay: selectedFormulario.selectedDay,
                        clientId: clientId,
                        profesionalId: userDetails.uid,
                        profesionalType: userDetails.tipo_profesional,
                        centroId: userDetails.IDBO || null,
                        status: 'active',
                        createdAt: serverTimestamp(),
                        scheduledDate: fechaActividad,
                        responses: [],
                        completado: false
                    });
                }
            } else if (newActivity.tipo === 'pesaje') {
                // Crear entrada en la colección measurements
                const measurementsRef = collection(db, 'measurements');
                
                await addDoc(measurementsRef, {
                    activityId: activityId,
                    clientId: clientId,
                    profesionalId: userDetails.uid,
                    profesionalType: userDetails.tipo_profesional,
                    centroId: userDetails.IDBO || null,
                    title: newActivity.titulo,
                    description: newActivity.descripcion,
                    status: 'pending',
                    createdAt: serverTimestamp(),
                    scheduledDate: fechaActividad,
                    data: {},  // Se llenará cuando el cliente introduzca las medidas
                    lastUpdated: null,
                    completado: false
                });
            } else if (newActivity.tipo === 'mensaje') {
                // Crear entrada en la colección reminders
                const remindersRef = collection(db, 'reminders');
                
                await addDoc(remindersRef, {
                    activityId: activityId,
                    clientId: clientId,
                    profesionalId: userDetails.uid,
                    profesionalType: userDetails.tipo_profesional,
                    centroId: userDetails.IDBO || null,
                    title: newActivity.titulo,
                    description: newActivity.descripcion,
                    status: 'active',
                    createdAt: serverTimestamp(),
                    scheduledDate: fechaActividad,
                    priority: newActivity.descripcion ? 'high' : 'normal',
                    read: false,
                    completado: false
                });
            }

            // Recargar actividades
            const activityQuery = query(
                activitiesRef,
                orderBy('fecha', 'asc')
            );
            const querySnapshot = await getDocs(activityQuery);
            const actividadesData = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    fecha: data.fecha.toDate ? data.fecha.toDate() : new Date(data.fecha),
                    fechaFin: data.fechaFin && (data.fechaFin.toDate ? data.fechaFin.toDate() : new Date(data.fechaFin))
                };
            });

            setActivities(actividadesData);
            setOpenDialog(false);
            setNewActivity({
                tipo: 'mensaje',
                titulo: '',
                descripcion: '',
                fecha: '',
                completado: false,
                fechaFin: '',
                rutina_id: '',
                dieta_id: '',
                formulario_id: ''
            });
            setSelectedActivity(null);
            setSnackbar({
                open: true,
                message: selectedActivity ? 'Actividad actualizada' : 'Actividad añadida',
                severity: 'success'
            });
        } catch (error) {
            console.error("Error submitting activity:", error);
            setSnackbar({
                open: true,
                message: 'Error al guardar la actividad',
                severity: 'error'
            });
        }
    };

    // Ver detalles de una actividad
    const handleEditActivity = (activity) => {
        setSelectedActivity(activity);
        setNewActivity({
            tipo: activity.tipo,
            titulo: activity.titulo,
            descripcion: activity.descripcion,
            fecha: format(activity.fecha, 'yyyy-MM-dd'),
            completado: activity.completado,
            fechaFin: activity.fechaFin ? format(activity.fechaFin, 'yyyy-MM-dd') : '',
            rutina_id: activity.rutina_id || '',
            dieta_id: activity.dieta_id || '',
            formulario_id: activity.formulario_id || ''
        });
        setOpenDialog(true);
    };

    // Marcar actividad como completada
    const handleToggleComplete = async (activity, event) => {
        event.stopPropagation();
        try {
            let activityDocRef;
            
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                activityDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'actividades', activity.id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                activityDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'actividades', activity.id);
            }

            const newStatus = !activity.completado;
            await updateDoc(activityDocRef, {
                completado: newStatus,
                ultima_actualizacion: serverTimestamp()
            });

            // Si es un formulario, actualizar también su estado en la colección forms
            if (activity.tipo === 'formulario') {
                const formsRef = collection(db, 'forms');
                const q = query(
                    formsRef,
                    where('activityId', '==', activity.id),
                    where('clientId', '==', clientId),
                    limit(1)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const formDoc = querySnapshot.docs[0];
                    await updateDoc(doc(db, 'forms', formDoc.id), {
                        completado: newStatus,
                        status: newStatus ? 'completed' : 'active',
                        lastUpdated: serverTimestamp()
                    });
                }
            } else if (activity.tipo === 'pesaje') {
                // Actualizar el estado en la colección measurements
                const measurementsRef = collection(db, 'measurements');
                const q = query(
                    measurementsRef,
                    where('activityId', '==', activity.id),
                    where('clientId', '==', clientId),
                    limit(1)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const measurementDoc = querySnapshot.docs[0];
                    await updateDoc(doc(db, 'measurements', measurementDoc.id), {
                        completado: newStatus,
                        status: newStatus ? 'completed' : 'pending',
                        lastUpdated: serverTimestamp()
                    });
                }
            } else if (activity.tipo === 'mensaje') {
                // Actualizar el estado en la colección reminders
                const remindersRef = collection(db, 'reminders');
                const q = query(
                    remindersRef,
                    where('activityId', '==', activity.id),
                    where('clientId', '==', clientId),
                    limit(1)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const reminderDoc = querySnapshot.docs[0];
                    await updateDoc(doc(db, 'reminders', reminderDoc.id), {
                        completado: newStatus,
                        status: newStatus ? 'completed' : 'active',
                        lastUpdated: serverTimestamp(),
                        read: true
                    });
                }
            }

            // Actualizar la lista de actividades
            setActivities(activities.map(a => 
                a.id === activity.id ? { ...a, completado: newStatus } : a
            ));
        } catch (err) {
            console.error('Error al actualizar estado de actividad:', err);
            setError('No se pudo actualizar el estado de la actividad. Por favor, inténtalo de nuevo.');
        }
    };

    // Función para manejar la eliminación de una actividad
    const handleDeleteActivity = async () => {
        if (!activityToDelete) return;
        
        try {
            let activityDocRef;
            
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                activityDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId, 'actividades', activityToDelete.id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                activityDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId, 'actividades', activityToDelete.id);
            }

            // Eliminar el documento de Firestore
            await deleteDoc(activityDocRef);
            
            // Si es un formulario, marcar como eliminado en la colección forms
            if (activityToDelete.tipo === 'formulario') {
                const formsRef = collection(db, 'forms');
                const q = query(
                    formsRef,
                    where('activityId', '==', activityToDelete.id),
                    where('clientId', '==', clientId),
                    limit(1)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const formDoc = querySnapshot.docs[0];
                    await updateDoc(doc(db, 'forms', formDoc.id), {
                        status: 'deleted',
                        deletedAt: serverTimestamp()
                    });
                }
            } else if (activityToDelete.tipo === 'pesaje') {
                // Marcar como eliminado en la colección measurements
                const measurementsRef = collection(db, 'measurements');
                const q = query(
                    measurementsRef,
                    where('activityId', '==', activityToDelete.id),
                    where('clientId', '==', clientId),
                    limit(1)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const measurementDoc = querySnapshot.docs[0];
                    await updateDoc(doc(db, 'measurements', measurementDoc.id), {
                        status: 'deleted',
                        deletedAt: serverTimestamp()
                    });
                }
            } else if (activityToDelete.tipo === 'mensaje') {
                // Marcar como eliminado en la colección reminders
                const remindersRef = collection(db, 'reminders');
                const q = query(
                    remindersRef,
                    where('activityId', '==', activityToDelete.id),
                    where('clientId', '==', clientId),
                    limit(1)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const reminderDoc = querySnapshot.docs[0];
                    await updateDoc(doc(db, 'reminders', reminderDoc.id), {
                        status: 'deleted',
                        deletedAt: serverTimestamp()
                    });
                }
            }

            // Actualizar la lista de actividades
            setActivities(activities.filter(a => a.id !== activityToDelete.id));
            
            // Cerrar el diálogo y limpiar el estado
            setOpenDeleteDialog(false);
            setActivityToDelete(null);
            
            // Mostrar mensaje de éxito
            setSnackbar({
                open: true,
                message: 'Actividad eliminada correctamente',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error al eliminar actividad:', error);
            setSnackbar({
                open: true,
                message: 'Error al eliminar la actividad',
                severity: 'error'
            });
        }
    };

    // Abrir diálogo de confirmación para eliminar
    const handleOpenDeleteDialog = (activity, event) => {
        if (event) {
            event.stopPropagation();
        }
        setActivityToDelete(activity);
        setOpenDeleteDialog(true);
    };

    // Cerrar diálogo de confirmación
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setActivityToDelete(null);
    };

    // Cerrar snackbar
    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // Función para abrir el diálogo de respuestas de formulario
    const handleOpenFormResponses = (activity, event) => {
        event.stopPropagation();
        setSelectedFormActivity(activity);
        setOpenFormResponsesDialog(true);
    };

    // Función para cerrar el diálogo de respuestas de formulario
    const handleCloseFormResponses = () => {
        setOpenFormResponsesDialog(false);
        setSelectedFormActivity(null);
    };

    // Función para abrir el diálogo de progreso de rutina
    const handleOpenRoutineProgress = (activity, event) => {
        event.stopPropagation();
        setSelectedRoutineActivity(activity);
        setOpenRoutineProgressDialog(true);
    };

    // Función para abrir el diálogo de progreso de dieta
    const handleOpenDietProgress = (activity, event) => {
        event.stopPropagation();
        setSelectedDietActivity(activity);
        setOpenDietProgressDialog(true);
    };

    // Renderizar el calendario
    const renderCalendar = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(currentMonth);
        const startDate = monthStart;
        const endDate = monthEnd;

        const dateFormat = "d";
        const days = eachDayOfInterval({ start: startDate, end: endDate });
        
        // Cambiamos el orden de los días para empezar por Lunes
        const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
        const dayShort = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

        const getActivitiesForDate = (date) => {
            return activities.filter(activity => {
                // Para actividades de un solo día
                if (!activity.fechaFin) {
                    return isSameDay(activity.fecha, date);
                }
                
                // Para actividades de tipo rutina, verificar si el día de la semana coincide
                if (activity.tipo === 'rutina' && activity.fechaFin) {
                    // Verificar si estamos dentro del intervalo de fechas
                    const isInDateRange = isWithinInterval(date, {
                        start: activity.fecha,
                        end: activity.fechaFin
                    });
                    
                    // Si no estamos en el rango de fechas, no mostrar la actividad
                    if (!isInDateRange) return false;
                    
                    // Buscar qué día de la semana corresponde a esta fecha en concreto
                    const dayOfWeekInSpanish = format(date, 'EEEE', { locale: es });
                    
                    // Capitalizar primera letra para que coincida con weekDays (Lunes, Martes, etc.)
                    const formattedDay = dayOfWeekInSpanish.charAt(0).toUpperCase() + dayOfWeekInSpanish.slice(1);
                    
                    // Si esta actividad tiene weekDays directamente, usarlos para filtrar
                    if (activity.weekDays && activity.weekDays.length > 0) {
                        return activity.weekDays.includes(formattedDay);
                    }
                    
                    // Ver si encontramos información de weekDays en el objeto de rutina asociado
                    // Si no hay weekDays configurados para ningún día, mostrar todos los días
                    if (activity.dayWeekMapping) {
                        // Tenemos un mapa de días configurados
                        return activity.dayWeekMapping[formattedDay] === true;
                    }
                    
                    // Si no tenemos información de días de la semana, mostrar la actividad todos los días
                    return true;
                }
                
                // Para otras actividades que duran varios días (dieta, etc.)
                return isWithinInterval(date, {
                    start: activity.fecha,
                    end: activity.fechaFin
                });
            });
        };

        return (
            <Box sx={{ width: '100%' }}>
                {/* Cabecera del calendario */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={prevMonth}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="h5" component="h2" sx={{ textTransform: 'capitalize' }}>
                        {format(currentMonth, 'MMMM yyyy', { locale: es })}
                    </Typography>
                    <IconButton onClick={nextMonth}>
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
                <Button 
                    variant="outlined" 
                    startIcon={<CalendarMonthIcon />}
                    onClick={goToToday}
                    sx={{ mb: 2 }}
                >
                    Hoy
                </Button>

                {/* Días de la semana */}
                <Grid container spacing={1} sx={{ mb: 2 }}>
                    {dayShort.map((day, idx) => (
                        <Grid item key={idx} xs={12/7}>
                            <Typography 
                                align="center" 
                                variant="subtitle2" 
                                sx={{ fontWeight: 'bold' }}
                            >
                                {day}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                {/* Días del mes */}
                <Grid container spacing={1}>
                    {days.map((day, idx) => {
                        const dayActivities = getActivitiesForDate(day);
                        const isCurrentDay = isToday(day);
                        const dayOfWeek = getDay(day);
                        const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                        const isWeekend = adjustedDayOfWeek >= 5; // Sábado o domingo
                        const isCurrentMonth = isSameMonth(day, currentMonth);
                        
                        // Si es el primer día del mes, añadir celdas vacías para los días anteriores
                        const emptyCells = [];
                        if (idx === 0) {
                            for (let i = 0; i < adjustedDayOfWeek; i++) {
                                emptyCells.push(
                                    <Grid item key={`empty-${i}`} xs={12/7}>
                                        <Paper 
                                            elevation={0} 
                                            sx={{ 
                                                height: 180,
                                                bgcolor: 'background.default',
                                                p: 1
                                            }}
                                        />
                                    </Grid>
                                );
                            }
                        }
                        
                        return (
                            <React.Fragment key={format(day, 'yyyy-MM-dd')}>
                                {idx === 0 && emptyCells}
                                <Grid item xs={12/7}>
                                    <Paper 
                                        elevation={0} 
                                        sx={{ 
                                            height: 'auto',
                                            minHeight: 180,
                                            bgcolor: isWeekend ? 'rgba(0,0,0,0.02)' : 'background.paper',
                                            p: 1,
                                            position: 'relative',
                                            border: '1px solid',
                                            borderColor: isCurrentDay ? 'primary.main' : 'divider',
                                            boxShadow: isCurrentDay ? '0 0 5px rgba(25, 118, 210, 0.5)' : 'none',
                                            borderRadius: 2,
                                            transition: 'all 0.2s',
                                            '&:hover': {
                                                borderColor: 'primary.light',
                                                boxShadow: '0 0 3px rgba(0,0,0,0.1)'
                                            }
                                        }}
                                        onClick={() => handleAddActivity(day)}
                                    >
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center',
                                            mb: 1,
                                            height: 28,
                                            width: 28,
                                            borderRadius: '50%',
                                            bgcolor: isCurrentDay ? 'primary.main' : 'transparent',
                                            color: isCurrentDay ? 'white' : isCurrentMonth ? 'text.primary' : 'text.disabled',
                                            fontWeight: isCurrentDay ? 'bold' : 'normal',
                                            mx: 'auto',
                                            position: 'relative',
                                            top: -4
                                        }}>
                                            <Typography variant="body2">
                                                {format(day, dateFormat)}
                                            </Typography>
                                        </Box>
                                        
                                        <Box sx={{ mt: 1, maxHeight: 130, overflowY: 'auto', '&::-webkit-scrollbar': { width: '6px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '6px' } }}>
                                            {dayActivities.map((activity, index) => (
                                                <Box
                                                    key={activity.id}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        bgcolor: (() => {
                                                            if (activity.tipo === 'rutina') {
                                                                return activity.tiene_dias_completados ? 'success.light' : 'primary.light';
                                                            } else if (activity.tipo === 'dieta') {
                                                                if (activity.tiene_dias_completados) {
                                                                    // Calcular el índice del día actual - ajustado para corregir el desplazamiento
                                                                    const dayDate = new Date(day);
                                                                    // Establecer la hora a medianoche para evitar problemas con zonas horarias
                                                                    dayDate.setHours(0, 0, 0, 0);
                                                                    const activityDate = new Date(activity.fecha);
                                                                    activityDate.setHours(0, 0, 0, 0);
                                                                    
                                                                    const dayIndex = Math.round(differenceInDays(dayDate, activityDate));
                                                                    
                                                                    // Comprobar si este día específico tiene registros
                                                                    return activity.dias_completados[dayIndex] ? 'success.main' : '#FB8C00';
                                                                }
                                                                return '#FFA726'; // Naranja para dietas sin progreso
                                                            } else if (activity.tipo === 'formulario') {
                                                                return 'info.light';
                                                            } else if (activity.tipo === 'pesaje') {
                                                                return '#FFE4EC';
                                                            } else {
                                                                return 'grey.200';
                                                            }
                                                        })(),
                                                        p: 0.7,
                                                        mb: 0.7,
                                                        borderRadius: 1.5,
                                                        cursor: 'pointer',
                                                        border: '1px solid',
                                                        borderColor: (() => {
                                                            if (activity.tipo === 'rutina') {
                                                                return activity.tiene_dias_completados ? 'success.main' : 'primary.main';
                                                            } else if (activity.tipo === 'dieta') {
                                                                if (activity.tiene_dias_completados) {
                                                                    // Calcular el índice del día actual - ajustado
                                                                    const dayDate = new Date(day);
                                                                    dayDate.setHours(0, 0, 0, 0);
                                                                    const activityDate = new Date(activity.fecha);
                                                                    activityDate.setHours(0, 0, 0, 0);
                                                                    
                                                                    const dayIndex = Math.round(differenceInDays(dayDate, activityDate));
                                                                    
                                                                    // Verificar si este día específico tiene registros
                                                                    if (activity.dias_completados[dayIndex]) {
                                                                        return 'success.main';
                                                                    }
                                                                }
                                                                return '#FB8C00'; // Naranja para dietas sin progreso
                                                            } else if (activity.tipo === 'formulario') {
                                                                return 'info.main';
                                                            } else if (activity.tipo === 'pesaje') {
                                                                return '#FF4081';
                                                            } else {
                                                                return 'grey.400';
                                                            }
                                                        })(),
                                                        '&:hover': {
                                                            boxShadow: '0 0 3px rgba(0,0,0,0.2)',
                                                            transform: 'translateY(-1px)',
                                                            transition: 'all 0.2s'
                                                        },
                                                        textDecoration: activity.completado ? 'line-through' : 'none',
                                                        opacity: activity.completado ? 0.7 : 1
                                                    }}
                                                    onClick={(event) => {
                                                        if (activity.tipo === 'formulario' && activity.completado) {
                                                            handleOpenFormResponses(activity, event);
                                                        } else if (activity.tipo === 'rutina' && activity.tiene_dias_completados) {
                                                            handleOpenRoutineProgress(activity, event);
                                                        } else if (activity.tipo === 'dieta' && activity.tiene_dias_completados) {
                                                            handleOpenDietProgress(activity, event);
                                                        } else if (activity.tipo === 'rutina' || activity.tipo === 'dieta') {
                                                            handleOpenDeleteDialog(activity, event);
                                                        } else {
                                                            handleEditActivity(activity);
                                                        }
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
                                                        <Box sx={{ mr: 0.5, display: 'flex', alignItems: 'center' }}>
                                                            {activity.tipo === 'mensaje' && <EmailIcon fontSize="small" />}
                                                            {activity.tipo === 'rutina' && (
                                                                <Tooltip title={
                                                                    activity.tiene_dias_completados 
                                                                        ? `Progreso: ${activity.estadisticas_rutina.porcentaje_completado}% completado (${activity.estadisticas_rutina.dias_completados}/${activity.estadisticas_rutina.total_dias} días)` 
                                                                        : "Rutina pendiente"
                                                                }>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                                        <FitnessCenterIcon 
                                                                            fontSize="small" 
                                                                            sx={{ 
                                                                                color: activity.tiene_dias_completados ? 'success.main' : 'inherit',
                                                                                mr: 0.5 
                                                                            }}
                                                                        />
                                                                        {activity.tiene_dias_completados && (
                                                                            <Box sx={{ position: 'absolute', top: -8, right: -8 }}>
                                                                                <CircularProgress
                                                                                    variant="determinate"
                                                                                    value={activity.estadisticas_rutina.porcentaje_completado}
                                                                                    size={16}
                                                                                    thickness={8}
                                                                                    sx={{ 
                                                                                        color: 'success.main',
                                                                                        bgcolor: 'background.paper',
                                                                                        borderRadius: '50%',
                                                                                        border: '1px solid white'
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                </Tooltip>
                                                            )}
                                                            {activity.tipo === 'dieta' && (
                                                                <Tooltip title={(() => {
                                                                    if (activity.tiene_dias_completados) {
                                                                        // Calcular el índice del día actual - ajustado
                                                                        const dayDate = new Date(day);
                                                                        dayDate.setHours(0, 0, 0, 0);
                                                                        const activityDate = new Date(activity.fecha);
                                                                        activityDate.setHours(0, 0, 0, 0);
                                                                        
                                                                        const dayIndex = Math.round(differenceInDays(dayDate, activityDate));
                                                                        
                                                                        // Si hay datos para este día, mostrar su información específica
                                                                        if (activity.dias_completados[dayIndex]) {
                                                                            const datosDelDia = activity.dias_completados[dayIndex];
                                                                            return `${datosDelDia.comidas_completadas} de ${datosDelDia.total_comidas} comidas registradas (${datosDelDia.porcentaje_completado}%)`;
                                                                        }
                                                                    }
                                                                    // Si no hay datos para este día o la actividad no tiene días completados
                                                                    return activity.tiene_dias_completados
                                                                        ? `Progreso general: ${activity.estadisticas_dieta.porcentaje_completado}% completado`
                                                                        : "Plan de alimentación pendiente";
                                                                })()}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                                        <RestaurantIcon 
                                                                            fontSize="small" 
                                                                            sx={{ 
                                                                                color: (() => {
                                                                                    if (activity.tiene_dias_completados) {
                                                                                        const dayDate = new Date(day);
                                                                                        dayDate.setHours(0, 0, 0, 0);
                                                                                        const activityDate = new Date(activity.fecha);
                                                                                        activityDate.setHours(0, 0, 0, 0);
                                                                                        
                                                                                        const dayIndex = Math.round(differenceInDays(dayDate, activityDate));
                                                                                        return activity.dias_completados[dayIndex] ? 'success.main' : 'inherit';
                                                                                    }
                                                                                    return 'inherit';
                                                                                })(),
                                                                                mr: 0.5 
                                                                            }}
                                                                        />
                                                                        {activity.tiene_dias_completados && (() => {
                                                                            // Calcular el índice del día actual - ajustado
                                                                            const dayDate = new Date(day);
                                                                            dayDate.setHours(0, 0, 0, 0);
                                                                            const activityDate = new Date(activity.fecha);
                                                                            activityDate.setHours(0, 0, 0, 0);
                                                                            
                                                                            const dayIndex = Math.round(differenceInDays(dayDate, activityDate));
                                                                            
                                                                            // Verificar si este día específico tiene registros
                                                                            if (activity.dias_completados[dayIndex]) {
                                                                                return (
                                                                                    <Box sx={{ position: 'absolute', top: -8, right: -8 }}>
                                                                                        <CircularProgress
                                                                                            variant="determinate"
                                                                                            value={activity.dias_completados[dayIndex].porcentaje_completado}
                                                                                            size={16}
                                                                                            thickness={8}
                                                                                            sx={{ 
                                                                                                color: 'success.main',
                                                                                                bgcolor: 'background.paper',
                                                                                                borderRadius: '50%',
                                                                                                border: '1px solid white'
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })()}
                                                                    </Box>
                                                                </Tooltip>
                                                            )}
                                                            {activity.tipo === 'formulario' && (
                                                                <Tooltip title={activity.completado ? "Ver respuestas" : "Formulario pendiente"}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <DescriptionIcon 
                                                                            fontSize="small" 
                                                                            sx={{ 
                                                                                color: activity.completado ? 'success.main' : 'inherit',
                                                                                mr: 0.5 
                                                                            }}
                                                                        />
                                                                        {activity.completado && (
                                                                            <CheckIcon 
                                                                                sx={{ 
                                                                                    fontSize: '12px',
                                                                                    color: 'success.main',
                                                                                    position: 'absolute',
                                                                                    top: -4,
                                                                                    right: -4,
                                                                                    bgcolor: 'white',
                                                                                    borderRadius: '50%'
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Tooltip>
                                                            )}
                                                            {activity.tipo === 'pesaje' && <ScaleIcon fontSize="small" />}
                                                            {activity.tipo === 'otro' && <AssignmentIcon fontSize="small" />}
                                                        </Box>
                                                        <Tooltip title={
                                                            activity.tipo === 'formulario' && activity.completado
                                                                ? `${activity.titulo} - Click para ver respuestas`
                                                                : activity.tipo === 'rutina' && activity.tiene_dias_completados
                                                                ? `${activity.titulo} - Click para ver progreso`
                                                                : activity.titulo
                                                        } arrow>
                                                            <Typography 
                                                                variant="caption" 
                                                                component="div" 
                                                                noWrap 
                                                                sx={{ 
                                                                    flexGrow: 1,
                                                                    fontWeight: 'medium',
                                                                    color: activity.completado ? 'text.disabled' : 'text.primary'
                                                                }}
                                                            >
                                                                {activity.titulo}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </Box>
        );
    };

    // Diálogo para añadir/editar actividad
    const renderActivityDialog = () => (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
            <DialogTitle>
                {selectedActivity ? 'Editar Actividad' : 'Nueva Actividad'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Tipo de Actividad</InputLabel>
                            <Select
                                value={newActivity.tipo}
                                label="Tipo de Actividad"
                                onChange={handleTipoChange}
                            >
                                <MenuItem value="mensaje">Mensaje/Recordatorio</MenuItem>
                                <MenuItem value="rutina">Rutina de Ejercicios</MenuItem>
                                <MenuItem value="dieta">Plan de Alimentación</MenuItem>
                                <MenuItem value="formulario">Formulario</MenuItem>
                                <MenuItem value="pesaje">Pesaje/Medición</MenuItem>
                                <MenuItem value="otro">Otro</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {newActivity.tipo === 'rutina' ? (
                            <FormControl fullWidth>
                                <InputLabel>Seleccionar Rutina</InputLabel>
                                <Select
                                    value={newActivity.rutina_id}
                                    label="Seleccionar Rutina"
                                    onChange={(e) => {
                                        const selectedRutina = rutinas.find(r => r.id === e.target.value);
                                        setNewActivity({
                                            ...newActivity, 
                                            rutina_id: e.target.value,
                                            titulo: selectedRutina ? `${selectedRutina.name || 'Sin nombre'}` : 'Nueva Rutina'
                                        });
                                    }}
                                >
                                    {rutinas.map(rutina => (
                                        <MenuItem key={rutina.id} value={rutina.id}>
                                            {rutina.name || 'Rutina sin nombre'} 
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : newActivity.tipo === 'dieta' ? (
                            <FormControl fullWidth>
                                <InputLabel>Seleccionar Plan de Alimentación</InputLabel>
                                <Select
                                    value={newActivity.dieta_id}
                                    label="Seleccionar Plan de Alimentación"
                                    onChange={(e) => {
                                        const selectedDieta = dietas.find(d => d.id === e.target.value);
                                        setNewActivity({
                                            ...newActivity, 
                                            dieta_id: e.target.value,
                                            titulo: `Plan de Alimentación`
                                        });
                                    }}
                                >
                                    {dietas.map((dieta, index) => {
                                        let fechaFormateada;
                                        try {
                                            const fecha = dieta.createdAt instanceof Timestamp 
                                                ? dieta.createdAt.toDate() 
                                                : dieta.createdAt 
                                                    ? new Date(dieta.createdAt) 
                                                    : new Date();
                                            
                                            fechaFormateada = format(fecha, 'dd/MM/yyyy');
                                        } catch (error) {
                                            console.error('Error al formatear fecha:', error);
                                            fechaFormateada = 'Fecha no disponible';
                                        }
                                        
                                        return (
                                            <MenuItem key={dieta.id} value={dieta.id}>
                                                Plan de Alimentación {index + 1} ({fechaFormateada})
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        ) : newActivity.tipo === 'formulario' ? (
                            <FormControl fullWidth>
                                <InputLabel>Seleccionar Formulario</InputLabel>
                                <Select
                                    value={newActivity.formulario_id}
                                    label="Seleccionar Formulario"
                                    onChange={(e) => {
                                        const selectedFormulario = formularios.find(f => f.id === e.target.value);
                                        setNewActivity({
                                            ...newActivity, 
                                            formulario_id: e.target.value,
                                            titulo: selectedFormulario ? `Formulario: ${selectedFormulario.name || selectedFormulario.title || 'Sin nombre'}` : 'Nuevo Formulario'
                                        });
                                    }}
                                >
                                    {formularios.map((formulario) => (
                                        <MenuItem key={formulario.id} value={formulario.id}>
                                            {formulario.name || formulario.title || 'Formulario sin nombre'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField
                                fullWidth
                                label="Título"
                                value={newActivity.titulo}
                                onChange={(e) => setNewActivity({ ...newActivity, titulo: e.target.value })}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Descripción"
                            multiline
                            rows={4}
                            value={newActivity.descripcion}
                            onChange={(e) => setNewActivity({...newActivity, descripcion: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            type="date"
                            label="Fecha de inicio"
                            InputLabelProps={{ shrink: true }}
                            value={newActivity.fecha}
                            onChange={(e) => setNewActivity({...newActivity, fecha: e.target.value})}
                        />
                    </Grid>
                    {(newActivity.tipo === 'rutina' || newActivity.tipo === 'dieta') && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="date"
                                label="Fecha de finalización"
                                InputLabelProps={{ shrink: true }}
                                value={newActivity.fechaFin}
                                onChange={(e) => setNewActivity({...newActivity, fechaFin: e.target.value})}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
                {selectedActivity && (
                    <Button 
                        onClick={() => {
                            setActivityToDelete(selectedActivity);
                            setOpenDeleteDialog(true);
                            handleCloseDialog();
                        }} 
                        variant="outlined" 
                        color="error"
                        startIcon={<DeleteIcon />}
                        sx={{ mr: 'auto' }}
                    >
                        Eliminar
                    </Button>
                )}
                <Button 
                    onClick={handleSubmitActivity} 
                    variant="contained" 
                    color="primary"
                    disabled={!newActivity.titulo.trim()}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Diálogo de confirmación para eliminar actividad
    const renderDeleteDialog = () => (
        <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    borderTop: '4px solid',
                    borderColor: 'error.main'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <DeleteIcon color="error" />
                {"¿Eliminar esta actividad?"}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" paragraph>
                    ¿Estás seguro de que deseas eliminar la actividad <strong>"{activityToDelete?.titulo}"</strong>?
                </Typography>
                <Typography variant="body2" color="error">
                    Esta acción no se puede deshacer.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                    Cancelar
                </Button>
                <Button 
                    onClick={handleDeleteActivity} 
                    color="error" 
                    variant="contained" 
                    startIcon={<DeleteIcon />}
                    autoFocus
                >
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Diálogo para mostrar las respuestas de un formulario
    const renderFormResponsesDialog = () => (
        <Dialog 
            open={openFormResponsesDialog} 
            onClose={handleCloseFormResponses}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Respuestas del Formulario
            </DialogTitle>
            <DialogContent>
                {selectedFormActivity ? (
                    <FormResponses 
                        clientId={clientId} 
                        formId={selectedFormActivity.formulario_id}
                        activityId={selectedFormActivity.id}
                    />
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseFormResponses}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Añadir el diálogo de progreso de rutina
    const renderRoutineProgressDialog = () => (
        <Dialog 
            open={openRoutineProgressDialog} 
            onClose={() => setOpenRoutineProgressDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Progreso de la Rutina: {selectedRoutineActivity?.titulo}
            </DialogTitle>
            <DialogContent>
                {selectedRoutineActivity ? (
                    <Box>
                        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6">
                                Progreso general
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress
                                    variant="determinate"
                                    value={selectedRoutineActivity.estadisticas_rutina.porcentaje_completado}
                                    size={40}
                                    thickness={4}
                                    sx={{ mr: 2, color: 'success.main' }}
                                />
                                <Typography variant="h5" color="success.main">
                                    {selectedRoutineActivity.estadisticas_rutina.porcentaje_completado}%
                                </Typography>
                            </Box>
                        </Box>
                        
                        <Typography variant="subtitle1" gutterBottom>
                            {selectedRoutineActivity.estadisticas_rutina.dias_completados} de {selectedRoutineActivity.estadisticas_rutina.total_dias} días completados
                        </Typography>
                        
                        <Divider sx={{ my: 2 }} />
                        
                        <Typography variant="h6" gutterBottom>
                            Detalle por día
                        </Typography>
                        
                        <Grid container spacing={2}>
                            {Array.from({ length: selectedRoutineActivity.estadisticas_rutina.total_dias }).map((_, dayIndex) => {
                                const dayData = selectedRoutineActivity.dias_completados[dayIndex];
                                const rutina = rutinas.find(r => r.id === selectedRoutineActivity.rutina_id);
                                const dayName = rutina?.days?.[dayIndex]?.dayName || `Día ${parseInt(dayIndex) + 1}`;
                                
                                return (
                                    <Grid item xs={12} sm={6} key={dayIndex}>
                                        <Paper sx={{ 
                                            p: 2, 
                                            bgcolor: dayData ? 'success.light' : 'background.default',
                                            border: '1px solid',
                                            borderColor: dayData ? 'success.main' : 'divider'
                                        }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant="subtitle1" fontWeight="medium">
                                                    {dayName}
                                                </Typography>
                                                {dayData ? (
                                                    <Chip 
                                                        icon={<CheckIcon />} 
                                                        label={dayData.completado_tipo === 'completo' ? "Completado" : "Parcial"} 
                                                        color="success" 
                                                        size="small" 
                                                    />
                                                ) : (
                                                    <Chip 
                                                        label="Pendiente" 
                                                        variant="outlined"
                                                        size="small" 
                                                    />
                                                )}
                                            </Box>
                                            
                                            {dayData && (
                                                <>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        {dayData.ejercicios_completados} de {dayData.total_ejercicios} ejercicios ({dayData.porcentaje_completado}%)
                                                    </Typography>
                                                    
                                                    {dayData.fecha_completado && (
                                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                                                            Último registro: {format(
                                                                new Date(dayData.fecha_completado), 
                                                                'dd/MM/yyyy HH:mm', 
                                                                { locale: es }
                                                            )}
                                                        </Typography>
                                                    )}
                                                    
                                                    {dayData.feedback && (
                                                        <Box sx={{ mt: 1, p: 1, bgcolor: 'rgba(255,255,255,0.7)', borderRadius: 1 }}>
                                                            <Typography variant="body2" fontWeight="medium">
                                                                Feedback:
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                                                "{dayData.feedback}"
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </>
                                            )}
                                        </Paper>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenRoutineProgressDialog(false)}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Añadir el diálogo de progreso de dieta
    const renderDietProgressDialog = () => (
        <Dialog 
            open={openDietProgressDialog} 
            onClose={() => setOpenDietProgressDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Progreso del Plan de Alimentación: {selectedDietActivity?.titulo}
            </DialogTitle>
            <DialogContent>
                {selectedDietActivity ? (
                    <Box>
                        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6">
                                Progreso general
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress
                                    variant="determinate"
                                    value={selectedDietActivity.estadisticas_dieta.porcentaje_completado}
                                    size={40}
                                    thickness={4}
                                    sx={{ mr: 2, color: 'success.main' }}
                                />
                                <Typography variant="h5" color="success.main">
                                    {selectedDietActivity.estadisticas_dieta.porcentaje_completado}%
                                </Typography>
                            </Box>
                        </Box>
                        
                        <Typography variant="subtitle1" gutterBottom>
                            {selectedDietActivity.estadisticas_dieta.dias_completados} de {selectedDietActivity.estadisticas_dieta.total_dias} días completados
                        </Typography>
                        
                        <Divider sx={{ my: 2 }} />
                        
                        <Typography variant="h6" gutterBottom>
                            Detalle por día
                        </Typography>
                        
                        <Grid container spacing={2}>
                            {Array.from({ length: selectedDietActivity.estadisticas_dieta.total_dias }).map((_, dayIndex) => {
                                const dayData = selectedDietActivity.dias_completados[dayIndex];
                                const fecha = new Date(selectedDietActivity.fecha);
                                fecha.setDate(fecha.getDate() + dayIndex);
                                
                                return (
                                    <Grid item xs={12} sm={6} key={dayIndex}>
                                        <Paper sx={{ 
                                            p: 2, 
                                            bgcolor: dayData ? 'success.light' : 'background.default',
                                            border: '1px solid',
                                            borderColor: dayData ? 'success.main' : 'divider'
                                        }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant="subtitle1" fontWeight="medium">
                                                    {format(fecha, 'dd/MM/yyyy', { locale: es })}
                                                </Typography>
                                                {dayData ? (
                                                    <Chip 
                                                        icon={<CheckIcon />} 
                                                        label={dayData.completado_tipo === 'completo' ? "Completado" : "Parcial"} 
                                                        color="success" 
                                                        size="small" 
                                                    />
                                                ) : (
                                                    <Chip 
                                                        label="Pendiente" 
                                                        variant="outlined"
                                                        size="small" 
                                                    />
                                                )}
                                            </Box>
                                            
                                            {dayData && (
                                                <>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        {dayData.comidas_completadas} de {dayData.total_comidas} comidas ({dayData.porcentaje_completado}%)
                                                    </Typography>
                                                    
                                                    {dayData.comidas && dayData.comidas.length > 0 && (
                                                        <Box sx={{ mt: 1 }}>
                                                            <Typography variant="body2" fontWeight="medium">
                                                                Comidas registradas:
                                                            </Typography>
                                                            {dayData.comidas.map((comida, idx) => (
                                                                <Box 
                                                                    key={idx} 
                                                                    sx={{ 
                                                                        mt: 0.5,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: 1
                                                                    }}
                                                                >
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {comida.hora} - {comida.nombre}
                                                                    </Typography>
                                                                    {comida.tiene_foto && (
                                                                        <Tooltip title="Foto registrada">
                                                                            <Box 
                                                                                component="span" 
                                                                                sx={{ 
                                                                                    width: 8, 
                                                                                    height: 8, 
                                                                                    borderRadius: '50%', 
                                                                                    bgcolor: 'success.main',
                                                                                    display: 'inline-block'
                                                                                }} 
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    )}
                                                    
                                                    {dayData.fecha_completado && (
                                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                                            Último registro: {format(
                                                                new Date(dayData.fecha_completado), 
                                                                'dd/MM/yyyy HH:mm', 
                                                                { locale: es }
                                                            )}
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                        </Paper>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDietProgressDialog(false)}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Agenda del Cliente
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                    Vista mensual de las actividades programadas para este cliente. Puedes hacer clic en cualquier día para añadir una nueva actividad.
                </Typography>

                <Button 
                    variant="contained" 
                    startIcon={<AddIcon />}
                    sx={{ mb: 3 }}
                    onClick={() => {
                        setSelectedActivity(null);
                        setNewActivity({
                            tipo: 'mensaje',
                            titulo: '',
                            descripcion: '',
                            fecha: format(new Date(), 'yyyy-MM-dd'),
                            completado: false,
                            fechaFin: '',
                            rutina_id: '',
                            dieta_id: '',
                            formulario_id: ''
                        });
                        setOpenDialog(true);
                    }}
                >
                    Añadir Actividad
                </Button>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {renderCalendar()}
                    </Box>
                )}

                {renderActivityDialog()}
                {renderDeleteDialog()}
                {renderFormResponsesDialog()}
                {renderRoutineProgressDialog()}
                {renderDietProgressDialog()}
            </Paper>

            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AgendaView; 