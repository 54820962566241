import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Paper, Grid, Button, TextField, IconButton, Modal, Fade, List, ListItem, ListItemIcon, ListItemText, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import BoltIcon from '@mui/icons-material/Bolt';
import { Link } from 'react-router-dom';
import homeBackground from '../../assets/images/imagen-home-dashboard.png';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { 
    getFirestore, 
    doc, 
    getDoc, 
    updateDoc, 
    collection, 
    query, 
    where, 
    orderBy, 
    limit, 
    getDocs,
    setDoc 
} from "firebase/firestore";
import { db } from '../../config/firebaseConfig';
import ReactMarkdown from 'react-markdown';
import RefreshIcon from '@mui/icons-material/Refresh';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/Resumen_Home_Loading.json';
import confetti from 'canvas-confetti';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import InfoIcon from '@mui/icons-material/Info';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import NorthRoundedIcon from '@mui/icons-material/NorthRounded';
import { getAuth } from "firebase/auth";
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import RedeemIcon from '@mui/icons-material/Redeem';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SchoolIcon from '@mui/icons-material/School';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const NewFeatureBanner = ({ text }) => (
    <Box 
        sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 3,
            overflow: 'hidden',
            position: 'relative',
            width: 'fit-content',
            borderRadius: '20px',
            boxShadow: '0 4px 12px rgba(255, 87, 34, 0.4)',
            background: 'linear-gradient(90deg, #FF5722 0%, #FF9800 100%)',
            transition: 'all 0.3s ease',
            border: '2px solid rgba(255, 255, 255, 0.3)',
            '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(255, 87, 34, 0.5)',
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                top: '-50%',
                left: '-50%',
                width: '200%',
                height: '200%',
                background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)',
                transform: 'rotate(30deg)',
                animation: 'shine 3s infinite linear',
                pointerEvents: 'none',
            },
            '@keyframes shine': {
                '0%': { transform: 'translateX(-100%) rotate(30deg)' },
                '100%': { transform: 'translateX(100%) rotate(30deg)' },
            }
        }}
    >
        <Box 
            sx={{ 
                py: 1, 
                px: 2, 
                fontWeight: 'bold',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                zIndex: 1,
            }}
        >
            <BoltIcon 
                sx={{ 
                    mr: 0.5, 
                    fontSize: '1rem',
                    animation: 'bounce 1s infinite alternate',
                    '@keyframes bounce': {
                        '0%': { transform: 'translateY(0)' },
                        '100%': { transform: 'translateY(-3px)' },
                    }
                }} 
            />
            <Typography 
                variant="body1" 
                sx={{ 
                    fontWeight: 700,
                    fontSize: '0.9rem',
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px',
                    textShadow: '0 1px 2px rgba(0,0,0,0.2)',
                    animation: 'pulse 2s infinite',
                    '@keyframes pulse': {
                        '0%': { opacity: 1 },
                        '50%': { opacity: 0.8 },
                        '100%': { opacity: 1 },
                    }
                }}
            >
                Nuevo!
            </Typography>
        </Box>
        <Box 
            sx={{ 
                py: 1, 
                px: 2, 
                backgroundColor: 'white',
                color: '#333',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: -10,
                    top: 0,
                    bottom: 0,
                    width: 20,
                    backgroundColor: 'white',
                    transform: 'skewX(-15deg)',
                }
            }}
        >
            <Typography 
                variant="body1" 
                sx={{ 
                    fontWeight: 500,
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                {text}
            </Typography>
        </Box>
    </Box>
);

const InfoCard = ({ title, value, icon, change }) => (
    <Paper elevation={2} sx={{ 
        p: 3, 
        borderRadius: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: 3,
        }
    }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" component="div" color="text.secondary">
                {title}
            </Typography>
            {icon}
        </Box>
        <Typography variant="h4" component="div" sx={{ mb: 1 }}>
            {value}
        </Typography>
        {change && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {change > 0 ? (
                    <ArrowUpwardIcon sx={{ color: 'success.main', mr: 1 }} />
                ) : (
                    <ArrowDownwardIcon sx={{ color: 'error.main', mr: 1 }} />
                )}
                <Typography 
                    variant="body2" 
                    color={change > 0 ? 'success.main' : 'error.main'}
                >
                    {Math.abs(change)}% este mes
                </Typography>
            </Box>
        )}
    </Paper>
);

const LoadingAnimation = () => (
    <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            p: 3,
            position: 'relative'
        }}
    >
        <Lottie 
            animationData={loadingAnimation}
            style={{ 
                width: 150,
                height: 150,
                filter: 'drop-shadow(0 0 10px rgba(255, 87, 34, 0.3))'
            }}
        />
        <Typography 
            variant="h6" 
            sx={{ 
                mt: 2,
                color: '#FF5722',
                textAlign: 'center',
                animation: 'pulse 1.5s infinite alternate',
                '@keyframes pulse': {
                    '0%': { opacity: 0.6 },
                    '50%': { opacity: 1 },
                    '100%': { opacity: 0.6 }
                }
            }}
        >
            Generando tu resumen diario...
        </Typography>
    </Box>
);

const Home = () => {
    const { userDetails } = useContext(AuthContext);
    const [chatInput, setChatInput] = useState('');
    const [chatResponse, setChatResponse] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const [clientCount, setClientCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [assistantId, setAssistantId] = useState('');
    const [dailySummaryData, setDailySummaryData] = useState(null);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false);
    const [summaryError, setSummaryError] = useState(null);
    const [completedItems, setCompletedItems] = useState(new Set());
    const [clientesStats, setClientesStats] = useState({
        historico: [],
        mesActual: {
            total: 0,
            variacion: 0
        },
        mesesFaltantes: 0
    });
    const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);
    const [isSubmittingReferral, setIsSubmittingReferral] = useState(false);
    const [referralSuccess, setReferralSuccess] = useState(false);
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
    const [selectedTutorial, setSelectedTutorial] = useState(null);
    const [tutoriales, setTutoriales] = useState([
        {
            id: 1,
            titulo: "Añade un cliente y créale una rutina",
            descripcion: "Aprende a añadir un nuevo cliente y crear su primera rutina de entrenamiento personalizada.",
            icono: <PersonAddIcon />,
            pasos: [
                "Accede a la sección 'Clientes' desde el menú lateral",
                "Haz clic en el botón '+' para añadir un nuevo cliente",
                "Completa los datos básicos del cliente",
                "Una vez creado, selecciona el cliente y ve a 'Crear rutina'",
                "Diseña la rutina según las necesidades del cliente",
                "Guarda y asigna la rutina al cliente"
            ],
            demoId: "cm876u00k20zs10u15an964du", // Usando el mismo ID real de Supademo por ahora
            video: null
        },
        {
            id: 2,
            titulo: "Pon formularios a tus clientes",
            descripcion: "Aprende a crear y asignar formularios personalizados para recopilar información de tus clientes.",
            icono: <CalendarMonthIcon />,
            pasos: [
                "Accede a la sección 'Formularios' desde el menú lateral",
                "Haz clic en 'Crear formulario'",
                "Añade las preguntas que deseas incluir en el formulario",
                "Configura el tipo de respuesta para cada pregunta",
                "Guarda el formulario con un nombre descriptivo",
                "Asigna el formulario a tus clientes"
            ],
            demoId: "cm87dv8fa252510u1wfjxf94z", // ID real de Supademo para formularios
            video: null
        },
        {
            id: 3,
            titulo: "Programa una rutina/dieta a un cliente",
            descripcion: "Aprende a programar una rutina o dieta ya creada a uno de tus clientes de manera rápida y sencilla.",
            icono: <FitnessCenterIcon />,
            pasos: [
                "Accede a la sección 'Clientes' desde el menú lateral",
                "Selecciona el cliente al que quieres asignar la rutina/dieta",
                "Ve a la pestaña de 'Rutinas' o 'Dietas'",
                "Haz clic en 'Programar rutina' o 'Programar dieta'",
                "Selecciona la rutina/dieta que deseas asignar",
                "Configura las fechas y ajustes",
                "Guarda los cambios para asignar la programación"
            ],
            demoId: "cm8en0kn92qr12ugqcd3rhhry",
            video: null
        }
    ]);

    useEffect(() => {
        fetchDailySummary();
    }, [userDetails]);

    useEffect(() => {
        const fetchClientCount = async () => {
            if (!userDetails) return;

            try {
                let count = 0;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    const userRef = doc(db, 'userDetails', userDetails.uid);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = userSnap.data();
                        count = userData.clientes?.length || 0;
                    }
                } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional)) {
                    if (userDetails.IDBO) {
                        const centroRef = doc(db, 'Centros', userDetails.IDBO);
                        const centroSnap = await getDoc(centroRef);
                        if (centroSnap.exists()) {
                            const centroData = centroSnap.data();
                            count = centroData.clientes?.length || 0;
                        }
                    }
                }
                setClientCount(count);
            } catch (error) {
                console.error("Error al obtener el número de clientes:", error);
            }
        };

        fetchClientCount();

        const fetchAssistantId = async () => {
            const docRef = doc(db, 'parametros_globales', 'dashboard_assistant');
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setAssistantId(docSnap.data().assistant_id);
            }
        };

        fetchAssistantId();
    }, [userDetails]);

    useEffect(() => {
        fetchHistoricoClientes();
    }, [userDetails]);

    useEffect(() => {
        if (window.Supademo) {
            window.Supademo("5302413a653e4386dbecb821a39736ae31bcc4a1b166e53e53d13c43c63aa9ca", { // Reemplazar con el token real de Supademo si es necesario
                variables: {
                    email: userDetails?.email || "",
                    name: userDetails?.name || ""
                }
            });
        }
    }, [userDetails]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChatSubmit = async (e) => {
        e.preventDefault();
        if (!chatInput.trim()) return;

        setIsLoading(true);
        try {
            const auth = getAuth();
            const idToken = await auth.currentUser.getIdToken();

            let requestData = {
                question: chatInput,
                assistant_id: assistantId,
                thread_id: userDetails.thread_id || '',
                nombre: userDetails.name,
                tipo_de_profesional: userDetails.tipo_profesional
            };

            if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (userDetails.IDBO) {
                    const centroRef = doc(db, 'Centros', userDetails.IDBO);
                    const centroSnap = await getDoc(centroRef);
                    if (centroSnap.exists()) {
                        requestData.nombre_business = centroSnap.data().nombreCentro;
                    }
                }
            }

            const response = await fetch(
                'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/asistente-dashboard',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                    body: JSON.stringify(requestData)
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Error en la respuesta del servidor');
            }

            const data = await response.json();
            setChatResponse(data.respuesta);
            setIsModalOpen(true);

            if (!userDetails.thread_id && data.thread_id) {
                const userRef = doc(db, 'userDetails', userDetails.uid);
                await updateDoc(userRef, {
                    thread_id: data.thread_id
                });
            }
        } catch (error) {
            console.error("Error al obtener la respuesta:", error);
            setChatResponse("Lo siento, ha ocurrido un error al procesar tu pregunta.");
        } finally {
            setIsLoading(false);
            setChatInput('');
        }
    };

    const fetchDailySummary = async () => {
        if (!userDetails?.uid) return;

        setIsLoadingSummary(true);
        setSummaryError(null);
        
        try {
            const currentDate = getCurrentDate();
            const summaryRef = doc(db, 'userDetails', userDetails.uid, 'daily_summaries', currentDate);
            const summarySnap = await getDoc(summaryRef);
            
            if (summarySnap.exists()) {
                setDailySummaryData(summarySnap.data());
            } else {
                setDailySummaryData(null);
            }
        } catch (error) {
            console.error('Error al cargar el resumen diario:', error);
            setSummaryError('Error al cargar el resumen diario');
        } finally {
            setIsLoadingSummary(false);
        }
    };

    const generateDailySummary = async () => {
        if (!userDetails?.uid) return;

        setIsLoadingSummary(true);
        setSummaryError(null);

        try {
            const requestData = {
                uid: userDetails.uid,
                IDPT: userDetails.IDPT || null,
                IDBO: userDetails.IDBO || null,
                tipo_profesional: userDetails.tipo_profesional
            };

            const response = await axios.post(
                'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/crear-resumen-profesional',
                requestData
            );

            setTimeout(async () => {
                await fetchDailySummary();
            }, 1000);

        } catch (error) {
            console.error('Error al generar el resumen:', error);
            setSummaryError('No se pudo generar el resumen. Por favor, inténtalo de nuevo.');
        } finally {
            setIsLoadingSummary(false);
        }
    };

    const getCurrentDate = () => {
        return new Date().toISOString().split('T')[0];
    };

    const handleItemClick = (index, event) => {
        const newCompletedItems = new Set(completedItems);
        if (completedItems.has(index)) {
            newCompletedItems.delete(index);
        } else {
            newCompletedItems.add(index);
            confetti({
                particleCount: 50,
                spread: 60,
                origin: {
                    x: event.clientX / window.innerWidth,
                    y: event.clientY / window.innerHeight
                },
                colors: ['#FF5722', '#FF9800', '#FFC107']
            });
        }
        setCompletedItems(newCompletedItems);
    };

    const renderSummaryContent = () => {
        if (!dailySummaryData) return null;

        const resumenData = dailySummaryData.resumen;

        return (
            <Box sx={{ p: 2 }}>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        mb: 3, 
                        color: 'text.primary',
                        textAlign: 'center'
                    }}
                >
                    {resumenData.title}
                </Typography>

                <List>
                    {resumenData.summary_items.map((item, index) => (
                        <ListItem 
                            key={index}
                            sx={{
                                py: 1.5,
                                backgroundColor: index % 2 === 0 ? 'rgba(255, 87, 34, 0.05)' : 'transparent',
                                borderRadius: 1,
                                mb: 1,
                                cursor: 'pointer',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                }
                            }}
                            onClick={(e) => handleItemClick(index, e)}
                        >
                            <ListItemIcon>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        fontSize: '1.5rem'
                                    }}
                                >
                                    {item.emoji}
                                </Typography>
                            </ListItemIcon>
                            <ListItemText 
                                primary={item.text}
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        fontWeight: 500,
                                        color: 'text.primary',
                                        textDecoration: completedItems.has(index) ? 'line-through' : 'none',
                                        opacity: completedItems.has(index) ? 0.6 : 1,
                                        transition: 'all 0.2s ease-in-out'
                                    }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>

                <Box 
                    sx={{ 
                        mt: 3,
                        p: 2,
                        backgroundColor: 'rgba(255, 87, 34, 0.1)',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <EmojiEmotionsIcon sx={{ color: '#FF5722' }} />
                    <Typography 
                        variant="body1"
                        sx={{ 
                            fontStyle: 'italic',
                            color: 'text.secondary',
                            fontWeight: 500
                        }}
                    >
                        {resumenData.conclusion}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const fetchHistoricoClientes = async () => {
        if (!userDetails?.uid) return;
        
        try {
            let coleccionRef;
            
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                coleccionRef = collection(db, 'userDetails', userDetails.uid, 'historico_clientes');
            } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional) && userDetails.IDBO) {
                coleccionRef = collection(db, 'Centros', userDetails.IDBO, 'historico_clientes');
            } else {
                throw new Error('Tipo de usuario no válido o IDBO faltante');
            }

            const historicoSnap = await getDocs(query(
                coleccionRef,
                orderBy('fecha_actualizacion', 'desc'),
                limit(12)
            ));

            const datos = [];
            historicoSnap.forEach(doc => {
                const [mes, año] = doc.id.split('-');
                const nombreMes = new Date(año, parseInt(mes) - 1).toLocaleString('es', { month: 'short' });
                
                datos.push({
                    name: `${nombreMes} ${año}`,
                    total: doc.data().total_clientes
                });
            });

            datos.reverse();

            const mesesFaltantes = datos.length < 6 ? 6 - datos.length : 0;

            const mesActual = datos[datos.length - 1];
            const mesAnterior = datos[datos.length - 2];
            const variacion = mesAnterior ? 
                ((mesActual.total - mesAnterior.total) / mesAnterior.total) * 100 : 0;

            setClientesStats({
                historico: datos,
                mesActual: {
                    total: mesActual?.total || 0,
                    variacion: Math.round(variacion * 10) / 10
                },
                mesesFaltantes
            });

        } catch (error) {
            console.error("Error al obtener histórico de clientes:", error);
        }
    };

    const ClientesChart = ({ data, mesesFaltantes }) => (
        <Paper elevation={2} sx={{ 
            p: 3, 
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            height: 400,
            position: 'relative',
            overflow: 'hidden'
        }}>
            <Typography variant="h6" gutterBottom>
                Evolución de Clientes
            </Typography>
            {data.length > 0 ? (
                <Box sx={{ height: mesesFaltantes > 0 ? '85%' : '90%' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="name"
                                tick={{ fontSize: 12 }}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis />
                            <Tooltip />
                            <Line 
                                type="monotone" 
                                dataKey="total" 
                                stroke="#FF5722" 
                                strokeWidth={2}
                                dot={{ r: 4 }}
                                activeDot={{ r: 6 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            ) : (
                <Box sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' 
                }}>
                    <Typography variant="body1" color="text.secondary">
                        No hay datos históricos disponibles
                    </Typography>
                </Box>
            )}
            
            {mesesFaltantes > 0 && (
                <Box sx={{ 
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    right: 16,
                    backgroundColor: 'rgba(255, 87, 34, 0.05)',
                    borderRadius: 2,
                    p: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    border: '1px dashed rgba(255, 87, 34, 0.2)'
                }}>
                    <QueryStatsIcon sx={{ color: '#FF5722', fontSize: 20 }} />
                    <Box>
                        <Typography 
                            variant="subtitle2" 
                            sx={{ 
                                color: '#FF5722',
                                fontWeight: 600,
                                fontSize: '0.75rem',
                                lineHeight: 1
                            }}
                        >
                            Datos en Proceso
                        </Typography>
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: 'text.secondary',
                                fontSize: '0.7rem',
                                lineHeight: 1.2
                            }}
                        >
                            Faltan {mesesFaltantes} {mesesFaltantes === 1 ? 'mes' : 'meses'} más para mostrar estadísticas completas
                        </Typography>
                    </Box>
                </Box>
            )}
        </Paper>
    );

    const handleCopyInstructions = () => {
        const instructions = `¡Hola! Aquí tienes los pasos para acceder a tu app de entrenamiento:

1. Con el navegador del móvil ve a: https://fitai-client.web.app/ 
2. Una vez ahí dale al botón de compartir (cuadrado con flechita)
3. Dale al botón de Añadir a pantalla de Inicio
4. ¡Listo! Ya tendrás tu app en el móvil
5. Ingresa con el correo y la contraseña que te ha creado tu entrenador
6. ¡Disfruta de tu entrenamiento!`;

        navigator.clipboard.writeText(instructions).then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        });
    };

    const handleReferralSubmit = async () => {
        if (!userDetails) return;
        
        setIsSubmittingReferral(true);
        setReferralSuccess(false);
        
        try {
            const url = 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/solicitud-asistencia';
            const data = {
                name: userDetails?.display_name || userDetails?.name || 'Usuario no identificado',
                email: userDetails?.email || 'Email no disponible',
                message: `Quiero entrar en el programa de referidos. ID de usuario: ${userDetails.uid}`,
                date: new Date().toISOString()
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setReferralSuccess(true);
                setTimeout(() => {
                    setIsReferralModalOpen(false);
                    setReferralSuccess(false);
                }, 3000);
            } else {
                throw new Error('Error al enviar la solicitud');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error al enviar la solicitud. Por favor, intenta de nuevo.');
        } finally {
            setIsSubmittingReferral(false);
        }
    };

    const handleOpenTutorial = (tutorial) => {
        if (!tutorial.demoId) {
            setSelectedTutorial(tutorial);
            setIsTutorialModalOpen(true);
        }
    };

    const handleCloseTutorial = () => {
        setIsTutorialModalOpen(false);
        setTimeout(() => {
            setSelectedTutorial(null);
        }, 300);
    };

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3, 
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${homeBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.2,
                    zIndex: -1,
                }
            }}
        >
            {/* Botón flotante de referidos */}
            <Box
                onClick={() => setIsReferralModalOpen(true)}
                sx={{
                    position: 'fixed',
                    bottom: 30,
                    right: 30,
                    zIndex: 1000,
                    borderRadius: '50px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(255, 152, 0, 0.5)',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        transform: 'translateY(-5px) scale(1.05)',
                        boxShadow: '0 8px 25px rgba(255, 152, 0, 0.6)',
                    },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'linear-gradient(45deg, #FF9800, #FF5722, #FFC107, #FF9800)',
                        backgroundSize: '300% 300%',
                        animation: 'gradientAnimation 5s ease infinite',
                        zIndex: -1,
                    },
                    '@keyframes gradientAnimation': {
                        '0%': { backgroundPosition: '0% 50%' },
                        '50%': { backgroundPosition: '100% 50%' },
                        '100%': { backgroundPosition: '0% 50%' },
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    p: '10px 20px',
                    position: 'relative',
                    zIndex: 1,
                }}>
                    <MonetizationOnIcon sx={{ 
                        color: 'white', 
                        mr: 1,
                        animation: 'pulse 1.5s infinite',
                        '@keyframes pulse': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        }
                    }} />
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            color: 'white', 
                            fontWeight: 'bold',
                            textShadow: '0 1px 3px rgba(0,0,0,0.3)'
                        }}
                    >
                        ¡Gana 25€!
                    </Typography>
                </Box>
            </Box>

            {/* Modal de Programa de Referidos */}
            <Dialog 
                open={isReferralModalOpen} 
                onClose={() => setIsReferralModalOpen(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogTitle sx={{ 
                    background: 'linear-gradient(45deg, #FF9800, #FF5722)',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    py: 2
                }}>
                    <CardGiftcardIcon />
                    Programa de Referidos
                </DialogTitle>
                <DialogContent sx={{ mt: 2, p: 3 }}>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        mb: 3
                    }}>
                        <RedeemIcon sx={{ 
                            fontSize: 60, 
                            color: '#FF5722',
                            mb: 2
                        }} />
                        <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                            ¡Gana 25€ por cada entrenador referido!
                        </Typography>
                    </Box>
                    
                    <Typography variant="body1" paragraph>
                        Por cada entrenador que nos traigas y complete el primer mes, recibirás un crédito de 25€ para usar en Fitai.
                    </Typography>
                    
                    <Box sx={{ 
                        bgcolor: 'rgba(255, 87, 34, 0.1)', 
                        p: 2, 
                        borderRadius: 2,
                        mb: 3
                    }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            ¿Cómo funciona?
                        </Typography>
                        <List dense>
                            <ListItem>
                                <ListItemIcon>
                                    <Typography variant="h6">1️⃣</Typography>
                                </ListItemIcon>
                                <ListItemText primary="Invita a un entrenador a unirse a Fitai" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Typography variant="h6">2️⃣</Typography>
                                </ListItemIcon>
                                <ListItemText primary="Cuando complete su primer mes como usuario" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Typography variant="h6">3️⃣</Typography>
                                </ListItemIcon>
                                <ListItemText primary="¡Recibirás 25€ de crédito automáticamente!" />
                            </ListItem>
                        </List>
                    </Box>
                    
                    <Box sx={{ 
                        bgcolor: 'rgba(76, 175, 80, 0.1)', 
                        p: 2, 
                        borderRadius: 2,
                        mb: 3,
                        border: '1px dashed rgba(76, 175, 80, 0.5)'
                    }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, color: '#4CAF50', display: 'flex', alignItems: 'center' }}>
                            <CheckIcon sx={{ mr: 1, fontSize: 20 }} />
                            ¡Beneficio adicional!
                        </Typography>
                        <Typography variant="body2">
                            Además, el entrenador referido obtendrá un <strong>descuento especial</strong> en su primer mes:
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            my: 2,
                            gap: 2
                        }}>
                            <Typography 
                                variant="h6" 
                                sx={{ 
                                    textDecoration: 'line-through', 
                                    color: 'text.secondary',
                                    fontWeight: 'normal'
                                }}
                            >
                                75€
                            </Typography>
                            <ArrowRightIcon sx={{ color: 'text.secondary' }} />
                            <Typography 
                                variant="h5" 
                                sx={{ 
                                    color: '#4CAF50',
                                    fontWeight: 'bold',
                                    animation: 'pulse 2s infinite',
                                    '@keyframes pulse': {
                                        '0%': { transform: 'scale(1)' },
                                        '50%': { transform: 'scale(1.05)' },
                                        '100%': { transform: 'scale(1)' },
                                    }
                                }}
                            >
                                ¡Solo 49€!
                            </Typography>
                        </Box>
                        <Box 
                            sx={{ 
                                mt: 2, 
                                p: 1.5, 
                                bgcolor: 'rgba(76, 175, 80, 0.2)', 
                                borderRadius: 1.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1
                            }}
                        >
                            <BoltIcon sx={{ color: '#4CAF50', fontSize: 20 }} />
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    fontWeight: 'bold',
                                    color: '#4CAF50',
                                    textAlign: 'center'
                                }}
                            >
                                ¡Y además 15 días GRATIS de prueba!
                            </Typography>
                        </Box>
                    </Box>
                    
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                bgcolor: '#FF5722',
                                '&:hover': { bgcolor: '#E64A19' },
                                px: 4,
                                py: 1.5,
                                borderRadius: 3,
                                fontWeight: 'bold',
                                boxShadow: '0 4px 10px rgba(255, 87, 34, 0.3)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-3px)',
                                    boxShadow: '0 6px 15px rgba(255, 87, 34, 0.4)',
                                    bgcolor: '#E64A19'
                                }
                            }}
                            onClick={handleReferralSubmit}
                            disabled={isSubmittingReferral || referralSuccess}
                        >
                            {isSubmittingReferral ? (
                                <CircularProgress size={24} sx={{ color: 'white' }} />
                            ) : referralSuccess ? (
                                <>
                                    <CheckIcon sx={{ mr: 1 }} />
                                    ¡Solicitud enviada!
                                </>
                            ) : (
                                'Inscribirme al programa'
                            )}
                        </Button>
                        {referralSuccess && (
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    mt: 2, 
                                    color: '#4CAF50',
                                    fontWeight: 'medium'
                                }}
                            >
                                Tu solicitud ha sido enviada. Nos pondremos en contacto contigo pronto.
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button 
                        onClick={() => setIsReferralModalOpen(false)}
                        sx={{ color: '#FF5722' }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 4,
                position: 'relative'
            }}>
                <Box
                    sx={{
                        position: 'relative',
                        width: 80,
                        height: 80,
                        mr: 3,
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: -4,
                            left: -4,
                            right: -4,
                            bottom: -4,
                            background: 'linear-gradient(45deg, #FF5722, #FFA000)',
                            borderRadius: '50%',
                            filter: 'blur(8px)',
                            opacity: 0.7,
                            animation: 'glow 4s ease-in-out infinite alternate',
                        },
                        '@keyframes glow': {
                            '0%': {
                                opacity: 0.5,
                                transform: 'scale(1)',
                            },
                            '50%': {
                                opacity: 0.7,
                                transform: 'scale(1.05)',
                            },
                            '100%': {
                                opacity: 0.5,
                                transform: 'scale(1)',
                            },
                        },
                    }}
                >
                    <Avatar
                        src={userDetails?.photo_url}
                        alt={userDetails?.name}
                        sx={{
                            width: 80,
                            height: 80,
                            border: '4px solid white',
                            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                        }}
                    />
                </Box>
                <Box>
                    <Typography variant="h4" gutterBottom>
                        {`Bienvenido${userDetails ? `, ${userDetails.name}` : ''}`}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Resumen de tu negocio como entrenador personal
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <NewFeatureBanner text="Auto asignación con IA de videos de ejercicios" />
            </Box>

            <Paper 
                elevation={3} 
                sx={{ 
                    mb: 4, 
                    p: 3, 
                    borderRadius: 4, 
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -50,
                        left: -50,
                        right: -50,
                        bottom: -50,
                        background: 'radial-gradient(circle, rgba(255,87,34,0.2) 0%, rgba(255,87,34,0) 70%)',
                        animation: 'glow 5s infinite alternate',
                    },
                    '@keyframes glow': {
                        '0%': { transform: 'translate(10%, 10%)' },
                        '100%': { transform: 'translate(-10%, -10%)' },
                    },
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Conoce tu negocio
                </Typography>
                <form onSubmit={handleChatSubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Pregunta lo que quieras de la plataforma y del negocio"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        disabled={isLoading}
                        InputProps={{
                            endAdornment: (
                                <Box sx={{ mr: 1 }}>
                                    <IconButton 
                                        type="submit" 
                                        edge="end"
                                        disabled={isLoading}
                                        sx={{
                                            backgroundColor: '#FF5722',
                                            color: 'white',
                                            borderRadius: '50%',
                                            width: 40,
                                            height: 40,
                                            '&:hover': {
                                                backgroundColor: '#FF7043',
                                                transform: 'scale(1.05)',
                                            },
                                            transition: 'all 0.3s ease-in-out',
                                        }}
                                    >
                                        {isLoading ? 
                                            <CircularProgress size={24} sx={{ color: 'white' }} /> : 
                                            <NorthRoundedIcon sx={{ fontSize: 20 }} />
                                        }
                                    </IconButton>
                                </Box>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 4,
                                backgroundColor: 'white',
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FF5722',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF5722',
                                },
                            }
                        }}
                    />
                </form>
            </Paper>

            {/* Sección de resumen diario comentada temporalmente
            <Paper 
                elevation={3} 
                sx={{ 
                    mb: 4, 
                    p: 3, 
                    borderRadius: 4, 
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -50,
                        left: -50,
                        right: -50,
                        bottom: -50,
                        background: 'radial-gradient(circle, rgba(255,87,34,0.1) 0%, rgba(255,87,34,0) 70%)',
                        animation: 'pulse 5s infinite alternate',
                    },
                    '@keyframes pulse': {
                        '0%': { opacity: 0.6 },
                        '100%': { opacity: 1 },
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 2
                }}>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            color: 'text.primary'
                        }}
                    >
                        Resumen de hoy
                    </Typography>
                    {dailySummaryData && (
                        <Button
                            onClick={generateDailySummary}
                            startIcon={<RefreshIcon />}
                            sx={{ 
                                color: '#FF5722',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.1)'
                                }
                            }}
                        >
                            Actualizar
                        </Button>
                    )}
                </Box>
                
                {isLoadingSummary ? (
                    <LoadingAnimation />
                ) : summaryError ? (
                    <Typography color="error">{summaryError}</Typography>
                ) : !dailySummaryData ? (
                    <Box sx={{ textAlign: 'center', p: 2 }}>
                        <Typography gutterBottom>
                            No hay resumen disponible para hoy
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={generateDailySummary}
                            sx={{
                                bgcolor: '#FF5722',
                                '&:hover': { bgcolor: '#E64A19' }
                            }}
                        >
                            Generar Resumen Diario
                        </Button>
                    </Box>
                ) : (
                    renderSummaryContent()
                )}
            </Paper>
            */}

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isModalOpen}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 800,
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        overflow: 'auto',
                    }}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#FF5722' }}>
                            Respuesta del Asistente
                        </Typography>
                        <ReactMarkdown>
                            {chatResponse}
                        </ReactMarkdown>
                        <Button 
                            onClick={() => setIsModalOpen(false)}
                            sx={{ 
                                mt: 3, 
                                bgcolor: '#FF5722', 
                                color: 'white',
                                '&:hover': {
                                    bgcolor: '#E64A19',
                                }
                            }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Fade>
            </Modal>

            <Paper 
                elevation={3} 
                sx={{ 
                    mb: 4, 
                    p: 3, 
                    borderRadius: 4, 
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                    },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -50,
                        left: -50,
                        right: -50,
                        bottom: -50,
                        background: 'radial-gradient(circle, rgba(255,87,34,0.2) 0%, rgba(255,87,34,0) 70%)',
                        animation: 'glow 5s infinite alternate',
                    }
                }}
                onClick={() => setIsInviteModalOpen(true)}
            >
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2 
                }}>
                    <SendIcon sx={{ fontSize: 40, color: '#FF5722' }} />
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Invita a tus clientes a usar la app
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Haz clic aquí para obtener las instrucciones de instalación
                        </Typography>
                    </Box>
                </Box>
            </Paper>

            {/* Modal de Invitación */}
            <Dialog 
                open={isInviteModalOpen} 
                onClose={() => setIsInviteModalOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ 
                    bgcolor: '#FF5722', 
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>
                    <SendIcon />
                    Instrucciones para tus clientes
                </DialogTitle>
                <DialogContent sx={{ mt: 2 }}>
                    <Typography variant="body1" gutterBottom>
                        Comparte estas instrucciones con tus clientes para que puedan acceder a la app:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <Typography variant="h6">1️⃣</Typography>
                            </ListItemIcon>
                            <ListItemText primary="Con el navegador del móvil ve a: https://fitai-client.web.app/" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Typography variant="h6">2️⃣</Typography>
                            </ListItemIcon>
                            <ListItemText primary="Una vez ahí dale al botón de compartir (cuadrado con flechita)" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Typography variant="h6">3️⃣</Typography>
                            </ListItemIcon>
                            <ListItemText primary="Dale al botón de Añadir a pantalla de Inicio" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Typography variant="h6">4️⃣</Typography>
                            </ListItemIcon>
                            <ListItemText primary="¡Listo! Ya tendrás tu app en el móvil" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Typography variant="h6">5️⃣</Typography>
                            </ListItemIcon>
                            <ListItemText primary="Ingresa con el correo y la contraseña que te ha creado tu entrenador" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Typography variant="h6">6️⃣</Typography>
                            </ListItemIcon>
                            <ListItemText primary="¡Disfruta de tu entrenamiento!" />
                        </ListItem>
                    </List>
                    <Box sx={{ 
                        mt: 2, 
                        p: 2, 
                        bgcolor: 'rgba(255, 87, 34, 0.1)', 
                        borderRadius: 2,
                        textAlign: 'center'
                    }}>
                        <Typography variant="subtitle1" gutterBottom>
                            ¡Copia y envíaselo por WhatsApp a tus clientes!
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleCopyInstructions}
                            startIcon={copySuccess ? <CheckIcon /> : <ContentCopyIcon />}
                            sx={{
                                bgcolor: copySuccess ? '#4CAF50' : '#FF5722',
                                '&:hover': {
                                    bgcolor: copySuccess ? '#45a049' : '#E64A19'
                                }
                            }}
                        >
                            {copySuccess ? '¡Copiado!' : 'Copiar'}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setIsInviteModalOpen(false)}
                        sx={{ color: '#FF5722' }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Recuadro de Formativos */}
            <Paper 
                elevation={3} 
                sx={{ 
                    mb: 4, 
                    p: 3, 
                    borderRadius: 4, 
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -50,
                        left: -50,
                        right: -50,
                        bottom: -50,
                        background: 'radial-gradient(circle, rgba(255, 87, 34, 0.1) 0%, rgba(255, 87, 34, 0) 70%)',
                        animation: 'glow 5s infinite alternate',
                    },
                    '@keyframes glow': {
                        '0%': { transform: 'translate(10%, 10%)' },
                        '100%': { transform: 'translate(-10%, -10%)' },
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 3
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SchoolIcon sx={{ fontSize: 30, color: '#FF5722', mr: 1.5 }} />
                        <Typography variant="h5" sx={{ fontWeight: 600 }}>
                            Formativos
                        </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                        Tutoriales para sacar el máximo provecho a la plataforma
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    {tutoriales.map((tutorial) => (
                        <Grid item xs={12} sm={6} md={4} key={tutorial.id}>
                            <Paper 
                                elevation={2} 
                                sx={{ 
                                    p: 2.5, 
                                    borderRadius: 3,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    transition: 'all 0.3s ease',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: 4,
                                        bgcolor: 'rgba(255, 87, 34, 0.05)'
                                    }
                                }}
                                onClick={() => handleOpenTutorial(tutorial)}
                            >
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    mb: 2 
                                }}>
                                    <Avatar 
                                        sx={{ 
                                            bgcolor: 'rgba(255, 87, 34, 0.1)', 
                                            color: '#FF5722',
                                            mr: 1.5
                                        }}
                                    >
                                        {tutorial.icono}
                                    </Avatar>
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                            lineHeight: 1.2
                                        }}
                                    >
                                        {tutorial.titulo}
                                    </Typography>
                                </Box>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{ 
                                        mb: 2,
                                        flexGrow: 1
                                    }}
                                >
                                    {tutorial.descripcion}
                                </Typography>
                                <Box 
                                    sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        mt: 'auto'
                                    }}
                                >
                                    <Button
                                        size="small"
                                        endIcon={<PlayCircleOutlineIcon />}
                                        sx={{ 
                                            color: '#FF5722',
                                            '&:hover': {
                                                bgcolor: 'rgba(255, 87, 34, 0.1)'
                                            }
                                        }}
                                        data-supademo-demo={tutorial.demoId}
                                        onClick={(e) => {
                                            // Detener la propagación para evitar que se active el onClick del Paper
                                            e.stopPropagation();
                                            // Si no hay demoId, abrir el modal personalizado
                                            if (!tutorial.demoId) {
                                                handleOpenTutorial(tutorial);
                                            }
                                            // Si hay demoId, el atributo data-supademo-demo se encargará de mostrar la demo
                                        }}
                                    >
                                        Ver tutorial
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Paper>

            {/* Modal de Tutorial */}
            <Dialog 
                open={isTutorialModalOpen} 
                onClose={handleCloseTutorial}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        overflow: 'hidden'
                    }
                }}
            >
                {selectedTutorial && (
                    <>
                        <DialogTitle 
                            sx={{ 
                                bgcolor: '#FF5722', 
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1.5,
                                py: 2
                            }}
                        >
                            <OndemandVideoIcon />
                            {selectedTutorial.titulo}
                        </DialogTitle>
                        <DialogContent sx={{ mt: 2, p: 3 }}>
                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
                                Sigue estos pasos:
                            </Typography>
                            <List>
                                {selectedTutorial.pasos.map((paso, index) => (
                                    <ListItem 
                                        key={index}
                                        sx={{
                                            py: 1.5,
                                            backgroundColor: index % 2 === 0 ? 'rgba(255, 87, 34, 0.05)' : 'transparent',
                                            borderRadius: 1,
                                            mb: 1
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Avatar 
                                                sx={{ 
                                                    width: 32, 
                                                    height: 32, 
                                                    bgcolor: '#FF5722',
                                                    fontSize: '0.875rem',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {index + 1}
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText primary={paso} />
                                    </ListItem>
                                ))}
                            </List>
                            
                            {selectedTutorial.video && (
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
                                        Video tutorial:
                                    </Typography>
                                    <Box 
                                        sx={{ 
                                            width: '100%', 
                                            height: 0,
                                            paddingBottom: '56.25%', // 16:9 aspect ratio
                                            position: 'relative',
                                            borderRadius: 2,
                                            overflow: 'hidden',
                                            bgcolor: 'black'
                                        }}
                                    >
                                        <Box 
                                            sx={{ 
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                color: 'white',
                                                bgcolor: 'rgba(0,0,0,0.7)'
                                            }}
                                        >
                                            <FitnessCenterIcon sx={{ fontSize: 60, mb: 2, opacity: 0.7 }} />
                                            <Typography variant="body1">
                                                Video en preparación
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions sx={{ p: 2 }}>
                            <Button 
                                onClick={handleCloseTutorial}
                                sx={{ color: '#FF5722' }}
                            >
                                Cerrar
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <ClientesChart 
                        data={clientesStats.historico} 
                        mesesFaltantes={clientesStats.mesesFaltantes}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InfoCard 
                                title="Total Clientes" 
                                value={clientesStats.mesActual.total.toString()} 
                                icon={<PeopleIcon sx={{ fontSize: 40, color: '#FF5722', opacity: 0.7 }} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoCard 
                                title="Variación Mensual" 
                                value={
                                    clientesStats.mesActual.variacion > 0 
                                        ? `+${clientesStats.mesActual.variacion}`
                                        : clientesStats.mesActual.variacion.toString()
                                }
                                icon={
                                    clientesStats.mesActual.variacion > 0 
                                        ? <TrendingUpIcon sx={{ fontSize: 40, color: '#4CAF50', opacity: 0.7 }} />
                                        : clientesStats.mesActual.variacion < 0
                                            ? <TrendingDownIcon sx={{ fontSize: 40, color: '#f44336', opacity: 0.7 }} />
                                            : <TrendingFlatIcon sx={{ fontSize: 40, color: '#757575', opacity: 0.7 }} />
                                }
                                suffix="%"
                                color={
                                    clientesStats.mesActual.variacion > 0 
                                        ? 'success.main'
                                        : clientesStats.mesActual.variacion < 0
                                            ? 'error.main'
                                            : 'text.secondary'
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Espacio adicional al final */}
            <Box sx={{ height: 100 }} />

            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Funcionalidad en desarrollo</DialogTitle>
                <DialogContent>
                    <Typography>
                        Estamos trabajando en esta funcionalidad. Pronto podrás hacer preguntas sobre tu negocio y obtener respuestas instantáneas.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Home;
