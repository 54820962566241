import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Alert,
    Button,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    ImageList,
    ImageListItem,
    IconButton,
    TextField,
    Menu,
    MenuItem,
    Checkbox,
    ListItemText,
    DialogActions,
    Tabs,
    Tab,
    Grid,
    Card,
    CardContent,
    Divider,
    keyframes,
    Tooltip,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    FitnessCenter as FitnessCenterIcon,
    Info as InfoIcon,
    VideoLibrary as VideoLibraryIcon,
    Add as AddIcon,
    Close as CloseIcon,
    Search as SearchIcon,
    Image as ImageIcon,
    CalendarMonth as CalendarIcon,
    Check as CheckIcon,
    Delete as DeleteIcon,
    Upload as UploadFileIcon,
    InsertDriveFile as InsertDriveFileIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, updateDoc, doc, serverTimestamp, addDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { AuthContext } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const RoutineView = ({ clientId }) => {
    const [rutina, setRutina] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openResourceModal, setOpenResourceModal] = useState(false);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [recursos, setRecursos] = useState([]);
    const [loadingRecursos, setLoadingRecursos] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedType, setSelectedType] = useState('all');
    const [previewResource, setPreviewResource] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [editingValue, setEditingValue] = useState('');
    const [editingDayIndex, setEditingDayIndex] = useState(null);
    const [editingExerciseIndex, setEditingExerciseIndex] = useState(null);
    const [editingCircuitoIndex, setEditingCircuitoIndex] = useState(null);
    const [editingCircuitoExerciseIndex, setEditingCircuitoExerciseIndex] = useState(null);
    const [openNewExerciseModal, setOpenNewExerciseModal] = useState(false);
    const [newExerciseDayIndex, setNewExerciseDayIndex] = useState(null);
    
    // Nuevo estado para almacenar todas las rutinas y el índice de la rutina seleccionada
    const [allRoutines, setAllRoutines] = useState([]);
    const [selectedRoutineIndex, setSelectedRoutineIndex] = useState(0);
    
    // Nuevo estado para la edición de nombres de rutinas en las pestañas
    const [editingTabIndex, setEditingTabIndex] = useState(null);
    const [editingTabName, setEditingTabName] = useState('');
    
    const [newExercise, setNewExercise] = useState({
        name: '',
        sets: '',
        reps: '',
        weight: '',
        muscleGroups: [],
        restTimeSeconds: '',
        restBetweenSets: '',
        restBetweenExercises: '',
        instructions: '',
        rir: ''
    });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteExerciseData, setDeleteExerciseData] = useState(null);
    const [openNewDayModal, setOpenNewDayModal] = useState(false);
    const [newDay, setNewDay] = useState({
        dayName: '',
        weekDays: []
    });
    const [deleteDayConfirmOpen, setDeleteDayConfirmOpen] = useState(false);
    const [deleteDayIndex, setDeleteDayIndex] = useState(null);
    const navigate = useNavigate();
    const [suggestedResource, setSuggestedResource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [openNewResourceModal, setOpenNewResourceModal] = useState(false);
    const [newResourceData, setNewResourceData] = useState({
        titulo: '',
        descripcion: '',
        categoria: [],
        file: null
    });
    const [uploadingResource, setUploadingResource] = useState(false);
    const [previewResourceUrl, setPreviewResourceUrl] = useState(null);
    const [deleteRoutineConfirmOpen, setDeleteRoutineConfirmOpen] = useState(false);

    const diasSemana = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo'
    ];

    const categorias = [
        "Pecho",
        "Espalda",
        "Hombros",
        "Bíceps",
        "Tríceps",
        "Abdominales",
        "Pierna",
        "Glúteos",
        "Cardio",
        "Estiramiento",
        "Otro"
    ];

    useEffect(() => {
        const fetchRutina = async () => {
            try {
                console.log('Valores de búsqueda:', {
                    IDPT: userDetails.IDPT,
                    clientId: clientId,
                });
                
                // Obtener todas las rutinas del cliente
                const routinesRef = collection(db, 'routines');
                const q = query(
                    routinesRef,
                    where('IDPT', '==', userDetails.IDPT),
                    where('clientId', '==', clientId)
                );
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    // Convertir todas las rutinas y ordenarlas manualmente
                    const rutinas = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    // Función para convertir cualquier formato de fecha a timestamp
                    const getTimestamp = (date) => {
                        if (!date) return 0;
                        if (typeof date === 'string') {
                            return new Date(date).getTime();
                        }
                        if (date.seconds) {
                            return date.seconds * 1000 + (date.nanoseconds || 0) / 1000000;
                        }
                        return 0;
                    };

                    // Ordenar las rutinas por updatedAt
                    rutinas.sort((a, b) => {
                        const timestampA = getTimestamp(a.updatedAt);
                        const timestampB = getTimestamp(b.updatedAt);
                        return timestampB - timestampA;
                    });

                    // Guardar todas las rutinas en el estado
                    setAllRoutines(rutinas);

                    if (rutinas.length > 0) {
                        const rutinaData = rutinas[0];
                        console.log('Rutina más reciente encontrada:', rutinaData);
                        
                        // Si la rutina tiene updatedAt como string, actualizarla a timestamp
                        if (typeof rutinaData.updatedAt === 'string') {
                            const docRef = doc(db, 'routines', rutinaData.id);
                            await updateDoc(docRef, {
                                updatedAt: serverTimestamp()
                            });
                            console.log('Actualizado formato de fecha para rutina:', rutinaData.id);
                        }
                        
                        setRutina(rutinaData);
                    } else {
                        console.log('No se encontraron rutinas para este cliente');
                    }
                } else {
                    console.log('No se encontraron rutinas con los filtros aplicados');
                }
            } catch (error) {
                console.error('Error al cargar la rutina:', error);
                setError('Error al cargar la rutina del cliente');
            } finally {
                setLoading(false);
            }
        };

        if (userDetails?.IDPT && clientId) {
            fetchRutina();
        }
    }, [clientId, userDetails?.IDPT, db]);

    // Nuevo efecto para asignar videos automáticamente cuando la rutina se carga y está recién creada
    useEffect(() => {
        if (rutina && rutina.isNewlyCreated) {
            // Es una rutina recién creada, asignar videos automáticamente
            autoAssignResources();
            
            // Quitar la marca de recién creada
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails?.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            
            getDocs(q).then(querySnapshot => {
                if (!querySnapshot.empty) {
                    const routineDoc = querySnapshot.docs[0];
                    updateDoc(doc(db, 'routines', routineDoc.id), {
                        isNewlyCreated: false
                    });
                }
            }).catch(error => {
                console.error("Error al actualizar el estado de la rutina:", error);
            });
        }
    }, [rutina?.id]);

    const fetchRecursos = async () => {
        setLoadingRecursos(true);
        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setRecursos(recursosData);
        } catch (error) {
            console.error("Error al cargar recursos:", error);
        } finally {
            setLoadingRecursos(false);
        }
    };

    const handleOpenResourceModal = (exercise, dayIndex, exerciseIndex, circuitoIndex = null, circuitoExerciseIndex = null) => {
        // Encontrar el índice real del día en el array
        const realDayIndex = rutina.days.findIndex(d => d.dayIndex === dayIndex);
        setSelectedExercise({ 
            ...exercise, 
            dayIndex: realDayIndex, 
            exerciseIndex,
            circuitoIndex,
            circuitoExerciseIndex
        });
        setOpenResourceModal(true);
        fetchRecursos();
    };

    const handleCloseResourceModal = () => {
        setOpenResourceModal(false);
        setSelectedExercise(null);
    };

    const handleAddResource = async (resource) => {
        if (!selectedExercise || !rutina) return;

        try {
            // Crear una copia profunda de la rutina para evitar problemas de referencia
            const newRutina = JSON.parse(JSON.stringify(rutina));
            
            // Verificar que el día existe
            if (!newRutina.days || !newRutina.days[selectedExercise.dayIndex]) {
                throw new Error('Día no encontrado en la rutina');
            }

            const day = newRutina.days[selectedExercise.dayIndex];
            let exercise;

            // Determinar si estamos trabajando con un ejercicio normal o de circuito
            if (selectedExercise.circuitoIndex !== null && selectedExercise.circuitoExerciseIndex !== null) {
                // Es un ejercicio de circuito
                if (!day.circuitos || 
                    !day.circuitos[selectedExercise.circuitoIndex] || 
                    !day.circuitos[selectedExercise.circuitoIndex].exercises || 
                    !day.circuitos[selectedExercise.circuitoIndex].exercises[selectedExercise.circuitoExerciseIndex]) {
                    throw new Error('Ejercicio de circuito no encontrado');
                }
                
                exercise = day.circuitos[selectedExercise.circuitoIndex].exercises[selectedExercise.circuitoExerciseIndex];
            } else {
                // Es un ejercicio normal
                if (!day.exercises || !day.exercises[selectedExercise.exerciseIndex]) {
                    throw new Error('Ejercicio no encontrado en el día');
                }
                
                exercise = day.exercises[selectedExercise.exerciseIndex];
            }
            
            // Inicializar el array de recursos si no existe
            if (!exercise.recursos) {
                exercise.recursos = [];
            }

            // Evitar duplicados
            if (!exercise.recursos.find(r => r.id === resource.id)) {
                exercise.recursos.push({
                    id: resource.id,
                    tipo: resource.fileType,
                    url: resource.fileUrl,
                    titulo: resource.titulo
                });
            }

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                handleCloseResourceModal();
            }
        } catch (error) {
            console.error("Error al añadir recurso:", error);
            alert(`Error al añadir el recurso al ejercicio: ${error.message}`);
        }
    };

    const handleDeleteResource = async (dayIndex, exerciseIndex, resourceId, circuitoIndex = null, circuitoExerciseIndex = null) => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            // Encontrar el índice real del día
            const realDayIndex = newRutina.days.findIndex(d => d.dayIndex === dayIndex);
            if (realDayIndex === -1) throw new Error('Día no encontrado');
            
            let exercise;
            
            // Determinar si estamos trabajando con un ejercicio normal o de circuito
            if (circuitoIndex !== null && circuitoExerciseIndex !== null) {
                // Es un ejercicio de circuito
                if (!newRutina.days[realDayIndex].circuitos || 
                    !newRutina.days[realDayIndex].circuitos[circuitoIndex] || 
                    !newRutina.days[realDayIndex].circuitos[circuitoIndex].exercises || 
                    !newRutina.days[realDayIndex].circuitos[circuitoIndex].exercises[circuitoExerciseIndex]) {
                    throw new Error('Ejercicio de circuito no encontrado');
                }
                
                exercise = newRutina.days[realDayIndex].circuitos[circuitoIndex].exercises[circuitoExerciseIndex];
            } else {
                // Es un ejercicio normal
                exercise = newRutina.days[realDayIndex].exercises[exerciseIndex];
                if (!exercise) throw new Error('Ejercicio no encontrado');
            }
            
            exercise.recursos = exercise.recursos.filter(r => r.id !== resourceId);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar recurso:", error);
            alert('Error al eliminar el recurso del ejercicio: ' + error.message);
        }
    };

    const handleDayClick = (event, dayIndex) => {
        setAnchorEl(event.currentTarget);
        setSelectedDayIndex(dayIndex);
    };

    const handleDayClose = () => {
        setAnchorEl(null);
        setSelectedDayIndex(null);
    };

    const handleDaySelect = async (weekDay) => {
        if (!rutina || selectedDayIndex === null) return;

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const dayToUpdate = newRutina.days.find(d => d.dayIndex === selectedDayIndex);

            if (!dayToUpdate) {
                throw new Error('Día no encontrado');
            }

            // Inicializar el array de días de la semana si no existe
            if (!dayToUpdate.weekDays) {
                dayToUpdate.weekDays = [];
            }

            // Toggle el día seleccionado
            const index = dayToUpdate.weekDays.indexOf(weekDay);
            if (index === -1) {
                dayToUpdate.weekDays.push(weekDay);
            } else {
                dayToUpdate.weekDays.splice(index, 1);
            }

            // Ordenar los días según el orden de la semana
            dayToUpdate.weekDays.sort((a, b) => diasSemana.indexOf(a) - diasSemana.indexOf(b));

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al actualizar días de la semana:", error);
            alert('Error al actualizar los días de la semana: ' + error.message);
        }
    };

    const handleStartEdit = (field, value, dayIndex = null, exerciseIndex = null, circuitoIndex = null, circuitoExerciseIndex = null) => {
        setEditingField(field);
        setEditingValue(value);
        setEditingDayIndex(dayIndex);
        setEditingExerciseIndex(exerciseIndex);
        setEditingCircuitoIndex(circuitoIndex);
        setEditingCircuitoExerciseIndex(circuitoExerciseIndex);
    };

    const handleCancelEdit = () => {
        setEditingField(null);
        setEditingValue('');
        setEditingDayIndex(null);
        setEditingExerciseIndex(null);
        setEditingCircuitoIndex(null);
        setEditingCircuitoExerciseIndex(null);
    };

    const handleSaveEdit = async () => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));

            if (editingField === 'name' || editingField === 'description' || editingField === 'generalInstructions') {
                newRutina[editingField] = editingValue;
            } else if (editingField === 'dayName') {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day) {
                    day.dayName = editingValue;
                }
            } else if (editingField === 'calentamiento' || editingField === 'cardio' || editingField === 'enfriamiento') {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day) {
                    day[editingField] = editingValue;
                }
            } else if (editingField.startsWith('exercise')) {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day && day.exercises[editingExerciseIndex]) {
                    const fieldMap = {
                        'exerciseName': 'name',
                        'exerciseSets': 'sets',
                        'exerciseReps': 'reps',
                        'exerciseWeight': 'weight',
                        'exerciseMuscleGroups': 'muscleGroups',
                        'exerciseRestTimeSeconds': 'restTimeSeconds',
                        'exerciseRestBetweenSets': 'restBetweenSets',
                        'exerciseRestBetweenExercises': 'restBetweenExercises',
                        'exerciseInstructions': 'instructions',
                        'exerciseRir': 'rir'
                    };

                    const field = fieldMap[editingField];
                    if (field) {
                        if (field === 'muscleGroups') {
                            day.exercises[editingExerciseIndex][field] = editingValue.split(',').map(g => g.trim()).filter(g => g);
                        } else if (['sets', 'reps', 'weight', 'restTimeSeconds', 'restBetweenSets', 'restBetweenExercises'].includes(field)) {
                            day.exercises[editingExerciseIndex][field] = editingValue ? parseInt(editingValue) : null;
                        } else if (field === 'rir') {
                            day.exercises[editingExerciseIndex][field] = editingValue || '';
                        } else {
                            day.exercises[editingExerciseIndex][field] = editingValue;
                        }
                    }
                }
            } else if (editingField === 'circuitoRepetitions') {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day && day.circuitos && day.circuitos[editingCircuitoIndex]) {
                    day.circuitos[editingCircuitoIndex].repetitions = editingValue ? parseInt(editingValue) : 1;
                }
            } else if (editingField.startsWith('circuitoExercise')) {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day && day.circuitos && day.circuitos[editingCircuitoIndex] && 
                    day.circuitos[editingCircuitoIndex].exercises[editingCircuitoExerciseIndex]) {
                    
                    const fieldMap = {
                        'circuitoExerciseName': 'name',
                        'circuitoExerciseSets': 'sets',
                        'circuitoExerciseReps': 'reps',
                        'circuitoExerciseWeight': 'weight',
                        'circuitoExerciseMuscleGroups': 'muscleGroups',
                        'circuitoExerciseRestTimeSeconds': 'restTimeSeconds',
                        'circuitoExerciseRestBetweenSets': 'restBetweenSets',
                        'circuitoExerciseRestBetweenExercises': 'restBetweenExercises',
                        'circuitoExerciseInstructions': 'instructions'
                    };

                    const field = fieldMap[editingField];
                    if (field) {
                        const exercise = day.circuitos[editingCircuitoIndex].exercises[editingCircuitoExerciseIndex];
                        if (field === 'muscleGroups') {
                            exercise[field] = editingValue.split(',').map(g => g.trim()).filter(g => g);
                        } else if (['sets', 'reps', 'weight', 'restTimeSeconds', 'restBetweenSets', 'restBetweenExercises'].includes(field)) {
                            exercise[field] = editingValue ? parseInt(editingValue) : null;
                        } else {
                            exercise[field] = editingValue;
                        }
                    }
                }
            }

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    ...newRutina,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                
                // Si se está editando el nombre de la rutina, actualizar también el estado de allRoutines
                if (editingField === 'name') {
                    const updatedRoutines = [...allRoutines];
                    updatedRoutines[selectedRoutineIndex] = {
                        ...updatedRoutines[selectedRoutineIndex],
                        name: editingValue
                    };
                    setAllRoutines(updatedRoutines);
                    
                    enqueueSnackbar('Nombre de rutina actualizado correctamente', { variant: 'success' });
                }
            }

            handleCancelEdit();
        } catch (error) {
            console.error("Error al guardar la edición:", error);
            enqueueSnackbar('Error al guardar los cambios', { variant: 'error' });
        }
    };

    const handleOpenNewExerciseModal = (dayIndex) => {
        setNewExerciseDayIndex(dayIndex);
        setNewExercise({
            name: '',
            sets: '',
            reps: '',
            weight: '',
            muscleGroups: [],
            restTimeSeconds: '',
            restBetweenSets: '',
            restBetweenExercises: '',
            instructions: '',
            rir: ''
        });
        setOpenNewExerciseModal(true);
    };

    const handleCloseNewExerciseModal = () => {
        setOpenNewExerciseModal(false);
        setNewExerciseDayIndex(null);
        setNewExercise({
            name: '',
            sets: '',
            reps: '',
            weight: '',
            muscleGroups: [],
            restTimeSeconds: '',
            restBetweenSets: '',
            restBetweenExercises: '',
            instructions: '',
            rir: ''
        });
    };

    const findResourceSuggestion = async (exerciseName, muscleGroups = []) => {
        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // Normalizar nombres para la búsqueda
            const normalizeText = (text) => {
                if (!text) return '';
                return text.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")  // Eliminar acentos
                    .replace(/[^\w\s]/g, "")         // Eliminar signos de puntuación
                    .replace(/\s+/g, " ")           // Normalizar espacios
                    .trim();
            };
            
            const exerciseNameNormalized = normalizeText(exerciseName);
            const exerciseWords = exerciseNameNormalized.split(" ").filter(word => word.length > 3);
            
            // Sistema de puntuación
            const scoredResources = recursosData.map(recurso => {
                let score = 0;
                const tituloNormalized = normalizeText(recurso.titulo);
                
                // Coincidencia exacta o parcial del título
                if (tituloNormalized === exerciseNameNormalized) {
                    score += 100; // Coincidencia exacta
                } else if (tituloNormalized.includes(exerciseNameNormalized)) {
                    score += 50;  // El título contiene el ejercicio completo
                } else if (exerciseNameNormalized.includes(tituloNormalized)) {
                    score += 40;  // El ejercicio contiene el título completo
                }
                
                // Coincidencia por palabras
                exerciseWords.forEach(word => {
                    if (tituloNormalized.includes(word)) {
                        score += 10; // Puntos por cada palabra coincidente
                    }
                });
                
                // Coincidencia por categoría (usando las categorías múltiples)
                if (Array.isArray(recurso.categoria) && muscleGroups && muscleGroups.length > 0) {
                    // Normalizar los grupos musculares también
                    const normalizedMuscleGroups = muscleGroups.map(group => normalizeText(group));
                    
                    recurso.categoria.forEach(cat => {
                        const normalizedCat = normalizeText(cat);
                        if (normalizedMuscleGroups.some(group => 
                            normalizedCat.includes(group) || 
                            normalizedMuscleGroups.some(g => g.includes(normalizedCat))
                        )) {
                            score += 25; // Puntos por categoría coincidente
                        }
                    });
                }
                
                // Bonus para videos (preferimos videos sobre otros tipos)
                if (recurso.fileType === 'video') {
                    score += 15;
                }
                
                return { ...recurso, score };
            });
            
            // Ordenar por puntuación y obtener el mejor
            scoredResources.sort((a, b) => b.score - a.score);
            const bestMatch = scoredResources.length > 0 && scoredResources[0].score > 20 
                ? scoredResources[0] 
                : null;
                
            setSuggestedResource(bestMatch);
            return bestMatch;
        } catch (error) {
            console.error("Error al buscar sugerencias:", error);
            setSuggestedResource(null);
            return null;
        }
    };

    // Nueva función para asignar automáticamente recursos a todos los ejercicios
    const autoAssignResources = async () => {
        if (!rutina || !rutina.days) return;
        
        setIsLoading(true);
        try {
            // Hacer una copia profunda para trabajar
            const updatedRutina = JSON.parse(JSON.stringify(rutina));
            let hasChanges = false;
            
            // Recorrer todos los días y ejercicios
            for (let dayIndex = 0; dayIndex < updatedRutina.days.length; dayIndex++) {
                const day = updatedRutina.days[dayIndex];
                
                // Procesar ejercicios individuales
                for (let exerciseIndex = 0; exerciseIndex < day.exercises.length; exerciseIndex++) {
                    const exercise = day.exercises[exerciseIndex];
                    
                    // Solo buscar para ejercicios sin recursos
                    if (!exercise.recursos || exercise.recursos.length === 0) {
                        // Buscar un recurso coincidente
                        const matchingResource = await findResourceSuggestion(
                            exercise.name, 
                            exercise.muscleGroups
                        );
                        
                        if (matchingResource) {
                            // Inicializar array de recursos si no existe
                            if (!exercise.recursos) {
                                exercise.recursos = [];
                            }
                            
                            // Añadir el recurso encontrado
                            exercise.recursos.push({
                                id: matchingResource.id,
                                tipo: matchingResource.fileType,
                                url: matchingResource.fileUrl,
                                titulo: matchingResource.titulo
                            });
                            
                            hasChanges = true;
                        }
                    }
                }
                
                // Procesar ejercicios de circuitos
                if (day.circuitos && day.circuitos.length > 0) {
                    for (let circuitoIndex = 0; circuitoIndex < day.circuitos.length; circuitoIndex++) {
                        const circuito = day.circuitos[circuitoIndex];
                        
                        if (circuito.exercises && circuito.exercises.length > 0) {
                            for (let exerciseIndex = 0; exerciseIndex < circuito.exercises.length; exerciseIndex++) {
                                const exercise = circuito.exercises[exerciseIndex];
                                
                                // Solo buscar para ejercicios sin recursos
                                if (!exercise.recursos || exercise.recursos.length === 0) {
                                    // Buscar un recurso coincidente
                                    const matchingResource = await findResourceSuggestion(
                                        exercise.name, 
                                        exercise.muscleGroups
                                    );
                                    
                                    if (matchingResource) {
                                        // Inicializar array de recursos si no existe
                                        if (!exercise.recursos) {
                                            exercise.recursos = [];
                                        }
                                        
                                        // Añadir el recurso encontrado
                                        exercise.recursos.push({
                                            id: matchingResource.id,
                                            tipo: matchingResource.fileType,
                                            url: matchingResource.fileUrl,
                                            titulo: matchingResource.titulo
                                        });
                                        
                                        hasChanges = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            // Guardar los cambios en Firestore si se encontró al menos un recurso
            if (hasChanges) {
                const routinesRef = collection(db, 'routines');
                const q = query(
                    routinesRef,
                    where('IDPT', '==', userDetails.IDPT),
                    where('clientId', '==', clientId),
                    orderBy('updatedAt', 'desc'),
                    limit(1)
                );
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    const routineDoc = querySnapshot.docs[0];
                    await updateDoc(doc(db, 'routines', routineDoc.id), {
                        days: updatedRutina.days,
                        updatedAt: serverTimestamp()
                    });
                    
                    setRutina(updatedRutina);
                    enqueueSnackbar('Videos asignados automáticamente', { 
                        variant: 'success' 
                    });
                }
            } else {
                enqueueSnackbar('No se encontraron coincidencias para asignar videos', { 
                    variant: 'info' 
                });
            }
        } catch (error) {
            console.error("Error al asignar recursos automáticamente:", error);
            enqueueSnackbar('Error al asignar videos automáticamente', { 
                variant: 'error' 
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddSuggestedResource = async (dayIndex, exerciseIndex) => {
        if (!suggestedResource) return;

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === dayIndex);
            if (!day || !day.exercises[exerciseIndex]) return;

            const exercise = day.exercises[exerciseIndex];
            
            // Añadir el recurso sugerido
            if (!exercise.recursos) {
                exercise.recursos = [];
            }

            exercise.recursos.push({
                id: suggestedResource.id,
                tipo: suggestedResource.fileType,
                url: suggestedResource.fileUrl,
                titulo: suggestedResource.titulo
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                setSuggestedResource(null);
                handleCloseNewExerciseModal();
            }
        } catch (error) {
            console.error("Error al añadir recurso sugerido:", error);
            alert('Error al añadir el recurso sugerido: ' + error.message);
        }
    };

    const handleDeleteExercise = async (dayIndex, exerciseIndex) => {
        setDeleteExerciseData({ dayIndex, exerciseIndex });
        setDeleteConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const { dayIndex, exerciseIndex } = deleteExerciseData;
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === dayIndex);
            
            if (!day) {
                throw new Error('Día no encontrado');
            }

            // Eliminar el ejercicio del array
            day.exercises.splice(exerciseIndex, 1);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar ejercicio:", error);
            alert('Error al eliminar el ejercicio: ' + error.message);
        } finally {
            setDeleteConfirmOpen(false);
            setDeleteExerciseData(null);
        }
    };

    const handleCancelDelete = () => {
        setDeleteConfirmOpen(false);
        setDeleteExerciseData(null);
    };

    const handleOpenNewDayModal = () => {
        setNewDay({
            dayName: '',
            weekDays: []
        });
        setOpenNewDayModal(true);
    };

    const handleCloseNewDayModal = () => {
        setOpenNewDayModal(false);
        setNewDay({
            dayName: '',
            weekDays: []
        });
    };

    const handleAddNewDay = async () => {
        if (!newDay.dayName) {
            alert('El nombre del día es obligatorio');
            return;
        }

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            
            if (!newRutina.days) {
                newRutina.days = [];
            }

            // Encontrar el siguiente índice disponible
            const maxDayIndex = Math.max(...newRutina.days.map(d => d.dayIndex), 0);
            const newDayIndex = maxDayIndex + 1;

            // Añadir el nuevo día
            newRutina.days.push({
                dayIndex: newDayIndex,
                dayName: newDay.dayName,
                weekDays: newDay.weekDays,
                exercises: [],
                calentamiento: '',
                cardio: '',
                enfriamiento: '',
                circuitos: []
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                handleCloseNewDayModal();
            }
        } catch (error) {
            console.error("Error al añadir día:", error);
            alert('Error al añadir el día: ' + error.message);
        }
    };

    const handleDeleteDay = (dayIndex) => {
        setDeleteDayIndex(dayIndex);
        setDeleteDayConfirmOpen(true);
    };

    const handleConfirmDeleteDay = async () => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const dayIndex = newRutina.days.findIndex(d => d.dayIndex === deleteDayIndex);
            
            if (dayIndex === -1) {
                throw new Error('Día no encontrado');
            }

            // Eliminar el día
            newRutina.days.splice(dayIndex, 1);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar día:", error);
            alert('Error al eliminar el día: ' + error.message);
        } finally {
            setDeleteDayConfirmOpen(false);
            setDeleteDayIndex(null);
        }
    };

    const handleCancelDeleteDay = () => {
        setDeleteDayConfirmOpen(false);
        setDeleteDayIndex(null);
    };

    const filteredRecursos = recursos.filter(recurso => {
        const matchesSearch = recurso.titulo.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesType = selectedType === 'all' || recurso.fileType === selectedType;
        return matchesSearch && matchesType;
    });

    const handleCreateRoutine = () => {
        navigate('/generar', { state: { clientId, type: 'rutina' } });
    };

    const handleNewResourceFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewResourceData(prev => ({
                ...prev,
                file: file
            }));
            const url = URL.createObjectURL(file);
            setPreviewResourceUrl(url);
        }
    };

    const handleCreateResource = async () => {
        if (!newResourceData.titulo || !newResourceData.file || newResourceData.categoria.length === 0) {
            enqueueSnackbar('Por favor, completa todos los campos obligatorios', { 
                variant: 'warning' 
            });
            return;
        }

        setUploadingResource(true);
        try {
            const storage = getStorage();
            const fileName = `${Date.now()}_${newResourceData.file.name}`;
            const storageRefPath = `recursos/${fileName}`;
            const storageRef = ref(storage, storageRefPath);
            
            await uploadBytes(storageRef, newResourceData.file);
            const fileUrl = await getDownloadURL(storageRef);

            const fileType = newResourceData.file.type.startsWith('video/') ? 'video' :
                           newResourceData.file.type.startsWith('image/') ? 'imagen' :
                           newResourceData.file.type.startsWith('audio/') ? 'audio' :
                           newResourceData.file.type === 'application/pdf' ? 'pdf' : 'otro';

            const recursoData = {
                titulo: newResourceData.titulo,
                descripcion: newResourceData.descripcion,
                categoria: newResourceData.categoria,
                fileUrl: fileUrl,
                filePath: storageRefPath,
                fileType: fileType,
                fecha: serverTimestamp(),
                autor: userDetails?.display_name || 'Anónimo'
            };

            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            }

            await addDoc(recursosRef, recursoData);
            
            enqueueSnackbar('Recurso creado exitosamente', { 
                variant: 'success' 
            });
            
            setOpenNewResourceModal(false);
            setNewResourceData({
                titulo: '',
                descripcion: '',
                categoria: [],
                file: null
            });
            setPreviewResourceUrl(null);
            fetchRecursos(); // Recargar la lista de recursos
        } catch (error) {
            console.error("Error al crear el recurso:", error);
            enqueueSnackbar('Error al crear el recurso', { 
                variant: 'error' 
            });
        } finally {
            setUploadingResource(false);
        }
    };

    const renderResourceModal = () => {
        return (
            <Dialog 
                open={openResourceModal} 
                onClose={handleCloseResourceModal}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Añadir Recursos a {selectedExercise?.name}
                        </Typography>
                        <IconButton onClick={handleCloseResourceModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ mb: 3 }}>
                        {/* Filtros y búsqueda */}
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Buscar recursos..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                                    }}
                                />
                            </Box>
                            <Box>
                                <Button
                                    variant={selectedType === 'all' ? 'contained' : 'outlined'}
                                    size="small"
                                    onClick={() => setSelectedType('all')}
                                    sx={{ mr: 1 }}
                                >
                                    Todos
                                </Button>
                                <Button
                                    variant={selectedType === 'video' ? 'contained' : 'outlined'}
                                    size="small"
                                    startIcon={<VideoLibraryIcon />}
                                    onClick={() => setSelectedType('video')}
                                    sx={{ mr: 1 }}
                                >
                                    Videos
                                </Button>
                                <Button
                                    variant={selectedType === 'imagen' ? 'contained' : 'outlined'}
                                    size="small"
                                    startIcon={<ImageIcon />}
                                    onClick={() => setSelectedType('imagen')}
                                    sx={{ mr: 1 }}
                                >
                                    Imágenes
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={() => setOpenNewResourceModal(true)}
                                    sx={{
                                        background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                        color: 'white',
                                        '&:hover': {
                                            background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                        }
                                    }}
                                >
                                    Nuevo Recurso
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    {loadingRecursos ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : filteredRecursos.length === 0 ? (
                        <Box sx={{ textAlign: 'center', py: 4 }}>
                            <Typography color="text.secondary">
                                No se encontraron recursos
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
                            <ImageList cols={3} gap={8} sx={{ width: '100%' }}>
                                {filteredRecursos.map((recurso) => (
                                    <ImageListItem 
                                        key={recurso.id}
                                        sx={{ 
                                            cursor: 'pointer',
                                            '&:hover': { 
                                                '& .resource-overlay': {
                                                    opacity: 1
                                                }
                                            },
                                            position: 'relative',
                                            borderRadius: 1,
                                            overflow: 'hidden',
                                            height: '240px !important'
                                        }}
                                        onClick={() => handleAddResource(recurso)}
                                        onMouseEnter={() => setPreviewResource(recurso)}
                                        onMouseLeave={() => setPreviewResource(null)}
                                    >
                                        <Box sx={{ height: '200px', overflow: 'hidden' }}>
                                            {recurso.fileType === 'video' ? (
                                                <Box sx={{ position: 'relative', height: '100%' }}>
                                                    <video
                                                        src={recurso.fileUrl}
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    />
                                                    <Box 
                                                        sx={{ 
                                                            position: 'absolute', 
                                                            bottom: 0, 
                                                            left: 0, 
                                                            right: 0, 
                                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                            color: 'white',
                                                            padding: '6px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            fontSize: '0.75rem'
                                                        }}
                                                    >
                                                        {recurso.titulo}
                                                    </Box>
                                                </Box>
                                            ) : recurso.fileType === 'imagen' ? (
                                                <Box sx={{ position: 'relative', height: '100%' }}>
                                                    <img
                                                        src={recurso.fileUrl}
                                                        alt={recurso.titulo}
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    />
                                                    <Box 
                                                        sx={{ 
                                                            position: 'absolute', 
                                                            bottom: 0, 
                                                            left: 0, 
                                                            right: 0, 
                                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                            color: 'white',
                                                            padding: '6px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            fontSize: '0.75rem'
                                                        }}
                                                    >
                                                        {recurso.titulo}
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        bgcolor: 'grey.100'
                                                    }}
                                                >
                                                    <VideoLibraryIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                                                    <Typography variant="caption" sx={{ mt: 1, px: 2, textAlign: 'center' }}>
                                                        {recurso.titulo}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                bgcolor: 'background.paper',
                                                borderTop: '1px solid',
                                                borderColor: 'divider',
                                                p: 1,
                                                height: '40px',
                                                display: 'none'
                                            }}
                                        >
                                            <Typography variant="subtitle2" noWrap>
                                                {recurso.titulo}
                                            </Typography>
                                        </Box>
                                        <Box
                                            className="resource-overlay"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                height: '200px',
                                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                                color: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                opacity: 0,
                                                transition: 'opacity 0.2s'
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Click para añadir
                                            </Typography>
                                        </Box>
                                    </ImageListItem>
                                ))}
                            </ImageList>

                            {/* Preview Panel - Fixed position */}
                            {previewResource && (
                                <Box 
                                    sx={{ 
                                        position: 'absolute',
                                        top: 0,
                                        right: -320,
                                        width: '300px',
                                        p: 2,
                                        bgcolor: 'grey.50',
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        boxShadow: 2,
                                        zIndex: 1
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {previewResource.titulo}
                                    </Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <Chip 
                                            label={previewResource.fileType}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                    {previewResource.fileType === 'video' && (
                                        <Box sx={{ position: 'relative' }}>
                                            <video
                                                src={previewResource.fileUrl}
                                                controls
                                                style={{ width: '100%', borderRadius: 4 }}
                                            />
                                            <Box 
                                                sx={{ 
                                                    position: 'absolute', 
                                                    bottom: 0, 
                                                    left: 0, 
                                                    right: 0, 
                                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                    color: 'white',
                                                    padding: '8px',
                                                    borderBottomLeftRadius: 4,
                                                    borderBottomRightRadius: 4,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {previewResource.titulo}
                                            </Box>
                                        </Box>
                                    )}
                                    {previewResource.fileType === 'imagen' && (
                                        <Box sx={{ position: 'relative' }}>
                                            <img
                                                src={previewResource.fileUrl}
                                                alt={previewResource.titulo}
                                                style={{ width: '100%', borderRadius: 4 }}
                                            />
                                            <Box 
                                                sx={{ 
                                                    position: 'absolute', 
                                                    bottom: 0, 
                                                    left: 0, 
                                                    right: 0, 
                                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                    color: 'white',
                                                    padding: '8px',
                                                    borderBottomLeftRadius: 4,
                                                    borderBottomRightRadius: 4,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {previewResource.titulo}
                                            </Box>
                                        </Box>
                                    )}
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => handleAddResource(previewResource)}
                                        sx={{ 
                                            mt: 2,
                                            borderRadius: '12px',
                                            textTransform: 'none',
                                            px: 3,
                                            py: 1,
                                            fontSize: '0.95rem',
                                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                                            '&:hover': {
                                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                                transform: 'translateY(-1px)'
                                            },
                                            '&:active': {
                                                transform: 'translateY(0)',
                                            },
                                            transition: 'all 0.3s ease'
                                        }}
                                    >
                                        Añadir Recurso
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };

    const renderNewExerciseModal = () => {
        return (
            <Dialog
                open={openNewExerciseModal}
                onClose={handleCloseNewExerciseModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Nuevo Ejercicio
                        </Typography>
                        <IconButton onClick={handleCloseNewExerciseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            label="Nombre del ejercicio"
                            value={newExercise.name}
                            onChange={(e) => setNewExercise({ ...newExercise, name: e.target.value })}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Series"
                            type="number"
                            value={newExercise.sets}
                            onChange={(e) => setNewExercise({ ...newExercise, sets: e.target.value })}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Repeticiones"
                            type="number"
                            value={newExercise.reps}
                            onChange={(e) => setNewExercise({ ...newExercise, reps: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Peso (kg)"
                            type="number"
                            value={newExercise.weight}
                            onChange={(e) => setNewExercise({ ...newExercise, weight: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Grupos musculares (separados por comas)"
                            value={newExercise.muscleGroups.join(', ')}
                            onChange={(e) => setNewExercise({ 
                                ...newExercise, 
                                muscleGroups: e.target.value.split(',').map(g => g.trim()).filter(g => g)
                            })}
                            fullWidth
                        />
                        <TextField
                            label="Descanso entre series (segundos)"
                            type="number"
                            value={newExercise.restBetweenSets}
                            onChange={(e) => setNewExercise({ ...newExercise, restBetweenSets: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Descanso entre ejercicios (segundos)"
                            type="number"
                            value={newExercise.restBetweenExercises}
                            onChange={(e) => setNewExercise({ ...newExercise, restBetweenExercises: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Tiempo de descanso general (segundos)"
                            type="number"
                            value={newExercise.restTimeSeconds}
                            onChange={(e) => setNewExercise({ ...newExercise, restTimeSeconds: e.target.value })}
                            fullWidth
                        />
                        <Tooltip title="RIR (Repeticiones en Reserva) indica cuántas repeticiones podrías hacer además de las prescritas. También puedes usar notación RPE." placement="top">
                            <TextField
                                label="RIR"
                                value={newExercise.rir}
                                onChange={(e) => setNewExercise({ ...newExercise, rir: e.target.value })}
                                fullWidth
                                size="small"
                                placeholder="ej: 2, 2-3, RPE8"
                                sx={{ mt: 1 }}
                            />
                        </Tooltip>
                        <TextField
                            label="Instrucciones"
                            multiline
                            rows={4}
                            value={newExercise.instructions}
                            onChange={(e) => setNewExercise({ ...newExercise, instructions: e.target.value })}
                            fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: 'column', alignItems: 'stretch', p: 2, gap: 2 }}>
                    {suggestedResource && (
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 2, 
                            bgcolor: 'success.light', 
                            p: 2, 
                            borderRadius: 1 
                        }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="subtitle2" color="white">
                                    ¡Sugerencia encontrada!
                                </Typography>
                                <Typography variant="body2" color="white">
                                    {suggestedResource.titulo}
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => handleAddSuggestedResource(newExerciseDayIndex, rutina.days.find(d => d.dayIndex === newExerciseDayIndex)?.exercises?.length || 0)}
                            >
                                Añadir Sugerencia
                            </Button>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, width: '100%' }}>
                        <Button onClick={handleCloseNewExerciseModal}>
                            Cancelar
                        </Button>
                        <Button 
                            variant="contained" 
                            onClick={handleAddNewExercise}
                            disabled={!newExercise.name || !newExercise.sets}
                        >
                            Añadir Ejercicio
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    };

    const renderNewDayModal = () => {
        return (
            <Dialog
                open={openNewDayModal}
                onClose={handleCloseNewDayModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Nuevo Día
                        </Typography>
                        <IconButton onClick={handleCloseNewDayModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            label="Nombre del día"
                            value={newDay.dayName}
                            onChange={(e) => setNewDay({ ...newDay, dayName: e.target.value })}
                            fullWidth
                            required
                        />
                        <Typography variant="subtitle2" gutterBottom>
                            Días de la semana
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1.5, my: 1 }}>
                            {diasSemana.map((dia, index) => {
                                const isActive = newDay.weekDays.includes(dia);
                                const dayLabel = dia.charAt(0);
                                return (
                                    <Tooltip key={index} title={dia}>
                                        <Box
                                            sx={{
                                                width: 36,
                                                height: 36,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                bgcolor: isActive ? 'primary.main' : 'grey.200',
                                                color: isActive ? 'white' : 'text.secondary',
                                                transition: 'all 0.2s',
                                                cursor: 'pointer',
                                                boxShadow: isActive ? '0 2px 4px rgba(0,0,0,0.2)' : 'none',
                                                '&:hover': {
                                                    transform: 'scale(1.1)',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                                                }
                                            }}
                                            onClick={() => {
                                                const weekDays = newDay.weekDays.includes(dia)
                                                    ? newDay.weekDays.filter(d => d !== dia)
                                                    : [...newDay.weekDays, dia];
                                                setNewDay({ ...newDay, weekDays });
                                            }}
                                        >
                                            {dayLabel}
                                        </Box>
                                    </Tooltip>
                                );
                            })}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewDayModal}>
                        Cancelar
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleAddNewDay}
                        disabled={!newDay.dayName}
                    >
                        Añadir Día
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderNewResourceModal = () => {
        return (
            <Dialog
                open={openNewResourceModal}
                onClose={() => setOpenNewResourceModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Crear Nuevo Recurso
                        </Typography>
                        <IconButton onClick={() => setOpenNewResourceModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            fullWidth
                            label="Título del recurso"
                            value={newResourceData.titulo}
                            onChange={(e) => setNewResourceData(prev => ({
                                ...prev,
                                titulo: e.target.value
                            }))}
                            required
                        />

                        <TextField
                            fullWidth
                            label="Descripción"
                            multiline
                            rows={3}
                            value={newResourceData.descripcion}
                            onChange={(e) => setNewResourceData(prev => ({
                                ...prev,
                                descripcion: e.target.value
                            }))}
                        />

                        <FormControl fullWidth>
                            <InputLabel>Categorías</InputLabel>
                            <Select
                                multiple
                                value={newResourceData.categoria}
                                onChange={(e) => setNewResourceData(prev => ({
                                    ...prev,
                                    categoria: e.target.value
                                }))}
                                label="Categorías"
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {categorias.map((cat) => (
                                    <MenuItem key={cat} value={cat}>
                                        <Checkbox checked={newResourceData.categoria.indexOf(cat) > -1} />
                                        <ListItemText primary={cat} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button
                            variant="outlined"
                            component="label"
                            fullWidth
                            startIcon={<UploadFileIcon />}
                        >
                            {newResourceData.file ? 'Cambiar archivo' : 'Subir archivo'}
                            <input
                                type="file"
                                hidden
                                accept="video/*,image/*,audio/*,.pdf"
                                onChange={handleNewResourceFileChange}
                            />
                        </Button>

                        {previewResourceUrl && newResourceData.file && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Vista previa:
                                </Typography>
                                <Box sx={{ 
                                    width: '100%',
                                    height: '200px',
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                    bgcolor: 'grey.100'
                                }}>
                                    {newResourceData.file.type.startsWith('video/') ? (
                                        <video
                                            src={previewResourceUrl}
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                            controls
                                        />
                                    ) : newResourceData.file.type.startsWith('image/') ? (
                                        <img
                                            src={previewResourceUrl}
                                            alt="Vista previa"
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        />
                                    ) : (
                                        <Box sx={{ 
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Typography>{newResourceData.file.name}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenNewResourceModal(false)}
                        disabled={uploadingResource}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCreateResource}
                        disabled={uploadingResource || !newResourceData.titulo || !newResourceData.file || newResourceData.categoria.length === 0}
                        startIcon={uploadingResource ? <CircularProgress size={20} /> : null}
                    >
                        {uploadingResource ? 'Creando...' : 'Crear Recurso'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderExercise = (exercise, dayIndex, exerciseIndex) => {
        return (
            <Box
                sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: 2,
                    backgroundColor: 'background.default',
                    border: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                        {editingField === 'exerciseName' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Typography 
                                variant="subtitle1" 
                                fontWeight="bold"
                                onClick={() => handleStartEdit('exerciseName', exercise.name, dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 0.5,
                                    borderRadius: 1
                                }}
                            >
                                {exercise.name}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            startIcon={<AddIcon />}
                            size="small"
                            onClick={() => handleOpenResourceModal(exercise, dayIndex, exerciseIndex)}
                        >
                            Añadir Recurso
                        </Button>
                        <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleDeleteExercise(dayIndex, exerciseIndex)}
                            sx={{
                                '&:hover': {
                                    bgcolor: 'error.light',
                                    color: 'white'
                                }
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>

                {/* Grupos Musculares */}
                <Box sx={{ mb: 2 }}>
                    {editingField === 'exerciseMuscleGroups' && 
                     editingDayIndex === dayIndex && 
                     editingExerciseIndex === exerciseIndex ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                value={editingValue}
                                onChange={(e) => setEditingValue(e.target.value)}
                                variant="standard"
                                size="small"
                                autoFocus
                                placeholder="Separar grupos musculares por comas"
                            />
                            <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                <CheckIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleCancelEdit}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    ) : exercise.muscleGroups && exercise.muscleGroups.length > 0 ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {exercise.muscleGroups.map((muscle, index) => (
                                <Chip
                                    key={index}
                                    label={muscle}
                                    size="small"
                                    color="secondary"
                                    onClick={() => handleStartEdit('exerciseMuscleGroups', exercise.muscleGroups.join(', '), dayIndex, exerciseIndex)}
                                />
                            ))}
                        </Box>
                    ) : (
                        <Button
                            size="small"
                            onClick={() => handleStartEdit('exerciseMuscleGroups', '', dayIndex, exerciseIndex)}
                            sx={{ mb: 1 }}
                        >
                            Añadir grupos musculares
                        </Button>
                    )}
                </Box>

                {/* Series, Repeticiones y Peso */}
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            p: 1.5,
                            bgcolor: 'grey.50',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider',
                            width: '100%'
                        }}
                    >
                        {editingField === 'exerciseSets' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseSets', exercise.sets.toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.sets}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Series
                                </Typography>
                            </Box>
                        )}

                        {editingField === 'exerciseReps' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseReps', (exercise.reps || 0).toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100,
                                    borderLeft: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.reps || 0}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Repeticiones
                                </Typography>
                            </Box>
                        )}

                        {editingField === 'exerciseWeight' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseWeight', (exercise.weight || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100,
                                    borderLeft: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.weight || 0}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Peso (kg)
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* Tiempos de descanso */}
                <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    {/* Descanso entre series */}
                    <Box sx={{ flex: 1, minWidth: '200px' }}>
                        {editingField === 'exerciseRestBetweenSets' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Chip
                                label={`Descanso entre series: ${exercise.restBetweenSets || 0}s`}
                                onClick={() => handleStartEdit('exerciseRestBetweenSets', (exercise.restBetweenSets || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                    </Box>

                    {/* Descanso entre ejercicios */}
                    <Box sx={{ flex: 1, minWidth: '200px' }}>
                        {editingField === 'exerciseRestBetweenExercises' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Chip
                                label={`Descanso después: ${exercise.restBetweenExercises || 0}s`}
                                onClick={() => handleStartEdit('exerciseRestBetweenExercises', (exercise.restBetweenExercises || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                    </Box>

                    {/* Tiempo de descanso general */}
                    <Box sx={{ flex: 1, minWidth: '200px' }}>
                        {editingField === 'exerciseRestTimeSeconds' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    type="number"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Chip
                                label={`Descanso general: ${exercise.restTimeSeconds || 0}s`}
                                onClick={() => handleStartEdit('exerciseRestTimeSeconds', (exercise.restTimeSeconds || '').toString(), dayIndex, exerciseIndex)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                    </Box>
                </Box>

                {editingField === 'exerciseRir' && 
                 editingDayIndex === dayIndex && 
                 editingExerciseIndex === exerciseIndex ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="standard"
                            size="small"
                            autoFocus
                            placeholder="ej: 2, 2-3, RPE8"
                        />
                        <IconButton size="small" onClick={handleSaveEdit} color="primary">
                            <CheckIcon />
                        </IconButton>
                        <IconButton size="small" onClick={handleCancelEdit}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                ) : (
                    <Tooltip title="RIR (Repeticiones en Reserva) indica cuántas repeticiones podrías hacer además de las prescritas. También puedes usar notación RPE." placement="top">
                        <Chip
                            label={exercise.rir ? `RIR: ${exercise.rir}` : "Añadir RIR"}
                            onClick={() => handleStartEdit('exerciseRir', exercise.rir || '', dayIndex, exerciseIndex)}
                            sx={{ 
                                cursor: 'pointer',
                                bgcolor: exercise.rir ? 'primary.main' : 'grey.100',
                                color: exercise.rir ? 'white' : 'text.primary',
                                '&:hover': {
                                    bgcolor: exercise.rir ? 'primary.dark' : 'grey.200'
                                },
                                height: '24px'
                            }}
                            size="small"
                        />
                    </Tooltip>
                )}

                {editingField === 'exerciseInstructions' && 
                 editingDayIndex === dayIndex && 
                 editingExerciseIndex === exerciseIndex ? (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            autoFocus
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                            <Button size="small" onClick={handleCancelEdit}>
                                Cancelar
                            </Button>
                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                ) : exercise.instructions ? (
                    <Box sx={{ 
                        mt: 2,
                        p: 2,
                        backgroundColor: 'grey.50',
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'grey.200',
                        cursor: 'pointer',
                        '&:hover': { bgcolor: 'grey.100' }
                    }}
                    onClick={() => handleStartEdit('exerciseInstructions', exercise.instructions, dayIndex, exerciseIndex)}
                    >
                        <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
                            {exercise.instructions}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('exerciseInstructions', '', dayIndex, exerciseIndex)}
                        sx={{ mt: 2 }}
                        size="small"
                    >
                        Añadir instrucciones
                    </Button>
                )}
                
                {exercise.recursos && exercise.recursos.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Recursos:
                        </Typography>
                        <ImageList cols={3} gap={8} sx={{ mt: 1 }}>
                            {exercise.recursos.map((recurso) => (
                                <ImageListItem 
                                    key={recurso.id}
                                    sx={{ position: 'relative', height: 'auto !important' }}
                                >
                                    <Box sx={{ position: 'relative' }}>
                                        {recurso.tipo === 'video' ? (
                                            <Box sx={{ position: 'relative' }}>
                                                <video
                                                    src={recurso.url}
                                                    controls
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
                                                />
                                                <Box 
                                                    sx={{ 
                                                        position: 'absolute', 
                                                        bottom: 0, 
                                                        left: 0, 
                                                        right: 0, 
                                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                        color: 'white',
                                                        padding: '8px',
                                                        borderBottomLeftRadius: '8px',
                                                        borderBottomRightRadius: '8px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {recurso.titulo}
                                                </Box>
                                            </Box>
                                        ) : recurso.tipo === 'imagen' ? (
                                            <Box sx={{ position: 'relative' }}>
                                                <img
                                                    src={recurso.url}
                                                    alt={recurso.titulo}
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
                                                />
                                                <Box 
                                                    sx={{ 
                                                        position: 'absolute', 
                                                        bottom: 0, 
                                                        left: 0, 
                                                        right: 0, 
                                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                        color: 'white',
                                                        padding: '8px',
                                                        borderBottomLeftRadius: '8px',
                                                        borderBottomRightRadius: '8px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {recurso.titulo}
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    bgcolor: 'grey.100',
                                                    p: 2,
                                                    borderRadius: '8px'
                                                }}
                                            >
                                                <VideoLibraryIcon sx={{ fontSize: 40 }} />
                                                <Typography variant="caption" sx={{ mt: 1, textAlign: 'center' }}>
                                                    {recurso.titulo}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                bgcolor: 'rgba(0, 0, 0, 0.7)',
                                            },
                                            color: 'white'
                                        }}
                                        onClick={() => handleDeleteResource(dayIndex, exerciseIndex, recurso.id)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                )}
            </Box>
        );
    };

    const handleAddNewExercise = async () => {
        if (!newExercise.name || !newExercise.sets) {
            alert('El nombre y las series son obligatorios');
            return;
        }

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === newExerciseDayIndex);
            
            if (!day) {
                throw new Error('Día no encontrado');
            }

            if (!day.exercises) {
                day.exercises = [];
            }

            // Buscar sugerencia de recurso con la función mejorada
            await findResourceSuggestion(newExercise.name, newExercise.muscleGroups);

            // Añadir el nuevo ejercicio con la estructura correcta
            day.exercises.push({
                name: newExercise.name,
                sets: parseInt(newExercise.sets),
                reps: newExercise.reps ? parseInt(newExercise.reps) : 0,
                weight: newExercise.weight ? parseInt(newExercise.weight) : 0,
                muscleGroups: newExercise.muscleGroups,
                restTimeSeconds: newExercise.restTimeSeconds ? parseInt(newExercise.restTimeSeconds) : 0,
                restBetweenSets: newExercise.restBetweenSets ? parseInt(newExercise.restBetweenSets) : 0,
                restBetweenExercises: newExercise.restBetweenExercises ? parseInt(newExercise.restBetweenExercises) : 0,
                instructions: newExercise.instructions || '',
                rir: newExercise.rir || '',
                recursos: []
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('updatedAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: serverTimestamp()
                });
                setRutina(newRutina);
                // No cerramos el modal si hay una sugerencia
                if (!suggestedResource) {
                    handleCloseNewExerciseModal();
                }
            }
        } catch (error) {
            console.error("Error al añadir ejercicio:", error);
            alert('Error al añadir el ejercicio: ' + error.message);
        }
    };

    // Nueva función para cambiar entre rutinas
    const handleRoutineChange = (index) => {
        if (index >= 0 && index < allRoutines.length) {
            setSelectedRoutineIndex(index);
            setRutina(allRoutines[index]);
        }
    };
    
    // Nueva función para navegar a la página de creación de rutina
    const handleNavigateToCreateRoutine = () => {
        navigate('/generar', { state: { clientId, type: 'rutina' } });
    };
    
    // Nueva función para eliminar la rutina seleccionada
    const handleDeleteRoutineClick = () => {
        setDeleteRoutineConfirmOpen(true);
    };
    
    const handleCancelDeleteRoutine = () => {
        setDeleteRoutineConfirmOpen(false);
    };
    
    const handleConfirmDeleteRoutine = async () => {
        try {
            if (!rutina || !rutina.id) return;
            
            setIsLoading(true);
            
            // Eliminar la rutina de Firestore
            await deleteDoc(doc(db, 'routines', rutina.id));
            
            // Actualizar el estado local
            const updatedRoutines = allRoutines.filter(r => r.id !== rutina.id);
            setAllRoutines(updatedRoutines);
            
            // Si no quedan rutinas, establecer rutina a null
            if (updatedRoutines.length === 0) {
                setRutina(null);
            } else {
                // Seleccionar la primera rutina disponible
                setSelectedRoutineIndex(0);
                setRutina(updatedRoutines[0]);
            }
            
            enqueueSnackbar('Rutina eliminada correctamente', { variant: 'success' });
        } catch (error) {
            console.error("Error al eliminar la rutina:", error);
            enqueueSnackbar('Error al eliminar la rutina', { variant: 'error' });
        } finally {
            setDeleteRoutineConfirmOpen(false);
            setIsLoading(false);
        }
    };

    // Nueva función para iniciar la edición del nombre de una rutina en las pestañas
    const handleStartTabEdit = (event, index) => {
        event.stopPropagation(); // Evitar que se active la pestaña al hacer clic en el icono de edición
        setEditingTabIndex(index);
        setEditingTabName(allRoutines[index].name || `Rutina ${index + 1}`);
    };

    // Nueva función para guardar el nombre editado de la rutina
    const handleSaveTabEdit = async (event) => {
        event.stopPropagation(); // Evitar que se active la pestaña
        
        try {
            if (editingTabIndex === null || !allRoutines[editingTabIndex]) return;
            
            const routineId = allRoutines[editingTabIndex].id;
            const newName = editingTabName.trim();
            
            if (!newName) {
                enqueueSnackbar('El nombre de la rutina no puede estar vacío', { variant: 'error' });
                return;
            }
            
            // Actualizar en Firestore
            const routineRef = doc(db, 'routines', routineId);
            await updateDoc(routineRef, {
                name: newName,
                updatedAt: serverTimestamp()
            });
            
            // Actualizar el estado local
            const updatedRoutines = [...allRoutines];
            updatedRoutines[editingTabIndex] = {
                ...updatedRoutines[editingTabIndex],
                name: newName
            };
            
            setAllRoutines(updatedRoutines);
            
            // Si la rutina que se está editando es la actual, actualizar también el estado de rutina
            if (editingTabIndex === selectedRoutineIndex) {
                setRutina({
                    ...rutina,
                    name: newName
                });
            }
            
            enqueueSnackbar('Nombre de rutina actualizado correctamente', { variant: 'success' });
        } catch (error) {
            console.error("Error al actualizar el nombre de la rutina:", error);
            enqueueSnackbar('Error al actualizar el nombre de la rutina', { variant: 'error' });
        } finally {
            setEditingTabIndex(null);
            setEditingTabName('');
        }
    };

    // Nueva función para cancelar la edición del nombre de la rutina
    const handleCancelTabEdit = (event) => {
        event.stopPropagation(); // Evitar que se active la pestaña
        setEditingTabIndex(null);
        setEditingTabName('');
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 3 }}>
                {error}
            </Alert>
        );
    }

    if (!rutina || allRoutines.length === 0) {
        return (
            <Box sx={{ p: 3 }}>
                <Paper 
                    elevation={1} 
                    sx={{ 
                        p: 4, 
                        borderRadius: 2,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <FitnessCenterIcon 
                        sx={{ 
                            fontSize: 60,
                            color: 'primary.main',
                            mb: 2
                        }}
                    />
                    <Typography variant="h6" gutterBottom>
                        No hay rutina de entrenamiento asignada
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                        Este cliente aún no tiene una rutina de entrenamiento asignada. 
                        Puedes crear una nueva haciendo clic en el botón de abajo.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleCreateRoutine}
                        sx={{
                            background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
                            color: 'white',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #388E3C 30%, #4CAF50 90%)',
                            }
                        }}
                    >
                        Crear Rutina de Entrenamiento
                    </Button>
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(45deg, rgba(255,165,0,0.1) 0%, rgba(255,192,203,0.1) 100%)',
                    backgroundSize: '400% 400%',
                    animation: `${gradientAnimation} 15s ease infinite`,
                    zIndex: -1
                }}
            />
            <Paper elevation={1} sx={{ 
                p: 3, 
                borderRadius: 2,
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)'
            }}>
                {/* Componente de pestañas para navegar entre rutinas */}
                <Box sx={{ 
                    mb: 3, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        },
                    },
                }}>
                    {allRoutines.length > 0 ? (
                        <Tabs 
                            value={selectedRoutineIndex}
                            onChange={(e, newValue) => handleRoutineChange(newValue)}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{ 
                                flexGrow: 1,
                                '& .MuiTab-root': {
                                    minHeight: '48px',
                                    textTransform: 'none',
                                    fontSize: '0.9rem',
                                    fontWeight: 500,
                                    color: 'text.secondary',
                                    '&.Mui-selected': {
                                        color: 'primary.main',
                                        fontWeight: 600,
                                    },
                                },
                            }}
                        >
                            {allRoutines.map((routine, index) => (
                                <Tab 
                                    key={routine.id} 
                                    label={
                                        editingTabIndex === index ? (
                                            <Box 
                                                sx={{ 
                                                    display: 'flex', 
                                                    alignItems: 'center', 
                                                    gap: 1,
                                                    width: '100%',
                                                    maxWidth: '200px'
                                                }}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <FitnessCenterIcon fontSize="small" />
                                                <TextField
                                                    value={editingTabName}
                                                    onChange={(e) => setEditingTabName(e.target.value)}
                                                    variant="standard"
                                                    size="small"
                                                    autoFocus
                                                    onClick={(e) => e.stopPropagation()}
                                                    sx={{ minWidth: '100px' }}
                                                />
                                                <IconButton 
                                                    size="small" 
                                                    onClick={handleSaveTabEdit} 
                                                    color="primary"
                                                    sx={{ p: 0.5 }}
                                                >
                                                    <CheckIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton 
                                                    size="small" 
                                                    onClick={handleCancelTabEdit}
                                                    sx={{ p: 0.5 }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <Box 
                                                sx={{ 
                                                    display: 'flex', 
                                                    alignItems: 'center', 
                                                    gap: 1,
                                                    position: 'relative',
                                                    '&:hover .edit-icon': {
                                                        opacity: 1,
                                                    }
                                                }}
                                            >
                                                <FitnessCenterIcon fontSize="small" />
                                                <span>{routine.name || `Rutina ${index + 1}`}</span>
                                                <IconButton 
                                                    size="small" 
                                                    className="edit-icon"
                                                    onClick={(e) => handleStartTabEdit(e, index)}
                                                    sx={{ 
                                                        p: 0.5, 
                                                        ml: 0.5,
                                                        opacity: 0,
                                                        transition: 'opacity 0.2s',
                                                        '&:hover': {
                                                            bgcolor: 'rgba(0, 0, 0, 0.04)'
                                                        }
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        )
                                    } 
                                />
                            ))}
                        </Tabs>
                    ) : (
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 2 }}>
                            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                No hay rutinas disponibles
                            </Typography>
                        </Box>
                    )}
                    
                    {/* Botones para añadir y eliminar rutinas */}
                    <Box sx={{ display: 'flex', gap: 1, ml: 1, flexShrink: 0, borderLeft: allRoutines.length > 0 ? 1 : 0, borderColor: 'divider', pl: allRoutines.length > 0 ? 1 : 0 }}>
                        <Tooltip title="Crear nueva rutina">
                            <IconButton 
                                color="primary" 
                                onClick={handleNavigateToCreateRoutine}
                                size="small"
                                sx={{ 
                                    bgcolor: 'rgba(25, 118, 210, 0.1)',
                                    '&:hover': {
                                        bgcolor: 'rgba(25, 118, 210, 0.2)',
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        
                        {allRoutines.length > 0 && (
                            <Tooltip title="Eliminar rutina actual">
                                <IconButton 
                                    color="error" 
                                    onClick={handleDeleteRoutineClick}
                                    size="small"
                                    sx={{ 
                                        bgcolor: 'rgba(211, 47, 47, 0.1)',
                                        '&:hover': {
                                            bgcolor: 'rgba(211, 47, 47, 0.2)',
                                        }
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Box>
                        {editingField === 'name' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    autoFocus
                                    size="small"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Typography 
                                variant="h6" 
                                gutterBottom
                                onClick={() => handleStartEdit('name', rutina.name)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 0.5,
                                    borderRadius: 1
                                }}
                            >
                                {rutina.name}
                            </Typography>
                        )}
                        <Typography variant="body2" color="text.secondary">
                            Última actualización: {rutina.updatedAt ? new Date(rutina.updatedAt.seconds * 1000).toLocaleDateString() : 'No disponible'}
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        startIcon={<VideoLibraryIcon />}
                        onClick={autoAssignResources}
                        disabled={isLoading}
                        sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            fontSize: '0.95rem',
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        {isLoading ? 'Asignando...' : 'Asignar Videos Automáticamente'}
                    </Button>
                </Box>

                {editingField === 'description' ? (
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            autoFocus
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                            <Button size="small" onClick={handleCancelEdit}>
                                Cancelar
                            </Button>
                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                ) : rutina.description ? (
                    <Box sx={{ 
                        p: 2, 
                        mb: 3, 
                        backgroundColor: 'info.main', 
                        borderRadius: 2,
                        display: 'flex',
                        gap: 1,
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        '&:hover': { opacity: 0.9 }
                    }}
                    onClick={() => handleStartEdit('description', rutina.description)}
                    >
                        <InfoIcon sx={{ color: 'white', mt: 0.5 }} />
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {rutina.description}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('description', '')}
                        sx={{ mb: 3 }}
                    >
                        Añadir descripción
                    </Button>
                )}

                {editingField === 'generalInstructions' ? (
                    <Box sx={{ mb: 3 }}>
                        <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        variant="outlined"
                        size="small"
                        autoFocus
                        placeholder="Instrucciones generales de la rutina..."
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                        <Button size="small" onClick={handleCancelEdit}>
                            Cancelar
                        </Button>
                        <Button size="small" variant="contained" onClick={handleSaveEdit}>
                            Guardar
                        </Button>
                    </Box>
                </Box>
                ) : rutina.generalInstructions ? (
                    <Box sx={{ 
                        p: 2, 
                        mb: 3, 
                        backgroundColor: 'background.paper', 
                        borderRadius: 2,
                        display: 'flex',
                        gap: 1,
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        border: '1px solid',
                        borderColor: 'divider',
                        '&:hover': { bgcolor: 'grey.50' }
                    }}
                    onClick={() => handleStartEdit('generalInstructions', rutina.generalInstructions)}
                    >
                        <InfoIcon sx={{ color: 'text.primary', mt: 0.5 }} />
                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                            {rutina.generalInstructions}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('generalInstructions', '')}
                        sx={{ mb: 3 }}
                    >
                        Añadir instrucciones generales
                    </Button>
                )}

                {rutina.days?.map((day) => (
                    <Accordion
                        key={day.dayIndex}
                        defaultExpanded={true}
                        sx={{
                            mb: 2,
                            backgroundColor: 'white',
                            '&:before': { display: 'none' },
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            borderRadius: '8px !important',
                            overflow: 'hidden'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                backgroundColor: 'background.default',
                                '& .MuiAccordionSummary-content': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }
                            }}
                        >
                            <FitnessCenterIcon color="primary" />
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                <Typography variant="subtitle1" fontWeight="500">
                                    Día {day.dayIndex}
                                </Typography>
                                {editingField === 'dayName' && editingDayIndex === day.dayIndex ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            value={editingValue}
                                            onChange={(e) => setEditingValue(e.target.value)}
                                            variant="standard"
                                            size="small"
                                            autoFocus
                                        />
                                        <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={handleCancelEdit}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Chip 
                                        label={day.dayName} 
                                        size="small" 
                                        color="primary" 
                                        variant="outlined"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleStartEdit('dayName', day.dayName, day.dayIndex);
                                        }}
                                        sx={{ cursor: 'pointer' }}
                                    />
                                )}
                                <Box sx={{ flex: 1 }} />
                                <Button
                                    size="small"
                                    startIcon={<CalendarIcon />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDayClick(e, day.dayIndex);
                                    }}
                                    sx={{ ml: 2 }}
                                >
                                    Días de la semana
                                </Button>
                                {day.weekDays && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ml: 1 }}>
                                        {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((dia, index) => {
                                            const isActive = day.weekDays.includes(dia);
                                            const dayLabel = dia.charAt(0);
                                            return (
                                                <Tooltip key={index} title={dia}>
                                                    <Box
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            fontSize: '11px',
                                                            fontWeight: 'bold',
                                                            bgcolor: isActive ? 'primary.main' : 'grey.200',
                                                            color: isActive ? 'white' : 'text.secondary',
                                                            transition: 'all 0.2s',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                transform: 'scale(1.1)',
                                                                boxShadow: isActive ? '0 0 0 2px rgba(25, 118, 210, 0.3)' : 'none'
                                                            }
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDaySelect(dia);
                                                        }}
                                                    >
                                                        {dayLabel}
                                                    </Box>
                                                </Tooltip>
                                            );
                                        })}
                                    </Box>
                                )}
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteDay(day.dayIndex);
                                    }}
                                    sx={{
                                        ml: 1,
                                        '&:hover': {
                                            bgcolor: 'error.light',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 2 }}>
                            {/* Sección de Calentamiento */}
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: 1 }}>
                                    🔥 Calentamiento
                                </Typography>
                                {editingField === 'calentamiento' && editingDayIndex === day.dayIndex ? (
                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={editingValue}
                                            onChange={(e) => setEditingValue(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            autoFocus
                                            placeholder="Describe el calentamiento..."
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                                            <Button size="small" onClick={handleCancelEdit}>
                                                Cancelar
                                            </Button>
                                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                Guardar
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : day.calentamiento ? (
                                    <Box 
                                        sx={{ 
                                            p: 2,
                                            backgroundColor: 'grey.50',
                                            borderRadius: 1,
                                            cursor: 'pointer',
                                            '&:hover': { bgcolor: 'grey.100' }
                                        }}
                                        onClick={() => handleStartEdit('calentamiento', day.calentamiento, day.dayIndex)}
                                    >
                                        <Typography variant="body2">
                                            {day.calentamiento}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleStartEdit('calentamiento', '', day.dayIndex)}
                                        size="small"
                                    >
                                        Añadir calentamiento
                                    </Button>
                                )}
                            </Box>

                            {/* Sección de Cardio */}
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: 1 }}>
                                    ❤️ Cardio
                                </Typography>
                                {editingField === 'cardio' && editingDayIndex === day.dayIndex ? (
                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={editingValue}
                                            onChange={(e) => setEditingValue(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            autoFocus
                                            placeholder="Describe el trabajo cardiovascular..."
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                                            <Button size="small" onClick={handleCancelEdit}>
                                                Cancelar
                                            </Button>
                                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                Guardar
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : day.cardio ? (
                                    <Box 
                                        sx={{ 
                                            p: 2,
                                            backgroundColor: 'grey.50',
                                            borderRadius: 1,
                                            cursor: 'pointer',
                                            '&:hover': { bgcolor: 'grey.100' }
                                        }}
                                        onClick={() => handleStartEdit('cardio', day.cardio, day.dayIndex)}
                                    >
                                        <Typography variant="body2">
                                            {day.cardio}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleStartEdit('cardio', '', day.dayIndex)}
                                        size="small"
                                    >
                                        Añadir cardio
                                    </Button>
                                )}
                            </Box>

                            {/* Título de la sección de ejercicios individuales */}
                            <Typography variant="h6" gutterBottom sx={{ 
                                color: 'primary.main', 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                pb: 1,
                                mb: 2
                            }}>
                                💪 Ejercicios Individuales
                            </Typography>

                            {/* Lista de ejercicios existente */}
                            {day.exercises?.length > 0 ? (
                                <>
                                    {day.exercises.map((exercise, exerciseIndex) => (
                                        <React.Fragment key={exerciseIndex}>
                                            {renderExercise(exercise, day.dayIndex, exerciseIndex)}
                                        </React.Fragment>
                                    ))}
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleOpenNewExerciseModal(day.dayIndex)}
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        Añadir Ejercicio
                                    </Button>
                                </>
                            ) : (
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="body1" color="text.secondary" gutterBottom>
                                        No hay ejercicios individuales programados para este día
                                        {day.circuitos && day.circuitos.length > 0 && 
                                            " (pero hay circuitos de entrenamiento definidos abajo)"}
                                    </Typography>
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleOpenNewExerciseModal(day.dayIndex)}
                                        variant="outlined"
                                    >
                                        Añadir Ejercicio Individual
                                    </Button>
                                </Box>
                            )}

                            {/* Sección de Circuitos */}
                            {day.circuitos && day.circuitos.length > 0 && (
                                <Box sx={{ mt: 4, mb: 3 }}>
                                    <Typography variant="h6" gutterBottom sx={{ 
                                        color: 'primary.main', 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 1,
                                        borderBottom: '1px solid',
                                        borderColor: 'divider',
                                        pb: 1
                                    }}>
                                        🔄 Circuitos
                                    </Typography>
                                    
                                    {day.circuitos.map((circuito, circuitoIndex) => (
                                        <Paper 
                                            key={circuitoIndex} 
                                            elevation={2} 
                                            sx={{ 
                                                p: 2, 
                                                mb: 3, 
                                                borderRadius: 2,
                                                border: '1px dashed',
                                                borderColor: 'primary.light',
                                                bgcolor: 'background.paper'
                                            }}
                                        >
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'space-between', 
                                                alignItems: 'center',
                                                mb: 2
                                            }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                    Circuito #{circuitoIndex + 1}
                                                </Typography>
                                                
                                                {editingField === 'circuitoRepetitions' && 
                                                 editingDayIndex === day.dayIndex && 
                                                 editingCircuitoIndex === circuitoIndex ? (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            value={editingValue}
                                                            onChange={(e) => setEditingValue(e.target.value)}
                                                            InputProps={{ 
                                                                inputProps: { min: 1 },
                                                                endAdornment: <Typography variant="caption">repeticiones</Typography>
                                                            }}
                                                            sx={{ width: '120px' }}
                                                        />
                                                        <Button size="small" onClick={handleCancelEdit}>
                                                            Cancelar
                                                        </Button>
                                                        <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                            Guardar
                                                        </Button>
                                                    </Box>
                                                ) : (
                                                    <Chip 
                                                        label={`${circuito.repetitions} repeticiones`} 
                                                        color="secondary" 
                                                        size="small"
                                                        sx={{ 
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                            '&:hover': { bgcolor: 'secondary.light' }
                                                        }}
                                                        onClick={() => handleStartEdit('circuitoRepetitions', circuito.repetitions, day.dayIndex, null, circuitoIndex)}
                                                    />
                                                )}
                                            </Box>
                                            
                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                                Realiza los siguientes ejercicios en secuencia, completando {circuito.repetitions} vueltas al circuito.
                                            </Typography>
                                            
                                            <Box sx={{ pl: 2, borderLeft: '3px solid', borderColor: 'secondary.main' }}>
                                                {circuito.exercises.map((exercise, exerciseIndex) => (
                                                    <Box 
                                                        key={exerciseIndex} 
                                                        sx={{ 
                                                            p: 1.5, 
                                                            mb: 1.5, 
                                                            bgcolor: 'grey.50',
                                                            borderRadius: 1,
                                                            '&:last-child': { mb: 0 }
                                                        }}
                                                    >
                                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                            {editingField === 'circuitoExerciseName' && 
                                                             editingDayIndex === day.dayIndex && 
                                                             editingCircuitoIndex === circuitoIndex &&
                                                             editingCircuitoExerciseIndex === exerciseIndex ? (
                                                                <Box sx={{ width: '100%' }}>
                                                                    <TextField
                                                                        fullWidth
                                                                        size="small"
                                                                        value={editingValue}
                                                                        onChange={(e) => setEditingValue(e.target.value)}
                                                                        placeholder="Nombre del ejercicio"
                                                                        autoFocus
                                                                    />
                                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                                                                        <Button size="small" onClick={handleCancelEdit}>
                                                                            Cancelar
                                                                        </Button>
                                                                        <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                                            Guardar
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            ) : (
                                                                <Typography 
                                                                    variant="subtitle2" 
                                                                    sx={{ 
                                                                        fontWeight: 'bold',
                                                                        cursor: 'pointer',
                                                                        '&:hover': { color: 'primary.main' }
                                                                    }}
                                                                    onClick={() => handleStartEdit('circuitoExerciseName', exercise.name, day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                >
                                                                    {exerciseIndex + 1}. {exercise.name}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
                                                            {editingField === 'circuitoExerciseSets' && 
                                                             editingDayIndex === day.dayIndex && 
                                                             editingCircuitoIndex === circuitoIndex &&
                                                             editingCircuitoExerciseIndex === exerciseIndex ? (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                    <TextField
                                                                        type="number"
                                                                        size="small"
                                                                        value={editingValue}
                                                                        onChange={(e) => setEditingValue(e.target.value)}
                                                                        InputProps={{ 
                                                                            inputProps: { min: 1 },
                                                                            endAdornment: <Typography variant="caption">series</Typography>
                                                                        }}
                                                                        sx={{ width: '100px' }}
                                                                    />
                                                                    <Button size="small" onClick={handleCancelEdit}>
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                                        Guardar
                                                                    </Button>
                                                                </Box>
                                                            ) : (
                                                                exercise.sets && (
                                                                    <Chip 
                                                                        label={`${exercise.sets} series`} 
                                                                        size="small" 
                                                                        variant="outlined"
                                                                        sx={{ 
                                                                            bgcolor: 'background.paper',
                                                                            cursor: 'pointer',
                                                                            '&:hover': { bgcolor: 'grey.100' }
                                                                        }}
                                                                        onClick={() => handleStartEdit('circuitoExerciseSets', exercise.sets, day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                    />
                                                                )
                                                            )}
                                                            
                                                            {editingField === 'circuitoExerciseReps' && 
                                                             editingDayIndex === day.dayIndex && 
                                                             editingCircuitoIndex === circuitoIndex &&
                                                             editingCircuitoExerciseIndex === exerciseIndex ? (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                    <TextField
                                                                        type="number"
                                                                        size="small"
                                                                        value={editingValue}
                                                                        onChange={(e) => setEditingValue(e.target.value)}
                                                                        InputProps={{ 
                                                                            inputProps: { min: 1 },
                                                                            endAdornment: <Typography variant="caption">reps</Typography>
                                                                        }}
                                                                        sx={{ width: '100px' }}
                                                                    />
                                                                    <Button size="small" onClick={handleCancelEdit}>
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                                        Guardar
                                                                    </Button>
                                                                </Box>
                                                            ) : (
                                                                exercise.reps && (
                                                                    <Chip 
                                                                        label={`${exercise.reps} reps`} 
                                                                        size="small" 
                                                                        variant="outlined"
                                                                        sx={{ 
                                                                            bgcolor: 'background.paper',
                                                                            cursor: 'pointer',
                                                                            '&:hover': { bgcolor: 'grey.100' }
                                                                        }}
                                                                        onClick={() => handleStartEdit('circuitoExerciseReps', exercise.reps, day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                    />
                                                                )
                                                            )}
                                                            
                                                            {editingField === 'circuitoExerciseWeight' && 
                                                             editingDayIndex === day.dayIndex && 
                                                             editingCircuitoIndex === circuitoIndex &&
                                                             editingCircuitoExerciseIndex === exerciseIndex ? (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                    <TextField
                                                                        type="number"
                                                                        size="small"
                                                                        value={editingValue}
                                                                        onChange={(e) => setEditingValue(e.target.value)}
                                                                        InputProps={{ 
                                                                            inputProps: { min: 0, step: 0.5 },
                                                                            endAdornment: <Typography variant="caption">kg</Typography>
                                                                        }}
                                                                        sx={{ width: '100px' }}
                                                                    />
                                                                    <Button size="small" onClick={handleCancelEdit}>
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                                        Guardar
                                                                    </Button>
                                                                </Box>
                                                            ) : (
                                                                exercise.weight && (
                                                                    <Chip 
                                                                        label={`${exercise.weight} kg`} 
                                                                        size="small" 
                                                                        variant="outlined"
                                                                        sx={{ 
                                                                            bgcolor: 'background.paper',
                                                                            cursor: 'pointer',
                                                                            '&:hover': { bgcolor: 'grey.100' }
                                                                        }}
                                                                        onClick={() => handleStartEdit('circuitoExerciseWeight', exercise.weight, day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                    />
                                                                )
                                                            )}
                                                            
                                                            {editingField === 'circuitoExerciseRestTimeSeconds' && 
                                                             editingDayIndex === day.dayIndex && 
                                                             editingCircuitoIndex === circuitoIndex &&
                                                             editingCircuitoExerciseIndex === exerciseIndex ? (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                    <TextField
                                                                        type="number"
                                                                        size="small"
                                                                        value={editingValue}
                                                                        onChange={(e) => setEditingValue(e.target.value)}
                                                                        InputProps={{ 
                                                                            inputProps: { min: 0, step: 5 },
                                                                            endAdornment: <Typography variant="caption">seg</Typography>
                                                                        }}
                                                                        sx={{ width: '100px' }}
                                                                    />
                                                                    <Button size="small" onClick={handleCancelEdit}>
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                                        Guardar
                                                                    </Button>
                                                                </Box>
                                                            ) : (
                                                                exercise.restTimeSeconds && (
                                                                    <Chip 
                                                                        label={`${Math.floor(exercise.restTimeSeconds / 60)}:${(exercise.restTimeSeconds % 60).toString().padStart(2, '0')} descanso`} 
                                                                        size="small" 
                                                                        variant="outlined"
                                                                        sx={{ 
                                                                            bgcolor: 'background.paper',
                                                                            cursor: 'pointer',
                                                                            '&:hover': { bgcolor: 'grey.100' }
                                                                        }}
                                                                        onClick={() => handleStartEdit('circuitoExerciseRestTimeSeconds', exercise.restTimeSeconds, day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                    />
                                                                )
                                                            )}
                                                        </Box>
                                                        
                                                        {editingField === 'circuitoExerciseMuscleGroups' && 
                                                         editingDayIndex === day.dayIndex && 
                                                         editingCircuitoIndex === circuitoIndex &&
                                                         editingCircuitoExerciseIndex === exerciseIndex ? (
                                                            <Box sx={{ mt: 1 }}>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    value={editingValue}
                                                                    onChange={(e) => setEditingValue(e.target.value)}
                                                                    placeholder="Grupos musculares (separados por comas)"
                                                                    helperText="Ej: Pecho, Hombros, Tríceps"
                                                                />
                                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                                                                    <Button size="small" onClick={handleCancelEdit}>
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                                        Guardar
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            exercise.muscleGroups && exercise.muscleGroups.length > 0 && (
                                                                <Box 
                                                                    sx={{ 
                                                                        display: 'flex', 
                                                                        flexWrap: 'wrap', 
                                                                        gap: 0.5, 
                                                                        mb: 1,
                                                                        cursor: 'pointer',
                                                                        '&:hover': { opacity: 0.8 }
                                                                    }}
                                                                    onClick={() => handleStartEdit('circuitoExerciseMuscleGroups', exercise.muscleGroups.join(', '), day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                >
                                                                    {exercise.muscleGroups.map((group, idx) => (
                                                                        <Chip 
                                                                            key={idx} 
                                                                            label={group} 
                                                                            size="small" 
                                                                            color="primary"
                                                                            sx={{ fontSize: '0.7rem' }}
                                                                        />
                                                                    ))}
                                                                </Box>
                                                            )
                                                        )}
                                                        
                                                        {editingField === 'circuitoExerciseInstructions' && 
                                                         editingDayIndex === day.dayIndex && 
                                                         editingCircuitoIndex === circuitoIndex &&
                                                         editingCircuitoExerciseIndex === exerciseIndex ? (
                                                            <Box sx={{ mt: 1 }}>
                                                                <TextField
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    size="small"
                                                                    value={editingValue}
                                                                    onChange={(e) => setEditingValue(e.target.value)}
                                                                    placeholder="Instrucciones específicas para este ejercicio"
                                                                />
                                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                                                                    <Button size="small" onClick={handleCancelEdit}>
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                                        Guardar
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            exercise.instructions ? (
                                                                <Typography 
                                                                    variant="body2" 
                                                                    sx={{ 
                                                                        mt: 1, 
                                                                        color: 'text.secondary',
                                                                        cursor: 'pointer',
                                                                        '&:hover': { color: 'text.primary' }
                                                                    }}
                                                                    onClick={() => handleStartEdit('circuitoExerciseInstructions', exercise.instructions, day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                >
                                                                    {exercise.instructions}
                                                                </Typography>
                                                            ) : (
                                                                <Button
                                                                    size="small"
                                                                    sx={{ mt: 1 }}
                                                                    onClick={() => handleStartEdit('circuitoExerciseInstructions', '', day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                                >
                                                                    Añadir instrucciones
                                                                </Button>
                                                            )
                                                        )}
                                                        
                                                        {/* Botón para añadir recursos al ejercicio de circuito */}
                                                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Button
                                                                startIcon={<AddIcon />}
                                                                size="small"
                                                                onClick={() => handleOpenResourceModal(exercise, day.dayIndex, null, circuitoIndex, exerciseIndex)}
                                                            >
                                                                Añadir Recurso
                                                            </Button>
                                                        </Box>
                                                        
                                                        {/* Mostrar recursos del ejercicio de circuito si existen */}
                                                        {exercise.recursos && exercise.recursos.length > 0 && (
                                                            <Box sx={{ mt: 1 }}>
                                                                <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block', mb: 0.5 }}>
                                                                    Recursos:
                                                                </Typography>
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                                                    {exercise.recursos.map((recurso, idx) => (
                                                                        <Chip
                                                                            key={idx}
                                                                            label={recurso.titulo || 'Recurso'}
                                                                            size="small"
                                                                            color={recurso.tipo === 'video' ? 'secondary' : 'default'}
                                                                            icon={recurso.tipo === 'video' ? <VideoLibraryIcon fontSize="small" /> : <InsertDriveFileIcon fontSize="small" />}
                                                                            onDelete={() => handleDeleteResource(day.dayIndex, null, recurso.id, circuitoIndex, exerciseIndex)}
                                                                            sx={{ maxWidth: '100%', overflow: 'hidden' }}
                                                                        />
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                    ))}
                                </Box>
                            )}

                            {/* Sección de Enfriamiento */}
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: 1 }}>
                                    ❄️ Enfriamiento
                                </Typography>
                                {editingField === 'enfriamiento' && editingDayIndex === day.dayIndex ? (
                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={editingValue}
                                            onChange={(e) => setEditingValue(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            autoFocus
                                            placeholder="Describe el enfriamiento..."
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                                            <Button size="small" onClick={handleCancelEdit}>
                                                Cancelar
                                            </Button>
                                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                                Guardar
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : day.enfriamiento ? (
                                    <Box 
                                        sx={{ 
                                            p: 2,
                                            backgroundColor: 'grey.50',
                                            borderRadius: 1,
                                            cursor: 'pointer',
                                            '&:hover': { bgcolor: 'grey.100' }
                                        }}
                                        onClick={() => handleStartEdit('enfriamiento', day.enfriamiento, day.dayIndex)}
                                    >
                                        <Typography variant="body2">
                                            {day.enfriamiento}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleStartEdit('enfriamiento', '', day.dayIndex)}
                                        size="small"
                                    >
                                        Añadir enfriamiento
                                    </Button>
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenNewDayModal}
                    variant="contained"
                    fullWidth
                    sx={{ 
                        mt: 2,
                        bgcolor: 'grey.200',
                        color: 'text.primary',
                        '&:hover': {
                            bgcolor: 'grey.300'
                        }
                    }}
                >
                    Añadir Día
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleDayClose}
                >
                    {diasSemana.map((dia) => (
                        <MenuItem 
                            key={dia} 
                            onClick={() => handleDaySelect(dia)}
                            sx={{ minWidth: 200 }}
                        >
                            <Checkbox 
                                checked={rutina?.days
                                    ?.find(d => d.dayIndex === selectedDayIndex)
                                    ?.weekDays?.includes(dia) || false}
                            />
                            <ListItemText primary={dia} />
                        </MenuItem>
                    ))}
                </Menu>
            </Paper>
            {renderResourceModal()}
            {renderNewExerciseModal()}
            {renderNewDayModal()}
            {renderNewResourceModal()}
            <Dialog
                open={deleteConfirmOpen}
                onClose={handleCancelDelete}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar este ejercicio?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDelete} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDayConfirmOpen}
                onClose={handleCancelDeleteDay}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar este día?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDeleteDay}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDeleteDay} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteRoutineConfirmOpen}
                onClose={handleCancelDeleteRoutine}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar esta rutina?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDeleteRoutine}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDeleteRoutine} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RoutineView; 