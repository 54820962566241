import React, { useState, useEffect, useContext } from 'react';
import { 
    TextField, Button, FormControl, InputLabel, Select, MenuItem,
    Typography, Box, Autocomplete, CircularProgress
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { db, auth } from '../../config/firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc, addDoc } from 'firebase/firestore';
import { es } from 'date-fns/locale';
import { AuthContext } from '../../contexts/AuthContext'; // Asegúrate de importar el contexto de autenticación

const CreateIndividualClassForm = ({ onSubmit, onCancel }) => {
    const [classData, setClassData] = useState({
        nombreClase: '',
        fecha: null,
        hora: null,
        duracion: 60,
        clientes: [],
        modalidad: 'presencial',
        notas: ''
    });
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDetails } = useContext(AuthContext);

    useEffect(() => {
        fetchClientes();
    }, [userDetails]);

    const fetchClientes = async () => {
        if (!userDetails) {
            setError("No se ha encontrado un usuario autenticado o detalles del usuario");
            setLoading(false);
            return;
        }

        try {
            let clientesCollectionRef;

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                clientesCollectionRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('ID del Centro (IDBO) no disponible');
                }
                clientesCollectionRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros');
            } else {
                throw new Error("Tipo de profesional no reconocido");
            }

            const clientesSnapshot = await getDocs(clientesCollectionRef);
            const clientesData = clientesSnapshot.docs.map(doc => {
                const data = doc.data();
                const clienteData = {
                    id: doc.id,
                    uidcliente: data.uidcliente || '', // Cambiado de uid a uidcliente
                    nombre: `${data.nombre || ''} ${data.apellidos || ''}`.trim(),
                    email: data.email || '',
                    IDBO: userDetails?.IDBO || '',
                    IDPT: userDetails?.IDPT || ''
                };
                console.log("Datos del cliente:", clienteData);
                return clienteData;
            });

            setClientes(clientesData);
            setLoading(false);
        } catch (error) {
            console.error("Error al obtener los clientes:", error);
            setError("Error al cargar los clientes");
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setClassData({ ...classData, [name]: value });
    };

    const handleDateChange = (newDate) => {
        setClassData({ ...classData, fecha: newDate });
    };

    const handleTimeChange = (newTime) => {
        setClassData({ ...classData, hora: newTime });
    };

    const handleClienteChange = (event, newValue) => {
        setClassData({ ...classData, clientes: newValue });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Verificaciones adicionales
        if (!classData.nombreClase) {
            setError('El nombre de la clase es obligatorio');
            return;
        }
        if (!classData.fecha || !classData.hora) {
            setError('La fecha y hora son obligatorias');
            return;
        }
        if (classData.clientes.length === 0) {
            setError('Debes seleccionar al menos un cliente');
            return;
        }
        if (!classData.modalidad) {
            setError('Debes seleccionar una modalidad');
            return;
        }
        
        try {
            const fechaInicio = new Date(classData.fecha);
            fechaInicio.setHours(classData.hora.getHours(), classData.hora.getMinutes());
            const fechaFinal = new Date(fechaInicio.getTime() + classData.duracion * 60000);

            const newClassData = {
                nombreClase: classData.nombreClase,
                fecha_inicio: fechaInicio,
                fecha_final: fechaFinal,
                clientes: classData.clientes.map(cliente => ({
                    uid: cliente.uidcliente || '',
                    email: cliente.email || '',
                    nombre: cliente.nombre || ''
                })),
                aceptada: true,
                estado: 'confirmada',
                solicitada_por_cliente: false,
                IDBO: userDetails?.IDBO || '',
                IDPT: userDetails?.IDPT || '',
                modalidad: classData.modalidad,
                notas: classData.notas || ''
            };

            // Verificar que no haya valores undefined
            Object.keys(newClassData).forEach(key => {
                if (newClassData[key] === undefined) {
                    console.error(`Campo ${key} es undefined`);
                    throw new Error(`El campo ${key} no puede ser undefined`);
                }
            });

            console.log("Datos de la clase a crear:", newClassData);

            const docRef = await addDoc(collection(db, 'Citas_cliente_entrenador'), newClassData);
            console.log("Clase individual creada con ID: ", docRef.id);
            onSubmit(newClassData);
        } catch (error) {
            console.error("Error detallado al crear la clase individual: ", error);
            setError(`Error al crear la clase: ${error.message}`);
            
            // Log adicional para más detalles
            if (error.code) {
                console.error("Código de error:", error.code);
            }
            if (error.details) {
                console.error("Detalles del error:", error.details);
            }
        }
    };

    const textFieldStyle = {
        marginBottom: 2,
        '& .MuiOutlinedInput-root': {
            borderRadius: 4,
        },
        '& .MuiInputLabel-root': {
            background: 'white',
            padding: '0 4px',
        }
    };

    const buttonStyle = {
        borderRadius: '25px',
        padding: '10px 20px',
        textTransform: 'none',
        fontSize: '16px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-2px)',
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    name="nombreClase"
                    label="Nombre de la clase"
                    value={classData.nombreClase}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />
                <DatePicker
                    label="Fecha de la clase"
                    value={classData.fecha}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" required sx={textFieldStyle} />}
                />
                <TimePicker
                    label="Hora de la clase"
                    value={classData.hora}
                    onChange={handleTimeChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" required sx={textFieldStyle} />}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="duracion"
                    label="Duración (minutos)"
                    type="number"
                    value={classData.duracion}
                    onChange={handleChange}
                    required
                    sx={textFieldStyle}
                />
                <Autocomplete
                    multiple
                    options={clientes}
                    getOptionLabel={(option) => option.nombre}
                    value={classData.clientes}
                    onChange={handleClienteChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Clientes"
                            margin="normal"
                            error={classData.clientes.length === 0}
                            helperText={classData.clientes.length === 0 ? "Selecciona al menos un cliente" : ""}
                            sx={textFieldStyle}
                        />
                    )}
                />
                <FormControl fullWidth margin="normal" sx={textFieldStyle}>
                    <InputLabel>Modalidad</InputLabel>
                    <Select
                        name="modalidad"
                        value={classData.modalidad}
                        onChange={handleChange}
                        required
                    >
                        <MenuItem value="presencial">Presencial</MenuItem>
                        <MenuItem value="online">Online</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    name="notas"
                    label="Notas"
                    multiline
                    rows={4}
                    value={classData.notas}
                    onChange={handleChange}
                    sx={textFieldStyle}
                />
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={onCancel} sx={{ ...buttonStyle, color: '#FF5722' }}>
                        Cancelar
                    </Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        sx={{ ...buttonStyle, backgroundColor: '#FF5722', color: 'white', '&:hover': { backgroundColor: '#E64A19' } }}
                    >
                        Crear Clase Individual
                    </Button>
                </Box>
            </form>
        </LocalizationProvider>
    );
};

export default CreateIndividualClassForm;
