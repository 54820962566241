import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    Avatar,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Divider,
} from '@mui/material';
import {
    PeopleOutline as PeopleIcon,
    FitnessCenter as FitnessCenterIcon,
    RestaurantOutlined as RestaurantIcon,
    TrendingUp as TrendingUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, collectionGroup, Timestamp, doc, getDoc, orderBy, limit } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';

const EntrenadorRow = ({ entrenador }) => {
    const [open, setOpen] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchClientes = async () => {
        if (!open && clientes.length === 0) {
            setLoading(true);
            try {
                let clientesRef;
                if (entrenador.tipo_profesional === 'entrenador_independiente') {
                    clientesRef = collection(db, 'userDetails', entrenador.id, 'Clientes_asociados');
                } else if (['propietario_centro', 'trabajador_centro'].includes(entrenador.tipo_profesional)) {
                    clientesRef = collection(db, 'Centros', entrenador.IDBO, 'Clientes_asociados_centros');
                }

                const clientesSnapshot = await getDocs(clientesRef);
                const clientesData = clientesSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter(cliente => !cliente.isInSolicitudes);

                setClientes(clientesData);
            } catch (error) {
                console.error('Error al cargar clientes:', error);
            } finally {
                setLoading(false);
            }
        }
        setOpen(!open);
    };

    return (
        <>
            <TableRow 
                sx={{ 
                    '& > *': { borderBottom: 'unset' },
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                }}
                onClick={fetchClientes}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            fetchClientes();
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar 
                            src={entrenador.photo_url} 
                            alt={entrenador.display_name}
                            sx={{ 
                                width: 40, 
                                height: 40,
                                bgcolor: !entrenador.photo_url ? 'primary.main' : 'transparent'
                            }}
                        >
                            {!entrenador.photo_url && entrenador.display_name?.charAt(0)}
                        </Avatar>
                        <Box>
                            <Typography variant="subtitle2">
                                {entrenador.display_name}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {entrenador.email}
                            </Typography>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell>{entrenador.tipo_profesional}</TableCell>
                <TableCell>
                    <Chip 
                        label={entrenador.plan || 'Free'} 
                        color={entrenador.plan === 'Pro' ? 'primary' : 'default'}
                        size="small"
                    />
                </TableCell>
                <TableCell>{entrenador.especialidad || 'No especificada'}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Clientes ({clientes.length})
                            </Typography>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <List sx={{ 
                                    width: '100%', 
                                    bgcolor: 'background.paper',
                                    borderRadius: 1,
                                    boxShadow: 1
                                }}>
                                    {clientes.map((cliente, index) => (
                                        <React.Fragment key={cliente.id}>
                                            <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                    <Avatar 
                                                        src={cliente.photo_url}
                                                        alt={`${cliente.nombre} ${cliente.apellidos}`}
                                                    >
                                                        {cliente.nombre?.charAt(0)}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={`${cliente.nombre} ${cliente.apellidos}`}
                                                    secondary={
                                                        <>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {cliente.email}
                                                            </Typography>
                                                            {cliente.plan && (
                                                                <Chip 
                                                                    label={cliente.plan}
                                                                    size="small"
                                                                    color={cliente.plan === 'Pro' ? 'primary' : 'default'}
                                                                    sx={{ ml: 1 }}
                                                                />
                                                            )}
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                            {index < clientes.length - 1 && <Divider variant="inset" component="li" />}
                                        </React.Fragment>
                                    ))}
                                    {clientes.length === 0 && (
                                        <ListItem>
                                            <ListItemText 
                                                primary="No hay clientes"
                                                secondary="Este entrenador aún no tiene clientes asociados"
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const Admin = () => {
    const { userDetails } = useContext(AuthContext);
    const navigate = useNavigate();
    const [totalEntrenadores, setTotalEntrenadores] = useState('...');
    const [totalClientes, setTotalClientes] = useState('...');
    const [rutinasGeneradas, setRutinasGeneradas] = useState('...');
    const [dietasGeneradas, setDietasGeneradas] = useState('...');
    const [historial, setHistorial] = useState([]);
    const [loadingHistorial, setLoadingHistorial] = useState(true);
    const [entrenadores, setEntrenadores] = useState([]);
    const [loadingEntrenadores, setLoadingEntrenadores] = useState(true);

    // Verificar si el usuario tiene acceso de administrador
    React.useEffect(() => {
        if (!userDetails?.admin) {
            navigate('/');
        }
    }, [userDetails, navigate]);

    // Obtener el número total de entrenadores y sus clientes
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Obtener entrenadores con más detalles
                const entrenadoresQuery = query(
                    collection(db, 'userDetails'),
                    where('es_entrenador', '==', true)
                );
                const entrenadoresSnapshot = await getDocs(entrenadoresQuery);
                const entrenadoresData = entrenadoresSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setEntrenadores(entrenadoresData);
                setTotalEntrenadores(entrenadoresSnapshot.size.toString());
                setLoadingEntrenadores(false);

                // Obtener total de clientes usando collectionGroup
                const clientesQuery = collectionGroup(db, 'Clientes_asociados');
                const clientesSnapshot = await getDocs(clientesQuery);
                setTotalClientes(clientesSnapshot.size.toString());

                // Obtener estadísticas globales
                const statsRef = doc(db, 'stats', 'global');
                const statsDoc = await getDoc(statsRef);
                
                if (statsDoc.exists()) {
                    const stats = statsDoc.data();
                    setRutinasGeneradas(stats.total_rutinas?.toString() || '0');
                    setDietasGeneradas(stats.total_dietas?.toString() || '0');
                } else {
                    setRutinasGeneradas('0');
                    setDietasGeneradas('0');
                }

                // Obtener historial de generaciones
                const historialRef = collection(db, 'stats', 'global', 'historial');
                const historialQuery = query(
                    historialRef,
                    orderBy('fecha', 'desc'),
                    limit(30)
                );
                const historialSnapshot = await getDocs(historialQuery);
                const historialData = historialSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    fecha: doc.data().fecha?.toDate() // Convertir timestamp a Date
                }));
                setHistorial(historialData);
                setLoadingHistorial(false);

            } catch (error) {
                console.error('Error al obtener datos:', error);
                setTotalEntrenadores('Error');
                setTotalClientes('Error');
                setRutinasGeneradas('Error');
                setDietasGeneradas('Error');
                setLoadingEntrenadores(false);
                setLoadingHistorial(false);
            }
        };

        fetchData();
    }, []);

    if (!userDetails?.admin) {
        return null;
    }

    // Datos de las métricas
    const metrics = [
        {
            title: 'Total Entrenadores',
            value: totalEntrenadores,
            icon: <PeopleIcon sx={{ fontSize: 40, color: '#FF5722' }} />,
            description: 'Entrenadores activos en la plataforma',
        },
        {
            title: 'Total Clientes',
            value: totalClientes,
            icon: <PeopleIcon sx={{ fontSize: 40, color: '#2196F3' }} />,
            description: 'Clientes registrados en total',
        },
        {
            title: 'Rutinas Generadas',
            value: rutinasGeneradas,
            icon: <FitnessCenterIcon sx={{ fontSize: 40, color: '#4CAF50' }} />,
            description: 'Total de rutinas generadas con IA',
        },
        {
            title: 'Dietas Generadas',
            value: dietasGeneradas,
            icon: <RestaurantIcon sx={{ fontSize: 40, color: '#FFC107' }} />,
            description: 'Total de dietas generadas con IA',
        },
    ];

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                Panel de Administración
            </Typography>

            <Grid container spacing={3} sx={{ mb: 4 }}>
                {metrics.map((metric, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                height: '100%',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                },
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                {metric.icon}
                                <Typography variant="h4" sx={{ ml: 2, fontWeight: 'bold' }}>
                                    {metric.value}
                                </Typography>
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                {metric.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {metric.description}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                    Entrenadores Registrados
                </Typography>
                {loadingEntrenadores ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={50} />
                                    <TableCell>Entrenador</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Plan</TableCell>
                                    <TableCell>Especialidad</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entrenadores.map((entrenador) => (
                                    <EntrenadorRow key={entrenador.id} entrenador={entrenador} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>

            <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                    Últimas Generaciones
                </Typography>
                {loadingHistorial ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Entrenador</TableCell>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Modelo</TableCell>
                                    <TableCell>Fecha</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historial.map((item) => (
                                    <TableRow key={item.id} hover>
                                        <TableCell>
                                            <Chip
                                                label={item.tipo === 'rutina' ? 'Rutina' : 'Dieta'}
                                                color={item.tipo === 'rutina' ? 'success' : 'warning'}
                                                size="small"
                                                icon={item.tipo === 'rutina' ? 
                                                    <FitnessCenterIcon fontSize="small" /> : 
                                                    <RestaurantIcon fontSize="small" />}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {item.entrenador?.nombre || 'No disponible'}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                {item.entrenador?.tipo_profesional || 'No disponible'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{item.cliente?.nombre || 'No disponible'}</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={item.modelo === 'pro' ? 'Pro' : 'Standard'}
                                                variant="outlined"
                                                size="small"
                                                color={item.modelo === 'pro' ? 'primary' : 'default'}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {item.fecha ? new Date(item.fecha).toLocaleString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            }) : 'Fecha no disponible'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
    );
};

export default Admin; 