import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Card,
    CardMedia,
    CardContent,
    Tabs,
    Tab,
    Fade,
    Chip,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    IconButton,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar,
    Alert
} from '@mui/material';
import {
    FitnessCenter as FitnessCenterIcon,
    Restaurant as RestaurantIcon,
    PhotoLibrary as PhotoLibraryIcon,
    Timeline as TimelineIcon,
    Scale as ScaleIcon,
    TrendingUp as TrendingUpIcon,
    Rocket as RocketIcon,
    CompareArrows as CompareArrowsIcon,
    Add as AddIcon,
    Close as CloseIcon,
    PhotoCamera as PhotoCameraIcon,
    Delete as DeleteIcon,
    VideoLibrary as VideoLibraryIcon,
    CalendarToday as CalendarIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, addDoc, serverTimestamp, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { AuthContext } from '../../../contexts/AuthContext';
import { styled } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';

// Componente para mostrar las métricas
const MetricCard = ({ title, value, previousValue, icon, color }) => {
    const percentageChange = previousValue ? ((value - previousValue) / previousValue) * 100 : 0;
    
    return (
        <Card sx={{ 
            p: 2, 
            height: '100%',
            background: `linear-gradient(135deg, ${color}15, ${color}05)`,
            border: `1px solid ${color}30`,
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                        {title}
                    </Typography>
                    <Typography variant="h4" sx={{ color: color, mt: 1 }}>
                        {value}
                    </Typography>
                </Box>
                {icon}
            </Box>
            {previousValue && (
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TrendingUpIcon 
                        sx={{ 
                            color: percentageChange >= 0 ? 'success.main' : 'error.main',
                            transform: percentageChange >= 0 ? 'none' : 'rotate(180deg)'
                        }} 
                    />
                    <Typography 
                        variant="body2" 
                        color={percentageChange >= 0 ? 'success.main' : 'error.main'}
                    >
                        {Math.abs(percentageChange).toFixed(1)}% vs anterior
                    </Typography>
                </Box>
            )}
        </Card>
    );
};

const EmptySection = ({ title }) => (
    <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            justifyContent: 'center',
            py: 8,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
            minHeight: '400px'
        }}
    >
        {/* Fondo animado */}
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.1,
                background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
                backgroundSize: '400% 400%',
                animation: 'gradient 15s ease infinite',
                '@keyframes gradient': {
                    '0%': {
                        backgroundPosition: '0% 50%'
                    },
                    '50%': {
                        backgroundPosition: '100% 50%'
                    },
                    '100%': {
                        backgroundPosition: '0% 50%'
                    }
                }
            }}
        />

        <RocketIcon 
            sx={{ 
                fontSize: 100,
                color: 'primary.main',
                mb: 4,
                animation: 'float 3s ease-in-out infinite',
                '@keyframes float': {
                    '0%, 100%': {
                        transform: 'translateY(0)'
                    },
                    '50%': {
                        transform: 'translateY(-20px)'
                    }
                }
            }}
        />
        
        <Typography 
            variant="h5" 
            sx={{ 
                mb: 2,
                color: 'primary.main',
                fontWeight: 'bold',
                textAlign: 'center'
            }}
        >
            ¡Última fase de desarrollo! 🚀
        </Typography>
        
        <Typography 
            variant="h6" 
            color="text.secondary"
            sx={{ 
                mb: 1,
                textAlign: 'center'
            }}
        >
            {title}
        </Typography>
        
        <Typography 
            variant="body1" 
            color="text.secondary"
            align="center"
            sx={{
                maxWidth: '600px',
                px: 3
            }}
        >
            Estamos añadiendo las últimas sugerencias de nuestro equipo de entrenadores expertos. 
            ¡Esta funcionalidad estará disponible en las próximas 24-48 horas! 💪
        </Typography>
    </Box>
);

const ProgressView = ({ clientId }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [photos, setPhotos] = useState([]);
    const [weightHistory, setWeightHistory] = useState([]);
    const [exerciseProgress, setExerciseProgress] = useState([]);
    const [mealProgress, setMealProgress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [newPhotoFile, setNewPhotoFile] = useState(null);
    const [photoDescription, setPhotoDescription] = useState('');
    const [photoCategory, setPhotoCategory] = useState('frente');
    const [uploading, setUploading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const storage = getStorage();
    const [selectedMealPhoto, setSelectedMealPhoto] = useState(null);
    const [openMealPhotoDialog, setOpenMealPhotoDialog] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    
    // Nuevos estados para ejercicios
    const [exerciseHistory, setExerciseHistory] = useState([]);
    const [filteredExerciseHistory, setFilteredExerciseHistory] = useState([]);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [exerciseFilter, setExerciseFilter] = useState('all');
    const [dateRange, setDateRange] = useState('week');
    const [selectedFilterDate, setSelectedFilterDate] = useState(null);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [groupedExercises, setGroupedExercises] = useState({});
    const [exerciseStats, setExerciseStats] = useState({
        totalExercises: 0,
        totalSets: 0,
        totalReps: 0,
        averageRPE: 0
    });

    // Función para filtrar ejercicios
    const filterExercises = useCallback(() => {
        if (!exerciseHistory || !exerciseHistory.length) {
            setFilteredExerciseHistory([]);
            return;
        }

        let filtered = [...exerciseHistory];

        // Filtrar por fecha
        if (dateRange === 'day' && selectedFilterDate) {
            const selectedDay = new Date(selectedFilterDate);
            selectedDay.setHours(0, 0, 0, 0);
            filtered = filtered.filter(day => {
                const exerciseDate = new Date(day.fecha);
                exerciseDate.setHours(0, 0, 0, 0);
                return exerciseDate.getTime() === selectedDay.getTime();
            });
        } else {
            const today = new Date();
            const filterDate = new Date();
            switch (dateRange) {
                case 'week':
                    filterDate.setDate(today.getDate() - 7);
                    break;
                case 'month':
                    filterDate.setMonth(today.getMonth() - 1);
                    break;
                case '3months':
                    filterDate.setMonth(today.getMonth() - 3);
                    break;
                default:
                    break;
            }
            filtered = filtered.filter(day => new Date(day.fecha) >= filterDate);
        }

        // Filtrar por tipo de ejercicio
        if (exerciseFilter !== 'all') {
            filtered = filtered.map(day => ({
                ...day,
                ejercicios: day.ejercicios && Array.isArray(day.ejercicios) 
                    ? day.ejercicios.filter(exercise => {
                        if (!exercise.muscleGroups || !Array.isArray(exercise.muscleGroups)) {
                            return false;
                        }
                        
                        switch (exerciseFilter) {
                            case 'upper':
                                return exercise.muscleGroups.some(group => 
                                    ['pecho', 'espalda', 'hombros', 'bíceps', 'tríceps', 'brazos'].includes(group.toLowerCase())
                                );
                            case 'lower':
                                return exercise.muscleGroups.some(group => 
                                    ['piernas', 'cuádriceps', 'isquios', 'gemelos', 'glúteos'].includes(group.toLowerCase())
                                );
                            case 'core':
                                return exercise.muscleGroups.some(group => 
                                    ['abdominales', 'core', 'oblicuos', 'lumbar'].includes(group.toLowerCase())
                                );
                            default:
                                return true;
                        }
                    })
                    : []
            })).filter(day => day.ejercicios && day.ejercicios.length > 0);
        }

        setFilteredExerciseHistory(filtered);

        // Actualizar estadísticas
        const stats = {
            totalExercises: 0,
            totalSets: 0,
            totalReps: 0,
            totalRPE: 0,
            rpeCount: 0
        };

        filtered.forEach(day => {
            if (day.ejercicios && Array.isArray(day.ejercicios)) {
                day.ejercicios.forEach(exercise => {
                    stats.totalExercises++;
                    stats.totalSets += exercise.series?.length || 0;
                    if (exercise.series && Array.isArray(exercise.series)) {
                        exercise.series.forEach(serie => {
                            stats.totalReps += serie.repeticiones || 0;
                        });
                    }
                    if (exercise.rpe) {
                        stats.totalRPE += exercise.rpe;
                        stats.rpeCount++;
                    }
                });
            }
        });

        setExerciseStats({
            totalExercises: stats.totalExercises,
            totalSets: stats.totalSets,
            totalReps: stats.totalReps,
            averageRPE: stats.rpeCount > 0 ? (stats.totalRPE / stats.rpeCount).toFixed(1) : 0
        });
    }, [exerciseHistory, exerciseFilter, dateRange, selectedFilterDate]);

    // Efecto para aplicar filtros cuando cambian los valores
    useEffect(() => {
        filterExercises();
    }, [filterExercises, exerciseFilter, dateRange, selectedFilterDate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch fotos
                const photosRef = collection(db, 'progress_photos');
                const photosQuery = query(
                    photosRef,
                    where('clientId', '==', clientId),
                    orderBy('createdAt', 'desc')
                );
                const photosSnapshot = await getDocs(photosQuery);
                const photosData = photosSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPhotos(photosData || []);

                // Fetch historial de peso
                const weightRef = collection(db, 'weight_history');
                const weightQuery = query(
                    weightRef,
                    where('clientId', '==', clientId),
                    where('profesionalId', '==', userDetails.uid),
                    orderBy('date', 'desc'),
                    limit(30)
                );
                const weightSnapshot = await getDocs(weightQuery);
                const weightData = weightSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    date: doc.data().date?.toDate().toISOString().split('T')[0]
                })).sort((a, b) => new Date(a.date) - new Date(b.date));
                setWeightHistory(weightData || []);

                // Fetch historial de ejercicios
                const exerciseHistoryRef = collection(db, 'historial_ejercicios');
                const exerciseHistoryQuery = query(
                    exerciseHistoryRef,
                    where('uid', '==', clientId),
                    where('IDPT', '==', userDetails.IDPT),
                    orderBy('timestamp', 'desc'),
                    limit(30)
                );
                const exerciseHistorySnapshot = await getDocs(exerciseHistoryQuery);
                const exerciseHistoryData = exerciseHistorySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    ejercicios: doc.data().ejercicios || []
                }));
                setExerciseHistory(exerciseHistoryData || []);

                // Procesar estadísticas de ejercicios
                if (exerciseHistoryData && exerciseHistoryData.length > 0) {
                    const stats = {
                        totalExercises: 0,
                        totalSets: 0,
                        totalReps: 0,
                        totalRPE: 0,
                        rpeCount: 0
                    };

                    const grouped = {};

                    exerciseHistoryData.forEach(day => {
                        if (day.ejercicios && Array.isArray(day.ejercicios)) {
                            day.ejercicios.forEach(exercise => {
                                stats.totalExercises++;
                                stats.totalSets += exercise.series?.length || 0;
                                if (exercise.series && Array.isArray(exercise.series)) {
                                    exercise.series.forEach(serie => {
                                        stats.totalReps += serie.repeticiones || 0;
                                    });
                                }
                                if (exercise.rpe) {
                                    stats.totalRPE += exercise.rpe;
                                    stats.rpeCount++;
                                }

                                // Agrupar ejercicios por nombre
                                if (!grouped[exercise.nombre]) {
                                    grouped[exercise.nombre] = {
                                        count: 0,
                                        totalWeight: 0,
                                        maxWeight: 0,
                                        history: []
                                    };
                                }
                                grouped[exercise.nombre].count++;
                                
                                const maxSerieWeight = exercise.series && Array.isArray(exercise.series) 
                                    ? Math.max(...exercise.series.map(s => s.peso || 0))
                                    : 0;
                                grouped[exercise.nombre].maxWeight = Math.max(grouped[exercise.nombre].maxWeight, maxSerieWeight);
                                
                                const totalWeight = exercise.series && Array.isArray(exercise.series)
                                    ? exercise.series.reduce((sum, serie) => sum + (serie.peso || 0), 0)
                                    : 0;
                                grouped[exercise.nombre].totalWeight += totalWeight;

                                grouped[exercise.nombre].history.push({
                                    date: day.fecha,
                                    maxWeight: maxSerieWeight,
                                    series: exercise.series || [],
                                    rpe: exercise.rpe
                                });
                            });
                        }
                    });

                    setExerciseStats({
                        totalExercises: stats.totalExercises,
                        totalSets: stats.totalSets,
                        totalReps: stats.totalReps,
                        averageRPE: stats.rpeCount > 0 ? (stats.totalRPE / stats.rpeCount).toFixed(1) : 0
                    });

                    setGroupedExercises(grouped);
                } else {
                    setExerciseStats({
                        totalExercises: 0,
                        totalSets: 0,
                        totalReps: 0,
                        averageRPE: 0
                    });
                    setGroupedExercises({});
                }

                // Fetch historial de comidas
                const today = new Date();
                const last30Days = Array.from({length: 30}, (_, i) => {
                    const date = new Date();
                    date.setDate(today.getDate() - i);
                    return date.toISOString().split('T')[0];
                });

                const mealsPromises = last30Days.map(async (date) => {
                    const mealRef = doc(db, 'historial_comidas', `${clientId}_${date}`);
                    const mealDoc = await getDoc(mealRef);
                    if (mealDoc.exists()) {
                        return {
                            date,
                            ...mealDoc.data(),
                            meals: mealDoc.data().meals || []
                        };
                    }
                    return null;
                });

                const mealsResults = await Promise.all(mealsPromises);
                const validMeals = mealsResults.filter(meal => meal !== null);
                setMealProgress(validMeals || []);

            } catch (error) {
                console.error('Error al cargar datos:', error);
                setSnackbar({
                    open: true,
                    message: 'Error al cargar los datos del progreso',
                    severity: 'error'
                });
                // Inicializar con arrays vacíos para evitar errores
                setPhotos([]);
                setWeightHistory([]);
                setExerciseHistory([]);
                setMealProgress([]);
                setExerciseStats({
                    totalExercises: 0,
                    totalSets: 0,
                    totalReps: 0,
                    averageRPE: 0
                });
                setGroupedExercises({});
            } finally {
                setLoading(false);
            }
        };

        if (clientId && userDetails?.IDPT) {
            fetchData();
        } else {
            // Si no hay clientId o userDetails.IDPT, inicializar con arrays vacíos
            setPhotos([]);
            setWeightHistory([]);
            setExerciseHistory([]);
            setMealProgress([]);
            setLoading(false);
        }
    }, [clientId, userDetails?.IDPT, db]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const renderOverviewSection = () => (
        <Fade in timeout={500}>
            <Box>
                <Grid container spacing={3}>
                    {/* Métricas principales */}
                    <Grid item xs={12} md={4}>
                        <MetricCard
                            title="Peso Actual"
                            value={`${weightHistory[0]?.weight || '---'} kg`}
                            previousValue={weightHistory[1]?.weight}
                            icon={<ScaleIcon sx={{ color: '#2196F3' }} />}
                            color="#2196F3"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MetricCard
                            title="Ejercicios Completados"
                            value={exerciseHistory?.reduce((total, day) => total + (day.ejercicios?.length || 0), 0) || 0}
                            previousValue={exerciseHistory?.length > 0 ? exerciseHistory.slice(1).reduce((total, day) => total + (day.ejercicios?.length || 0), 0) : null}
                            icon={<FitnessCenterIcon sx={{ color: '#4CAF50' }} />}
                            color="#4CAF50"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MetricCard
                            title="Comidas Registradas"
                            value={mealProgress[0]?.meals?.length || 0}
                            previousValue={mealProgress[1]?.meals?.length}
                            icon={<RestaurantIcon sx={{ color: '#FF9800' }} />}
                            color="#FF9800"
                        />
                    </Grid>

                    {/* Gráfica de peso */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }}>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                mb: 2
                            }}>
                                <Typography variant="h6" gutterBottom>
                                    Evolución del Peso
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    {weightHistory.length > 0 && (
                                        <>
                                            <Chip
                                                label={`Inicial: ${weightHistory[0]?.weight} kg`}
                                                color="default"
                                                size="small"
                                            />
                                            <Chip
                                                label={`Actual: ${weightHistory[weightHistory.length - 1]?.weight} kg`}
                                                color="primary"
                                                size="small"
                                            />
                                            <Chip
                                                label={`Diferencia: ${(weightHistory[weightHistory.length - 1]?.weight - weightHistory[0]?.weight).toFixed(1)} kg`}
                                                color={weightHistory[weightHistory.length - 1]?.weight - weightHistory[0]?.weight > 0 ? "error" : "success"}
                                                size="small"
                                            />
                                        </>
                                    )}
                                </Box>
                            </Box>
                            <Box sx={{ height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={weightHistory || []}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis 
                                            dataKey="date" 
                                            tickFormatter={(date) => new Date(date).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: 'short'
                                            })}
                                        />
                                        <YAxis 
                                            domain={['dataMin - 1', 'dataMax + 1']}
                                            tickFormatter={(value) => `${value} kg`}
                                        />
                                        <Tooltip 
                                            labelFormatter={(date) => new Date(date).toLocaleDateString('es-ES', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                            formatter={(value) => [`${value} kg`, 'Peso']}
                                        />
                                        <Legend />
                                        <Line 
                                            type="monotone" 
                                            dataKey="weight" 
                                            stroke="#2196F3" 
                                            name="Peso"
                                            strokeWidth={2}
                                            dot={{ r: 4 }}
                                            activeDot={{ r: 6, strokeWidth: 2 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Fotos recientes */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">
                                    Fotos de Progreso Recientes
                                </Typography>
                                <Button
                                    startIcon={<CompareArrowsIcon />}
                                    variant="outlined"
                                    onClick={() => setCurrentTab(1)}
                                >
                                    Ver Comparación
                                </Button>
                            </Box>
                            <Grid container spacing={2}>
                                {photos.slice(0, 3).map((photo) => (
                                    <Grid item xs={12} sm={4} key={photo.id}>
                                        <Card 
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': { transform: 'scale(1.02)' },
                                                transition: 'transform 0.2s'
                                            }}
                                            onClick={() => {
                                                setSelectedPhoto(photo);
                                                setOpenPhotoDialog(true);
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="200"
                                                image={photo.url}
                                                alt={`Progreso ${photo.categoria}`}
                                                sx={{ objectFit: 'cover' }}
                                            />
                                            <CardContent>
                                                <Typography variant="body2" color="text.secondary">
                                                    {new Date(photo.createdAt?.seconds * 1000).toLocaleDateString()}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Tabla de ejercicios recientes */}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Últimos Ejercicios Realizados
                            </Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Ejercicio</TableCell>
                                            <TableCell align="right">Series</TableCell>
                                            <TableCell align="right">Repeticiones</TableCell>
                                            <TableCell align="right">Peso</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {exerciseProgress.slice(0, 5).map((exercise) => (
                                            <TableRow key={exercise.id}>
                                                <TableCell>{exercise.name}</TableCell>
                                                <TableCell align="right">{exercise.sets}</TableCell>
                                                <TableCell align="right">{exercise.reps}</TableCell>
                                                <TableCell align="right">{exercise.weight}kg</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>

                    {/* Tabla de comidas recientes */}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Últimas Comidas Registradas
                            </Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Comida</TableCell>
                                            <TableCell align="right">Calorías</TableCell>
                                            <TableCell align="right">Proteínas</TableCell>
                                            <TableCell align="right">Cumplimiento</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mealProgress.slice(0, 5).map((meal) => (
                                            <TableRow key={meal.date}>
                                                <TableCell>{meal.name}</TableCell>
                                                <TableCell align="right">{meal.calories}kcal</TableCell>
                                                <TableCell align="right">{meal.protein}g</TableCell>
                                                <TableCell align="right">
                                                    <Chip 
                                                        label={`${meal.adherence}%`}
                                                        size="small"
                                                        color={meal.adherence > 80 ? "success" : "warning"}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );

    const renderPhotosSection = () => (
        <Fade in timeout={500}>
            <Box>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 3 
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        Galería de Progreso
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenUploadDialog(true)}
                        sx={{
                            borderRadius: 8,
                            textTransform: 'none',
                            background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
                            boxShadow: '0 3px 10px rgba(255, 107, 107, 0.3)',
                            px: 3,
                            py: 1,
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF5252 30%, #FF7043 90%)',
                                transform: 'translateY(-2px)',
                                boxShadow: '0 6px 15px rgba(255, 107, 107, 0.4)',
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Añadir Foto
                    </Button>
                </Box>

                <Grid container spacing={2}>
                    {photos.map((photo) => (
                        <Grid item xs={12} sm={6} md={4} key={photo.id}>
                            <Card 
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { transform: 'scale(1.02)' },
                                    transition: 'transform 0.2s',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="240"
                                    image={photo.url}
                                    alt={`Progreso ${photo.categoria}`}
                                    sx={{ objectFit: 'cover' }}
                                    onClick={() => {
                                        setSelectedPhoto(photo);
                                        setOpenPhotoDialog(true);
                                    }}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <Box>
                                            <Chip
                                                label={photo.categoria}
                                                size="small"
                                                sx={{ 
                                                    mb: 1,
                                                    backgroundColor: 'primary.main',
                                                    color: 'white',
                                                }}
                                            />
                                            <Typography variant="body2" color="text.secondary">
                                                {photo.createdAt?.toDate().toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedPhoto(photo);
                                                setOpenDeleteDialog(true);
                                            }}
                                            sx={{ 
                                                color: 'error.main',
                                                '&:hover': {
                                                    backgroundColor: 'error.light',
                                                    color: 'error.dark',
                                                }
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                    {photo.description && (
                                        <Typography 
                                            variant="body2" 
                                            color="text.secondary"
                                            sx={{ 
                                                mt: 1,
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {photo.description}
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Fade>
    );

    const renderMealsSection = () => (
        <Fade in timeout={500}>
            <Box>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 3 
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        Registro de Alimentación
                    </Typography>
                </Box>

                {/* Resumen de hoy */}
                <Paper sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Resumen de Hoy
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ 
                                p: 2,
                                background: 'linear-gradient(135deg, #FF9800 15%, #FF9800 05)',
                                color: 'white'
                            }}>
                                <Typography variant="subtitle2">
                                    Comidas Registradas
                                </Typography>
                                <Typography variant="h4" sx={{ mt: 1 }}>
                                    {mealProgress[0]?.meals?.length || 0}
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Card sx={{ p: 2, height: '100%' }}>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    {['desayuno', 'media_manana', 'almuerzo', 'merienda', 'cena'].map((mealType) => (
                                        <Chip
                                            key={mealType}
                                            label={mealType.replace('_', ' ').toUpperCase()}
                                            color={mealProgress[0]?.meals?.some(m => m.type === mealType) ? "success" : "default"}
                                            variant={mealProgress[0]?.meals?.some(m => m.type === mealType) ? "filled" : "outlined"}
                                        />
                                    ))}
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Calendario y Lista de comidas */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 3 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                <DateCalendar 
                                    value={selectedDate}
                                    onChange={(newDate) => setSelectedDate(newDate)}
                                    sx={{
                                        width: '100%',
                                        '& .MuiPickersDay-root.Mui-selected': {
                                            background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
                                            '&:hover': {
                                                background: 'linear-gradient(45deg, #FF5252 30%, #FF7043 90%)',
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Comidas del {selectedDate.toLocaleDateString('es-ES', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </Typography>
                            <Grid container spacing={2}>
                                {mealProgress
                                    .find(day => day?.date === selectedDate.toISOString().split('T')[0])
                                    ?.meals?.map((meal) => (
                                        <Grid item xs={12} key={meal.id || meal.type}>
                                            <Card sx={{ 
                                                display: 'flex',
                                                height: '100%',
                                                position: 'relative',
                                                '&:hover': {
                                                    boxShadow: 3
                                                }
                                            }}>
                                                {meal.photo_url && (
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ 
                                                            width: 140,
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                opacity: 0.8
                                                            }
                                                        }}
                                                        image={meal.photo_url}
                                                        alt={meal.name}
                                                        onClick={() => {
                                                            setSelectedMealPhoto(meal.photo_url);
                                                            setOpenMealPhotoDialog(true);
                                                        }}
                                                    />
                                                )}
                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    flexDirection: 'column',
                                                    flex: 1,
                                                    p: 2
                                                }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                        <Box>
                                                            <Chip
                                                                label={(meal.type || '').replace('_', ' ').toUpperCase()}
                                                                size="small"
                                                                sx={{ mb: 1 }}
                                                            />
                                                            <Typography variant="subtitle1">
                                                                {meal.name}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="caption" color="text.secondary">
                                                            {meal.time}
                                                        </Typography>
                                                    </Box>
                                                    
                                                    {meal.description && (
                                                        <Typography 
                                                            variant="body2" 
                                                            color="text.secondary"
                                                            sx={{ mt: 1 }}
                                                        >
                                                            {meal.description}
                                                        </Typography>
                                                    )}

                                                    {meal.items && meal.items.length > 0 && (
                                                        <Box sx={{ mt: 1 }}>
                                                            <Typography variant="caption" color="text.secondary">
                                                                Alimentos:
                                                            </Typography>
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                                                                {meal.items.map((item, idx) => (
                                                                    <Chip
                                                                        key={idx}
                                                                        label={item}
                                                                        size="small"
                                                                        variant="outlined"
                                                                    />
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    )}

                                                    {meal.notes && (
                                                        <Typography 
                                                            variant="body2" 
                                                            color="text.secondary"
                                                            sx={{ 
                                                                mt: 1,
                                                                fontStyle: 'italic'
                                                            }}
                                                        >
                                                            Nota: {meal.notes}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Card>
                                        </Grid>
                                    )) || (
                                        <Grid item xs={12}>
                                            <Box sx={{ 
                                                p: 3, 
                                                textAlign: 'center',
                                                bgcolor: 'grey.50',
                                                borderRadius: 1
                                            }}>
                                                <Typography color="text.secondary">
                                                    No hay comidas registradas para este día
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );

    const renderExerciseSection = () => (
        <Fade in timeout={500}>
            <Box>
                {/* Cabecera y Filtros */}
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 3 
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        Registro de Ejercicios
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl size="small" sx={{ minWidth: 120 }}>
                            <InputLabel>Filtrar por</InputLabel>
                            <Select
                                value={exerciseFilter}
                                label="Filtrar por"
                                onChange={(e) => setExerciseFilter(e.target.value)}
                            >
                                <MenuItem value="all">Todos</MenuItem>
                                <MenuItem value="upper">Tren Superior</MenuItem>
                                <MenuItem value="lower">Tren Inferior</MenuItem>
                                <MenuItem value="core">Core</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl size="small" sx={{ minWidth: 120 }}>
                            <InputLabel>Periodo</InputLabel>
                            <Select
                                value={dateRange}
                                label="Periodo"
                                onChange={(e) => {
                                    setDateRange(e.target.value);
                                    if (e.target.value !== 'day') {
                                        setSelectedFilterDate(null);
                                    }
                                }}
                            >
                                <MenuItem value="day">Día específico</MenuItem>
                                <MenuItem value="week">Última Semana</MenuItem>
                                <MenuItem value="month">Último Mes</MenuItem>
                                <MenuItem value="3months">Últimos 3 Meses</MenuItem>
                            </Select>
                        </FormControl>
                        {dateRange === 'day' && (
                            <Box sx={{ minWidth: 120 }}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => setOpenDatePicker(true)}
                                    startIcon={<CalendarIcon />}
                                >
                                    {selectedFilterDate ? new Date(selectedFilterDate).toLocaleDateString() : 'Seleccionar día'}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* Tarjetas de Estadísticas */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card sx={{ 
                            p: 2,
                            background: 'linear-gradient(135deg, #4CAF50 30%, #81C784 90%)',
                            color: 'white',
                            boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)'
                        }}>
                            <Typography variant="subtitle2">
                                Ejercicios Realizados
                            </Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>
                                {exerciseStats.totalExercises}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card sx={{ 
                            p: 2,
                            background: 'linear-gradient(135deg, #2196F3 30%, #64B5F6 90%)',
                            color: 'white',
                            boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)'
                        }}>
                            <Typography variant="subtitle2">
                                Series Totales
                            </Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>
                                {exerciseStats.totalSets}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card sx={{ 
                            p: 2,
                            background: 'linear-gradient(135deg, #FF9800 30%, #FFB74D 90%)',
                            color: 'white',
                            boxShadow: '0 3px 5px 2px rgba(255, 152, 0, .3)'
                        }}>
                            <Typography variant="subtitle2">
                                Repeticiones Totales
                            </Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>
                                {exerciseStats.totalReps}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card sx={{ 
                            p: 2,
                            background: 'linear-gradient(135deg, #9C27B0 30%, #BA68C8 90%)',
                            color: 'white',
                            boxShadow: '0 3px 5px 2px rgba(156, 39, 176, .3)'
                        }}>
                            <Typography variant="subtitle2">
                                RPE Promedio
                            </Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>
                                {exerciseStats.averageRPE}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>

                {/* Lista de Ejercicios por Día */}
                {filteredExerciseHistory.map((day) => (
                    <Paper key={day.fecha} sx={{ mb: 3, overflow: 'hidden' }}>
                        <Box sx={{ 
                            p: 2, 
                            bgcolor: 'grey.50',
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Box>
                                <Typography variant="subtitle1" fontWeight="500">
                                    {new Date(day.fecha).toLocaleDateString('es-ES', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {day.routineName}
                                </Typography>
                            </Box>
                            <Chip 
                                label={`${day.ejercicios?.length || 0} ejercicios`}
                                color="primary"
                                size="small"
                            />
                        </Box>
                        
                        <Box sx={{ p: 2 }}>
                            {day.ejercicios && Array.isArray(day.ejercicios) && day.ejercicios.map((exercise, index) => (
                                <Card 
                                    key={exercise.id || index} 
                                    sx={{ 
                                        mb: index < (day.ejercicios?.length || 0) - 1 ? 2 : 0,
                                        '&:hover': {
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <CardContent>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                            <Box>
                                                <Typography variant="subtitle1" fontWeight="500">
                                                    {exercise.nombre}
                                                </Typography>
                                                <Box sx={{ display: 'flex', gap: 1, mt: 0.5 }}>
                                                    {exercise.muscleGroups && Array.isArray(exercise.muscleGroups) && exercise.muscleGroups.map((muscle, idx) => (
                                                        <Chip
                                                            key={idx}
                                                            label={muscle}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    ))}
                                                </Box>
                                            </Box>
                                            <Typography variant="caption" color="text.secondary">
                                                {exercise.hora}
                                            </Typography>
                                        </Box>

                                        {/* Tabla de Series */}
                                        {exercise.series && Array.isArray(exercise.series) && exercise.series.length > 0 && (
                                            <TableContainer sx={{ mt: 2 }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Serie</TableCell>
                                                            <TableCell align="right">Repeticiones</TableCell>
                                                            <TableCell align="right">Peso (kg)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {exercise.series.map((serie, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell>{idx + 1}</TableCell>
                                                                <TableCell align="right">{serie.repeticiones || 0}</TableCell>
                                                                <TableCell align="right">{serie.peso || 0}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}

                                        <Box sx={{ 
                                            mt: 2, 
                                            display: 'flex', 
                                            flexDirection: 'column',
                                            gap: 1
                                        }}>
                                            {exercise.rpe && (
                                                <Box sx={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Chip
                                                        label={`RPE: ${exercise.rpe}`}
                                                        size="small"
                                                        color="secondary"
                                                        sx={{
                                                            maxWidth: 'fit-content'
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                            {exercise.comentarios && (
                                                <Box sx={{
                                                    mt: 1,
                                                    p: 1.5,
                                                    bgcolor: 'grey.50',
                                                    borderRadius: 1,
                                                    border: '1px solid',
                                                    borderColor: 'divider'
                                                }}>
                                                    <Typography 
                                                        variant="caption" 
                                                        color="text.secondary"
                                                        component="div"
                                                        sx={{ mb: 0.5 }}
                                                    >
                                                        Comentario:
                                                    </Typography>
                                                    <Typography 
                                                        variant="body2" 
                                                        color="text.primary"
                                                    >
                                                        {exercise.comentarios}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>

                                        {exercise.techniqueVideo && (
                                            <Box sx={{ mt: 2 }}>
                                                <Button
                                                    startIcon={<VideoLibraryIcon />}
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedExercise(exercise);
                                                    }}
                                                >
                                                    Ver Video de Técnica
                                                </Button>
                                            </Box>
                                        )}
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Paper>
                ))}

                {filteredExerciseHistory.length === 0 && (
                    <Box sx={{ 
                        textAlign: 'center', 
                        py: 8,
                        bgcolor: 'grey.50',
                        borderRadius: 2
                    }}>
                        <FitnessCenterIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                            No hay ejercicios registrados
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {exerciseHistory.length === 0 
                                ? 'Los ejercicios completados aparecerán aquí'
                                : 'No hay ejercicios que coincidan con los filtros seleccionados'}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Fade>
    );

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setNewPhotoFile(file);
        } else {
            setSnackbar({
                open: true,
                message: 'Por favor, selecciona un archivo de imagen válido',
                severity: 'error'
            });
        }
    };

    const handleUploadPhoto = async () => {
        if (!newPhotoFile) return;

        setUploading(true);
        try {
            const fileName = `${Date.now()}_${newPhotoFile.name}`;
            const storageRef = ref(storage, `progress_pictures/${userDetails.uid}/${clientId}/${fileName}`);
            const uploadResult = await uploadBytes(storageRef, newPhotoFile);
            const downloadURL = await getDownloadURL(uploadResult.ref);

            const photoData = {
                url: downloadURL,
                description: photoDescription,
                categoria: photoCategory,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                createdBy: userDetails.uid,
                clientId: clientId,
                profesionalId: userDetails.uid,
                profesionalType: userDetails.tipo_profesional,
                centroId: userDetails.IDBO || null
            };

            await addDoc(collection(db, 'progress_photos'), photoData);

            setSnackbar({
                open: true,
                message: 'Foto subida exitosamente',
                severity: 'success'
            });
            
            handleCloseUploadDialog();
            const photosRef = collection(db, 'progress_photos');
            const photosQuery = query(
                photosRef,
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc')
            );
            const photosSnapshot = await getDocs(photosQuery);
            const photosData = photosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPhotos(photosData);
        } catch (error) {
            console.error('Error al subir la foto:', error);
            setSnackbar({
                open: true,
                message: 'Error al subir la foto',
                severity: 'error'
            });
        } finally {
            setUploading(false);
        }
    };

    const handleDeletePhoto = async () => {
        if (!selectedPhoto) return;

        try {
            const photoUrl = new URL(selectedPhoto.url);
            const storagePath = decodeURIComponent(photoUrl.pathname.split('/o/')[1].split('?')[0]);
            const storageRef = ref(storage, storagePath);

            await deleteObject(storageRef);
            await deleteDoc(doc(db, 'progress_photos', selectedPhoto.id));

            setSnackbar({
                open: true,
                message: 'Foto eliminada exitosamente',
                severity: 'success'
            });

            setOpenDeleteDialog(false);
            const photosRef = collection(db, 'progress_photos');
            const photosQuery = query(
                photosRef,
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc')
            );
            const photosSnapshot = await getDocs(photosQuery);
            const photosData = photosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPhotos(photosData);
        } catch (error) {
            console.error('Error al eliminar la foto:', error);
            setSnackbar({
                open: true,
                message: 'Error al eliminar la foto',
                severity: 'error'
            });
        }
    };

    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
        setNewPhotoFile(null);
        setPhotoDescription('');
        setPhotoCategory('frente');
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
        </Box>
    );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                <Tabs 
                    value={currentTab} 
                    onChange={handleTabChange}
                    variant="fullWidth"
                    sx={{
                        mb: 3,
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            minHeight: 64,
                            textTransform: 'none',
                            fontSize: '1rem',
                        }
                    }}
                >
                    <Tab 
                        icon={<TimelineIcon />} 
                        label="Resumen General" 
                        iconPosition="start"
                    />
                    <Tab 
                        icon={<PhotoLibraryIcon />} 
                        label="Fotos de Progreso" 
                        iconPosition="start"
                    />
                    <Tab 
                        icon={<FitnessCenterIcon />} 
                        label="Progreso de Ejercicios" 
                        iconPosition="start"
                    />
                    <Tab 
                        icon={<RestaurantIcon />} 
                        label="Registro de Comidas" 
                        iconPosition="start"
                    />
                </Tabs>

                {currentTab === 0 && renderOverviewSection()}
                {currentTab === 1 && renderPhotosSection()}
                {currentTab === 2 && renderExerciseSection()}
                {currentTab === 3 && renderMealsSection()}
            </Paper>

            {/* Dialog para ver foto */}
            <Dialog
                open={openPhotoDialog}
                onClose={() => setOpenPhotoDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent sx={{ p: 0 }}>
                    {selectedPhoto && (
                        <img
                            src={selectedPhoto.url}
                            alt="Foto de progreso"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* Dialog para ver video de técnica */}
            <Dialog
                open={Boolean(selectedExercise?.techniqueVideo)}
                onClose={() => setSelectedExercise(null)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Video de Técnica - {selectedExercise?.nombre}
                        </Typography>
                        <IconButton onClick={() => setSelectedExercise(null)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    {selectedExercise?.techniqueVideo && (
                        <video
                            src={selectedExercise.techniqueVideo}
                            controls
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* Dialog para subir foto */}
            <Dialog
                open={openUploadDialog}
                onClose={handleCloseUploadDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Subir Nueva Foto</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<PhotoCameraIcon />}
                            fullWidth
                        >
                            Seleccionar Foto
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleFileSelect}
                            />
                        </Button>
                        {newPhotoFile && (
                            <Typography variant="body2" color="text.secondary">
                                Archivo seleccionado: {newPhotoFile.name}
                            </Typography>
                        )}
                        <FormControl fullWidth>
                            <InputLabel>Categoría</InputLabel>
                            <Select
                                value={photoCategory}
                                label="Categoría"
                                onChange={(e) => setPhotoCategory(e.target.value)}
                            >
                                <MenuItem value="frente">Frente</MenuItem>
                                <MenuItem value="espalda">Espalda</MenuItem>
                                <MenuItem value="perfil">Perfil</MenuItem>
                                <MenuItem value="otro">Otro</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Descripción"
                            multiline
                            rows={3}
                            value={photoDescription}
                            onChange={(e) => setPhotoDescription(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUploadDialog}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleUploadPhoto}
                        variant="contained"
                        disabled={!newPhotoFile || uploading}
                        startIcon={uploading ? <CircularProgress size={20} /> : null}
                    >
                        {uploading ? 'Subiendo...' : 'Subir Foto'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog de confirmación para eliminar */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Eliminar Foto</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar esta foto? Esta acción no se puede deshacer.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeletePhoto}
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog para ver foto de comida */}
            <Dialog
                open={openMealPhotoDialog}
                onClose={() => setOpenMealPhotoDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent sx={{ p: 0 }}>
                    {selectedMealPhoto && (
                        <img
                            src={selectedMealPhoto}
                            alt="Foto de comida"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMealPhotoDialog(false)}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog del Selector de Fecha */}
            <Dialog
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Seleccionar Día</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                        <DateCalendar
                            value={selectedFilterDate || null}
                            onChange={(newDate) => {
                                setSelectedFilterDate(newDate);
                                setOpenDatePicker(false);
                            }}
                            sx={{
                                width: '100%',
                                '& .MuiPickersDay-root.Mui-selected': {
                                    background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #FF5252 30%, #FF7043 90%)',
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </DialogContent>
            </Dialog>

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert 
                    onClose={() => setSnackbar({ ...snackbar, open: false })} 
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProgressView; 