import React, { useState, useEffect, useContext } from 'react';
import { 
    Typography, Box, Paper, Container, Button, List, ListItem, 
    ListItemText, ListItemAvatar, Avatar, Divider, TextField, IconButton,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import messagesBackground from '../../assets/images/imagen-mensajes-dashboard-sinfondo.png';
import { AuthContext } from '../../contexts/AuthContext';
import { collection, query, where, getDocs, addDoc, serverTimestamp, orderBy, limit, doc, deleteDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';

const Messages = () => {
    const [chats, setChats] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { userDetails } = useContext(AuthContext);
    const [openNewChatDialog, setOpenNewChatDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(() => {
        fetchChats();
    }, [userDetails]); // Añadimos fetchChats como dependencia

    const fetchChats = async () => {
        if (!userDetails) return;

        let chatsQuery;
        if (['entrenador', 'propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
            chatsQuery = query(collection(db, 'Chats'), where('IDPT', '==', userDetails.IDPT));
        } else if (userDetails.tipo_profesional === 'entrenador_independiente') {
            chatsQuery = query(collection(db, 'Chats'), where('IDPT', '==', userDetails.IDPT));
        }

        try {
            const querySnapshot = await getDocs(chatsQuery);
            const fetchedChats = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();
                const clientParticipant = data.participantes.find(p => p.tipo === 'cliente');
                
                // Obtener el último mensaje
                const messagesQuery = query(collection(db, 'Chats', doc.id, 'messages'), orderBy('time', 'desc'), limit(1));
                const messagesSnapshot = await getDocs(messagesQuery);
                const lastMessage = messagesSnapshot.docs[0]?.data();

                return {
                    id: doc.id,
                    ...data,
                    name: clientParticipant ? clientParticipant.nombre : 'Cliente sin nombre',
                    photo_url: clientParticipant ? clientParticipant.photo_url : '',
                    isGroup: data.participantes.length > 2,
                    lastMessage: lastMessage?.texto || 'No hay mensajes',
                    timestamp: lastMessage?.time ? new Date(lastMessage.time.toDate()).toLocaleString() : 'Sin fecha',
                    unread: data.unreadCount || 0
                };
            }));
            
            // Ordenar los chats por el timestamp más reciente
            fetchedChats.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            
            setChats(fetchedChats);
        } catch (error) {
            console.error("Error fetching chats: ", error);
        }
    };

    const handleOpenNewChatDialog = async () => {
        await fetchClients();
        setOpenNewChatDialog(true);
    };

    const handleCloseNewChatDialog = () => {
        setOpenNewChatDialog(false);
        setSelectedClient(null);
    };

    const fetchClients = async () => {
        if (!userDetails) return;

        let clientsQuery;
        if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
            // Mantener la lógica existente para estos tipos de profesionales
            const ownerQuery = query(
                collection(db, 'userDetails'), 
                where('tipo_profesional', '==', 'propietario_centro'),
                where('IDBO', '==', userDetails.IDBO)
            );
            const ownerSnapshot = await getDocs(ownerQuery);
            if (!ownerSnapshot.empty) {
                const ownerDoc = ownerSnapshot.docs[0];
                clientsQuery = collection(ownerDoc.ref, 'Clientes_asociados');
            }
        } else if (userDetails.tipo_profesional === 'entrenador_independiente') {
            // Nueva lógica para entrenadores independientes
            clientsQuery = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
        }

        if (clientsQuery) {
            const clientsSnapshot = await getDocs(clientsQuery);
            const fetchedClients = clientsSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    fullName: `${data.nombre || ''} ${data.apellidos || ''}`,
                    photo_url: data.photo_url || null,
                    uidcliente: data.uidcliente || doc.id // Usar el ID del documento si no hay uidcliente
                };
            });
            setClients(fetchedClients);
        }
    };

    const handleCreateNewChat = async () => {
        if (!selectedClient || !userDetails) {
            console.error("No hay cliente seleccionado o detalles de usuario");
            return;
        }

        // Validar que tengamos los datos mínimos necesarios
        if (!userDetails.uid || !selectedClient.id) {
            console.error("Faltan datos necesarios para crear el chat");
            return;
        }

        try {
            const newChatDoc = await addDoc(collection(db, 'Chats'), {
                IDBO: userDetails.IDBO || null,
                IDPT: userDetails.IDPT || null,
                timeStamp: serverTimestamp(),
                ultimoMensaje: '',
                participantes: [
                    {
                        nombre: userDetails.name && userDetails.apellidos 
                            ? `${userDetails.name} ${userDetails.apellidos}`
                            : 'Usuario sin nombre',
                        photo_url: userDetails.photo_url || null,
                        tipo: 'profesional',
                        uid: userDetails.uid
                    },
                    {
                        nombre: selectedClient.fullName || 'Cliente sin nombre',
                        photo_url: selectedClient.photo_url || null,
                        tipo: 'cliente',
                        uid: selectedClient.uidcliente || selectedClient.id
                    }
                ],
                unreadCounts: {
                    [userDetails.uid]: 0,
                    [selectedClient.uidcliente || selectedClient.id]: 0
                }
            });

            console.log("Nuevo chat creado con ID: ", newChatDoc.id);
            handleCloseNewChatDialog();
            fetchChats(); // Actualizar la lista de chats
        } catch (error) {
            console.error("Error al crear nuevo chat: ", error);
            // Mostrar el error específico
            alert(`Error al crear el chat: ${error.message}`);
        }
    };

    const handleDeleteChat = async () => {
        if (!chatToDelete) return;

        try {
            const batch = writeBatch(db);
            
            // Primero, obtener todos los mensajes del chat
            const messagesRef = collection(db, 'Chats', chatToDelete.id, 'messages');
            const messagesSnapshot = await getDocs(messagesRef);
            
            // Eliminar cada mensaje
            messagesSnapshot.docs.forEach((messageDoc) => {
                batch.delete(doc(db, 'Chats', chatToDelete.id, 'messages', messageDoc.id));
            });
            
            // Eliminar el documento principal del chat
            batch.delete(doc(db, 'Chats', chatToDelete.id));
            
            // Ejecutar el batch
            await batch.commit();

            // Actualizar el estado local
            setChats(prevChats => prevChats.filter(chat => chat.id !== chatToDelete.id));
            setOpenDeleteDialog(false);
            setChatToDelete(null);
        } catch (error) {
            console.error("Error al eliminar el chat:", error);
            alert('Error al eliminar el chat');
        }
    };

    const handleOpenDeleteDialog = (chat, event) => {
        event.preventDefault(); // Prevenir la navegación al chat
        event.stopPropagation(); // Evitar que el evento llegue al ListItem
        setChatToDelete(chat);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setChatToDelete(null);
    };

    const filteredChats = chats.filter(chat => 
        chat.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3, 
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${messagesBackground})`,
                    backgroundSize: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    opacity: 0.1,
                    zIndex: -1,
                }
            }}
        >
            <Typography variant="h4" gutterBottom align="left" sx={{ mb: 3 }}>
                Mensajes
            </Typography>
            <Container maxWidth="md">
                <Paper 
                    elevation={3} 
                    sx={{ 
                        p: 3,
                        borderRadius: 4,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                        mb: 3
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleOpenNewChatDialog}
                            sx={{
                                backgroundColor: '#FF5722',
                                '&:hover': {
                                    backgroundColor: '#FF7043',
                                },
                            }}
                        >
                            Nuevo chat
                        </Button>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Buscar chat"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 4,
                                        backgroundColor: 'white',
                                    }
                                }}
                            />
                            <IconButton sx={{ ml: 1 }}>
                                <SearchIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <List>
                        {filteredChats.map((chat, index) => (
                            <React.Fragment key={chat.id}>
                                <ListItem 
                                    button 
                                    component={Link} 
                                    to={`/chat/${chat.id}`}
                                    alignItems="flex-start"
                                    sx={{
                                        borderRadius: 2,
                                        mb: 1,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                        },
                                        position: 'relative', // Para posicionar el botón de eliminar
                                    }}
                                >
                                    <ListItemAvatar>
                                        {chat.isGroup ? (
                                            <Avatar>
                                                <GroupIcon />
                                            </Avatar>
                                        ) : (
                                            <Avatar alt={chat.name} src={chat.photo_url} />
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography 
                                                variant="subtitle1" 
                                                sx={{ 
                                                    fontWeight: 'bold',
                                                    color: 'text.primary',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                {chat.name}
                                            </Typography>
                                        }
                                        secondary={
                                            <Box>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical',
                                                    }}
                                                >
                                                    {chat.lastMessage}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    color="text.secondary"
                                                    sx={{ display: 'block', mt: 0.5 }}
                                                >
                                                    {chat.timestamp}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                    <IconButton
                                        onClick={(e) => handleOpenDeleteDialog(chat, e)}
                                        sx={{
                                            position: 'absolute',
                                            right: 16,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            color: 'error.main',
                                            opacity: 0,
                                            transition: 'opacity 0.2s',
                                            '&:hover': {
                                                backgroundColor: 'error.light',
                                                color: 'white',
                                            },
                                            '.MuiListItem-root:hover &': {
                                                opacity: 1,
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItem>
                                {index < filteredChats.length - 1 && <Divider variant="inset" component="li" />}
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>

                {/* Diálogo de confirmación para eliminar chat */}
                <Dialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                >
                    <DialogTitle>Confirmar eliminación</DialogTitle>
                    <DialogContent>
                        <Typography>
                            ¿Estás seguro de que quieres eliminar esta conversación con {chatToDelete?.name}? Esta acción no se puede deshacer.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleDeleteChat} color="error" variant="contained">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>

            <Dialog open={openNewChatDialog} onClose={handleCloseNewChatDialog}>
                <DialogTitle>Crear nuevo chat</DialogTitle>
                <DialogContent>
                    <List>
                        {clients.map((client) => (
                            <ListItem 
                                button 
                                key={client.id} 
                                onClick={() => setSelectedClient(client)}
                                selected={selectedClient && selectedClient.id === client.id}
                                sx={{
                                    borderRadius: '10px',
                                    mb: 1,
                                    '&.Mui-selected': {
                                        backgroundColor: '#FF5722',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#F4511E',
                                        },
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                    },
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar 
                                        src={client.photo_url}
                                        sx={{
                                            border: selectedClient && selectedClient.id === client.id 
                                                ? '2px solid white' 
                                                : 'none'
                                        }}
                                    />
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={client.fullName}
                                    primaryTypographyProps={{
                                        fontWeight: selectedClient && selectedClient.id === client.id 
                                            ? 'bold' 
                                            : 'normal'
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewChatDialog}>Cancelar</Button>
                    <Button 
                        onClick={handleCreateNewChat} 
                        disabled={!selectedClient}
                        sx={{
                            backgroundColor: selectedClient ? '#FF5722' : 'grey',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: selectedClient ? '#F4511E' : 'grey',
                            },
                            '&:disabled': {
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                color: 'rgba(0, 0, 0, 0.26)',
                            },
                        }}
                    >
                        Crear Chat
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Messages;
