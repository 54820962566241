import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    Chip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Tools = () => {
    const navigate = useNavigate();

    const tools = [
        {
            title: 'Gestión de Formularios',
            description: 'Crea y gestiona formularios para tus clientes. Guarda plantillas para usarlas con diferentes clientes.',
            icon: <AssignmentIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
            route: '/tools/forms',
            chips: []
        },
        {
            title: 'Biblioteca de Contenidos',
            description: 'Crea y gestiona contenido educativo premium para tus clientes: videos tutoriales, e-books, guías de ejercicios y más.',
            icon: <LibraryBooksIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
            route: '/tools/content',
            chips: [{ label: 'NUEVO', color: 'success' }]
        }
    ];

    return (
        <Box sx={{ py: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Herramientas
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Accede a todas las herramientas disponibles para facilitar tu trabajo como entrenador.
            </Typography>

            <Grid container spacing={3} sx={{ mt: 2 }}>
                {tools.map((tool, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card 
                            sx={{ 
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                    boxShadow: 6,
                                }
                            }}
                        >
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                    {tool.icon}
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="h6" component="div">
                                            {tool.title}
                                        </Typography>
                                        <Box sx={{ display: 'flex', gap: 1, mt: 0.5 }}>
                                            {tool.chips.map((chip, chipIndex) => (
                                                <Chip
                                                    key={chipIndex}
                                                    label={chip.label}
                                                    color={chip.color}
                                                    size="small"
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {tool.description}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{ p: 2, pt: 0 }}>
                                <Button 
                                    fullWidth
                                    variant="contained"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => navigate(tool.route)}
                                    sx={{
                                        borderRadius: '12px',
                                        textTransform: 'none',
                                        py: 1
                                    }}
                                >
                                    Acceder
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Tools; 