import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
} from '@mui/material';
import DailyGoalsTool from '../tools/DailyGoalsTool/DailyGoalsTool';

const ToolsView = ({ clientId }) => {
    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Objetivos Diarios
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DailyGoalsTool clientId={clientId} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default ToolsView; 