import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Snackbar,
    Alert,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Chip,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Menu,
    MenuItem,
    Tabs,
    Tab,
    TextField,
    Grid,
    Paper,
    Select,
    FormControl,
    InputLabel,
    FormHelperText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import { getFirestore, collection, addDoc, serverTimestamp, query, orderBy, getDocs, where, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

// Adaptación del componente FormTool para la página de herramientas general
const FormTool = ({ clientId }) => {
    const { userDetails } = useAuth();
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [questions, setQuestions] = useState([{ question: '', type: 'text' }]);
    const [frequency, setFrequency] = useState('once');
    const [selectedDay, setSelectedDay] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [templates, setTemplates] = useState([]);
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [templateName, setTemplateName] = useState('');
    const [openSaveTemplateDialog, setOpenSaveTemplateDialog] = useState(false);

    const DIAS_SEMANA = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

    useEffect(() => {
        fetchTemplates();
    }, [userDetails]);

    const fetchTemplates = async () => {
        try {
            setLoadingTemplates(true);
            const db = getFirestore();
            let templatesRef;

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                templatesRef = collection(db, 'userDetails', userDetails.uid, 'form_templates');
            } else {
                templatesRef = collection(db, 'Centros', userDetails.IDBO, 'form_templates');
            }

            const q = query(templatesRef, orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(q);
            const templatesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setTemplates(templatesData);
        } catch (error) {
            console.error('Error al cargar las plantillas:', error);
            setSnackbar({
                open: true,
                message: 'Error al cargar las plantillas',
                severity: 'error'
            });
        } finally {
            setLoadingTemplates(false);
        }
    };

    const handleOpenFormDialog = () => setOpenFormDialog(true);
    const handleCloseFormDialog = () => {
        setOpenFormDialog(false);
        setFormTitle('');
        setFormDescription('');
        setQuestions([{ question: '', type: 'text' }]);
        setFrequency('once');
        setSelectedDay('');
        setIsEditing(false);
        setSelectedTemplate(null);
    };

    const handleAddQuestion = () => {
        setQuestions([...questions, { question: '', type: 'text' }]);
    };

    const handleQuestionChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].question = value;
        setQuestions(newQuestions);
    };

    const handleQuestionTypeChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].type = value;
        setQuestions(newQuestions);
    };

    const handleRemoveQuestion = (index) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleSaveAsTemplate = async () => {
        try {
            if (!templateName.trim()) {
                setSnackbar({
                    open: true,
                    message: 'Por favor, introduce un nombre para la plantilla',
                    severity: 'warning'
                });
                return;
            }

            const db = getFirestore();
            const templateData = {
                name: templateName,
                title: formTitle,
                description: formDescription,
                questions: questions,
                frequency,
                selectedDay: frequency !== 'daily' && frequency !== 'once' ? selectedDay : null,
                createdAt: serverTimestamp(),
                createdBy: userDetails.uid
            };

            // Guarda la plantilla en la colección correspondiente según el tipo de profesional
            let templateRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                templateRef = collection(db, 'userDetails', userDetails.uid, 'form_templates');
            } else {
                templateRef = collection(db, 'Centros', userDetails.IDBO, 'form_templates');
            }
            await addDoc(templateRef, templateData);

            setSnackbar({
                open: true,
                message: 'Plantilla guardada exitosamente',
                severity: 'success'
            });
            setOpenSaveTemplateDialog(false);
            setTemplateName('');
            fetchTemplates();
        } catch (error) {
            console.error('Error al guardar la plantilla:', error);
            setSnackbar({
                open: true,
                message: 'Error al guardar la plantilla',
                severity: 'error'
            });
        }
    };

    const handleUseTemplate = (template) => {
        setFormTitle(template.title);
        setFormDescription(template.description);
        setQuestions(template.questions || [{ question: '', type: 'text' }]);
        setFrequency(template.frequency || 'once');
        setSelectedDay(template.selectedDay || '');
        setSelectedTemplate(template);
        setIsEditing(true);
        setOpenFormDialog(true);
    };

    const handleDeleteTemplate = async (templateId) => {
        try {
            const db = getFirestore();
            let templateRef;
            
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                templateRef = doc(db, 'userDetails', userDetails.uid, 'form_templates', templateId);
            } else {
                templateRef = doc(db, 'Centros', userDetails.IDBO, 'form_templates', templateId);
            }
            
            await deleteDoc(templateRef);
            
            setSnackbar({
                open: true,
                message: 'Plantilla eliminada exitosamente',
                severity: 'success'
            });
            
            fetchTemplates();
        } catch (error) {
            console.error('Error al eliminar la plantilla:', error);
            setSnackbar({
                open: true,
                message: 'Error al eliminar la plantilla',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const handleUpdateTemplate = async () => {
        try {
            if (!selectedTemplate) {
                setSnackbar({
                    open: true,
                    message: 'No se ha seleccionado ninguna plantilla para actualizar',
                    severity: 'error'
                });
                return;
            }

            const db = getFirestore();
            const templateData = {
                title: formTitle,
                description: formDescription,
                questions: questions,
                frequency,
                selectedDay: frequency !== 'daily' && frequency !== 'once' ? selectedDay : null,
                lastUpdated: serverTimestamp()
            };

            // Actualiza la plantilla en la colección correspondiente según el tipo de profesional
            let templateRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                templateRef = doc(db, 'userDetails', userDetails.uid, 'form_templates', selectedTemplate.id);
            } else {
                templateRef = doc(db, 'Centros', userDetails.IDBO, 'form_templates', selectedTemplate.id);
            }
            await updateDoc(templateRef, templateData);

            setSnackbar({
                open: true,
                message: 'Plantilla actualizada exitosamente',
                severity: 'success'
            });
            
            fetchTemplates();
            handleCloseFormDialog();
        } catch (error) {
            console.error('Error al actualizar la plantilla:', error);
            setSnackbar({
                open: true,
                message: 'Error al actualizar la plantilla',
                severity: 'error'
            });
        }
    };

    return (
        <Box>
            <Card variant="outlined" sx={{ mb: 3, borderRadius: 2 }}>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">
                            Plantillas de Formularios
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleOpenFormDialog}
                            sx={{ borderRadius: 8 }}
                        >
                            Crear plantilla
                        </Button>
                    </Box>
                    
                    <Typography variant="body2" color="text.secondary" paragraph>
                        Crea y gestiona plantillas de formularios que podrás utilizar con tus clientes.
                    </Typography>

                    {loadingTemplates ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                            <CircularProgress size={30} />
                        </Box>
                    ) : templates.length === 0 ? (
                        <Box sx={{ textAlign: 'center', my: 3, p: 3, bgcolor: 'background.default', borderRadius: 2 }}>
                            <Typography variant="body1" color="text.secondary">
                                No hay plantillas de formularios disponibles.
                            </Typography>
                            <Button 
                                variant="outlined" 
                                onClick={handleOpenFormDialog}
                                sx={{ mt: 2, borderRadius: 8 }}
                            >
                                Crear tu primera plantilla
                            </Button>
                        </Box>
                    ) : (
                        <List>
                            {templates.map((template) => (
                                <React.Fragment key={template.id}>
                                    <ListItem
                                        sx={{
                                            borderRadius: 2,
                                            mb: 1,
                                            border: '1px solid',
                                            borderColor: 'divider',
                                            '&:hover': { bgcolor: 'background.default' }
                                        }}
                                    >
                                        <ListItemText
                                            primary={template.name}
                                            secondary={
                                                <>
                                                    <Typography variant="body2" component="span">
                                                        {template.title}
                                                    </Typography>
                                                    <br />
                                                    <Typography variant="caption" color="text.secondary" component="span">
                                                        {template.questions.length} pregunta(s)
                                                    </Typography>
                                                </>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleUseTemplate(template)}
                                                sx={{ mr: 1, borderRadius: 8 }}
                                            >
                                                Editar
                                            </Button>
                                            <IconButton edge="end" onClick={() => handleDeleteTemplate(template.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    )}
                </CardContent>
            </Card>

            {/* Diálogo para crear/editar formulario */}
            <Dialog
                open={openFormDialog}
                onClose={handleCloseFormDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {isEditing ? 'Editar Plantilla de Formulario' : 'Crear Plantilla de Formulario'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 1 }}>
                        <TextField
                            label="Título del formulario"
                            fullWidth
                            value={formTitle}
                            onChange={(e) => setFormTitle(e.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Descripción"
                            fullWidth
                            value={formDescription}
                            onChange={(e) => setFormDescription(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            multiline
                            rows={2}
                        />

                        <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                            Preguntas
                        </Typography>

                        {questions.map((q, index) => (
                            <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={8}>
                                        <TextField
                                            label={`Pregunta ${index + 1}`}
                                            fullWidth
                                            value={q.question}
                                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                value={q.type || 'text'}
                                                onChange={(e) => handleQuestionTypeChange(index, e.target.value)}
                                                label="Tipo"
                                            >
                                                <MenuItem value="text">Texto</MenuItem>
                                                <MenuItem value="number">Número</MenuItem>
                                                <MenuItem value="yesno">Sí/No</MenuItem>
                                                <MenuItem value="rating">Valoración</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton color="error" onClick={() => handleRemoveQuestion(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}

                        <Button
                            startIcon={<AddIcon />}
                            onClick={handleAddQuestion}
                            variant="outlined"
                            sx={{ mt: 1, mb: 3 }}
                        >
                            Añadir pregunta
                        </Button>

                        {/* Sección de frecuencia oculta pero mantenemos la funcionalidad interna */}
                        {/* 
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                            Frecuencia
                        </Typography>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Frecuencia</InputLabel>
                            <Select
                                value={frequency}
                                onChange={(e) => setFrequency(e.target.value)}
                                label="Frecuencia"
                            >
                                <MenuItem value="once">Una sola vez</MenuItem>
                                <MenuItem value="daily">Diario</MenuItem>
                                <MenuItem value="weekly">Semanal</MenuItem>
                                <MenuItem value="monthly">Mensual</MenuItem>
                            </Select>
                        </FormControl>

                        {(frequency === 'weekly') && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Día de la semana</InputLabel>
                                <Select
                                    value={selectedDay}
                                    onChange={(e) => setSelectedDay(e.target.value)}
                                    label="Día de la semana"
                                >
                                    {DIAS_SEMANA.map((dia, index) => (
                                        <MenuItem key={index} value={index}>{dia}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {(frequency === 'monthly') && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Día del mes</InputLabel>
                                <Select
                                    value={selectedDay}
                                    onChange={(e) => setSelectedDay(e.target.value)}
                                    label="Día del mes"
                                >
                                    {[...Array(28)].map((_, i) => (
                                        <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        */}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFormDialog}>Cancelar</Button>
                    {isEditing ? (
                        <Button
                            onClick={handleUpdateTemplate}
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Actualizar plantilla
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setOpenSaveTemplateDialog(true)}
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Guardar plantilla
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Diálogo para guardar como plantilla */}
            <Dialog
                open={openSaveTemplateDialog}
                onClose={() => setOpenSaveTemplateDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Guardar como plantilla</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nombre de la plantilla"
                        fullWidth
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSaveTemplateDialog(false)}>Cancelar</Button>
                    <Button
                        onClick={handleSaveAsTemplate}
                        variant="contained"
                        color="primary"
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default FormTool; 