import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    IconButton,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    InputAdornment,
    Paper,
    CircularProgress,
    Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import FilterListIcon from '@mui/icons-material/FilterList';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import resourcesBackground from '../../assets/images/imagen-dashboard-clients.png'; // Asegúrate de tener esta imagen
import { AuthContext } from '../../contexts/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../config/firebaseConfig';
import { doc, collection, addDoc, getDocs, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { deleteObject, ref as storageRef } from 'firebase/storage';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { useSnackbar } from 'notistack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Recursos = () => {
    const { userDetails } = useContext(AuthContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editingResource, setEditingResource] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [recursos, setRecursos] = useState([]);
    const [loadingRecursos, setLoadingRecursos] = useState(true);
    const [playingVideo, setPlayingVideo] = useState(null);
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        categoria: [],
        dificultad: 0,
        file: null
    });
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [previewUrl, setPreviewUrl] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedCategoria, setSelectedCategoria] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    // Categorías basadas en las zonas musculares del sistema
    const categorias = [
        "Pecho",
        "Espalda",
        "Hombros",
        "Bíceps",
        "Tríceps",
        "Abdominales",
        "Pierna",
        "Glúteos",
        "Cardio",
        "Estiramiento",
        "Otro"
    ];

    const dificultades = [
        { valor: 0, label: 'Principiante' },
        { valor: 1, label: 'Intermedio' },
        { valor: 2, label: 'Avanzado' },
        { valor: 3, label: 'Experto' }
    ];

    const getFileType = (file) => {
        if (file.type.startsWith('video/')) return 'video';
        if (file.type.startsWith('image/')) return 'imagen';
        if (file.type.startsWith('audio/')) return 'audio';
        if (file.type === 'application/pdf') return 'pdf';
        return 'otro';
    };

    useEffect(() => {
        fetchRecursos();
    }, [userDetails]);

    useEffect(() => {
        if (formData.file) {
            const url = URL.createObjectURL(formData.file);
            setPreviewUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [formData.file]);

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, []);

    useEffect(() => {
        // Limpiar reproducción de video al desmontar el componente
        return () => {
            setPlayingVideo(null);
        };
    }, []);

    const fetchRecursos = async () => {
        if (!userDetails) return;

        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            } else {
                throw new Error('Tipo de profesional no válido');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setRecursos(recursosData);
        } catch (error) {
            console.error("Error al cargar los recursos:", error);
            enqueueSnackbar('Error al cargar los recursos', { 
                variant: 'error' 
            });
        } finally {
            setLoadingRecursos(false);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData(prev => ({
                ...prev,
                file: file
            }));
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files);
        if (files.length > 0) {
            // Validar todos los archivos
            const validFiles = files.filter(file => {
                const fileType = getFileType(file);
                const maxSize = 100 * 1024 * 1024; // 100MB

                if (!['video', 'imagen', 'audio', 'pdf'].includes(fileType)) {
                    enqueueSnackbar(`Archivo "${file.name}" no soportado. Solo se permiten videos, imágenes, audios o PDFs.`, {
                        variant: 'warning'
                    });
                    return false;
                }

                if (file.size > maxSize) {
                    enqueueSnackbar(`Archivo "${file.name}" demasiado grande. Máximo 100MB permitido.`, {
                        variant: 'warning'
                    });
                    return false;
                }

                return true;
            });

            if (validFiles.length > 0) {
                setMultipleFiles(validFiles);
                setCurrentFileIndex(0);
                setFormData(prev => ({
                    ...prev,
                    file: validFiles[0],
                    titulo: '',
                    descripcion: '',
                    categoria: [],
                    dificultad: 0
                }));
                setOpenDialog(true);

                if (validFiles.length > 1) {
                    enqueueSnackbar(`Se procesarán ${validFiles.length} archivos en secuencia.`, {
                        variant: 'info'
                    });
                }
            }
        }
    };

    const handleSubmit = async () => {
        if (!formData.titulo || formData.categoria.length === 0 || !formData.file) {
            enqueueSnackbar('Por favor, completa todos los campos obligatorios', { 
                variant: 'warning' 
            });
            return;
        }

        setIsLoading(true);
        try {
            const storage = getStorage();
            const fileName = `${Date.now()}_${formData.file.name}`;
            const storageRefPath = `recursos/${fileName}`;
            const storageRef = ref(storage, storageRefPath);
            
            await uploadBytes(storageRef, formData.file);
            const fileUrl = await getDownloadURL(storageRef);

            const fileType = getFileType(formData.file);
            const recursoData = {
                titulo: formData.titulo,
                descripcion: formData.descripcion,
                categoria: formData.categoria,
                dificultad: formData.dificultad,
                fileUrl: fileUrl,
                filePath: storageRefPath,
                fileType: fileType,
                fecha: serverTimestamp(),
                autor: userDetails?.display_name || 'Anónimo',
                vistas: 0
            };

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
                await addDoc(recursosRef, recursoData);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                const recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
                await addDoc(recursosRef, recursoData);
            }

            enqueueSnackbar('Recurso subido exitosamente', { 
                variant: 'success' 
            });

            // Si hay más archivos por procesar
            if (currentFileIndex < multipleFiles.length - 1) {
                setCurrentFileIndex(prev => prev + 1);
                setFormData(prev => ({
                    ...prev,
                    file: multipleFiles[currentFileIndex + 1],
                    titulo: '',
                    descripcion: '',
                    categoria: [],
                    dificultad: 0
                }));
            } else {
                setOpenDialog(false);
                setFormData({
                    titulo: '',
                    descripcion: '',
                    categoria: [],
                    dificultad: 0,
                    file: null
                });
                setMultipleFiles([]);
                setCurrentFileIndex(0);
            }
            
            await fetchRecursos();
            
        } catch (error) {
            console.error("Error al subir el recurso:", error);
            enqueueSnackbar(`Error al subir el recurso: ${error.message}`, { 
                variant: 'error' 
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteRecurso = async (recurso) => {
        if (!window.confirm('¿Estás seguro de que quieres eliminar este recurso?')) {
            return;
        }

        try {
            // 1. Eliminar el archivo de Storage usando filePath
            const storage = getStorage();
            const fileRef = ref(storage, recurso.filePath);
            await deleteObject(fileRef);

            // 2. Eliminar el documento de Firestore
            let recursoRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursoRef = doc(db, 'userDetails', userDetails.uid, 'recursos', recurso.id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                recursoRef = doc(db, 'Centros', userDetails.IDBO, 'recursos', recurso.id);
            }

            await deleteDoc(recursoRef);
            await fetchRecursos();
            
            enqueueSnackbar('Recurso eliminado con éxito', { 
                variant: 'success' 
            });
        } catch (error) {
            console.error("Error al eliminar el recurso:", error);
            enqueueSnackbar(`Error al eliminar el recurso: ${error.message}`, { 
                variant: 'error' 
            });
        }
    };

    const handleEditResource = async (updatedData) => {
        if (!editingResource) return;

        setIsLoading(true);
        try {
            let recursoRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursoRef = doc(db, 'userDetails', userDetails.uid, 'recursos', editingResource.id);
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                recursoRef = doc(db, 'Centros', userDetails.IDBO, 'recursos', editingResource.id);
            }

            await updateDoc(recursoRef, {
                titulo: updatedData.titulo,
                descripcion: updatedData.descripcion,
                categoria: updatedData.categoria,
                dificultad: updatedData.dificultad,
                fecha_actualizacion: serverTimestamp()
            });

            enqueueSnackbar('Recurso actualizado con éxito', { 
                variant: 'success' 
            });
            setOpenEditDialog(false);
            setEditingResource(null);
            await fetchRecursos();
        } catch (error) {
            console.error("Error al actualizar el recurso:", error);
            enqueueSnackbar(`Error al actualizar el recurso: ${error.message}`, { 
                variant: 'error' 
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Función para filtrar recursos
    const filteredRecursos = recursos.filter(recurso => {
        const matchesSearch = recurso.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            recurso.descripcion.toLowerCase().includes(searchTerm.toLowerCase());
        
        // Modificar para manejar categoría como array
        const matchesCategoria = !selectedCategoria || 
            (Array.isArray(recurso.categoria) 
                ? recurso.categoria.includes(selectedCategoria)
                : recurso.categoria === selectedCategoria); // Compatibilidad con recursos antiguos
        
        return matchesSearch && matchesCategoria;
    });

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const VideoThumbnail = ({ recurso, onClick }) => {
        const [thumbnailError, setThumbnailError] = useState(false);
        const videoRef = useRef(null);

        useEffect(() => {
            if (videoRef.current) {
                videoRef.current.currentTime = 1; // Establecer el tiempo en 1 segundo para el thumbnail
            }
        }, []);

        const handleLoadedData = () => {
            if (videoRef.current) {
                const canvas = document.createElement('canvas');
                canvas.width = videoRef.current.videoWidth;
                canvas.height = videoRef.current.videoHeight;
                canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                
                try {
                    canvas.toDataURL(); // Intentar generar el thumbnail
                } catch (e) {
                    setThumbnailError(true);
                }
            }
        };

        if (thumbnailError) {
            return (
                <Box 
                    onClick={onClick}
                    sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'grey.100',
                        cursor: 'pointer'
                    }}
                >
                    <VideoLibraryIcon sx={{ fontSize: 60, color: 'primary.main', mb: 1 }} />
                    <Typography variant="caption" sx={{ px: 2, textAlign: 'center' }}>
                        Click para reproducir
                    </Typography>
                </Box>
            );
        }

        return (
            <>
                <video
                    ref={videoRef}
                    src={recurso.fileUrl}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                    }}
                    onLoadedData={handleLoadedData}
                    onClick={onClick}
                    muted
                    playsInline
                />
                <Box 
                    sx={{ 
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '50%',
                        p: 1,
                        cursor: 'pointer'
                    }}
                    onClick={onClick}
                >
                    <PlayArrowIcon sx={{ fontSize: 40 }} />
                </Box>
            </>
        );
    };

    const handleDialogClose = () => {
        if (!isLoading) {
            setOpenDialog(false);
            setFormData({
                titulo: '',
                descripcion: '',
                categoria: [],
                dificultad: 0,
                file: null
            });
            setMultipleFiles([]);
            setCurrentFileIndex(0);
            setPlayingVideo(null); // Detener reproducción al cerrar el diálogo
        }
    };

    return (
        <Box 
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${resourcesBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.1,
                    zIndex: -1,
                }
            }}
        >
            {/* Overlay de drag and drop */}
            {isDragging && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 87, 34, 0.1)',
                        backdropFilter: 'blur(4px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                        border: '3px dashed #FF5722',
                        margin: 2,
                        borderRadius: 4,
                    }}
                >
                    <UploadFileIcon sx={{ fontSize: 60, color: '#FF5722', mb: 2 }} />
                    <Typography variant="h5" sx={{ color: '#FF5722', fontWeight: 'bold' }}>
                        Suelta el archivo aquí
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#FF5722', mt: 1 }}>
                        para añadirlo a tus recursos
                    </Typography>
                </Box>
            )}

            {/* Encabezado */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Biblioteca de Recursos
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Gestiona y comparte videos de ejercicios con tus clientes
                </Typography>
                
                <Alert 
                    severity="info" 
                    sx={{ 
                        mt: 2, 
                        backgroundColor: 'rgba(232, 244, 253, 0.8)', 
                        '& .MuiAlert-icon': {
                            color: '#1976d2'
                        }
                    }}
                >
                    ¡Luces, Cámara, Fitness! 🎬 Estamos produciendo nuestros propios videos de alta calidad para la comunidad. ¡Muy pronto disponibles!
                </Alert>
            </Box>

            {/* Barra de acciones */}
            <Paper 
                elevation={2} 
                sx={{ 
                    p: 3, 
                    mb: 4, 
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Buscar recursos..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            startIcon={<FilterListIcon />}
                            onClick={() => setShowFilters(!showFilters)}
                            sx={{ 
                                borderRadius: '12px',
                                textTransform: 'none',
                                px: 3,
                                py: 1,
                                fontSize: '0.95rem',
                                backgroundColor: showFilters ? 'rgba(255, 87, 34, 0.04)' : 'white',
                                color: '#FF5722',
                                border: '1px solid rgba(255, 87, 34, 0.5)',
                                boxShadow: '0 2px 8px rgba(255, 87, 34, 0.15)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                    border: '1px solid #FF5722',
                                    boxShadow: '0 4px 12px rgba(255, 87, 34, 0.25)',
                                    transform: 'translateY(-1px)'
                                },
                                '&:active': {
                                    transform: 'translateY(0)',
                                },
                                transition: 'all 0.3s ease'
                            }}
                        >
                            Filtrar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setOpenDialog(true)}
                            sx={{
                                borderRadius: '12px',
                                textTransform: 'none',
                                px: 3,
                                py: 1,
                                fontSize: '0.95rem',
                                background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                    boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                    transform: 'translateY(-1px)'
                                },
                                '&:active': {
                                    transform: 'translateY(0)',
                                },
                                transition: 'all 0.3s ease'
                            }}
                        >
                            Nuevo Recurso
                        </Button>
                    </Grid>

                    {/* Panel de filtros */}
                    {showFilters && (
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth variant="outlined" sx={{ maxWidth: 300 }}>
                                <InputLabel>Filtrar por Categoría</InputLabel>
                                <Select
                                    value={selectedCategoria}
                                    onChange={(e) => setSelectedCategoria(e.target.value)}
                                    label="Filtrar por Categoría"
                                >
                                    <MenuItem value="">Todas las categorías</MenuItem>
                                    {categorias.map((categoria) => (
                                        <MenuItem key={categoria} value={categoria}>
                                            {categoria}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                                Se mostrarán recursos que contengan la categoría seleccionada
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>

            {/* Grid de recursos (usar filteredRecursos en lugar de recursos) */}
            {loadingRecursos ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : filteredRecursos.length > 0 ? (
                <Grid container spacing={3}>
                    {filteredRecursos.map((recurso) => (
                        <Grid item xs={12} sm={6} md={4} key={recurso.id}>
                            <Card sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                    boxShadow: 6,
                                },
                                '&:hover .card-actions': {
                                    opacity: 1
                                }
                            }}>
                                {/* Botones de acción */}
                                <Box 
                                    className="card-actions"
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        display: 'flex',
                                        gap: 1,
                                        opacity: 0,
                                        transition: 'opacity 0.2s ease',
                                        zIndex: 2,
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setEditingResource(recurso);
                                            setOpenEditDialog(true);
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                                color: 'primary.main'
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDeleteRecurso(recurso)}
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                                color: 'error.main'
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>

                                {/* Contenido según el tipo de archivo */}
                                <Box sx={{ position: 'relative', paddingTop: '56.25%', bgcolor: 'grey.100' }}>
                                    {recurso.fileType === 'video' && (
                                        playingVideo === recurso.id ? (
                                            <video
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                                src={recurso.fileUrl}
                                                controls
                                                autoPlay
                                                onEnded={() => setPlayingVideo(null)}
                                            />
                                        ) : (
                                            <VideoThumbnail 
                                                recurso={recurso} 
                                                onClick={() => setPlayingVideo(recurso.id)} 
                                            />
                                        )
                                    )}
                                    {recurso.fileType === 'imagen' && (
                                        <img
                                            src={recurso.fileUrl}
                                            alt={recurso.titulo}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    )}
                                    {recurso.fileType === 'audio' && (
                                        <Box sx={{ 
                                            position: 'absolute', 
                                            top: 0, 
                                            left: 0, 
                                            width: '100%', 
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 2
                                        }}>
                                            <AudiotrackIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                                            <audio controls src={recurso.fileUrl} style={{ width: '80%' }} />
                                        </Box>
                                    )}
                                    {recurso.fileType === 'pdf' && (
                                        <Box sx={{ 
                                            position: 'absolute', 
                                            top: 0, 
                                            left: 0, 
                                            width: '100%', 
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1
                                        }}>
                                            <PictureAsPdfIcon sx={{ fontSize: 60, color: 'error.main' }} />
                                            <Button 
                                                variant="contained" 
                                                href={recurso.fileUrl} 
                                                target="_blank"
                                                sx={{ 
                                                    mt: 1,
                                                    borderRadius: '12px',
                                                    textTransform: 'none',
                                                    px: 3,
                                                    py: 1,
                                                    fontSize: '0.95rem',
                                                    background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                                    boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                                                    '&:hover': {
                                                        background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                                        boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                                        transform: 'translateY(-1px)'
                                                    },
                                                    '&:active': {
                                                        transform: 'translateY(0)',
                                                    },
                                                    transition: 'all 0.3s ease'
                                                }}
                                            >
                                                Ver PDF
                                            </Button>
                                        </Box>
                                    )}
                                </Box>

                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {recurso.titulo}
                                    </Typography>
                                    {/* Mostrar múltiples categorías en las tarjetas */}
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
                                        {Array.isArray(recurso.categoria) 
                                            ? recurso.categoria.map(cat => (
                                                <Chip
                                                    key={cat}
                                                    icon={<FitnessCenterIcon />}
                                                    label={cat}
                                                    size="small"
                                                />
                                            ))
                                            : <Chip
                                                icon={<FitnessCenterIcon />}
                                                label={recurso.categoria}
                                                size="small"
                                            />
                                        }
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {recurso.descripcion}
                                    </Typography>
                                    <Typography variant="caption" display="block" sx={{ mt: 2 }}>
                                        Por {recurso.autor} • {recurso.fecha?.toDate?.() ? 
                                            new Date(recurso.fecha.toDate()).toLocaleDateString() : 
                                            'Fecha no disponible'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box sx={{ 
                    mt: 4, 
                    p: 3, 
                    textAlign: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: 2
                }}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        No se encontraron recursos
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {searchTerm || selectedCategoria ? 
                            'Prueba con otros criterios de búsqueda' : 
                            'Comienza subiendo tu primer recurso'}
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenDialog(true)}
                        sx={{
                            mt: 2,
                            backgroundColor: '#FF5722',
                            '&:hover': {
                                backgroundColor: '#F4511E'
                            }
                        }}
                    >
                        Subir Recurso
                    </Button>
                </Box>
            )}

            {/* Modal para nuevo recurso */}
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {multipleFiles.length > 1 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">
                                Subir Recurso
                            </Typography>
                            <Chip 
                                label={`${currentFileIndex + 1} de ${multipleFiles.length}`}
                                color="primary"
                                sx={{ 
                                    backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                    color: '#FF5722',
                                    fontWeight: 'bold'
                                }}
                            />
                        </Box>
                    ) : (
                        'Subir Recurso'
                    )}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {multipleFiles.length > 1 && (
                            <Alert severity="info" sx={{ mb: 2 }}>
                                Archivo actual: {formData.file?.name}
                            </Alert>
                        )}

                        {/* Preview del archivo */}
                        {formData.file && (
                            <Paper 
                                elevation={2}
                                sx={{ 
                                    p: 2,
                                    borderRadius: 2,
                                    bgcolor: 'grey.50',
                                    mb: 2
                                }}
                            >
                                <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary' }}>
                                    Vista previa:
                                </Typography>
                                <Box sx={{ 
                                    position: 'relative',
                                    width: '100%',
                                    height: '200px',
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                    bgcolor: 'grey.100'
                                }}>
                                    {getFileType(formData.file) === 'video' && (
                                        <video
                                            src={previewUrl}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                            controls
                                        />
                                    )}
                                    {getFileType(formData.file) === 'imagen' && (
                                        <img
                                            src={previewUrl}
                                            alt="Vista previa"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    )}
                                    {getFileType(formData.file) === 'audio' && (
                                        <Box sx={{ 
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 2
                                        }}>
                                            <AudiotrackIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                                            <audio 
                                                src={previewUrl}
                                                controls
                                                style={{ width: '80%' }}
                                            />
                                        </Box>
                                    )}
                                    {getFileType(formData.file) === 'pdf' && (
                                        <Box sx={{ 
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 2
                                        }}>
                                            <PictureAsPdfIcon sx={{ fontSize: 60, color: 'error.main' }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {formData.file.name}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ 
                                    mt: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <Typography variant="caption" color="text.secondary">
                                        {formData.file.name}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {(formData.file.size / (1024 * 1024)).toFixed(2)} MB
                                    </Typography>
                                </Box>
                            </Paper>
                        )}

                        <Box>
                            <TextField
                                fullWidth
                                label="Título del ejercicio"
                                variant="outlined"
                                value={formData.titulo}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    titulo: e.target.value
                                }))}
                            />
                            <Typography 
                                variant="caption" 
                                color="warning.main"
                                sx={{ 
                                    display: 'block', 
                                    mt: 1,
                                    px: 1,
                                    py: 0.5,
                                    bgcolor: 'warning.light',
                                    borderRadius: 1,
                                    color: 'black'
                                }}
                            >
                                ⚠️ El título debe ser descriptivo y específico (ej: "Press Banca con Mancuernas", 
                                "Sentadilla con Barra"). Evita títulos genéricos o poco descriptivos.
                            </Typography>
                        </Box>

                        <TextField
                            fullWidth
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={formData.descripcion}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                descripcion: e.target.value
                            }))}
                        />

                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Categoría</InputLabel>
                            <Select
                                multiple
                                value={formData.categoria}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    categoria: e.target.value
                                }))}
                                label="Categoría"
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {categorias.map((cat) => (
                                    <MenuItem key={cat} value={cat}>
                                        {cat}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography variant="caption" color="text.secondary" sx={{ mt: 1, ml: 1 }}>
                                Puedes seleccionar múltiples categorías (ej: "Brazo" y "Hombros")
                            </Typography>
                        </FormControl>

                        <Box>
                            <Typography variant="subtitle2" gutterBottom>
                                Dificultad (opcional)
                            </Typography>
                            <Box sx={{ 
                                display: 'flex', 
                                gap: 2,
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                borderRadius: 1,
                                p: 2
                            }}>
                                <Box sx={{ display: 'flex', gap: 0.5 }}>
                                    {[...Array(4)].map((_, index) => (
                                        <StarIcon 
                                            key={index}
                                            sx={{ 
                                                cursor: 'pointer',
                                                color: formData.dificultad >= index ? '#FFD700' : 'grey.400',
                                                transition: 'all 0.2s ease',
                                                '&:hover': {
                                                    transform: 'scale(1.2)'
                                                }
                                            }}
                                            onClick={() => setFormData(prev => ({
                                                ...prev,
                                                dificultad: index
                                            }))}
                                        />
                                    ))}
                                </Box>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{ ml: 1 }}
                                >
                                    {dificultades[formData.dificultad].label}
                                </Typography>
                            </Box>
                        </Box>

                        <Button
                            variant="outlined"
                            component="label"
                            fullWidth
                            startIcon={<UploadFileIcon />}
                            sx={{
                                borderRadius: '12px',
                                textTransform: 'none',
                                px: 3,
                                py: 1.5,
                                fontSize: '0.95rem',
                                backgroundColor: 'white',
                                color: '#FF5722',
                                border: '1px solid rgba(255, 87, 34, 0.5)',
                                boxShadow: '0 2px 8px rgba(255, 87, 34, 0.15)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                    border: '1px solid #FF5722',
                                    boxShadow: '0 4px 12px rgba(255, 87, 34, 0.25)',
                                    transform: 'translateY(-1px)'
                                },
                                '&:active': {
                                    transform: 'translateY(0)',
                                },
                                transition: 'all 0.3s ease'
                            }}
                        >
                            {formData.file ? 'Archivo seleccionado' : 'Subir Archivo'}
                            <input
                                type="file"
                                hidden
                                accept="video/*,image/*,audio/*,.pdf"
                                onChange={handleFileUpload}
                            />
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3, gap: 2 }}>
                    <Button 
                        onClick={() => setOpenDialog(false)}
                        disabled={isLoading}
                        sx={{ 
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            fontSize: '0.95rem',
                            backgroundColor: 'white',
                            color: '#FF5722',
                            border: '1px solid rgba(255, 87, 34, 0.5)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.15)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                border: '1px solid #FF5722',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.25)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            },
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                borderColor: 'rgba(0, 0, 0, 0.12)',
                                boxShadow: 'none'
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            fontSize: '0.95rem',
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            },
                            '&.Mui-disabled': {
                                background: 'rgba(0, 0, 0, 0.12)',
                                boxShadow: 'none'
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        {isLoading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <CircularProgress size={20} color="inherit" />
                                Subiendo...
                            </Box>
                        ) : (
                            'Subir'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal para editar recurso */}
            <Dialog open={openEditDialog} onClose={() => {
                setOpenEditDialog(false);
                setEditingResource(null);
            }} maxWidth="sm" fullWidth>
                <DialogTitle>Editar Recurso</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            label="Título del ejercicio"
                            variant="outlined"
                            value={editingResource?.titulo || ''}
                            onChange={(e) => setEditingResource(prev => ({
                                ...prev,
                                titulo: e.target.value
                            }))}
                        />

                        <TextField
                            fullWidth
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={editingResource?.descripcion || ''}
                            onChange={(e) => setEditingResource(prev => ({
                                ...prev,
                                descripcion: e.target.value
                            }))}
                        />

                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Categoría</InputLabel>
                            <Select
                                multiple
                                value={Array.isArray(editingResource?.categoria) 
                                    ? editingResource.categoria 
                                    : editingResource?.categoria ? [editingResource.categoria] : []}
                                onChange={(e) => setEditingResource(prev => ({
                                    ...prev,
                                    categoria: e.target.value
                                }))}
                                label="Categoría"
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {categorias.map((cat) => (
                                    <MenuItem key={cat} value={cat}>
                                        {cat}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography variant="caption" color="text.secondary" sx={{ mt: 1, ml: 1 }}>
                                Puedes seleccionar múltiples categorías (ej: "Brazo" y "Hombros")
                            </Typography>
                        </FormControl>

                        <Box>
                            <Typography variant="subtitle2" gutterBottom>
                                Dificultad
                            </Typography>
                            <Box sx={{ 
                                display: 'flex', 
                                gap: 2,
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                borderRadius: 1,
                                p: 2
                            }}>
                                <Box sx={{ display: 'flex', gap: 0.5 }}>
                                    {[...Array(4)].map((_, index) => (
                                        <StarIcon 
                                            key={index}
                                            sx={{ 
                                                cursor: 'pointer',
                                                color: (editingResource?.dificultad || 0) >= index ? '#FFD700' : 'grey.400',
                                                transition: 'all 0.2s ease',
                                                '&:hover': {
                                                    transform: 'scale(1.2)'
                                                }
                                            }}
                                            onClick={() => setEditingResource(prev => ({
                                                ...prev,
                                                dificultad: index
                                            }))}
                                        />
                                    ))}
                                </Box>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{ ml: 1 }}
                                >
                                    {dificultades[(editingResource?.dificultad || 0)].label}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3, gap: 2 }}>
                    <Button 
                        onClick={() => {
                            setOpenEditDialog(false);
                            setEditingResource(null);
                        }}
                        disabled={isLoading}
                        sx={{ 
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            fontSize: '0.95rem',
                            backgroundColor: 'white',
                            color: '#FF5722',
                            border: '1px solid rgba(255, 87, 34, 0.5)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.15)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                border: '1px solid #FF5722',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.25)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            },
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                borderColor: 'rgba(0, 0, 0, 0.12)',
                                boxShadow: 'none'
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleEditResource(editingResource)}
                        disabled={isLoading}
                        sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            fontSize: '0.95rem',
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            },
                            '&.Mui-disabled': {
                                background: 'rgba(0, 0, 0, 0.12)',
                                boxShadow: 'none'
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        {isLoading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <CircularProgress size={20} color="inherit" />
                                Actualizando...
                            </Box>
                        ) : (
                            'Guardar Cambios'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Recursos;