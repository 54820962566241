import React from 'react';
import {
    Box,
    Typography,
    IconButton,
    Breadcrumbs,
    Link
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ContentTool from './ContentTool';

const ContentToolPage = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ py: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton 
                    onClick={() => navigate('/tools')}
                    sx={{ mr: 2 }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link 
                            color="inherit" 
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/tools');
                            }}
                        >
                            Herramientas
                        </Link>
                        <Typography color="text.primary">Biblioteca de Contenidos</Typography>
                    </Breadcrumbs>
                    <Typography variant="h4" component="h1">
                        Biblioteca de Contenidos
                    </Typography>
                </Box>
            </Box>

            <ContentTool />
        </Box>
    );
};

export default ContentToolPage; 