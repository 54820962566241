import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, Box, Divider, Button, Typography, IconButton, Avatar } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import logo from '../../assets/images/logo.png';

// Importamos los iconos
import { FiHome, FiCalendar, FiUsers, FiMessageSquare, FiSettings, FiLogOut } from 'react-icons/fi';
import { Lightning, ShieldStar, Sparkle } from "@phosphor-icons/react";
import { Cpu, CaretLeft, CaretRight, UsersThree, Wrench } from "@phosphor-icons/react";
import logoIcono from '../../assets/images/imagen_redimensionada.png';
import Modal from '@mui/material/Modal';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Tooltip from '@mui/material/Tooltip';

const Navbar = () => {
    const expandedDrawerWidth = 250;
    const collapsedDrawerWidth = 80;
    const [isExpanded, setIsExpanded] = useState(true);
    const [openDiscordModal, setOpenDiscordModal] = useState(false);
    const drawerWidth = isExpanded ? expandedDrawerWidth : collapsedDrawerWidth;
    const { logout, userDetails } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const navItems = [
        { text: 'Inicio', icon: <FiHome size={22} />, path: '/' },
        { text: 'Generar', icon: <Cpu size={22} weight="duotone" />, path: '/generar' },
        { text: 'Asistente', icon: <Lightning size={22} weight="duotone" />, path: '/chatbot' },
        { text: 'Herramientas', icon: <Sparkle size={22} weight="duotone" />, path: '/tools' },
        { text: 'Clases', icon: <FiCalendar size={22} />, path: '/classes' },
        { text: 'Clientes', icon: <FiUsers size={22} />, path: '/clients' },
        { text: 'Recursos', icon: <VideoLibraryIcon fontSize="small" />, path: '/recursos' },
        { text: 'Mensajes', icon: <FiMessageSquare size={22} />, path: '/messages' },
        { text: 'Configuración', icon: <FiSettings size={22} />, path: '/settings' },
        // Agregar el ítem de Admin solo si el usuario es administrador
        ...(userDetails?.admin ? [{ text: 'Admin', icon: <ShieldStar size={22} weight="duotone" />, path: '/admin' }] : [])
    ];

    const DiscordModal = () => (
        <Modal
            open={openDiscordModal}
            onClose={() => setOpenDiscordModal(false)}
            aria-labelledby="discord-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="discord-modal-title" variant="h6" component="h2" gutterBottom>
                    Únete a la comunidad de FitAI Labs
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    ¿Quieres formar parte de nuestra comunidad de entrenadores? ¡Únete a nuestro Discord!
                </Typography>
                <Button
                    variant="contained"
                    fullWidth
                    href="https://discord.gg/tu-link"
                    target="_blank"
                    sx={{
                        backgroundColor: '#5865F2',
                        '&:hover': {
                            backgroundColor: '#4752C4',
                        },
                    }}
                >
                    Unirse a Discord
                </Button>
            </Box>
        </Modal>
    );

    return (
        <>
            {/* Espacio reservado para el drawer */}
            <Box sx={{ 
                width: `calc(${drawerWidth}px + 12px)`, 
                flexShrink: 0,
                transition: 'width 0.3s ease',
                height: '100vh'
            }} />
            
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    position: 'fixed',
                    height: '100vh',
                    zIndex: 1200, // Aseguramos que esté por encima de otros elementos
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        background: 'rgba(255, 255, 255, 0.97)',
                        color: '#333',
                        transition: 'all 0.3s ease',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        borderRight: 'none',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                        borderRadius: '0 24px 24px 0',
                        margin: '12px 0 12px 12px',
                        height: 'calc(100vh - 24px)',
                        position: 'fixed',
                        display: 'flex',
                        flexDirection: 'column',
                        top: 0,
                        left: 0,
                    },
                }}
            >
                <Box sx={{ 
                    p: 2, 
                    textAlign: 'center', 
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexShrink: 0
                }}>
                    <img 
                        src={isExpanded ? logo : logoIcono} 
                        alt="FitAI Labs Logo" 
                        style={{ 
                            maxWidth: isExpanded ? '60%' : '40px', 
                            height: 'auto',
                            objectFit: 'contain',
                        }} 
                    />
                    <IconButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        size="small"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'rgba(255, 87, 34, 0.08)',
                            width: '24px',
                            height: '24px',
                            borderRadius: '8px',
                            '&:hover': { 
                                backgroundColor: 'rgba(255, 87, 34, 0.15)',
                            },
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        {isExpanded ? <CaretLeft weight="bold" size={14} color="#FF5722" /> : <CaretRight weight="bold" size={14} color="#FF5722" />}
                    </IconButton>
                </Box>
                
                <Divider sx={{ opacity: 0.6, mx: 2, mb: 1, flexShrink: 0 }} />
                
                <Box sx={{ px: isExpanded ? 2 : 1.5, mb: 0.5, flexShrink: 0 }}>
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            color: 'text.secondary', 
                            opacity: 0.8,
                            fontWeight: 500,
                            textTransform: 'uppercase',
                            ml: isExpanded ? 1.5 : 0,
                            display: isExpanded ? 'block' : 'none',
                            fontSize: '0.65rem',
                            letterSpacing: 0.5
                        }}
                    >
                        Menú Principal
                    </Typography>
                </Box>
                
                <Box sx={{ 
                    flexGrow: 1, 
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    '&::-webkit-scrollbar': {
                        width: '3px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#ddd',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#ccc',
                    },
                }}>
                    <List sx={{ 
                        px: isExpanded ? 2 : 1.5,
                        py: 0.5
                    }}>
                        {navItems.map((item) => (
                            <ListItem key={item.text} disablePadding sx={{ mb: 0.5 }}>
                                {isExpanded ? (
                                    <Button
                                        component={RouterLink}
                                        to={item.path}
                                        startIcon={React.cloneElement(item.icon, { 
                                            style: { 
                                                color: location.pathname === item.path ? '#FF5722' : '#666',
                                                transition: 'color 0.3s ease'
                                            } 
                                        })}
                                        fullWidth
                                        sx={{
                                            justifyContent: 'flex-start',
                                            color: location.pathname === item.path ? '#FF5722' : '#666',
                                            backgroundColor: location.pathname === item.path ? 'rgba(255, 87, 34, 0.08)' : 'transparent',
                                            borderRadius: '10px',
                                            transition: 'all 0.3s ease',
                                            py: 0.9,
                                            px: 1.5,
                                            transform: 'scale(1)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                                color: '#FF5722',
                                                transform: 'translateX(5px)',
                                            },
                                            fontWeight: location.pathname === item.path ? 600 : 400,
                                            textTransform: 'none',
                                        }}
                                    >
                                        <Typography 
                                            variant="body2" 
                                            sx={{ 
                                                ml: 1, 
                                                fontWeight: 'inherit',
                                                opacity: location.pathname === item.path ? 1 : 0.85,
                                                fontSize: '0.85rem'
                                            }}
                                        >
                                            {item.text}
                                        </Typography>
                                    </Button>
                                ) : (
                                    <Tooltip title={item.text} placement="right">
                                        <Button
                                            component={RouterLink}
                                            to={item.path}
                                            fullWidth
                                            sx={{
                                                minWidth: 'auto',
                                                justifyContent: 'center',
                                                color: location.pathname === item.path ? '#FF5722' : '#666',
                                                backgroundColor: location.pathname === item.path ? 'rgba(255, 87, 34, 0.08)' : 'transparent',
                                                borderRadius: '10px',
                                                transition: 'all 0.3s ease',
                                                py: 0.9,
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                                    color: '#FF5722',
                                                    transform: 'scale(1.1)',
                                                },
                                            }}
                                        >
                                            {React.cloneElement(item.icon, { 
                                                style: { 
                                                    color: location.pathname === item.path ? '#FF5722' : '#666',
                                                    transition: 'color 0.3s ease'
                                                } 
                                            })}
                                        </Button>
                                    </Tooltip>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Box>

                <Box sx={{ flexShrink: 0 }}>
                    <Divider sx={{ opacity: 0.6, mx: 2, my: 2 }} />
                    
                    <Box sx={{ p: 1.5 }}>
                        {userDetails && (
                            <Box 
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    mb: 1.5,
                                    px: 1,
                                    justifyContent: isExpanded ? 'flex-start' : 'center'
                                }}
                            >
                                <Avatar 
                                    src={userDetails.photo_url || ''} 
                                    alt={userDetails.name || 'Usuario'} 
                                    sx={{ 
                                        width: 32, 
                                        height: 32,
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                                    }}
                                />
                                {isExpanded && (
                                    <Box sx={{ ml: 1.5, overflow: 'hidden', maxWidth: '160px' }}>
                                        <Typography 
                                            variant="body2" 
                                            noWrap 
                                            sx={{ 
                                                fontWeight: 500, 
                                                lineHeight: 1.2,
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {userDetails.name || 'Usuario'}
                                        </Typography>
                                        <Typography 
                                            variant="caption" 
                                            noWrap 
                                            sx={{ 
                                                color: 'text.secondary',
                                                lineHeight: 1.2,
                                                fontSize: '0.7rem'
                                            }}
                                        >
                                            {userDetails.email || ''}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                        
                        <Button
                            fullWidth
                            onClick={handleLogout}
                            sx={{
                                justifyContent: isExpanded ? 'flex-start' : 'center',
                                py: 1,
                                px: isExpanded ? 1.5 : 0,
                                color: '#666',
                                borderRadius: '12px',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 59, 48, 0.05)',
                                    color: '#FF3B30',
                                    transform: isExpanded ? 'translateX(5px)' : 'scale(1.1)',
                                },
                                transition: 'all 0.3s ease',
                            }}
                        >
                            <FiLogOut size={20} />
                            {isExpanded && (
                                <Typography variant="body2" sx={{ ml: 1, fontSize: '0.8rem' }}>Cerrar Sesión</Typography>
                            )}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            <DiscordModal />
        </>
    );
};

export default Navbar;


