import { getFirestore, collection, getDocs, updateDoc } from 'firebase/firestore';

const initializeClientBonos = async (userDetails) => {
    const db = getFirestore();
    let updatedCount = 0;
    let errorCount = 0;

    try {
        let clientsCollectionRef;
        
        if (userDetails.tipo_profesional === 'entrenador_independiente') {
            clientsCollectionRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
        } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
            if (!userDetails.IDBO) {
                throw new Error('ID del Centro (IDBO) no disponible');
            }
            clientsCollectionRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros');
        } else {
            throw new Error('Tipo de profesional no reconocido');
        }

        const clientsSnapshot = await getDocs(clientsCollectionRef);
        
        for (const clientDoc of clientsSnapshot.docs) {
            try {
                const clientData = clientDoc.data();
                
                // Si el campo bonos no existe o es undefined
                if (clientData.bonos === undefined) {
                    await updateDoc(clientDoc.ref, {
                        bonos: 0
                    });
                    updatedCount++;
                    console.log(`Bonos inicializados para el cliente ${clientData.nombre} ${clientData.apellidos}`);
                }
            } catch (error) {
                console.error(`Error al actualizar cliente ${clientDoc.id}:`, error);
                errorCount++;
            }
        }

        return {
            success: true,
            updatedClients: updatedCount,
            failedUpdates: errorCount,
            message: `Se han inicializado los bonos de ${updatedCount} clientes. ${errorCount > 0 ? `Fallaron ${errorCount} actualizaciones.` : ''}`
        };
    } catch (error) {
        console.error('Error al inicializar bonos:', error);
        return {
            success: false,
            error: error.message,
            updatedClients: updatedCount,
            failedUpdates: errorCount
        };
    }
};

export default initializeClientBonos; 