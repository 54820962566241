// src/components/common/RestrictedAccess.js
import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Paper, Divider, Link } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../contexts/AuthContext'; // Asegúrate de importar tu contexto de autenticación
import { useNavigate } from 'react-router-dom';

const RestrictedAccess = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openPlanSelection, setOpenPlanSelection] = useState(false);
  const { currentUser, userDetails, logout } = useAuth(); // Asegúrate de obtener userDetails y logout
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const showDialog = (message) => {
    setDialogMessage(message);
    setOpenDialog(true);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      showDialog('Error al cerrar sesión');
    }
  };

  const handlePlanSelection = async (planType) => {
    setIsLoading(true);
    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      
      let priceId;
      switch(planType) {
        case 'starter':
          priceId = 'price_1R3g6z06MQnyY4JV0TALIEMx';  // Reemplaza con el ID real del plan Starter mensual
          break;
        case 'grow-monthly':
          priceId = 'price_1Q4mKu06MQnyY4JVfgEnQT2o';  // Plan Grow mensual
          break;
        case 'grow-annual':
          priceId = 'price_1Qk5i606MQnyY4JVB0XAt8cu';  // Plan Grow anual
          break;
        default:
          priceId = 'price_1R3g6z06MQnyY4JV0TALIEMx';  // Por defecto, plan Starter
      }

      const result = await createCheckoutSession({
        priceId,
        returnUrl: window.location.origin,
        customerEmail: currentUser.email,
        customerName: userDetails.name || currentUser.displayName || '',
        trial_period_days: 15
      });

      if (result.data && result.data.url) {
        window.location.href = result.data.url;
      } else {
        showDialog('Error: No se recibió una URL de checkout válida.');
      }
    } catch (error) {
      console.error('Error al crear la sesión de checkout:', error);
      showDialog(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
      setOpenPlanSelection(false);
    }
  };

  const handleDashboardSubscription = () => {
    setOpenPlanSelection(true);
  };

  const PlanSelectionDialog = ({ open, onClose, onSelectPlan, isLoading }) => {
    return (
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="lg" 
        fullWidth
        sx={{
          zIndex: 10000,
          '& .MuiDialog-paper': {
            borderRadius: '16px',
            padding: '16px',
          }
        }}
      >
        <DialogTitle>
          <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 600, color: '#1a1a1a' }}>
            Selecciona tu Plan
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {/* Plan Starter */}
            <Grid item xs={12} md={4}>
              <Paper sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                borderRadius: '16px',
                transition: 'all 0.3s ease',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
                }
              }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>Plan Starter</Typography>
                <Typography variant="h4" gutterBottom sx={{ color: '#FF5722', fontWeight: 700 }}>
                  25€<Typography variant="caption" sx={{ fontSize: '1rem', ml: 1 }}>/mes</Typography>
                </Typography>
                <Box sx={{ flex: 1, my: 2 }}>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    ✓ Hasta 5 clientes
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    ✓ Prueba gratuita de 15 días
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    ✓ Cancela cuando quieras
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  fullWidth 
                  onClick={() => onSelectPlan('starter')}
                  disabled={isLoading}
                  sx={{
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    fontSize: '1rem',
                    background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                    boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                      boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Comenzar Plan Starter
                </Button>
              </Paper>
            </Grid>

            {/* Plan Grow Mensual */}
            <Grid item xs={12} md={4}>
              <Paper sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                borderRadius: '16px',
                transition: 'all 0.3s ease',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
                }
              }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>Plan Grow Mensual</Typography>
                <Typography variant="h4" gutterBottom sx={{ color: '#FF5722', fontWeight: 700 }}>
                  75€<Typography variant="caption" sx={{ fontSize: '1rem', ml: 1 }}>/mes</Typography>
                </Typography>
                <Box sx={{ flex: 1, my: 2 }}>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    ✓ Clientes ilimitados
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    ✓ Generación de rutinas y dietas
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    ✓ Prueba gratuita de 15 días
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  fullWidth 
                  onClick={() => onSelectPlan('grow-monthly')}
                  disabled={isLoading}
                  sx={{
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    fontSize: '1rem',
                    background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                    boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                      boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Comenzar Plan Grow Mensual
                </Button>
              </Paper>
            </Grid>

            {/* Plan Grow Anual */}
            <Grid item xs={12} md={4}>
              <Paper sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                borderRadius: '16px',
                border: '2px solid #FF5722',
                transition: 'all 0.3s ease',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.15)',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 8px 24px rgba(255, 87, 34, 0.25)',
                },
                '&::before': {
                  content: '"MEJOR VALOR"',
                  position: 'absolute',
                  top: -12,
                  right: 24,
                  backgroundColor: '#FF5722',
                  color: 'white',
                  padding: '4px 16px',
                  borderRadius: '20px',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                }
              }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>Plan Grow Anual</Typography>
                <Typography variant="h4" gutterBottom sx={{ color: '#FF5722', fontWeight: 700 }}>
                  675€<Typography variant="caption" sx={{ fontSize: '1rem', ml: 1 }}>/año</Typography>
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#FF5722', fontWeight: 500, mb: 2 }}>
                  (56,25€/mes)
                </Typography>
                <Box sx={{ flex: 1, my: 2 }}>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    ✓ Todo lo incluido en el plan mensual
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    ✓ 3 meses gratis (25% descuento)
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    ✓ Prueba gratuita de 15 días
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  fullWidth 
                  onClick={() => onSelectPlan('grow-annual')}
                  disabled={isLoading}
                  sx={{
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    fontSize: '1rem',
                    backgroundColor: '#FF5722',
                    position: 'relative',
                    overflow: 'hidden',
                    boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent)',
                      transform: 'translateX(-100%)',
                      transition: 'transform 0.6s ease',
                    },
                    '&:hover': {
                      backgroundColor: '#F4511E',
                      transform: 'translateY(-3px)',
                      boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                      '&::before': {
                        transform: 'translateX(100%)',
                      },
                    },
                    '&:active': {
                      transform: 'translateY(-1px)',
                    },
                  }}
                >
                  Comenzar Plan Grow Anual
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(135deg, rgba(255,255,255,0.95) 0%, rgba(255,243,240,0.95) 100%)',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: 5,
          borderRadius: '24px',
          textAlign: 'center',
          maxWidth: 500,
          boxShadow: '0 8px 32px rgba(255, 87, 34, 0.1)',
          border: '1px solid rgba(255, 87, 34, 0.1)',
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              fontWeight: 700,
              background: 'linear-gradient(45deg, #FF5722, #FF1493)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Acceso Premium Requerido
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              color: '#666',
              mb: 3
            }}
          >
            Desbloquea todo el potencial de Fitai Labs
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ 
              p: 2, 
              borderRadius: 2, 
              bgcolor: 'rgba(255, 87, 34, 0.05)',
              height: '100%'
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#FF5722', fontWeight: 600 }}>
                ¿Qué obtendrás?
              </Typography>
              <Typography variant="body2" paragraph>
                • Dashboard completo y personalizable
              </Typography>
              <Typography variant="body2" paragraph>
                • Gestión avanzada de clientes
              </Typography>
              <Typography variant="body2">
                • Herramientas de análisis y seguimiento
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ 
              p: 2, 
              borderRadius: 2, 
              bgcolor: 'rgba(255, 87, 34, 0.05)',
              height: '100%'
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#FF5722', fontWeight: 600 }}>
                Beneficios Premium
              </Typography>
              <Typography variant="body2" paragraph>
                • 15 días de prueba gratuita
              </Typography>
              <Typography variant="body2" paragraph>
                • Soporte prioritario
              </Typography>
              <Typography variant="body2">
                • Actualizaciones exclusivas
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            onClick={handleDashboardSubscription}
            disabled={isLoading}
            sx={{
              py: 1.5,
              px: 4,
              borderRadius: '12px',
              textTransform: 'none',
              fontSize: '1.1rem',
              fontWeight: 600,
              background: 'linear-gradient(45deg, #FF5722, #FF1493)',
              boxShadow: '0 4px 15px rgba(255, 87, 34, 0.25)',
              position: 'relative',
              overflow: 'hidden',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent)',
                transform: 'translateX(-100%)',
                transition: 'transform 0.6s ease',
              },
              '&:hover': {
                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 20px rgba(255, 87, 34, 0.35)',
                '&::before': {
                  transform: 'translateX(100%)',
                },
              },
              '&:active': {
                transform: 'translateY(-1px)',
              },
            }}
          >
            Desbloquear Acceso Premium
          </Button>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            ¿Necesitas más información?
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
            <Link 
              href="mailto:alvaro@fitailabs.com" 
              underline="none"
              sx={{ 
                color: '#FF5722',
                fontWeight: 500,
                '&:hover': { color: '#F4511E' }
              }}
            >
              Contactar con soporte
            </Link>
            <Typography color="text.secondary">•</Typography>
            <Button
              variant="text"
              color="error"
              onClick={handleLogout}
              sx={{ 
                textTransform: 'none',
                '&:hover': { backgroundColor: 'rgba(244, 67, 54, 0.08)' }
              }}
            >
              Cerrar Sesión
            </Button>
          </Box>
        </Box>
      </Box>

      <PlanSelectionDialog 
        open={openPlanSelection}
        onClose={() => setOpenPlanSelection(false)}
        onSelectPlan={handlePlanSelection}
        isLoading={isLoading}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Aviso</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RestrictedAccess;
