import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Chip,
    Divider,
    LinearProgress,
    Grid,
    IconButton
} from '@mui/material';
import {
    CheckCircle as CheckCircleIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
    AccessTime as AccessTimeIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, where, orderBy, limit, onSnapshot, Timestamp } from 'firebase/firestore';

const DailyProgress = ({ clientId }) => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(null);
    const [error, setError] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const fetchProgress = () => {
            const db = getFirestore();
            
            // Obtener el inicio y fin del día seleccionado
            const startOfDay = new Date(selectedDate);
            startOfDay.setHours(0, 0, 0, 0);
            const endOfDay = new Date(selectedDate);
            endOfDay.setHours(23, 59, 59, 999);

            console.log('Filtros de búsqueda:', {
                clientId,
                startOfDay: startOfDay.toISOString(),
                endOfDay: endOfDay.toISOString()
            });

            // Crear la consulta
            const goalsRef = collection(db, 'daily_goals');
            const q = query(
                goalsRef,
                where('clientId', '==', clientId),
                where('createdAt', '>=', Timestamp.fromDate(startOfDay)),
                where('createdAt', '<=', Timestamp.fromDate(endOfDay)),
                orderBy('lastUpdated', 'desc'),
                limit(1)
            );

            // Establecer el listener en tiempo real
            const unsubscribe = onSnapshot(q, (snapshot) => {
                if (!snapshot.empty) {
                    const doc = snapshot.docs[0];
                    const data = doc.data();
                    
                    console.log('Documento encontrado:', {
                        id: doc.id,
                        createdAt: data.createdAt?.toDate()?.toISOString(),
                        lastUpdated: data.lastUpdated?.toDate()?.toISOString(),
                        status: data.status,
                        isCompleted: data.isCompleted,
                        goals: data.goals?.length,
                        completedGoals: data.completedGoals?.length,
                        completedAt: data.completedAt?.toDate()?.toISOString(),
                        rawData: data
                    });
                    
                    // Calcular el porcentaje de completado y los objetivos completados
                    const totalGoals = data.goals?.length || 0;
                    let completedGoalsArray = [];
                    
                    // Comprobar objetivos completados de ambas formas posibles
                    if (data.completedGoals) {
                        // Si existe el array completedGoals, usarlo
                        completedGoalsArray = data.completedGoals;
                    } else {
                        // Si no existe, revisar el campo completed de cada objetivo
                        completedGoalsArray = data.goals
                            .map((goal, index) => goal.completed ? index : null)
                            .filter(index => index !== null);
                    }

                    const completedGoalsCount = completedGoalsArray.length;
                    const completionPercentage = totalGoals > 0 ? (completedGoalsCount / totalGoals) * 100 : 0;

                    console.log('Cálculos:', {
                        totalGoals,
                        completedGoalsArray,
                        completedGoalsCount,
                        completionPercentage,
                        isAllCompleted: totalGoals > 0 && completedGoalsCount === totalGoals
                    });

                    // Calcular el tiempo transcurrido si está completado
                    let completionTime = null;
                    if (data.completedAt && data.createdAt) {
                        const start = data.createdAt.toDate();
                        const end = data.completedAt.toDate();
                        const diffInMinutes = Math.floor((end - start) / (1000 * 60));
                        completionTime = diffInMinutes;
                    }

                    setProgress({
                        goals: data.goals || [],
                        completedGoals: completedGoalsArray,
                        status: data.status,
                        completionPercentage,
                        completionTime,
                        lastUpdated: data.lastUpdated?.toDate(),
                        isCompleted: data.status === 'completed' || (totalGoals > 0 && completedGoalsCount === totalGoals)
                    });
                } else {
                    console.log('No se encontraron documentos para la fecha:', selectedDate.toISOString());
                    setProgress(null);
                }
                setLoading(false);
            }, (error) => {
                console.error('Error en la consulta:', error);
                setError('Error al cargar el progreso diario');
                setLoading(false);
            });

            return unsubscribe;
        };

        if (clientId) {
            const unsubscribe = fetchProgress();
            return () => unsubscribe();
        }
    }, [clientId, selectedDate]);

    const handlePrevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
    };

    const renderCalendar = () => {
        const days = [];
        const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const lastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const firstDayOfWeek = firstDay.getDay();

        // Añadir días vacíos al principio
        for (let i = 0; i < firstDayOfWeek; i++) {
            days.push(<Box key={`empty-${i}`} sx={{ p: 1 }} />);
        }

        // Añadir los días del mes
        for (let day = 1; day <= lastDay.getDate(); day++) {
            const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
            const isSelected = date.toDateString() === selectedDate.toDateString();
            const isToday = date.toDateString() === new Date().toDateString();

            days.push(
                <Box
                    key={day}
                    onClick={() => handleDateClick(date)}
                    sx={{
                        p: 1,
                        cursor: 'pointer',
                        textAlign: 'center',
                        borderRadius: 1,
                        bgcolor: isSelected ? 'primary.main' : isToday ? 'action.hover' : 'transparent',
                        color: isSelected ? 'white' : 'inherit',
                        '&:hover': {
                            bgcolor: isSelected ? 'primary.dark' : 'action.hover'
                        }
                    }}
                >
                    <Typography variant="body2">
                        {day}
                    </Typography>
                </Box>
            );
        }

        return days;
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography color="error" sx={{ p: 2 }}>
                {error}
            </Typography>
        );
    }

    return (
        <Grid container spacing={3}>
            {/* Panel izquierdo: Progreso del día */}
            <Grid item xs={12} md={8}>
                <Paper elevation={1} sx={{ p: 3, height: '100%' }}>
                    <Typography variant="h6" gutterBottom>
                        Progreso del {selectedDate.toLocaleDateString('es-ES', { 
                            weekday: 'long', 
                            year: 'numeric', 
                            month: 'long', 
                            day: 'numeric' 
                        })}
                    </Typography>
                    
                    {progress ? (
                        <>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <LinearProgress 
                                            variant="determinate" 
                                            value={progress.completionPercentage}
                                            sx={{ height: 10, borderRadius: 5 }}
                                        />
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {Math.round(progress.completionPercentage)}%
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                                    <Chip
                                        label={progress.status}
                                        color={progress.isCompleted ? 'success' : 'default'}
                                        size="small"
                                    />
                                    {progress.completionTime && (
                                        <Chip
                                            icon={<AccessTimeIcon />}
                                            label={`Completado en ${progress.completionTime} minutos`}
                                            size="small"
                                            color="primary"
                                        />
                                    )}
                                </Box>
                            </Box>

                            <List>
                                {progress.goals.map((goal, index) => {
                                    const isCompleted = progress.completedGoals.includes(index) || goal.completed;
                                    return (
                                        <ListItem key={index} sx={{ py: 1 }}>
                                            <ListItemIcon>
                                                {isCompleted ? 
                                                    <CheckCircleIcon color="success" /> : 
                                                    <RadioButtonUncheckedIcon color="disabled" />
                                                }
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={goal.description}
                                                sx={{
                                                    '& .MuiListItemText-primary': {
                                                        color: isCompleted ? 'text.secondary' : 'text.primary',
                                                        textDecoration: isCompleted ? 'line-through' : 'none'
                                                    }
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </>
                    ) : (
                        <Typography variant="body2" color="text.secondary">
                            No hay objetivos registrados para este día.
                        </Typography>
                    )}
                </Paper>
            </Grid>

            {/* Panel derecho: Calendario */}
            <Grid item xs={12} md={4}>
                <Paper elevation={1} sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <IconButton onClick={handlePrevMonth} size="small">
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography variant="subtitle1">
                            {currentMonth.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}
                        </Typography>
                        <IconButton onClick={handleNextMonth} size="small">
                            <ChevronRightIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0.5, mb: 1 }}>
                        {['D', 'L', 'M', 'X', 'J', 'V', 'S'].map((day) => (
                            <Box key={day} sx={{ p: 1, textAlign: 'center' }}>
                                <Typography variant="caption" color="text.secondary">
                                    {day}
                                </Typography>
                            </Box>
                        ))}
                    </Box>

                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0.5 }}>
                        {renderCalendar()}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default DailyProgress; 