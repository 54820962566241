import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import RestrictedAccess from './RestrictedAccess';
import { Box, CircularProgress } from '@mui/material';

const PrivateRoute = ({ children }) => {
    const { currentUser, dashboardAccess, isLoadingAccess } = useContext(AuthContext);
    const location = useLocation();
    
    // Permitir acceso a la página de confirmación de suscripción sin redirecciones
    if (location.pathname === '/subscription-confirmation') {
        return children;
    }

    // Para otras rutas, verificar autenticación
    if (!currentUser) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    // Mostrar loading mientras se verifica el acceso
    if (isLoadingAccess) {
        return (
            <>
                {children}
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                    }}
                >
                    <CircularProgress />
                </Box>
            </>
        );
    }

    if (!dashboardAccess && !location.pathname.includes('/subscription-confirmation')) {
        return (
            <>
                {children}
                <RestrictedAccess />
            </>
        );
    }

    return children;
};

export default PrivateRoute;
