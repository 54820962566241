export const INSPIRATIONAL_QUOTES = [
    {
        quote: "Que la comida sea tu alimento, y el alimento tu medicina.",
        author: "Hipócrates, médico de la antigua Grecia"
    },
    {
        quote: "Caminar es el mejor ejercicio posible. Habitúate a caminar muy lejos.",
        author: "Thomas Jefferson, tercer presidente de los Estados Unidos"
    },
    {
        quote: "Cuida tu cuerpo. Es el único lugar en el que tienes que vivir.",
        author: "Jim Rohn, empresario y orador motivacional"
    },
    {
        quote: "La primera riqueza es la salud.",
        author: "Ralph Waldo Emerson, escritor y filósofo estadounidense"
    },
    {
        quote: "Aquellos que piensan que no tienen tiempo para una alimentación saludable, tarde o temprano encontrarán tiempo para la enfermedad.",
        author: "Edward Stanley, político británico"
    },
    {
        quote: "Para asegurarte una buena salud: come lo necesario, respira profundamente, vive con moderación, cultiva la alegría e interésate por la vida.",
        author: "Buda, líder espiritual"
    },
    {
        quote: "El ejercicio es clave para la salud física y de la mente.",
        author: "Nelson Mandela, líder sudafricano"
    },
    {
        quote: "Saber comer es saber vivir.",
        author: "Confucio, filósofo chino"
    },
    {
        quote: "Sólo la alegría es garantía de salud y longevidad.",
        author: "Santiago Ramón y Cajal, médico y científico español"
    },
    {
        quote: "La salud es la riqueza real y no piezas de oro y plata.",
        author: "Mahatma Gandhi, líder pacifista indio"
    }
]; 