import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Switch,
    FormControlLabel
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { 
    X as CloseIcon, 
    Copy as ContentCopyIcon, 
    DownloadSimple as DownloadIcon,
    FloppyDisk as SaveIcon,
    PaperPlaneTilt as SendIcon,
    Table as TableIcon
} from '@phosphor-icons/react';

const ProGenerationModal = ({
    open,
    onClose,
    isGenerating,
    progress,
    generationType,
    generatedContent,
    currentQuote,
    onGeneratePDF,
    onImplant,
    onEdit,
    isEditing,
    editPrompt,
    setEditPrompt,
    editableContent,
    setEditableContent,
    isGeneralContent,
    onSaveTemplate,
    preferTables,
    setPreferTables
}) => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Dialog
            open={open && (generatedContent !== null || isGenerating)}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                {isGenerating ? (
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '300px',
                        textAlign: 'center',
                        gap: 3
                    }}>
                        <Typography variant="h6" gutterBottom>
                            Generando contenido personalizado con FitAI-Gen-1 Pro...
                        </Typography>
                        <Box sx={{ width: '100%', maxWidth: 400, position: 'relative' }}>
                            <LinearProgress 
                                variant="determinate" 
                                value={progress}
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    backgroundColor: '#f0f0f0',
                                    '& .MuiLinearProgress-bar': {
                                        borderRadius: 5,
                                        background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                        backgroundSize: '200% 200%',
                                        animation: 'gradient 2s ease infinite',
                                    },
                                    '@keyframes gradient': {
                                        '0%': {
                                            backgroundPosition: '0% 50%'
                                        },
                                        '50%': {
                                            backgroundPosition: '100% 50%'
                                        },
                                        '100%': {
                                            backgroundPosition: '0% 50%'
                                        },
                                    }
                                }}
                            />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                            {progress < 95 ? 'Esto puede tardar unos segundos...' : 'Casi listo...'}
                        </Typography>

                        {/* Sección de la cita */}
                        {currentQuote && (
                            <Box sx={{ 
                                mt: 4, 
                                maxWidth: '80%',
                                p: 3,
                                borderRadius: 2,
                                backgroundColor: 'rgba(255, 87, 34, 0.05)',
                                border: '1px solid rgba(255, 87, 34, 0.1)'
                            }}>
                                <Typography 
                                    variant="body1" 
                                    sx={{ 
                                        fontStyle: 'italic',
                                        mb: 1,
                                        color: 'text.primary',
                                        fontSize: '1.1rem',
                                        lineHeight: 1.6
                                    }}
                                >
                                    "{currentQuote.quote}"
                                </Typography>
                                <Typography 
                                    variant="subtitle2" 
                                    sx={{ 
                                        color: 'text.secondary',
                                        mt: 1,
                                        textAlign: 'right',
                                        fontWeight: 500
                                    }}
                                >
                                    — {currentQuote.author}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        
                        <Typography variant="h6" gutterBottom>
                            Vista previa del contenido (FitAI-Gen-1 Pro)
                        </Typography>

                        {/* Campo de texto editable con botón de copiar */}
                        <Box sx={{ position: 'relative', mb: 3 }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={20}
                                value={editableContent}
                                onChange={(e) => setEditableContent(e.target.value)}
                                variant="outlined"
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        fontFamily: 'monospace',
                                        fontSize: '0.9rem',
                                        lineHeight: '1.5',
                                        backgroundColor: '#f8f9fa',
                                        '&:hover': {
                                            backgroundColor: '#fff',
                                        },
                                        '& fieldset': {
                                            borderColor: 'rgba(0, 0, 0, 0.12)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#FF5722',
                                        }
                                    }
                                }}
                            />
                            <Tooltip title="Copiar al portapapeles">
                                <IconButton 
                                    onClick={() => {
                                        navigator.clipboard.writeText(editableContent);
                                        enqueueSnackbar('Contenido copiado al portapapeles', { 
                                            variant: 'success',
                                            autoHideDuration: 2000
                                        });
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        bgcolor: 'background.paper',
                                        boxShadow: 1,
                                        '&:hover': {
                                            bgcolor: 'action.hover'
                                        }
                                    }}
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        {/* Sección de edición por prompt */}
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Editar mediante prompt
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <TextField
                                    fullWidth
                                    value={editPrompt}
                                    onChange={(e) => setEditPrompt(e.target.value)}
                                    placeholder="Escribe tus instrucciones para editar el contenido..."
                                    disabled={isEditing}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                            },
                                            '&.Mui-focused': {
                                                backgroundColor: 'white',
                                                boxShadow: '0 0 0 2px rgba(255, 87, 34, 0.2)',
                                            },
                                            '& fieldset': {
                                                borderColor: 'rgba(0, 0, 0, 0.1)',
                                                transition: 'all 0.3s ease',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(255, 87, 34, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#FF5722',
                                            }
                                        },
                                        '& .MuiInputBase-input': {
                                            fontSize: '0.95rem',
                                            padding: '12px 16px',
                                        }
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={onEdit}
                                    disabled={isEditing}
                                    sx={{
                                        minWidth: '120px',
                                        borderRadius: '12px',
                                        textTransform: 'none',
                                        px: 3,
                                        fontSize: '0.95rem',
                                        background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                        boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                                        '&:hover': {
                                            background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                            boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                            transform: 'translateY(-1px)'
                                        },
                                        '&:active': {
                                            transform: 'translateY(0)',
                                        },
                                        '&.Mui-disabled': {
                                            background: 'rgba(0, 0, 0, 0.12)',
                                        },
                                        transition: 'all 0.3s ease'
                                    }}
                                >
                                    {isEditing ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <CircularProgress size={20} color="inherit" />
                                            Editando...
                                        </Box>
                                    ) : (
                                        'Editar'
                                    )}
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Una vez tengas el resultado que te guste, puedes:
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={preferTables}
                                            onChange={(e) => setPreferTables(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <TableIcon size={20} />
                                            <Typography variant="body2">Incluir tablas en el PDF</Typography>
                                        </Box>
                                    }
                                />
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        onClick={onGeneratePDF}
                                        disabled={isGenerating}
                                        startIcon={<DownloadIcon />}
                                        sx={{
                                            borderRadius: '12px',
                                            textTransform: 'none',
                                            px: 3,
                                            py: 1,
                                            fontSize: '0.95rem',
                                            backgroundColor: 'white',
                                            color: '#FF5722',
                                            border: '1px solid rgba(255, 87, 34, 0.5)',
                                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.15)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                                border: '1px solid #FF5722',
                                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.25)',
                                            },
                                            transition: 'all 0.3s ease'
                                        }}
                                    >
                                        {isGenerating ? 'Generando PDF...' : 'Generar PDF'}
                                    </Button>
                                    {isGeneralContent ? (
                                        <Button
                                            variant="contained"
                                            onClick={onSaveTemplate}
                                            disabled={isGenerating}
                                            startIcon={<SaveIcon />}
                                            sx={{
                                                borderRadius: '12px',
                                                textTransform: 'none',
                                                px: 3,
                                                py: 1,
                                                fontSize: '0.95rem',
                                                background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                                boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                                                '&:hover': {
                                                    background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                                    boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                                    transform: 'translateY(-1px)'
                                                },
                                                '&:active': {
                                                    transform: 'translateY(0)',
                                                },
                                                transition: 'all 0.3s ease'
                                            }}
                                        >
                                            {isGenerating ? 'Guardando...' : 'Guardar como plantilla'}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={onImplant}
                                            disabled={isGenerating}
                                            startIcon={<SendIcon />}
                                            sx={{
                                                borderRadius: '12px',
                                                textTransform: 'none',
                                                px: 3,
                                                py: 1,
                                                fontSize: '0.95rem',
                                                background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                                                boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                                                '&:hover': {
                                                    background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                                    boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                                    transform: 'translateY(-1px)'
                                                },
                                                '&:active': {
                                                    transform: 'translateY(0)',
                                                },
                                                transition: 'all 0.3s ease'
                                            }}
                                        >
                                            {isGenerating ? 'Procesando...' : 'Implantar en App'}
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ProGenerationModal; 