import React, { useState, useContext, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    FormControl, 
    Select, 
    MenuItem, 
    InputLabel,
    Paper,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Grid,
    Avatar,
    ListItemAvatar,
    ListItemText,
    Chip,
    Tooltip,
    LinearProgress,
    DialogTitle,
    Switch,
    FormControlLabel,
    ToggleButton,
    ToggleButtonGroup,
    Slider,
    Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Users as GroupIcon } from "@phosphor-icons/react";
import { AuthContext } from '../../contexts/AuthContext';
import { collection, onSnapshot, doc, getDoc, runTransaction, increment, addDoc, serverTimestamp, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
import { useSnackbar } from 'notistack';
import ReactMarkdown from 'react-markdown';
import { Page, Text, Document, StyleSheet, PDFDownloadLink, View, Image } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { 
    Barbell, 
    ForkKnife, 
    Timer,
    Bandaids,
    Microphone,
    MicrophoneSlash
} from "@phosphor-icons/react";
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsIcon from '@mui/icons-material/Settings';
import PlantillaConfigModal from '../modals/PlantillaConfigModal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../config/firebaseConfig';
import { getStorage, ref, getBytes, uploadBytes, getDownloadURL } from 'firebase/storage';
import SendIcon from '@mui/icons-material/Send';
import Lottie from 'lottie-react';
import successAnimation from '../../assets/animations/Success.json';
import { FiCpu } from 'react-icons/fi';
import IA from '../pages/IA';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Link from '@mui/material/Link';
import downloadBlockedImage from '../../assets/images/download-blocked.png';
import SaveIcon from '@mui/icons-material/Save';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { INSPIRATIONAL_QUOTES } from '../../constants/quotes';
import ProGenerationModal from '../modals/ProGenerationModal';
import StandardGenerationModal from '../modals/StandardGenerationModal';

// Estilos para el PDF actualizados
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Helvetica',
        backgroundColor: '#f8f8f8'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
        borderBottom: '1 solid #ddd',
        paddingBottom: 20
    },
    logo: {
        width: 80,
        height: 80,
        marginRight: 15,
        objectFit: 'contain'
    },
    businessInfo: {
        flex: 1
    },
    businessName: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5
    },
    businessDetails: {
        fontSize: 10,
        color: '#666',
        marginBottom: 3
    },
    title: {
        fontSize: 24,
        marginBottom: 20,
        fontWeight: 'bold',
        color: '#333',
        textAlign: 'center'
    },
    section: {
        marginBottom: 10
    },
    heading1: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 15,
        color: '#FF5722'
    },
    heading2: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
        marginTop: 12,
        color: '#FF5722'
    },
    heading3: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 6,
        marginTop: 10,
        color: '#333'
    },
    paragraph: {
        fontSize: 12,
        lineHeight: 1.5,
        marginBottom: 8,
        color: '#555'
    },
    listItem: {
        fontSize: 12,
        lineHeight: 1.5,
        marginBottom: 4,
        paddingLeft: 15,
        color: '#555'
    }
});

// Función para procesar el markdown
const processMarkdown = async (markdown) => {
    try {
        await unified()
            .use(remarkParse)
            .use(remarkStringify)
            .process(markdown);

        // Dividir el contenido en secciones
        const sections = markdown.split('\n\n').map(section => {
            if (section.startsWith('# ')) {
                return { type: 'h1', content: section.replace('# ', '') };
            } else if (section.startsWith('## ')) {
                return { type: 'h2', content: section.replace('## ', '') };
            } else if (section.startsWith('### ')) {
                return { type: 'h3', content: section.replace('### ', '') };
            } else if (section.startsWith('- ')) {
                return { 
                    type: 'list', 
                    items: section.split('\n').map(item => item.replace('- ', ''))
                };
            } else {
                return { type: 'paragraph', content: section };
            }
        });

        return sections;
    } catch (error) {
        console.error('Error procesando markdown:', error);
        return [{ type: 'paragraph', content: markdown }];
    }
};

// Componente para el PDF actualizado
const PDFDocument = ({ markdown, clientData, userDetails, generationType }) => {
    const [sections, setSections] = useState([]);
    
    useEffect(() => {
        processMarkdown(markdown).then(setSections);
    }, [markdown]);

    // Determinar la URL del logo según el tipo de profesional
    const businessName = userDetails?.tipo_profesional === 'entrenador_independiente'
        ? userDetails?.display_name
        : userDetails?.tipo_profesional === 'entrenador_centro'
            ? userDetails?.centro?.nombreCentro
            : userDetails?.tipo_profesional === 'propietario_centro'
                ? userDetails?.centro?.nombreCentro
                : userDetails?.display_name;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Encabezado mejorado */}
                <View style={styles.header}>
                    <View style={styles.businessInfo}>
                        <Text style={styles.businessName}>
                            {businessName || 'Nombre del Negocio'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            {userDetails?.especialidad || 'Especialidad no especificada'}
                        </Text>
                        {userDetails?.tipo_profesional !== 'entrenador_independiente' && (
                            <Text style={styles.businessDetails}>
                                {userDetails?.centro?.email || 'Email no especificado'}
                            </Text>
                        )}
                        <Text style={styles.businessDetails}>
                            Profesional: {userDetails?.display_name || 'Nombre no especificado'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            Cliente: {clientData?.nombre || 'Cliente no especificado'}
                        </Text>
                        <Text style={styles.businessDetails}>
                            Fecha: {new Date().toLocaleDateString('es-ES')}
                        </Text>
                    </View>
                </View>

                {/* Contenido */}
                {sections.map((section, index) => (
                    <View key={index} style={styles.section}>
                        {section.type === 'heading1' && (
                            <Text style={styles.heading1}>{section.content}</Text>
                        )}
                        {section.type === 'heading2' && (
                            <Text style={styles.heading2}>{section.content}</Text>
                        )}
                        {section.type === 'heading3' && (
                            <Text style={styles.heading3}>{section.content}</Text>
                        )}
                        {section.type === 'paragraph' && (
                            <Text style={styles.paragraph}>{section.content}</Text>
                        )}
                        {section.type === 'listItem' && (
                            <Text style={styles.listItem}>• {section.content}</Text>
                        )}
                    </View>
                ))}
            </Page>
        </Document>
    );
};

const getBase64FromStorageUrl = async (url) => {
    try {
        // Extraer el path del storage de la URL
        const storage = getStorage(app);
        const fullPath = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
        const storageRef = ref(storage, fullPath);
        
        // Obtener los bytes de la imagen
        const bytes = await getBytes(storageRef);
        
        // Convertir a base64
        let binary = '';
        const bytes_array = new Uint8Array(bytes);
        for (let i = 0; i < bytes_array.byteLength; i++) {
            binary += String.fromCharCode(bytes_array[i]);
        }
        const base64 = btoa(binary);
        
        // Determinar el tipo de imagen basado en la extensión o usar un tipo por defecto
        const fileExtension = fullPath.split('.').pop()?.toLowerCase() || 'png';
        const mimeType = fileExtension === 'jpg' || fileExtension === 'jpeg' 
            ? 'image/jpeg' 
            : 'image/png';
        
        return `data:${mimeType};base64,${base64}`;
    } catch (error) {
        console.error('Error convirtiendo imagen a base64:', error);
        return null;
    }
};

const ModernSwitch = styled(Switch)(({ theme }) => ({
    width: 56,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 4,
        '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                borderColor: 'transparent',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 24,
        height: 24,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        borderRadius: 32,
        backgroundColor: theme.palette.grey[300],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const SwitchOption = ({ icon: Icon, label, description, checked, onChange }) => (
    <Box
        sx={{
            p: 2,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            transition: 'all 0.2s',
            '&:hover': {
                bgcolor: 'action.hover',
                transform: 'translateY(-2px)',
            },
        }}
    >
        <FormControlLabel
            control={
                <ModernSwitch 
                    checked={checked}
                    onChange={onChange}
                />
            }
            label={
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 3.5,
                    color: checked ? 'primary.main' : 'text.primary',
                    transition: 'color 0.2s',
                    ml: 2
                }}>
                    <Icon size={24} weight={checked ? "fill" : "duotone"} />
                    <Box>
                        <Typography 
                            variant="body1"
                            sx={{ 
                                fontWeight: checked ? 500 : 400,
                                transition: 'font-weight 0.2s'
                            }}
                        >
                            {label}
                        </Typography>
                        {description && (
                            <Typography variant="body2" sx={{ 
                                color: 'text.secondary',
                                fontSize: '0.875rem'
                            }}>
                                {description}
                            </Typography>
                        )}
                    </Box>
                </Box>
            }
            sx={{
                m: 0,
                width: '100%',
                '& .MuiFormControlLabel-label': {
                    flex: 1
                }
            }}
        />
    </Box>
);

// Slider personalizado con gradiente
const GradientSlider = styled(Slider)(({ theme }) => ({
    height: 8,
    borderRadius: 4,
    '& .MuiSlider-track': {
        background: 'linear-gradient(45deg, #FF5722, #FF1493)',
        border: 'none',
    },
    '& .MuiSlider-rail': {
        background: '#e0e0e0',
        opacity: 0.5,
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: '0 3px 8px rgba(255, 87, 34, 0.4)',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
            transform: 'scale(0.8)',
        },
    },
    '& .MuiSlider-valueLabel': {
        background: 'linear-gradient(45deg, #FF5722, #FF1493)',
        borderRadius: '8px',
        padding: '4px 8px',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 2,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: '#fff',
        },
    },
}));

// Componente para mostrar la intensidad con iconos y etiquetas
const IntensitySlider = ({ value, onChange, disabled }) => {
    const intensityLabels = [
        { value: 1, label: 'Muy baja', icon: '🐢' },
        { value: 2, label: 'Baja', icon: '🚶' },
        { value: 3, label: 'Media', icon: '🏃' },
        { value: 4, label: 'Alta', icon: '🏋️' },
        { value: 5, label: 'Muy alta', icon: '🔥' },
    ];

    return (
        <Box sx={{ width: '100%', my: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
                Intensidad del entrenamiento
            </Typography>
            <Box sx={{ px: 2, pt: 1 }}>
                <GradientSlider
                    value={value}
                    onChange={onChange}
                    step={1}
                    marks
                    min={1}
                    max={5}
                    disabled={disabled}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => intensityLabels.find(item => item.value === value)?.label}
                />
                <Stack 
                    direction="row" 
                    justifyContent="space-between" 
                    sx={{ mt: 1, px: 0.5 }}
                >
                    {intensityLabels.map((item) => (
                        <Box 
                            key={item.value} 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center',
                                opacity: value === item.value ? 1 : 0.6,
                                transform: value === item.value ? 'scale(1.1)' : 'scale(1)',
                                transition: 'all 0.2s ease',
                            }}
                        >
                            <Typography variant="h5" sx={{ mb: 0.5 }}>
                                {item.icon}
                            </Typography>
                            <Typography 
                                variant="caption" 
                                sx={{ 
                                    fontWeight: value === item.value ? 'bold' : 'normal',
                                    color: value === item.value ? '#FF5722' : 'text.secondary',
                                }}
                            >
                                {item.label}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

const Generar = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [instructions, setInstructions] = useState('');
    const [loading, setLoading] = useState(true);
    const [generationType, setGenerationType] = useState('');
    const [routineDays, setRoutineDays] = useState(3);
    const [dietType] = useState('semanal'); // Eliminamos el setter ya que será siempre semanal
    const [selectedObjectives, setSelectedObjectives] = useState([]);
    const [routineLocation, setRoutineLocation] = useState('gimnasio');
    const [customLocation, setCustomLocation] = useState('');
    const [trainingIntensity, setTrainingIntensity] = useState(3); // Valor por defecto: intensidad media (rango 1-5)
    const [trainingType, setTrainingType] = useState('clasico'); // Nuevo estado para tipo de entrenamiento
    const [routineFocus, setRoutineFocus] = useState('fullbody'); // Estado para el foco de la rutina
    const [customTrainingType, setCustomTrainingType] = useState(''); // Estado para tipo de entrenamiento personalizado
    const [customRoutineFocus, setCustomRoutineFocus] = useState(''); // Estado para foco de rutina personalizado
    const [useCircuits, setUseCircuits] = useState(false); // Estado para el uso de circuitos
    const [selectedMaterials, setSelectedMaterials] = useState([]); // Estado para los materiales seleccionados
    const [customMaterial, setCustomMaterial] = useState(''); // Estado para material personalizado
    const [preferTables, setPreferTables] = useState(true); // Estado para preferencia de tablas en el PDF
    const [calories, setCalories] = useState('');
    const [avoidFoods, setAvoidFoods] = useState([]);
    const [customFood, setCustomFood] = useState('');
    const { userDetails } = useContext(AuthContext);
    const [isGenerating, setIsGenerating] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [generatedContent, setGeneratedContent] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [openUnavailableModal, setOpenUnavailableModal] = useState(false);
    const [unavailableFeature, setUnavailableFeature] = useState('');
    const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
    const [selectedClientData, setSelectedClientData] = useState(null);
    const [editableContent, setEditableContent] = useState('');
    const [editPrompt, setEditPrompt] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const [plantillaConfig, setPlantillaConfig] = useState(null);
    const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
    const [openIASettings, setOpenIASettings] = useState(false);
    const [selectedModel, setSelectedModel] = useState('standard');
    const [openHelpModal, setOpenHelpModal] = useState(false);
    const [isGeneralContent, setIsGeneralContent] = useState(false);
    const [openSaveTemplateModal, setOpenSaveTemplateModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [openTemplatesModal, setOpenTemplatesModal] = useState(false);
    const [templates, setTemplates] = useState({ rutinas: [], dietas: [] });
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [openClientSelectModal, setOpenClientSelectModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    // Añadir este estado después de los otros estados
    const [currentQuote, setCurrentQuote] = useState(null);

    const commonFoodsToAvoid = [
        { value: 'lacteos', label: 'Lácteos', icon: '🥛' },
        { value: 'gluten', label: 'Gluten', icon: '🌾' },
        { value: 'frutos_secos', label: 'Frutos secos', icon: '🥜' },
        { value: 'mariscos', label: 'Mariscos', icon: '🦐' },
        { value: 'huevos', label: 'Huevos', icon: '🥚' },
        { value: 'soja', label: 'Soja', icon: '🫘' },
        { value: 'cerdo', label: 'Cerdo', icon: '🐷' },
        { value: 'pescado', label: 'Pescado', icon: '🐟' }
    ];

    // Opciones de materiales para las rutinas
    const materialOptions = [
        { value: 'mancuernas', label: 'Mancuernas', icon: '🏋️‍♂️' },
        { value: 'barras', label: 'Barras', icon: '🏋️' },
        { value: 'maquinas', label: 'Máquinas', icon: '🏋️‍♀️' },
        { value: 'bandas_elasticas', label: 'Bandas elásticas', icon: '⭕' },
        { value: 'kettlebells', label: 'Kettlebells', icon: '🔔' },
        { value: 'trx', label: 'TRX', icon: '🧵' },
        { value: 'foam_roller', label: 'Foam roller', icon: '🧱' },
        { value: 'step', label: 'Step', icon: '🪜' },
        { value: 'bosu', label: 'Bosu', icon: '🥏' },
        { value: 'fitball', label: 'Fitball', icon: '⚽' }
    ];

    // Efecto para manejar la barra de progreso
    useEffect(() => {
        let timer;
        if (isGenerating && progress < 95) {
            timer = setTimeout(() => {
                // Fórmula para hacer que el progreso sea cada vez más lento
                const increment = Math.max(0.5, (100 - progress) / 15);
                setProgress(prev => Math.min(95, prev + increment));
            }, 200);
        }
        return () => {
            clearTimeout(timer);
            if (!isGenerating) {
                setProgress(0);
            }
        };
    }, [isGenerating, progress]);

    // Añadir este useEffect después de los otros useEffect
    useEffect(() => {
        if (isGenerating) {
            const randomQuote = INSPIRATIONAL_QUOTES[Math.floor(Math.random() * INSPIRATIONAL_QUOTES.length)];
            setCurrentQuote(randomQuote);
        } else {
            setCurrentQuote(null);
        }
    }, [isGenerating]);

    const generationTypes = [
        { 
            value: 'rutina', 
            label: 'Rutina de Entrenamiento', 
            endpoint: 'crear-rutina-beta-v4',
            icon: <Barbell size={24} weight="duotone" />,
            color: '#FF5722'
        },
        { 
            value: 'dieta', 
            label: 'Plan de Alimentación', 
            endpoint: 'crear-dieta-beta-v3',
            icon: <ForkKnife size={24} weight="duotone" />,
            color: '#4CAF50'
        }
    ];

    const objectives = [
        { value: 'perder_peso', label: 'Perder peso', icon: '🎯' },
        { value: 'aumentar_masa', label: 'Aumentar masa muscular', icon: '💪' },
        { value: 'mantenimiento', label: 'Mantenimiento', icon: '⚖️' },
        { value: 'definicion', label: 'Definición muscular', icon: '🏋️' },
        { value: 'fuerza', label: 'Ganar fuerza', icon: '🏋️‍♂️' },
        { value: 'resistencia', label: 'Mejorar resistencia', icon: '🏃' },
        { value: 'flexibilidad', label: 'Aumentar flexibilidad', icon: '🧘‍♂️' },
        { value: 'salud', label: 'Mejorar salud general', icon: '❤️' }
    ];

    const trainingTypes = [
        { value: 'clasico', label: 'Clásico', icon: '🏋️' },
        { value: 'funcional', label: 'Funcional', icon: '⚡' },
        { value: 'crossfit', label: 'Crossfit', icon: '🔄' },
        { value: 'hiit', label: 'HIIT', icon: '🔥' },
        { value: 'otro', label: 'Otro', icon: '✏️' }
    ];

    const routineFocusOptions = [
        { value: 'fullbody', label: 'Full Body', icon: '👤' },
        { value: 'superior', label: 'Superior', icon: '💪' },
        { value: 'inferior', label: 'Inferior', icon: '🦵' },
        { value: 'otro', label: 'Otro', icon: '✏️' }
    ];

    useEffect(() => {
        let unsubscribe;
        
        const fetchClients = async () => {
            if (!userDetails) {
                setLoading(false);
                return;
            }

            try {
                let clientsCollectionRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientsCollectionRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientsCollectionRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros');
                }

                unsubscribe = onSnapshot(clientsCollectionRef, (snapshot) => {
                    const clientsData = snapshot.docs
                        .map(doc => ({
                            id: doc.id,
                            ...doc.data()
                        }))
                        .filter(client => !client.isInSolicitudes);

                    setClients(clientsData);
                    setLoading(false);
                });

            } catch (error) {
                console.error("Error al cargar los clientes:", error);
                setLoading(false);
            }
        };

        fetchClients();
        return () => unsubscribe && unsubscribe();
    }, [userDetails]);

    // Inicializar el reconocimiento de voz
    useEffect(() => {
        const initializeSpeechRecognition = () => {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            
            if (SpeechRecognition) {
                const recognitionInstance = new SpeechRecognition();
                recognitionInstance.continuous = false;
                recognitionInstance.interimResults = false;
                recognitionInstance.lang = 'es-ES';

                recognitionInstance.onstart = () => {
                    setIsRecording(true);
                };

                recognitionInstance.onend = () => {
                    setIsRecording(false);
                };

                recognitionInstance.onerror = (event) => {
                    console.error('Error en reconocimiento de voz:', event.error);
                    setIsRecording(false);
                    enqueueSnackbar('Error en el reconocimiento de voz. Por favor, intenta de nuevo.', { 
                        variant: 'error' 
                    });
                };

                recognitionInstance.onresult = (event) => {
                    const transcript = event.results[0][0].transcript;
                    setInstructions(prev => prev + (prev ? ' ' : '') + transcript);
                };

                setRecognition(recognitionInstance);
            }
        };

        initializeSpeechRecognition();

        return () => {
            if (recognition) {
                recognition.onresult = null;
                recognition.onend = null;
                recognition.onerror = null;
                recognition.onstart = null;
            }
        };
    }, []);

    useEffect(() => {
        if (selectedClient && clients) {
            const clientData = clients.find(client => client.id === selectedClient);
            setSelectedClientData(clientData);
        }
    }, [selectedClient, clients]);

    const toggleRecording = () => {
        if (!recognition) {
            enqueueSnackbar('Tu navegador no soporta reconocimiento de voz. Por favor, utiliza Chrome o Edge.', { 
                variant: 'error',
                autoHideDuration: 5000
            });
            return;
        }

        if (isRecording) {
            recognition.stop();
        } else {
            try {
                recognition.start();
            } catch (error) {
                console.error('Error al iniciar reconocimiento:', error);
                setIsRecording(false);
                enqueueSnackbar('Error al iniciar el reconocimiento de voz. Por favor, intenta de nuevo.', { 
                    variant: 'error' 
                });
            }
        }
    };

    const updateInstructionsText = (currentInstructions, selectedObjs, daysCount, dietType, location, customLoc, cals, avoided, intensity, trainType, customTrainType, clientData, routineFocusType, customRoutineFocusType, useCircuitsValue, materials, customMat) => {
        // Primero, eliminar todo el contenido después de la primera línea divisoria
        let newInstructions = currentInstructions.replace(/\n+_+\n.*$/s, '');

        // Determinar si necesitamos añadir la sección de parámetros
        const hasParameters = selectedObjs.length > 0 || daysCount !== null || dietType || (location && daysCount !== null) || (cals && dietType) || (avoided && avoided.length > 0) || (intensity !== undefined && daysCount !== null) || (trainType && daysCount !== null) || (routineFocusType && daysCount !== null) || (useCircuitsValue !== undefined && daysCount !== null) || (materials && materials.length > 0);

        // Añadir línea divisoria y parámetros si es necesario
        if (hasParameters) {
            newInstructions += '\n\n\n\n\n' + '_'.repeat(50) + '\n';

            // Añadir información del cliente si es deportista
            if (clientData && clientData.es_deportista === 'si') {
                let deporteText = '';
                if (clientData.tipo_deporte === 'otro' && clientData.otro_deporte) {
                    deporteText = clientData.otro_deporte;
                } else if (clientData.tipo_deporte) {
                    const deporteMap = {
                        'padel': 'Pádel',
                        'natacion': 'Natación',
                        'running': 'Running',
                        'ciclismo': 'Ciclismo',
                        'futbol': 'Fútbol',
                        'baloncesto': 'Baloncesto',
                        'tenis': 'Tenis'
                    };
                    deporteText = deporteMap[clientData.tipo_deporte] || clientData.tipo_deporte;
                }
                newInstructions += `\nCLIENTE DEPORTISTA: ${deporteText}`;
            }

            // Añadir objetivos si hay
            if (selectedObjs.length > 0) {
                const objectivesText = selectedObjs
                    .map(obj => objectives.find(o => o.value === obj)?.label)
                    .filter(Boolean)
                    .join(', ');
                newInstructions += `\nOBJETIVOS PRINCIPALES: ${objectivesText}`;
            }

            // Añadir tipo de entrenamiento solo para rutinas
            if (trainType && daysCount !== null) {
                const trainingTypeText = trainType === 'otro' ? customTrainType : trainingTypes.find(t => t.value === trainType)?.label || trainType;
                newInstructions += `\n\nTIPO DE ENTRENAMIENTO: ${trainingTypeText}`;
            }

            // Añadir foco de la rutina solo para rutinas
            if (routineFocusType && daysCount !== null) {
                const focusText = routineFocusType === 'otro' ? customRoutineFocusType : routineFocusOptions.find(f => f.value === routineFocusType)?.label || routineFocusType;
                newInstructions += `\n\nFOCO DE LA RUTINA: ${focusText}`;
            }

            // Añadir uso de circuitos solo para rutinas
            if (useCircuitsValue !== undefined && daysCount !== null) {
                newInstructions += `\n\nUSAR CIRCUITOS: ${useCircuitsValue ? 'Sí' : 'No'}`;
            }

            // Añadir ubicación solo para rutinas
            if (location && daysCount !== null) {
                const locationText = location === 'otro' ? customLoc : location;
                newInstructions += `\n\nUBICACIÓN: ${locationText}`;
            }

            // Añadir materiales disponibles solo para rutinas
            if (materials && materials.length > 0 && daysCount !== null) {
                const materialsText = materials
                    .map(mat => {
                        const materialOption = materialOptions.find(m => m.value === mat);
                        return materialOption ? materialOption.label : mat;
                    })
                    .join(', ');
                
                // Añadir material personalizado si existe
                const fullMaterialsText = customMat && customMat.trim() ? `${materialsText}, ${customMat}` : materialsText;
                newInstructions += `\n\nMATERIAL DISPONIBLE: ${fullMaterialsText}`;
            }

            // Añadir intensidad solo para rutinas
            if (intensity !== undefined && daysCount !== null) {
                const intensityLabels = {
                    1: 'Muy baja',
                    2: 'Baja',
                    3: 'Media',
                    4: 'Alta',
                    5: 'Muy alta'
                };
                newInstructions += `\n\nINTENSIDAD DEL ENTRENAMIENTO: ${intensityLabels[intensity]}`;
            }

            // Añadir calorías y alimentos a evitar para dietas
            if (dietType) {
                if (cals) {
                    newInstructions += `\n\nCALORÍAS DIARIAS: ${cals} kcal`;
                }
                if (avoided && avoided.length > 0) {
                    const avoidedText = avoided
                        .map(food => {
                            const commonFood = commonFoodsToAvoid.find(f => f.value === food);
                            return commonFood ? commonFood.label : food;
                        })
                        .join(', ');
                    newInstructions += `\n\nALIMENTOS A EVITAR: ${avoidedText}`;
                }
            }

            // Añadir días de rutina o tipo de dieta según corresponda
            if (daysCount !== null) {
                newInstructions += `\n\nIMPORTANTE, DEVUELVE ${daysCount} ${daysCount === 1 ? 'DÍA' : 'DÍAS'} DE RUTINA`;
            } else if (dietType) {
                newInstructions += `\n\nIMPORTANTE, DEVUELVE UNA DIETA ${dietType.toUpperCase()}`;
            }
        }

        return newInstructions;
    };

    const handleObjectiveToggle = (objective) => {
        setSelectedObjectives(prev => {
            const newObjectives = prev.includes(objective)
                ? prev.filter(obj => obj !== objective)
                : [...prev, objective];
            
            const newInstructions = updateInstructionsText(
                instructions,
                newObjectives,
                generationType === 'rutina' ? routineDays : null,
                generationType === 'dieta' ? dietType : null,
                routineLocation,
                customLocation,
                calories,
                avoidFoods,
                trainingIntensity,
                trainingType,
                customTrainingType,
                selectedClientData,
                routineFocus,
                customRoutineFocus,
                useCircuits,
                selectedMaterials,
                customMaterial
            );
            
            setInstructions(newInstructions);
            return newObjectives;
        });
    };

    const handleRoutineDaysChange = (e) => {
        setRoutineDays(e.target.value);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            e.target.value,
            null,
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    

    const handleLocationChange = (event) => {
        setRoutineLocation(event.target.value);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            routineDays,
            null,
            event.target.value,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    const handleCustomLocationChange = (event) => {
        setCustomLocation(event.target.value);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            routineDays,
            'semanal',
            routineLocation,
            event.target.value,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    const handleIntensityChange = (event, newValue) => {
        setTrainingIntensity(newValue);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            routineDays,
            null,
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            newValue,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    const handleTrainingTypeChange = (event) => {
        const value = event.target.value;
        setTrainingType(value);
        // Resetear el tipo personalizado si se selecciona un tipo estándar
        if (value !== 'otro') {
            setCustomTrainingType('');
        }
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            routineDays,
            null,
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            value,
            value !== 'otro' ? '' : customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    const handleCustomTrainingTypeChange = (event) => {
        const value = event.target.value;
        setCustomTrainingType(value);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            generationType === 'rutina' ? routineDays : null,
            'semanal',
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            value,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    const handleRoutineFocusChange = (event) => {
        const value = event.target.value;
        setRoutineFocus(value);
        // Resetear el foco personalizado si se selecciona un foco estándar
        if (value !== 'otro') {
            setCustomRoutineFocus('');
        }
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            routineDays,
            null,
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            value,
            value !== 'otro' ? '' : customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    const handleCustomRoutineFocusChange = (event) => {
        const value = event.target.value;
        setCustomRoutineFocus(value);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            generationType === 'rutina' ? routineDays : null,
            'semanal',
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            value,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    // Función para manejar el cambio en el uso de circuitos
    const handleCircuitsToggle = (event) => {
        const checked = event.target.checked;
        setUseCircuits(checked);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            generationType === 'rutina' ? routineDays : null,
            generationType === 'dieta' ? dietType : null,
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            checked,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    // Función para manejar el toggle de materiales
    const handleMaterialToggle = (material) => {
        setSelectedMaterials(prev => {
            const newMaterials = prev.includes(material)
                ? prev.filter(mat => mat !== material)
                : [...prev, material];
            
            const newInstructions = updateInstructionsText(
                instructions,
                selectedObjectives,
                generationType === 'rutina' ? routineDays : null,
                generationType === 'dieta' ? dietType : null,
                routineLocation,
                customLocation,
                calories,
                avoidFoods,
                trainingIntensity,
                trainingType,
                customTrainingType,
                selectedClientData,
                routineFocus,
                customRoutineFocus,
                useCircuits,
                newMaterials,
                customMaterial
            );
            
            setInstructions(newInstructions);
            return newMaterials;
        });
    };

    // Función para manejar el cambio en el material personalizado
    const handleCustomMaterialChange = (event) => {
        const value = event.target.value;
        setCustomMaterial(value);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            generationType === 'rutina' ? routineDays : null,
            generationType === 'dieta' ? dietType : null,
            routineLocation,
            customLocation,
            calories,
            avoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            value
        );
        
        setInstructions(newInstructions);
    };

    const handleCaloriesChange = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 5000)) {
            setCalories(value);
            
            const newInstructions = updateInstructionsText(
                instructions,
                selectedObjectives,
                generationType === 'rutina' ? routineDays : null,
                'semanal',
                routineLocation,
                customLocation,
                value,
                avoidFoods,
                trainingIntensity,
                trainingType,
                customTrainingType,
                selectedClientData,
                routineFocus,
                customRoutineFocus,
                useCircuits,
                selectedMaterials,
                customMaterial
            );
            
            setInstructions(newInstructions);
        }
    };

    const handleAvoidFoodToggle = (food) => {
        const newAvoidFoods = avoidFoods.includes(food)
            ? avoidFoods.filter(f => f !== food)
            : [...avoidFoods, food];
        
        setAvoidFoods(newAvoidFoods);
        
        const newInstructions = updateInstructionsText(
            instructions,
            selectedObjectives,
            generationType === 'rutina' ? routineDays : null,
            'semanal',
            routineLocation,
            customLocation,
            calories,
            newAvoidFoods,
            trainingIntensity,
            trainingType,
            customTrainingType,
            selectedClientData,
            routineFocus,
            customRoutineFocus,
            useCircuits,
            selectedMaterials,
            customMaterial
        );
        
        setInstructions(newInstructions);
    };

    const handleAddCustomFood = () => {
        if (customFood.trim()) {
            const newAvoidFoods = [...avoidFoods, customFood.trim()];
            setAvoidFoods(newAvoidFoods);
            setCustomFood('');
            
            const newInstructions = updateInstructionsText(
                instructions,
                selectedObjectives,
                generationType === 'rutina' ? routineDays : null,
                generationType === 'dieta' ? dietType : null,
                routineLocation,
                customLocation,
                calories,
                newAvoidFoods,
                trainingIntensity,
                trainingType,
                customTrainingType,
                selectedClientData,
                routineFocus,
                customRoutineFocus,
                useCircuits,
                selectedMaterials,
                customMaterial
            );
            
            setInstructions(newInstructions);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verificar si es crossfit o rehabilitación
        if (generationType === 'crossfit' || generationType === 'rehabilitacion') {
            setUnavailableFeature(generationType === 'crossfit' ? 'CrossFit' : 'Rehabilitación');
            setOpenUnavailableModal(true);
            return;
        }

        setIsGenerating(true);
        setProgress(0);
        
        // Abrir el modal correspondiente según el modelo seleccionado
        if (selectedModel === 'pro') {
        setOpenPreview(true);
        } else {
            setOpenPreview(true);
        }

        try {
            // Modificar la validación del cliente
            if (!isGeneralContent && !selectedClient) {
                throw new Error('Cliente no seleccionado');
            }

            let selectedClientData = null;
            if (!isGeneralContent) {
                selectedClientData = clients.find(client => client.id === selectedClient);
                if (!selectedClientData) {
                    throw new Error('Cliente no encontrado');
                }
            }

            const selectedType = generationTypes.find(type => type.value === generationType);
            if (!selectedType) {
                throw new Error('Tipo de generación no válido');
            }

            // Obtener la configuración de IA
            let iaConfig;
            try {
                let configDoc;
                if (userDetails.tipo_profesional === 'propietario_centro') {
                    const centroRef = doc(db, 'Centros', userDetails.IDBO);
                    const iaConfigRef = doc(collection(centroRef, 'IA'), 'configuracion');
                    configDoc = await getDoc(iaConfigRef);
                } else if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    const userRef = doc(db, 'userDetails', userDetails.uid);
                    const iaConfigRef = doc(collection(userRef, 'IA'), 'configuracion');
                    configDoc = await getDoc(iaConfigRef);
                }
                
                if (configDoc && configDoc.exists()) {
                    iaConfig = configDoc.data();
                }
            } catch (error) {
                console.error("Error al obtener la configuración de IA:", error);
            }

            // Preparar el payload con la configuración de IA
            const payload = {
                IDPT: userDetails.IDPT,
                IDBO: userDetails.IDBO || null,
                instrucciones: instructions,
                tipo_profesional: userDetails.tipo_profesional,
                tipo_generacion: generationType,
                ia_config: iaConfig || null,
                isGeneralContent: isGeneralContent
            };

            // Añadir información específica según el tipo de generación
            if (generationType === 'rutina') {
                // Determinar el tipo de entrenamiento (valor por defecto o personalizado)
                const tipoEntrenamiento = trainingType === 'otro' 
                    ? customTrainingType 
                    : trainingTypes.find(t => t.value === trainingType)?.label || 'Clásico';
                
                // Determinar el foco de la rutina (valor por defecto o personalizado)
                const focoRutina = routineFocus === 'otro'
                    ? customRoutineFocus
                    : routineFocusOptions.find(f => f.value === routineFocus)?.label || 'Full Body';
                
                payload.tipo_entrenamiento = tipoEntrenamiento;
                payload.foco_rutina = focoRutina;
                payload.usar_circuitos = useCircuits;
                
                // Añadir información de materiales disponibles si hay seleccionados
                if (selectedMaterials.length > 0 || customMaterial) {
                    const materialesSeleccionados = selectedMaterials.map(mat => {
                        const materialOption = materialOptions.find(m => m.value === mat);
                        return materialOption ? materialOption.label : mat;
                    });
                    
                    // Añadir material personalizado si existe
                    if (customMaterial.trim()) {
                        materialesSeleccionados.push(customMaterial.trim());
                    }
                    
                    payload.materiales_disponibles = materialesSeleccionados;
                }
            }

            console.log('Payload a enviar:', payload);

            // Determinar el endpoint según el modelo y tipo de generación
            let endpoint;
            if (generationType === 'rutina') {
                endpoint = selectedModel === 'pro' 
                    ? 'https://crear-rutina-beta-v4-pro-39035072089.europe-southwest1.run.app'
                    : 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/crear-rutina-beta-v4';
            } else {
                endpoint = `https://europe-southwest1-fitai-2cd85.cloudfunctions.net/${selectedType.endpoint}`;
            }

            // Realizar la petición al endpoint correspondiente
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            // Verificar la respuesta
            if (!response.ok) {
                let errorMessage;
                try {
                    const errorData = await response.json();
                    console.error('Error detallado:', errorData);
                    errorMessage = errorData.error || 'Error en la generación';
                } catch (e) {
                    const errorText = await response.text();
                    console.error('Error completo:', errorText);
                    errorMessage = 'Error en la generación';
                }
                throw new Error(errorMessage);
            }

            // Procesar la respuesta
            let data;
            try {
                data = await response.json();
                console.log('Respuesta completa:', data);

                const markdown = data.plan_markdown || data.rutina_markdown || data.contenido_markdown;

                if (!markdown) {
                    console.error('La respuesta no contiene el markdown esperado:', data);
                    throw new Error('Formato de respuesta inválido');
                }

                // Eliminar asteriscos del texto
                const cleanMarkdown = markdown.replace(/\*/g, '');

                // Actualizar contador global después de una generación exitosa
                const statsRef = doc(db, 'stats', 'global');
                await runTransaction(db, async (transaction) => {
                    const statsDoc = await transaction.get(statsRef);
                    if (!statsDoc.exists()) {
                        transaction.set(statsRef, { 
                            [`total_${generationType}s`]: 1 
                        });
                    } else {
                        transaction.update(statsRef, {
                            [`total_${generationType}s`]: increment(1)
                        });
                    }
                });

                // Guardar en el historial
                const historialRef = collection(db, 'stats', 'global', 'historial');
                await addDoc(historialRef, {
                    tipo: generationType,
                    entrenador: {
                        id: userDetails.uid,
                        nombre: userDetails.display_name,
                        tipo_profesional: userDetails.tipo_profesional
                    },
                    modelo: selectedModel,
                    fecha: serverTimestamp(),
                    cliente: isGeneralContent ? null : {
                        id: selectedClient,
                        nombre: selectedClientData.nombre
                    }
                });

                setProgress(100);
                setGeneratedContent(cleanMarkdown);
                setEditableContent(cleanMarkdown);
                enqueueSnackbar('Contenido generado con éxito', { variant: 'success' });
            } catch (error) {
                console.error('Error al procesar la respuesta:', error);
                throw new Error('Error al procesar la respuesta del servidor');
            }

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error en la generación', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    const handlePromptEdit = async () => {
        if (!editPrompt.trim()) {
            enqueueSnackbar('Por favor, introduce un prompt para editar el contenido', { variant: 'warning' });
            return;
        }

        setIsEditing(true);
        try {
            const endpoint = `editar-${generationType}-beta`;

            // Determinar el nombre del parámetro según el tipo de contenido
            const contentParam = generationType === 'dieta' ? 'dieta_actual' : 'rutina_actual';
            
            const response = await fetch(`https://europe-southwest1-fitai-2cd85.cloudfunctions.net/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    tipo_profesional: userDetails.tipo_profesional,
                    IDPT: userDetails.tipo_profesional === 'entrenador_independiente' ? userDetails.IDPT : null,
                    IDBO: userDetails.tipo_profesional !== 'entrenador_independiente' ? userDetails.IDBO : null,
                    id_cliente: selectedClient,
                    instrucciones: editPrompt,
                    [contentParam]: editableContent
                })
            });

            let errorData;
            try {
                errorData = await response.json();
            } catch (e) {
                errorData = await response.text();
            }

            if (!response.ok) {
                console.error('Error de la cloud function:', errorData);
                throw new Error(
                    errorData?.error || 
                    'Error al editar el contenido. Por favor, intenta con otras instrucciones.'
                );
            }

            // Obtener el contenido según el tipo de generación
            let newContent;
            if (generationType === 'dieta') {
                newContent = errorData.dieta_markdown;
            } else if (generationType === 'rutina') {
                newContent = errorData.rutina_markdown;
            } else {
                newContent = errorData.contenido_markdown;
            }
            
            if (!newContent) {
                console.error('Respuesta recibida:', errorData);
                throw new Error('La respuesta no contiene el contenido esperado. Por favor, intenta de nuevo.');
            }

            setEditableContent(newContent);
            setGeneratedContent(newContent);
            setEditPrompt('');
            enqueueSnackbar('Contenido editado con éxito', { variant: 'success' });
        } catch (error) {
            console.error('Error al editar:', error);
            enqueueSnackbar(
                error.message || 
                'Ha ocurrido un error al editar el contenido. Por favor, intenta de nuevo.', 
                { variant: 'error' }
            );
        } finally {
            setIsEditing(false);
        }
    };

    const handleSavePlantillaConfig = (config) => {
        setPlantillaConfig(config);
        // Aquí puedes guardar la configuración en tu backend o donde sea necesario
    };

    const handleGeneratePDF = async () => {
        try {
            setIsGenerating(true);
            setProgress(0);

            // Obtener las URLs de las imágenes
            const businessLogoUrl = userDetails?.tipo_profesional === 'entrenador_independiente'
                ? userDetails?.business_logo_url
                : userDetails?.centro?.business_logo_url;

            const centroPhotoUrl = userDetails?.centro?.photo_url;

            // Obtener la configuración de la plantilla
            const docRef = userDetails?.tipo_profesional === 'entrenador_independiente'
                ? doc(db, 'userDetails', userDetails.uid)
                : doc(db, 'Centros', userDetails.IDBO);

            const docSnap = await getDoc(docRef);
            const plantillaConfig = docSnap.exists() ? docSnap.data().plantillaConfig : null;

            // Preparar los datos para la cloud function
            const pdfData = {
                content: {
                    text: editableContent,
                    type: generationType,
                    clientData: isGeneralContent ? null : selectedClientData
                },
                businessData: {
                    businessName: plantillaConfig?.businessName || (userDetails?.tipo_profesional === 'entrenador_independiente' 
                        ? userDetails?.display_name 
                        : userDetails?.centro?.nombreCentro),
                    business_logo_url: businessLogoUrl,
                    contactInfo: plantillaConfig?.contactInfo || userDetails?.contactInfo,
                    tipo_profesional: userDetails?.tipo_profesional,
                    centro_photo_url: centroPhotoUrl,
                    nombreCentro: userDetails?.centro?.nombreCentro
                },
                design: {
                    headerStyle: plantillaConfig?.design?.headerStyle || 'modern',
                    primaryColor: plantillaConfig?.design?.primaryColor || '#FF5722',
                    secondaryColor: plantillaConfig?.design?.secondaryColor || '#FF1493',
                    fontSize: plantillaConfig?.design?.fontSize || 'medium',
                    showLogo: plantillaConfig?.showLogo ?? true,
                    showDate: plantillaConfig?.showDate ?? true,
                    showContactInfo: plantillaConfig?.showContactInfo ?? true,
                    footerText: plantillaConfig?.footerText || '',
                    preferTables: preferTables // Añadimos la preferencia de tablas
                },
                isGeneralContent: isGeneralContent
            };

            console.log('Enviando datos a la cloud function:', pdfData);

            // Llamar a la cloud function
            const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/pdf-creator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(pdfData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(`Error al generar el PDF: ${errorText}`);
            }

            const result = await response.json();
            console.log('Respuesta de la cloud function recibida');
            
            if (!result.pdf_base64) {
                throw new Error('No se recibió el PDF en base64');
            }

            // Convertir base64 a Blob
            const byteCharacters = atob(result.pdf_base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Crear URL del blob y descargar localmente
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${generationType}_${selectedClientData?.nombre || 'documento'}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            setProgress(100);
            enqueueSnackbar('PDF generado correctamente', { variant: 'success' });
            return blob;

        } catch (error) {
            console.error('Error al generar el PDF:', error);
            enqueueSnackbar(error.message || 'Error al generar el PDF', { variant: 'error' });
            return null;
        } finally {
            setIsGenerating(false);
        }
    };

    const handleImplantRoutine = async () => {
        try {
            setIsGenerating(true);
            
            // 1. Convertir la rutina a JSON
            const response = await fetch('https://routine-to-json-v3-39035072089.europe-southwest1.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    routine_text: editableContent
                })
            });

            if (!response.ok) {
                throw new Error('Error al convertir la rutina a JSON');
            }

            const routineData = await response.json();
            console.log('Rutina convertida a JSON:', routineData);

            // 2. Guardar en Firestore
            const routinesRef = collection(db, 'routines');
            const newRoutineDoc = await addDoc(routinesRef, {
                ...routineData,
                clientId: selectedClient,
                IDPT: userDetails.IDPT,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                isNewlyCreated: true  // Marcar como recién creada para asignar videos automáticamente
            });

            console.log('Rutina guardada con ID:', newRoutineDoc.id);
            enqueueSnackbar('Rutina implantada exitosamente', { variant: 'success' });
            
            // Cerrar el modal de previsualización y mostrar animación
            setOpenPreview(false);
            setShowSuccessAnimation(true);
            
            // Cerrar la animación después de 2 segundos
            setTimeout(() => {
                setShowSuccessAnimation(false);
            }, 2000);

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error al procesar la rutina', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    const handleImplantDiet = async () => {
        try {
            setIsGenerating(true);
            
            // 1. Convertir la dieta a JSON
            const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/diet-to-json-v3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    diet_text: editableContent
                })
            });

            if (!response.ok) {
                throw new Error('Error al convertir la dieta a JSON');
            }

            const dietData = await response.json();
            console.log('Dieta convertida a JSON:', dietData);

            // 2. Generar el PDF y obtener la URL
            const storage = getStorage(app);
            const fileName = `${generationType}_${selectedClient}_${Date.now()}.pdf`;
            const pdfRef = ref(storage, `pdf_generados/${fileName}`);
            
            // Convertir el contenido a PDF y subirlo al Storage
            const pdfBlob = await handleGeneratePDF();
            await uploadBytes(pdfRef, pdfBlob);
            const pdfUrl = await getDownloadURL(pdfRef);

            // 3. Guardar en la colección diets con la nueva estructura
            const dietsRef = collection(db, 'diets');
            const newDietDoc = await addDoc(dietsRef, {
                clientId: selectedClient,
                IDPT: userDetails.IDPT,
                pdfUrl: pdfUrl,
                content: editableContent,
                diet_data: dietData,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            });

            console.log('Dieta guardada con ID:', newDietDoc.id);
            enqueueSnackbar('Dieta implantada exitosamente', { variant: 'success' });

            // Cerrar el modal de previsualización y mostrar animación
            setOpenPreview(false);
            setShowSuccessAnimation(true);
            
            // Cerrar la animación después de 2 segundos
            setTimeout(() => {
                setShowSuccessAnimation(false);
            }, 2000);

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error al procesar la dieta', { variant: 'error' });
        } finally {
            setIsGenerating(false);
        }
    };

    const handleSaveTemplate = async () => {
        try {
            setIsGenerating(true);
            
            // Crear la colección según el tipo de contenido
            const collectionName = generationType === 'rutina' ? 'rutinas_generales' : 'dietas_generales';
            const templatesRef = collection(db, collectionName);
            
            // Preparar los datos de la plantilla
            const templateData = {
                nombre: templateName,
                contenido: editableContent,
                tipo: generationType,
                createdAt: serverTimestamp(),
                createdBy: {
                    uid: userDetails.uid,
                    nombre: userDetails.display_name,
                    tipo_profesional: userDetails.tipo_profesional
                }
            };
            
            // Añadir el tipo de entrenamiento si es una rutina
            if (generationType === 'rutina') {
                const tipoEntrenamiento = trainingType === 'otro' 
                    ? customTrainingType 
                    : trainingTypes.find(t => t.value === trainingType)?.label || 'Clásico';
                
                templateData.tipo_entrenamiento = tipoEntrenamiento;
            }
            
            // Guardar en Firestore
            await addDoc(templatesRef, templateData);

            enqueueSnackbar('Plantilla guardada exitosamente', { variant: 'success' });
            setOpenSaveTemplateModal(false);
            setOpenPreview(false);
            setShowSuccessAnimation(true);
            
            // Cerrar la animación después de 2 segundos
            setTimeout(() => {
                setShowSuccessAnimation(false);
            }, 2000);

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(error.message || 'Error al guardar la plantilla', { variant: 'error' });
        } finally {
            setIsGenerating(false);
            setTemplateName('');
        }
    };

    useEffect(() => {
        const fetchTemplates = async () => {
            setLoadingTemplates(true);
            try {
                // Obtener rutinas generales
                const rutinasSnapshot = await getDocs(collection(db, 'rutinas_generales'));
                const rutinas = rutinasSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Obtener dietas generales
                const dietasSnapshot = await getDocs(collection(db, 'dietas_generales'));
                const dietas = dietasSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setTemplates({
                    rutinas,
                    dietas
                });
            } catch (error) {
                console.error('Error al cargar las plantillas:', error);
                enqueueSnackbar('Error al cargar las plantillas', { variant: 'error' });
            } finally {
                setLoadingTemplates(false);
            }
        };

        fetchTemplates();
    }, [enqueueSnackbar]);

    const handleUseTemplate = (template) => {
        setSelectedTemplate(template);
        setOpenClientSelectModal(true);
    };

    const handleClientSelect = (clientId) => {
        if (!clientId) {
            enqueueSnackbar('Por favor, selecciona un cliente', { variant: 'warning' });
            return;
        }

        const clientData = clients.find(client => client.id === clientId);
        if (!clientData) {
            enqueueSnackbar('Cliente no encontrado', { variant: 'error' });
            return;
        }

        setSelectedClient(clientId);
        setSelectedClientData(clientData);
        setOpenClientSelectModal(false);
        setEditableContent(selectedTemplate.contenido);
        setGeneratedContent(selectedTemplate.contenido);
        setGenerationType(selectedTemplate.tipo);
        setIsGeneralContent(false);
        setOpenTemplatesModal(false);
        setOpenPreview(true);

        // Cargar el tipo de entrenamiento si está disponible en la plantilla
        if (selectedTemplate.tipo === 'rutina' && selectedTemplate.tipo_entrenamiento) {
            // Verificar si es un tipo predefinido o personalizado
            const predefinedType = trainingTypes.find(t => 
                t.label.toLowerCase() === selectedTemplate.tipo_entrenamiento.toLowerCase()
            );
            
            if (predefinedType) {
                setTrainingType(predefinedType.value);
                setCustomTrainingType('');
            } else {
                setTrainingType('otro');
                setCustomTrainingType(selectedTemplate.tipo_entrenamiento);
            }
        }

        enqueueSnackbar(`Plantilla cargada para ${clientData.nombre}`, { variant: 'success' });
    };

    const handleDeleteTemplate = async (templateId, type) => {
        try {
            const collectionName = type === 'rutina' ? 'rutinas_generales' : 'dietas_generales';
            await deleteDoc(doc(db, collectionName, templateId));
            
            setTemplates(prev => ({
                ...prev,
                [type === 'rutina' ? 'rutinas' : 'dietas']: prev[type === 'rutina' ? 'rutinas' : 'dietas'].filter(t => t.id !== templateId)
            }));
            
            enqueueSnackbar('Plantilla eliminada correctamente', { variant: 'success' });
        } catch (error) {
            console.error('Error al eliminar la plantilla:', error);
            enqueueSnackbar('Error al eliminar la plantilla', { variant: 'error' });
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Generador de Contenido
                </Typography>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<FolderIcon sx={{ fontSize: 18 }} />}
                        onClick={() => setOpenTemplatesModal(true)}
                        sx={{
                            borderRadius: '8px',
                            textTransform: 'none',
                            px: 2,
                            py: 0.5,
                            fontSize: '0.875rem',
                            backgroundColor: 'white',
                            color: '#FF5722',
                            border: '1px solid rgba(255, 87, 34, 0.3)',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.02)',
                                border: '1px solid rgba(255, 87, 34, 0.5)',
                                boxShadow: '0 2px 4px rgba(255, 87, 34, 0.15)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)'
                            },
                            transition: 'all 0.2s ease',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                        }}
                    >
                        Plantillas
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<SettingsIcon sx={{ fontSize: 18 }} />}
                        onClick={() => setOpenConfigModal(true)}
                        sx={{
                            borderRadius: '8px',
                            textTransform: 'none',
                            px: 2,
                            py: 0.5,
                            fontSize: '0.875rem',
                            backgroundColor: 'white',
                            color: '#FF5722',
                            border: '1px solid rgba(255, 87, 34, 0.3)',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.02)',
                                border: '1px solid rgba(255, 87, 34, 0.5)',
                                boxShadow: '0 2px 4px rgba(255, 87, 34, 0.15)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)'
                            },
                            transition: 'all 0.2s ease',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                        }}
                    >
                        Configurar PDF
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<FiCpu size={14} />}
                        onClick={() => setOpenIASettings(true)}
                        sx={{
                            borderRadius: '8px',
                            textTransform: 'none',
                            px: 2,
                            py: 0.5,
                            fontSize: '0.875rem',
                            background: 'linear-gradient(45deg, rgba(255, 87, 34, 0.8), rgba(255, 20, 147, 0.8))',
                            boxShadow: 'none',
                            '&:hover': {
                                background: 'linear-gradient(45deg, rgba(255, 87, 34, 0.9), rgba(255, 20, 147, 0.9))',
                                boxShadow: '0 2px 4px rgba(255, 87, 34, 0.15)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)'
                            },
                            transition: 'all 0.2s ease',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                        }}
                    >
                        Configurar IA
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }}>
                <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                    Selecciona un cliente y el tipo de contenido que deseas generar.
                </Typography>
                <FormControl size="small" sx={{ ml: 1 }}>
                    <Select
                        value={selectedModel}
                        onChange={(e) => setSelectedModel(e.target.value)}
                        variant="outlined"
                        sx={{
                            backgroundColor: 'rgba(255, 87, 34, 0.04)',
                            color: '#FF5722',
                            fontWeight: 500,
                            borderRadius: '12px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid rgba(255, 87, 34, 0.2)',
                                borderRadius: '12px',
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(255, 87, 34, 0.3)',
                                }
                            },
                            '&.Mui-focused': {
                                backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#FF5722',
                                    borderWidth: '1px',
                                }
                            },
                            '& .MuiSelect-select': {
                                paddingY: '6px',
                                paddingX: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                minWidth: '220px'
                            },
                            '& .MuiSelect-icon': {
                                color: '#FF5722'
                            }
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    borderRadius: '12px',
                                    mt: 1,
                                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                    '& .MuiMenuItem-root': {
                                        padding: '12px 16px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: 'rgba(255, 87, 34, 0.08)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 87, 34, 0.12)'
                                            }
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        <MenuItem value="standard">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, py: 0.5 }}>
                                <FiCpu size={16} style={{ opacity: 0.9 }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: 1.2 }}>
                                        FitAI-Gen-1
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" sx={{ 
                                        fontSize: '0.75rem',
                                        opacity: 0.8,
                                        lineHeight: 1.2
                                    }}>
                                        Rápido y avanzado
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                        <MenuItem value="pro">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, py: 0.5 }}>
                                <FiCpu size={16} style={{ opacity: 0.9 }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: 1.2 }}>
                                        FitAI-Gen-1 Pro
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" sx={{ 
                                        fontSize: '0.75rem',
                                        opacity: 0.8,
                                        lineHeight: 1.2
                                    }}>
                                        Lento pero potente
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                    </Select>
                </FormControl>
                <Tooltip title="Elige entre nuestros modelos de IA: FitAI-Gen-1 para resultados rápidos y avanzados, o FitAI-Gen-1 Pro para resultados más potentes pero con mayor tiempo de procesamiento." placement="right">
                    <IconButton size="small" sx={{ 
                        ml: 1, 
                        color: '#FF5722',
                        opacity: 0.8,
                        '&:hover': {
                            opacity: 1,
                            backgroundColor: 'rgba(255, 87, 34, 0.08)'
                        }
                    }}>
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Box>

            <Paper elevation={3} sx={{ p: 4 }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ position: 'relative', mb: 4 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"1"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Selecciona el tipo de contenido
                        </Typography>

                        <Grid container spacing={2} sx={{ mb: 3 }}>
                            {generationTypes.map((type) => (
                                <Grid item xs={12} sm={6} md={3} key={type.value}>
                                    <Paper
                                        elevation={generationType === type.value ? 3 : 1}
                                        sx={{
                                            p: 2,
                                            cursor: 'pointer',
                                            transition: 'all 0.3s ease',
                                            border: generationType === type.value ? `2px solid ${type.color}` : '2px solid transparent',
                                            '&:hover': {
                                                transform: 'translateY(-2px)',
                                                boxShadow: 3
                                            },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1,
                                            height: '100%',
                                            backgroundColor: generationType === type.value ? `${type.color}10` : 'transparent'
                                        }}
                                        onClick={() => {
                                            setGenerationType(type.value);
                                            // Resetear instrucciones y objetivos al cambiar de tipo
                                            setInstructions('');
                                            setSelectedObjectives([]);
                                        }}
                                    >
                                        <Box sx={{ 
                                            color: type.color,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            {type.icon}
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                            sx={{
                                                fontWeight: generationType === type.value ? 600 : 400
                                            }}
                                        >
                                            {type.label}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box sx={{ position: 'relative', mb: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"2"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Selecciona el cliente
                        </Typography>

                        <Box sx={{ mb: 2 }}>
                            <ToggleButtonGroup
                                value={isGeneralContent ? "general" : "cliente"}
                                exclusive
                                onChange={(e, newValue) => {
                                    if (newValue !== null) {
                                        setIsGeneralContent(newValue === "general");
                                        if (newValue === "general") {
                                            setSelectedClient('');
                                        }
                                    }
                                }}
                                aria-label="Tipo de contenido"
                                sx={{ mb: 2 }}
                            >
                                <ToggleButton value="cliente" aria-label="para un cliente concreto">
                                    Para un cliente concreto
                                </ToggleButton>
                                <ToggleButton value="general" aria-label="contenido general">
                                    Contenido General
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {!isGeneralContent && (
                            <FormControl fullWidth>
                                <InputLabel id="client-select-label">Cliente</InputLabel>
                                <Select
                                    labelId="client-select-label"
                                    value={selectedClient}
                                    label="Cliente"
                                    onChange={(e) => setSelectedClient(e.target.value)}
                                    required={!isGeneralContent}
                                    disabled={isGenerating || isGeneralContent}
                                    sx={{
                                        '& .MuiSelect-select': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2
                                        }
                                    }}
                                >
                                    {clients.map((client) => (
                                        <MenuItem 
                                            key={client.id} 
                                            value={client.id}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                py: 1
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar 
                                                    src={client.photo_url} 
                                                    alt={`${client.nombre} ${client.apellidos}`}
                                                    sx={{ 
                                                        width: 40, 
                                                        height: 40,
                                                        bgcolor: !client.photo_url ? 'primary.main' : 'transparent'
                                                    }}
                                                >
                                                    {!client.photo_url && `${client.nombre.charAt(0)}${client.apellidos.charAt(0)}`}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        {`${client.nombre} ${client.apellidos}`}
                                                        {client.es_deportista === 'si' && (
                                                            <Chip
                                                                label={client.tipo_deporte === 'otro' ? client.otro_deporte : 
                                                                    {
                                                                        'padel': 'Pádel',
                                                                        'natacion': 'Natación',
                                                                        'running': 'Running',
                                                                        'ciclismo': 'Ciclismo',
                                                                        'futbol': 'Fútbol',
                                                                        'baloncesto': 'Baloncesto',
                                                                        'tenis': 'Tenis'
                                                                    }[client.tipo_deporte] || client.tipo_deporte}
                                                                size="small"
                                                                color="info"
                                                                sx={{ ml: 1 }}
                                                            />
                                                        )}
                                                    </Box>
                                                }
                                                secondary={client.email}
                                                primaryTypographyProps={{
                                                    fontWeight: 500
                                                }}
                                            />
                                            {client.plan === 'Pro' && (
                                                <Chip 
                                                    label="Pro"
                                                    size="small"
                                                    sx={{
                                                        background: 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        ml: 'auto'
                                                    }}
                                                />
                                            )}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>

                    <Box sx={{ position: 'relative', mb: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"3"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Define los objetivos
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: 1,
                            '& > *': { m: 0.5 }
                        }}>
                            {objectives.map((objective) => (
                                <Chip
                                    key={objective.value}
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                            <span>{objective.icon}</span>
                                            <span>{objective.label}</span>
                                        </Box>
                                    }
                                    onClick={() => handleObjectiveToggle(objective.value)}
                                    color={selectedObjectives.includes(objective.value) ? "primary" : "default"}
                                    variant={selectedObjectives.includes(objective.value) ? "filled" : "outlined"}
                                    sx={{
                                        cursor: 'pointer',
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: 1
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    {/* Selector de días para rutina */}
                    {(generationType === 'rutina' || generationType === 'dieta') && (
                        <Box sx={{ position: 'relative', mb: 3 }}>
                            <Typography variant="h6" gutterBottom sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                color: 'text.secondary',
                                '&::before': {
                                    content: '"4"',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                    color: '#FF5722',
                                    marginRight: '8px',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }
                            }}>
                                {generationType === 'rutina' ? 'Configura la rutina' : 'Configura la dieta'}
                            </Typography>
                            {generationType === 'rutina' && (
                                <>
                                    <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                                        <InputLabel>Días de Rutina</InputLabel>
                                        <Select
                                            value={routineDays}
                                            onChange={handleRoutineDaysChange}
                                            label="Días de Rutina"
                                        >
                                            {[1,2,3,4,5,6,7].map((day) => (
                                                <MenuItem key={day} value={day}>
                                                    {day} {day === 1 ? 'día' : 'días'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <InputLabel>Ubicación</InputLabel>
                                        <Select
                                            value={routineLocation}
                                            onChange={handleLocationChange}
                                            label="Ubicación"
                                        >
                                            <MenuItem value="gimnasio">Gimnasio</MenuItem>
                                            <MenuItem value="casa">Casa</MenuItem>
                                            <MenuItem value="hibrido">Híbrido</MenuItem>
                                            <MenuItem value="otro">Otro</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {routineLocation === 'otro' && (
                                        <TextField
                                            fullWidth
                                            label="Especifica la ubicación"
                                            value={customLocation}
                                            onChange={handleCustomLocationChange}
                                            sx={{ mb: 2 }}
                                            placeholder="Ej: Parque, Playa, etc."
                                        />
                                    )}

                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <InputLabel>Tipo de Entrenamiento</InputLabel>
                                        <Select
                                            value={trainingType}
                                            onChange={handleTrainingTypeChange}
                                            label="Tipo de Entrenamiento"
                                        >
                                            {trainingTypes.map((type) => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.icon} {type.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {trainingType === 'otro' && (
                                        <TextField
                                            fullWidth
                                            label="Especifica el tipo de entrenamiento"
                                            value={customTrainingType}
                                            onChange={handleCustomTrainingTypeChange}
                                            sx={{ mb: 2 }}
                                            placeholder="Ej: Calistenia, Pilates, etc."
                                        />
                                    )}

                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <InputLabel>Foco de la Rutina</InputLabel>
                                        <Select
                                            value={routineFocus}
                                            onChange={handleRoutineFocusChange}
                                            label="Foco de la Rutina"
                                        >
                                            {routineFocusOptions.map((focus) => (
                                                <MenuItem key={focus.value} value={focus.value}>
                                                    {focus.icon} {focus.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {routineFocus === 'otro' && (
                                        <TextField
                                            fullWidth
                                            label="Especifica el foco de la rutina"
                                            value={customRoutineFocus}
                                            onChange={handleCustomRoutineFocusChange}
                                            sx={{ mb: 2 }}
                                            placeholder="Ej: Push/Pull/Legs, Split, etc."
                                        />
                                    )}

                                    {/* Opción para usar circuitos */}
                                    <Box sx={{ mb: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <ModernSwitch 
                                                    checked={useCircuits} 
                                                    onChange={handleCircuitsToggle} 
                                                />
                                            }
                                            label={
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    <Timer size={24} weight={useCircuits ? "fill" : "duotone"} />
                                                    <Box>
                                                        <Typography variant="body1" sx={{ fontWeight: useCircuits ? 500 : 400 }}>
                                                            Usar circuitos en la rutina
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Ejercicios consecutivos con poco descanso entre ellos
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            }
                                            sx={{ my: 1 }}
                                        />
                                    </Box>

                                    {/* Sección de selección de materiales */}
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="subtitle1" gutterBottom sx={{ mb: 1 }}>
                                            Material disponible
                                        </Typography>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            flexWrap: 'wrap', 
                                            gap: 1,
                                            mb: 2
                                        }}>
                                            {materialOptions.map((material) => (
                                                <Chip
                                                    key={material.value}
                                                    label={
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                            <span>{material.icon}</span>
                                                            <span>{material.label}</span>
                                                        </Box>
                                                    }
                                                    onClick={() => handleMaterialToggle(material.value)}
                                                    color={selectedMaterials.includes(material.value) ? "primary" : "default"}
                                                    variant={selectedMaterials.includes(material.value) ? "filled" : "outlined"}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        transition: 'all 0.2s ease',
                                                        '&:hover': {
                                                            transform: 'translateY(-1px)',
                                                            boxShadow: 1
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={customMaterial}
                                                onChange={handleCustomMaterialChange}
                                                placeholder="Añadir otro material disponible..."
                                            />
                                        </Box>
                                    </Box>

                                    <IntensitySlider 
                                        value={trainingIntensity}
                                        onChange={handleIntensityChange}
                                        disabled={isGenerating}
                                    />
                                </>
                            )}

                            {generationType === 'dieta' && (
                                <>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                        Se generará un plan específico para cada día de la semana
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Calorías diarias"
                                        value={calories}
                                        onChange={handleCaloriesChange}
                                        sx={{ mb: 2 }}
                                        placeholder="Ej: 2000"
                                        InputProps={{
                                            endAdornment: <Typography color="text.secondary">kcal</Typography>
                                        }}
                                    />

                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="subtitle1" gutterBottom sx={{ mb: 1 }}>
                                            Alimentos a evitar
                                        </Typography>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            flexWrap: 'wrap', 
                                            gap: 1,
                                            mb: 2
                                        }}>
                                            {commonFoodsToAvoid.map((food) => (
                                                <Chip
                                                    key={food.value}
                                                    label={
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                            <span>{food.icon}</span>
                                                            <span>{food.label}</span>
                                                        </Box>
                                                    }
                                                    onClick={() => handleAvoidFoodToggle(food.value)}
                                                    color={avoidFoods.includes(food.value) ? "primary" : "default"}
                                                    variant={avoidFoods.includes(food.value) ? "filled" : "outlined"}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        transition: 'all 0.2s ease',
                                                        '&:hover': {
                                                            transform: 'translateY(-1px)',
                                                            boxShadow: 1
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={customFood}
                                                onChange={(e) => setCustomFood(e.target.value)}
                                                placeholder="Añadir otro alimento a evitar..."
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        handleAddCustomFood();
                                                    }
                                                }}
                                            />
                                            <Button
                                                variant="outlined"
                                                onClick={handleAddCustomFood}
                                                disabled={!customFood.trim()}
                                            >
                                                Añadir
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}

                    <Box sx={{ position: 'relative', mb: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: 'text.secondary',
                            '&::before': {
                                content: '"5"',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                color: '#FF5722',
                                marginRight: '8px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }}>
                            Escribe las instrucciones específicas
                        </Typography>
                        <Box sx={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={12}
                                label="Instrucciones"
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                                required
                                disabled={isGenerating}
                                sx={{ 
                                    mb: 3,
                                    '& .MuiOutlinedInput-root': {
                                        pr: 5 // Espacio para el botón de micrófono
                                    }
                                }}
                                placeholder={`Describe aquí las instrucciones para generar ${
                                    generationType === 'rutina' ? 'la rutina' :
                                    generationType === 'dieta' ? 'el plan de alimentación' :
                                    'el contenido'
                                } para tu cliente...`}
                            />
                            <Tooltip title={isRecording ? "Detener grabación" : "Grabar instrucciones por voz"}>
                                <IconButton
                                    onClick={toggleRecording}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: isRecording ? 'error.main' : 'action.active',
                                        animation: isRecording ? 'pulse 1.5s infinite' : 'none',
                                        '@keyframes pulse': {
                                            '0%': {
                                                transform: 'scale(1)',
                                                opacity: 1
                                            },
                                            '50%': {
                                                transform: 'scale(1.2)',
                                                opacity: 0.8
                                            },
                                            '100%': {
                                                transform: 'scale(1)',
                                                opacity: 1
                                            }
                                        }
                                    }}
                                >
                                    {isRecording ? 
                                        <MicrophoneSlash size={24} weight="duotone" /> : 
                                        <Microphone size={24} weight="duotone" />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Button 
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={isGenerating}
                        sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1.5,
                            fontSize: '1rem',
                            fontWeight: 500,
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            },
                            '&.Mui-disabled': {
                                background: 'rgba(0, 0, 0, 0.12)',
                                boxShadow: 'none'
                            },
                            transition: 'all 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2
                        }}
                    >
                        {isGenerating ? (
                            <>
                                <CircularProgress 
                                    size={24} 
                                    sx={{ 
                                        color: 'white',
                                        opacity: 0.9
                                    }} 
                                />
                                <span>Generando...</span>
                            </>
                        ) : (
                            <>
                                <FiCpu size={20} />
                                <span>Generar Contenido</span>
                            </>
                        )}
                    </Button>
                </form>
            </Paper>

            {/* Dialog para previsualizar el contenido (modelo estándar) */}
            <StandardGenerationModal 
                open={selectedModel === 'standard' && openPreview}
                onClose={() => {
                    if (!isGenerating) {
                        setOpenCloseConfirmation(true);
                    }
                }}
                isGenerating={isGenerating}
                progress={progress}
                generationType={generationType}
                generatedContent={generatedContent}
                currentQuote={currentQuote}
                onGeneratePDF={handleGeneratePDF}
                onImplant={generationType === 'rutina' ? handleImplantRoutine : handleImplantDiet}
                onEdit={handlePromptEdit}
                isEditing={isEditing}
                editPrompt={editPrompt}
                setEditPrompt={setEditPrompt}
                editableContent={editableContent}
                setEditableContent={setEditableContent}
                isGeneralContent={isGeneralContent}
                onSaveTemplate={() => setOpenSaveTemplateModal(true)}
                onOpenHelp={() => setOpenHelpModal(true)}
                preferTables={preferTables}
                setPreferTables={setPreferTables}
            />

            {/* Modal de funcionalidad no disponible */}
            <Dialog
                open={openUnavailableModal}
                onClose={() => setOpenUnavailableModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent sx={{ textAlign: 'center', py: 4 }}>
                    <Box sx={{ mb: 3 }}>
                        <PsychologyIcon sx={{ 
                            fontSize: 60, 
                            color: 'primary.main',
                            mb: 2
                        }} />
                        <Typography variant="h6" gutterBottom>
                            Funcionalidad en Desarrollo
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            La generación de programas de {unavailableFeature} estará disponible próximamente.
                            Estamos trabajando para ofrecerte la mejor experiencia posible.
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={() => setOpenUnavailableModal(false)}
                        sx={{
                            mt: 2,
                            px: 4,
                            borderRadius: 2
                        }}
                    >
                        Entendido
                    </Button>
                </DialogContent>
            </Dialog>

            <PlantillaConfigModal
                open={openConfigModal}
                onClose={() => setOpenConfigModal(false)}
                onSave={handleSavePlantillaConfig}
                initialConfig={plantillaConfig}
            />

            {/* Modal de configuración de IA */}
            <Dialog
                open={openIASettings}
                onClose={() => setOpenIASettings(false)}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        minHeight: '80vh',
                        maxHeight: '90vh',
                        borderRadius: 2
                    }
                }}
            >
                <DialogTitle>
                    Configuración de IA
                    <IconButton
                        onClick={() => setOpenIASettings(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <IA />
                </DialogContent>
            </Dialog>

            {/* Modal de animación de éxito */}
            <Dialog
                open={showSuccessAnimation}
                onClose={() => setShowSuccessAnimation(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogContent>
                    <Box sx={{ width: '100%', height: '300px' }}>
                        <Lottie
                            animationData={successAnimation}
                            loop={false}
                            autoplay={true}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Modal de ayuda para descargas bloqueadas */}
            <Dialog
                open={openHelpModal}
                onClose={() => setOpenHelpModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Guía: ¿Problemas al descargar PDF?
                    <IconButton
                        onClick={() => setOpenHelpModal(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ py: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Si no puedes descargar el PDF, sigue estos pasos:
                        </Typography>
                        
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                1. Verifica los permisos de descarga
                            </Typography>
                            <Typography variant="body1" paragraph>
                                • Busca el icono de descarga bloqueada en la barra de navegación de tu navegador
                            </Typography>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                mb: 2,
                                border: '1px solid #e0e0e0',
                                borderRadius: 1,
                                p: 2,
                                bgcolor: '#f5f5f5'
                            }}>
                                <img 
                                    src={downloadBlockedImage} 
                                    alt="Icono de descarga bloqueada en el navegador" 
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                        borderRadius: '4px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                    }}
                                />
                            </Box>
                            <Typography variant="body1" paragraph>
                                • Haz clic en el icono y permite las descargas para este sitio
                            </Typography>
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                2. Intenta de nuevo
                            </Typography>
                            <Typography variant="body1" paragraph>
                                • Una vez permitidas las descargas, haz clic nuevamente en "Generar PDF"
                            </Typography>
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                3. ¿Sigues teniendo problemas?
                            </Typography>
                            <Typography variant="body1">
                                Si aún no puedes descargar el PDF, contáctanos en:
                            </Typography>
                            <Link
                                href="mailto:alvaro@fitailabs.com"
                                sx={{
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                            >
                                alvaro@fitailabs.com
                            </Link>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenHelpModal(false)} color="primary">
                        Entendido
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Añadir el nuevo modal para guardar plantilla */}
            <Dialog
                open={openSaveTemplateModal}
                onClose={() => setOpenSaveTemplateModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Guardar como plantilla
                    <IconButton
                        onClick={() => setOpenSaveTemplateModal(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2 }}>
                        <Typography variant="body1" gutterBottom>
                            Introduce un nombre para tu plantilla de {generationType === 'rutina' ? 'rutina' : 'dieta'}:
                        </Typography>
                        <TextField
                            fullWidth
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            placeholder={`Ej: ${generationType === 'rutina' ? 'Rutina de fuerza para principiantes' : 'Dieta vegetariana 2000kcal'}`}
                            sx={{ mt: 2 }}
                            autoFocus
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSaveTemplateModal(false)}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleSaveTemplate}
                        variant="contained"
                        disabled={!templateName.trim() || isGenerating}
                    >
                        {isGenerating ? 'Guardando...' : 'Guardar'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de Plantillas Guardadas */}
            <Dialog
                open={openTemplatesModal}
                onClose={() => setOpenTemplatesModal(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Plantillas Guardadas
                    <IconButton
                        onClick={() => setOpenTemplatesModal(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {loadingTemplates ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                Rutinas
                            </Typography>
                            <Grid container spacing={2}>
                                {templates.rutinas.map((template) => (
                                    <Grid item xs={12} sm={6} key={template.id}>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                {template.nombre}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Creada: {template.createdAt?.toDate().toLocaleDateString()}
                                            </Typography>
                                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    onClick={() => handleUseTemplate(template)}
                                                >
                                                    Usar Plantilla
                                                </Button>
                                                <IconButton
                                                    size="small"
                                                    color="error"
                                                    onClick={() => handleDeleteTemplate(template.id, 'rutina')}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                                Dietas
                            </Typography>
                            <Grid container spacing={2}>
                                {templates.dietas.map((template) => (
                                    <Grid item xs={12} sm={6} key={template.id}>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                {template.nombre}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Creada: {template.createdAt?.toDate().toLocaleDateString()}
                                            </Typography>
                                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    onClick={() => handleUseTemplate(template)}
                                                >
                                                    Usar Plantilla
                                                </Button>
                                                <IconButton
                                                    size="small"
                                                    color="error"
                                                    onClick={() => handleDeleteTemplate(template.id, 'dieta')}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

            {/* Modal de Selección de Cliente */}
            <Dialog
                open={openClientSelectModal}
                onClose={() => setOpenClientSelectModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Selecciona un Cliente
                    <IconButton
                        onClick={() => setOpenClientSelectModal(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2 }}>
                        <Typography variant="body1" gutterBottom>
                            Selecciona un cliente para usar esta plantilla:
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="client-select-label">Cliente</InputLabel>
                            <Select
                                labelId="client-select-label"
                                value={selectedClient}
                                label="Cliente"
                                onChange={(e) => setSelectedClient(e.target.value)}
                                required
                                disabled={isGenerating}
                                sx={{
                                    '& .MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }
                                }}
                            >
                                {clients.map((client) => (
                                    <MenuItem 
                                        key={client.id} 
                                        value={client.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            py: 1
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar 
                                                src={client.photo_url} 
                                                alt={`${client.nombre} ${client.apellidos}`}
                                                sx={{ 
                                                    width: 40, 
                                                    height: 40,
                                                    bgcolor: !client.photo_url ? 'primary.main' : 'transparent'
                                                }}
                                            >
                                                {!client.photo_url && `${client.nombre.charAt(0)}${client.apellidos.charAt(0)}`}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {`${client.nombre} ${client.apellidos}`}
                                                    {client.es_deportista === 'si' && (
                                                        <Chip
                                                            label={client.tipo_deporte === 'otro' ? client.otro_deporte : 
                                                                {
                                                                    'padel': 'Pádel',
                                                                    'natacion': 'Natación',
                                                                    'running': 'Running',
                                                                    'ciclismo': 'Ciclismo',
                                                                    'futbol': 'Fútbol',
                                                                    'baloncesto': 'Baloncesto',
                                                                    'tenis': 'Tenis'
                                                                }[client.tipo_deporte] || client.tipo_deporte}
                                                            size="small"
                                                            color="info"
                                                            sx={{ ml: 1 }}
                                                        />
                                                    )}
                                                </Box>
                                            }
                                            secondary={client.email}
                                            primaryTypographyProps={{
                                                fontWeight: 500
                                            }}
                                        />
                                        {client.plan === 'Pro' && (
                                            <Chip 
                                                label="Pro"
                                                size="small"
                                                sx={{
                                                    background: 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    ml: 'auto'
                                                }}
                                            />
                                        )}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenClientSelectModal(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => handleClientSelect(selectedClient)}
                        variant="contained"
                        disabled={isGenerating}
                    >
                        Seleccionar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de confirmación de cierre */}
            <Dialog
                open={openCloseConfirmation}
                onClose={() => setOpenCloseConfirmation(false)}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        p: 1
                    }
                }}
            >
                <DialogTitle sx={{ 
                    pb: 1,
                    fontSize: '1.25rem',
                    fontWeight: 500
                }}>
                    ¿Estás seguro?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                        Si cierras esta ventana perderás todo el contenido generado. ¿Deseas continuar?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3, gap: 2 }}>
                    <Button 
                        onClick={() => setOpenCloseConfirmation(false)}
                        variant="outlined"
                        sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            fontSize: '0.95rem',
                            backgroundColor: 'white',
                            color: '#FF5722',
                            border: '1px solid rgba(255, 87, 34, 0.5)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.15)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                border: '1px solid #FF5722',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.25)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)'
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => {
                            setOpenCloseConfirmation(false);
                            setOpenPreview(false);
                            setGeneratedContent(null);
                            setEditableContent('');
                            setEditPrompt('');
                        }}
                        variant="contained"
                        sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            fontSize: '0.95rem',
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)'
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Sí, cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de generación Pro */}
            <ProGenerationModal 
                open={selectedModel === 'pro' && openPreview}
                onClose={() => {
                    if (!isGenerating) {
                        setOpenCloseConfirmation(true);
                    }
                }}
                isGenerating={isGenerating}
                progress={progress}
                generationType={generationType}
                generatedContent={generatedContent}
                currentQuote={currentQuote}
                onGeneratePDF={handleGeneratePDF}
                onImplant={generationType === 'rutina' ? handleImplantRoutine : handleImplantDiet}
                onEdit={handlePromptEdit}
                isEditing={isEditing}
                editPrompt={editPrompt}
                setEditPrompt={setEditPrompt}
                editableContent={editableContent}
                setEditableContent={setEditableContent}
                isGeneralContent={isGeneralContent}
                onSaveTemplate={() => setOpenSaveTemplateModal(true)}
                preferTables={preferTables}
                setPreferTables={setPreferTables}
            />
        </Box>
    );
};

export default Generar; 