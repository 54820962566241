import React, { useState, useEffect } from 'react';
import { 
    Typography, Box, Paper, Grid, 
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Button, Container,
    Dialog, DialogTitle, DialogContent, ToggleButton, ToggleButtonGroup,
    IconButton, Tooltip
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import ViewListIcon from '@mui/icons-material/ViewList';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import classesBackground from '../../assets/images/clases-sf.png';
import CreateGroupClassForm from './CreateGroupClassForm';
import CreateIndividualClassForm from './CreateIndividualClassForm';
import { db, auth } from '../../config/firebaseConfig';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { startOfWeek, endOfWeek, format, addDays, isBefore, isAfter, getDay, parse } from 'date-fns';
import { es } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';
import IndividualClassSummaryModal from './IndividualClassSummaryModal';
import GroupClassSummaryModal from './GroupClassSummaryModal';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const difficultyLevels = [
    { value: 1, label: 'Principiante' },
    { value: 2, label: 'Intermedio' },
    { value: 3, label: 'Avanzado' },
    { value: 4, label: 'Experto' },
];

const diasSemana = [
    { value: 0, label: 'Domingo' },
    { value: 1, label: 'Lunes' },
    { value: 2, label: 'Martes' },
    { value: 3, label: 'Miércoles' },
    { value: 4, label: 'Jueves' },
    { value: 5, label: 'Viernes' },
    { value: 6, label: 'Sábado' },
];

// Función auxiliar para convertir cualquier formato de fecha a Date
const convertToDate = (dateValue) => {
    if (!dateValue) return null;
    
    // Si ya es un objeto Date
    if (dateValue instanceof Date) return dateValue;
    
    // Si es un Timestamp de Firestore
    if (dateValue instanceof Timestamp) return dateValue.toDate();
    
    // Si es un string, intentar convertirlo
    if (typeof dateValue === 'string') {
        const date = new Date(dateValue);
        return isNaN(date.getTime()) ? null : date;
    }
    
    // Si es un objeto con seconds (formato serializado de Timestamp)
    if (typeof dateValue === 'object' && dateValue.seconds !== undefined) {
        return new Date(dateValue.seconds * 1000 + (dateValue.nanoseconds || 0) / 1000000);
    }
    
    // Si es un número (timestamp en milisegundos)
    if (typeof dateValue === 'number') {
        return new Date(dateValue);
    }
    
    return null;
};

// Configuración del localizador para el calendario
const locales = {
    'es': es,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { locale: es }),
    getDay,
    locales,
});

const Classes = () => {
    const [groupClasses, setGroupClasses] = useState([]);
    const [individualClasses, setIndividualClasses] = useState([]);
    const [openCreateClassModal, setOpenCreateClassModal] = useState(false);
    const [classType, setClassType] = useState('group');
    const [openCreateIndividualClassModal, setOpenCreateIndividualClassModal] = useState(false);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [openSummaryModal, setOpenSummaryModal] = useState(false);
    const [selectedGroupClass, setSelectedGroupClass] = useState(null);
    const [openGroupSummaryModal, setOpenGroupSummaryModal] = useState(false);
    const [viewMode, setViewMode] = useState('list'); // 'list' o 'calendar'
    const [calendarFilters, setCalendarFilters] = useState({
        puntual: true,
        periodica: true,
        sesion_periodica: true,
        pendientes: true
    });

    useEffect(() => {
        fetchClasses();
    }, []);

    const fetchClasses = async () => {
        try {
            const user = auth.currentUser;
            if (!user) {
                console.error('No hay usuario autenticado');
                return;
            }

            console.log('Usuario autenticado:', user.uid);

            const userDetailsRef = doc(db, 'userDetails', user.uid);
            const userDetailsSnap = await getDoc(userDetailsRef);
            const userDetails = userDetailsSnap.data();

            console.log('Detalles del usuario:', userDetails);

            const today = new Date();
            today.setHours(0, 0, 0, 0); // Establecer a las 00:00:00 para comparaciones correctas
            console.log('Fecha actual para comparaciones:', today);

            // Obtener clases grupales
            const groupClassesRef = collection(db, 'ClasesProgramadas');
            let groupQuery;

            if (userDetails.tipo_profesional === 'propietario_centro' || userDetails.tipo_profesional === 'entrenador_centro') {
                groupQuery = query(
                    groupClassesRef,
                    where('IDBO', '==', userDetails.IDBO)
                );
            } else {
                groupQuery = query(
                    groupClassesRef,
                    where('IDPT', '==', userDetails.IDPT)
                );
            }

            console.log('Tipo de consulta para clases grupales:', userDetails.tipo_profesional);

            const groupSnapshot = await getDocs(groupQuery);

            console.log(`Clases grupales recuperadas: ${groupSnapshot.docs.length}`);

            const groupClassesData = [];

            // Para cada clase grupal, procesamos la clase principal y sus sesiones
            for (const doc of groupSnapshot.docs) {
                const classData = doc.data();
                console.log('Datos de clase grupal:', classData);

                try {
                    // Filtrar clases no periódicas cuya fecha ya ha pasado
                    if (
                        classData.configuracionTemporal?.tipo === 'puntual' &&
                        classData.configuracionTemporal?.fechaInicio
                    ) {
                        const fechaInicio = convertToDate(classData.configuracionTemporal.fechaInicio);
                        
                        console.log('Fecha de clase puntual:', fechaInicio, 'Es válida:', fechaInicio && !isNaN(fechaInicio?.getTime()));
                        
                        if (fechaInicio && !isNaN(fechaInicio.getTime()) && isBefore(fechaInicio, today)) {
                            console.log('Clase puntual descartada por fecha pasada');
                            continue;
                        }
                    }

                    // Si es una clase puntual, la procesamos directamente
                    if (classData.configuracionTemporal?.tipo === 'puntual') {
                        const fechaInicio = convertToDate(classData.configuracionTemporal.fechaInicio);
                        
                        if (!fechaInicio || isNaN(fechaInicio.getTime())) {
                            console.error('Fecha inválida para clase puntual:', classData.configuracionTemporal.fechaInicio);
                            continue;
                        }

                const newClassItem = {
                    id: doc.id,
                    nombreClase: classData.nombreClase || 'Sin nombre',
                            Fecha: fechaInicio,
                            Hora: fechaInicio,
                    Duracion: classData.configuracionTemporal?.duracionMinutos || 0,
                    CapacidadMaxima: classData.capacidadMaxima || 0,
                    Dificultad: difficultyLevels.find(level => level.value === classData.dificultad)?.label || classData.dificultad || 'N/A',
                    clientes: classData.alumnosInscritos || [],
                            Dia: '',
                            Tipo: 'puntual',
                            Periodicidad: '',
                            Categorias: classData.categorias || [],
                            Descripcion: classData.descripcion || '',
                            fecha_final: null,
                            clasePrincipalId: doc.id, // ID de la clase principal
                            esClasePrincipal: true
                        };

                        console.log('Clase puntual procesada:', newClassItem);
                        groupClassesData.push(newClassItem);
                    } 
                    // Si es una clase periódica, obtenemos sus sesiones
                    else if (classData.configuracionTemporal?.tipo === 'periodica') {
                        console.log('Procesando clase periódica:', classData.nombreClase);
                        
                        // Obtener las sesiones de esta clase periódica
                        const sesionesRef = collection(db, 'ClasesProgramadas', doc.id, 'sesiones');
                        const sesionesSnapshot = await getDocs(sesionesRef);
                        
                        console.log(`Sesiones recuperadas para clase ${doc.id}: ${sesionesSnapshot.docs.length}`);
                        
                        if (sesionesSnapshot.empty) {
                            console.log('No hay sesiones para esta clase periódica');
                        } else {
                            // Procesar cada sesión individual
                            for (const sesionDoc of sesionesSnapshot.docs) {
                                const sesionData = sesionDoc.data();
                                
                                console.log('Datos de sesión:', sesionDoc.id, JSON.stringify(sesionData));
                                
                                try {
                                    // Convertir fechaInicio a Date usando la función auxiliar
                                    const fechaInicio = convertToDate(sesionData.fechaInicio);
                                    
                                    console.log('Fecha de inicio de sesión procesada:', fechaInicio, 
                                        'Tipo original:', typeof sesionData.fechaInicio, 
                                        'Es Timestamp:', sesionData.fechaInicio instanceof Timestamp,
                                        'Es válida:', fechaInicio && !isNaN(fechaInicio?.getTime()));
                                    
                                    if (fechaInicio && !isNaN(fechaInicio.getTime())) {
                                        console.log('¿Es después de hoy?', isAfter(fechaInicio, today));
                                        
                                        if (isAfter(fechaInicio, today)) {
                                            const diaSemana = fechaInicio.getDay();
                                            const diaLabel = diasSemana.find(d => d.value === diaSemana)?.label || '';
                                            
                                            const sesionItem = {
                                                id: sesionDoc.id,
                                                nombreClase: classData.nombreClase || 'Sin nombre',
                                                Fecha: fechaInicio,
                                                Hora: fechaInicio,
                                                Duracion: classData.configuracionTemporal?.duracionMinutos || 0,
                                                CapacidadMaxima: classData.capacidadMaxima || 0,
                                                Dificultad: difficultyLevels.find(level => level.value === classData.dificultad)?.label || classData.dificultad || 'N/A',
                                                clientes: sesionData.alumnosInscritos || [],
                                                aforoActual: sesionData.aforoActual || 0,
                                                Dia: diaLabel,
                                                Tipo: 'sesion_periodica',
                    Periodicidad: classData.configuracionTemporal?.frecuencia || '',
                    Categorias: classData.categorias || [],
                    Descripcion: classData.descripcion || '',
                                                fecha_final: null,
                                                clasePrincipalId: doc.id, // ID de la clase principal
                                                esClasePrincipal: false,
                                                sesionId: sesionDoc.id
                                            };
                                            
                                            console.log('Sesión procesada y añadida:', sesionItem);
                                            groupClassesData.push(sesionItem);
                                        } else {
                                            console.log('Sesión descartada por fecha pasada');
                                        }
                                    } else {
                                        console.log('Sesión descartada por fecha inválida');
                                    }
                                } catch (error) {
                                    console.error('Error al procesar sesión:', error, sesionData);
                                }
                            }
                        }
                    }
            } catch (error) {
                console.error('Error al procesar clase grupal:', error, classData);
                }
            }

        console.log('Clases grupales procesadas:', groupClassesData);

        // Ordenar las clases grupales por fecha (de más cercana a más lejana)
        groupClassesData.sort((a, b) => {
            // Caso especial: si ambas son clases periódicas sin fecha específica
            if (a.Tipo === 'periodica' && b.Tipo === 'periodica') {
                // Ordenar por día de la semana si está disponible
                if (a.Dia && b.Dia) {
                    return a.Dia.localeCompare(b.Dia);
                }
                return 0;
            }
            
            // Si una es periódica sin fecha específica, ponerla al final
            if (a.Tipo === 'periodica' && !a.Fecha) return 1;
            if (b.Tipo === 'periodica' && !b.Fecha) return -1;
            
            // Si alguna de las fechas es inválida, ponerla al final
            if (!a.Fecha && !b.Fecha) return 0;
            if (!a.Fecha) return 1;
            if (!b.Fecha) return -1;
            
            // Ordenar por fecha ascendente (de más cercana a más lejana)
            return a.Fecha.getTime() - b.Fecha.getTime();
        });

        console.log('Clases grupales ordenadas por fecha:', groupClassesData);

        setGroupClasses(groupClassesData);
        console.log('Estado de clases grupales actualizado');

        // Obtener clases individuales y solicitudes
        const individualClassesRef = collection(db, 'Citas_cliente_entrenador');
        let individualQuery;

        if (userDetails.tipo_profesional === 'propietario_centro' || userDetails.tipo_profesional === 'entrenador_centro') {
            individualQuery = query(
                individualClassesRef,
                where('IDBO', '==', userDetails.IDBO)
            );
        } else {
            individualQuery = query(
                individualClassesRef,
                where('IDPT', '==', userDetails.IDPT)
            );
        }

        const individualSnapshot = await getDocs(individualQuery);

        const individualClassesData = [];
        const pendingRequestsData = [];

        individualSnapshot.forEach((doc) => {
            const classData = doc.data();
            try {
                    const classDate = convertToDate(classData.fecha_inicio);
                    const finalDate = convertToDate(classData.fecha_final);
                
                if (classDate && !isNaN(classDate.getTime()) && isAfter(classDate, today)) {
                    // Verificar si la cita está confirmada (por el nuevo campo estado o por el campo aceptada)
                    if (classData.estado === 'confirmada' || classData.aceptada) {
                        individualClassesData.push({
                            id: doc.id,
                            nombreClase: classData.nombreClase,
                            Fecha: classDate,
                            Hora: classDate,
                            Duracion: finalDate && !isNaN(finalDate.getTime()) 
                                ? (finalDate - classDate) / 60000 
                                : 60, // Duración predeterminada de 60 minutos si no hay fecha final válida
                            clientes: classData.clientes || [],
                            modalidad: classData.modalidad,
                            notas: classData.notas,
                            estado: classData.estado || (classData.aceptada ? 'confirmada' : 'pendiente')
                        });
                    // Verificar si la cita está pendiente (no confirmada ni cancelada)
                    } else if (classData.solicitada_por_cliente && 
                              (classData.estado === 'pendiente' || (!classData.estado && !classData.aceptada && !classData.denegada))) {
                        pendingRequestsData.push({
                            id: doc.id,
                            nombreClase: classData.nombreClase,
                            Fecha: classDate,
                            Hora: classDate,
                            Duracion: finalDate && !isNaN(finalDate.getTime()) 
                                ? (finalDate - classDate) / 60000 
                                : 60, // Duración predeterminada de 60 minutos si no hay fecha final válida
                            clientes: classData.clientes || [],
                            modalidad: classData.modalidad,
                            notas: classData.notas,
                            estado: classData.estado || 'pendiente'
                        });
                    }
                }
            } catch (error) {
                console.error('Error al procesar clase individual:', error, classData);
            }
        });

        // Ordenar las clases individuales por fecha (de más cercana a más lejana)
        individualClassesData.sort((a, b) => {
            if (!a.Fecha && !b.Fecha) return 0;
            if (!a.Fecha) return 1;
            if (!b.Fecha) return -1;
            return a.Fecha.getTime() - b.Fecha.getTime();
        });

        // Ordenar las solicitudes pendientes por fecha (de más cercana a más lejana)
        pendingRequestsData.sort((a, b) => {
            if (!a.Fecha && !b.Fecha) return 0;
            if (!a.Fecha) return 1;
            if (!b.Fecha) return -1;
            return a.Fecha.getTime() - b.Fecha.getTime();
        });

        setIndividualClasses(individualClassesData);
        setPendingRequests(pendingRequestsData);

    } catch (error) {
        console.error('Error al obtener las clases:', error);
    }
};

const handleCreateGroupClass = () => {
    setOpenCreateClassModal(true);
};

const handleCloseModal = () => {
    setOpenCreateClassModal(false);
};

const handleSubmitClass = (newClass) => {
    setGroupClasses([...groupClasses, newClass]);
    setOpenCreateClassModal(false);
    fetchClasses();
};

const handleScheduleIndividualClass = () => {
    setOpenCreateIndividualClassModal(true);
};

const handleCloseIndividualClassModal = () => {
    setOpenCreateIndividualClassModal(false);
};

const handleSubmitIndividualClass = async (newClass) => {
    try {
        // La clase ya se ha creado en Firestore en el formulario
        console.log("Clase individual creada:", newClass);
        setOpenCreateIndividualClassModal(false);
        fetchClasses(); // Actualiza la lista de clases
    } catch (error) {
        console.error('Error al procesar la clase individual:', error);
    }
};

const handleClassTypeChange = (event, newClassType) => {
    if (newClassType !== null) {
        setClassType(newClassType);
    }
};

const handleAcceptRequest = async (requestId) => {
    try {
        const requestRef = doc(db, 'Citas_cliente_entrenador', requestId);
        await updateDoc(requestRef, {
            estado: 'confirmada',
            aceptada: true // Mantenemos este campo por compatibilidad
        });
        fetchClasses();
    } catch (error) {
        console.error('Error al aceptar la solicitud:', error);
    }
};

const handleRejectRequest = async (requestId) => {
    try {
        const requestRef = doc(db, 'Citas_cliente_entrenador', requestId);
        await updateDoc(requestRef, {
            estado: 'cancelada',
            denegada: true // Mantenemos este campo por compatibilidad
        });
        fetchClasses();
    } catch (error) {
        console.error('Error al rechazar la solicitud:', error);
    }
};

const handleOpenSummaryModal = (classData) => {
    setSelectedClass(classData);
    setOpenSummaryModal(true);
};

const handleCloseSummaryModal = () => {
    setOpenSummaryModal(false);
    setSelectedClass(null);
};

const handleOpenGroupSummaryModal = (classData) => {
    setSelectedGroupClass(classData);
    setOpenGroupSummaryModal(true);
};

const handleCloseGroupSummaryModal = () => {
    setOpenGroupSummaryModal(false);
    setSelectedGroupClass(null);
};

// Convertir los datos de clases al formato esperado por el calendario
const getCalendarEvents = () => {
    const currentClasses = classType === 'group' ? groupClasses : individualClasses;
    
    return currentClasses.map(clase => {
        // Si es una clase periódica sin fecha específica, pero con día de la semana, generar eventos para las próximas semanas
        if (classType === 'group' && clase.Tipo === 'periodica' && !clase.Fecha && clase.DiaSemana) {
            const eventos = [];
            const today = new Date();
            const startDay = startOfWeek(today, { locale: es });
            
            // Generar eventos para las próximas 4 semanas
            for (let week = 0; week < 4; week++) {
                const targetDay = addDays(startDay, (clase.DiaSemana + week * 7));
                
                // Solo incluir fechas futuras
                if (isBefore(targetDay, today)) continue;
                
                // Configurar hora si está disponible
                if (clase.Hora) {
                    const [hours, minutes] = clase.Hora.split(':').map(num => parseInt(num, 10));
                    targetDay.setHours(hours || 0, minutes || 0, 0, 0);
                }
                
                const endDay = new Date(targetDay);
                endDay.setMinutes(endDay.getMinutes() + (clase.Duracion || 60));
                
                eventos.push({
                    id: `${clase.id}-week-${week}`,
                    title: `${clase.nombreClase || 'Clase periódica'} (${getDayName(clase.DiaSemana)})`,
                    start: targetDay,
                    end: endDay,
                    allDay: false,
                    resource: { ...clase, esSesionProyectada: true },
                    backgroundColor: '#FFA000'
                });
            }
            
            return eventos;
        }
        
        // Verificar que la fecha sea válida para clases puntuales y sesiones periódicas
        if (!clase.Fecha || !(clase.Fecha instanceof Date) || isNaN(clase.Fecha.getTime())) {
            return null;
        }

        // Calcular hora de fin
        const start = new Date(clase.Fecha);
        const end = new Date(clase.Fecha);
        end.setMinutes(end.getMinutes() + (clase.Duracion || 60));

        // Determinar el color según el tipo de clase
        let backgroundColor;
        let title = clase.nombreClase || 'Sin nombre';
        
        if (classType === 'group') {
            if (clase.Tipo === 'puntual') {
                backgroundColor = '#FF5722'; // Color para clases puntuales
            } else if (clase.Tipo === 'sesion_periodica') {
                backgroundColor = '#FFA000'; // Color para sesiones de clases periódicas
                // Añadir información adicional al título si es una sesión
                if (clase.DiaSemana !== undefined) {
                    title += ` (${getDayName(clase.DiaSemana)})`;
                }
            } else {
                backgroundColor = '#FFA000'; // Color para otras clases periódicas
            }
        } else {
            if (clase.estado === 'pendiente') {
                backgroundColor = '#FFB74D'; // Color para clases pendientes
                title = `${title} (Pendiente)`;
            } else {
                backgroundColor = '#4CAF50'; // Color para clases individuales confirmadas
            }
        }

        return {
            id: clase.id,
            title: title,
            start: start,
            end: end,
            allDay: false,
            resource: clase,
            backgroundColor: backgroundColor
        };
    }).flat().filter(Boolean); // Aplanar y filtrar eventos nulos
};

// Función para obtener el nombre del día de la semana
const getDayName = (dayNumber) => {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return days[dayNumber % 7];
};

// Manejar clic en un evento del calendario
const handleCalendarEventClick = (event) => {
    if (classType === 'individual') {
        setSelectedClass(event.resource);
        setOpenSummaryModal(true);
    } else {
        setSelectedGroupClass(event.resource);
        setOpenGroupSummaryModal(true);
    }
};

// Componentes personalizados para el calendario
const calendarComponents = {
    event: (props) => {
        const { event } = props;
        return (
            <Tooltip 
                title={
                    <React.Fragment>
                        <Typography variant="subtitle2">{event.title}</Typography>
                        <Typography variant="body2">
                            {`Hora: ${format(event.start, 'HH:mm', { locale: es })} - ${format(event.end, 'HH:mm', { locale: es })}`}
                        </Typography>
                        {event.resource.Tipo && (
                            <Typography variant="body2">
                                {`Tipo: ${event.resource.Tipo === 'puntual' ? 'Puntual' : 
                                  event.resource.Tipo === 'periodica' ? 'Periódica' : 
                                  event.resource.Tipo === 'sesion_periodica' ? 'Sesión' : 'Otro'}`}
                            </Typography>
                        )}
                        {classType === 'group' && (
                            <Typography variant="body2">
                                {`Capacidad: ${event.resource.aforoActual || event.resource.clientes?.length || 0}/${event.resource.CapacidadMaxima || 0}`}
                            </Typography>
                        )}
                    </React.Fragment>
                }
                arrow
                placement="top"
            >
                <div
                    style={{
                        backgroundColor: event.backgroundColor,
                        color: 'white',
                        padding: '2px 5px',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer',
                        border: event.resource.esSesionProyectada ? '1px dashed white' : 'none'
                    }}
                >
                    {event.title}
                </div>
            </Tooltip>
        );
    }
};

const handleToggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'list' ? 'calendar' : 'list');
};

// Filtrar eventos para el calendario
const getFilteredCalendarEvents = () => {
    const allEvents = getCalendarEvents();
    
    return allEvents.filter(event => {
        if (classType === 'group') {
            // Filtrar clases grupales
            if (event.resource.Tipo === 'puntual' && !calendarFilters.puntual) {
                return false;
            }
            if (event.resource.Tipo === 'periodica' && !calendarFilters.periodica) {
                return false;
            }
            if (event.resource.Tipo === 'sesion_periodica' && !calendarFilters.sesion_periodica) {
                return false;
            }
        } else {
            // Filtrar clases individuales
            if (event.resource.estado === 'pendiente' && !calendarFilters.pendientes) {
                return false;
            }
        }
        
        return true;
    });
};

// Manejar cambios en los filtros
const handleFilterChange = (filterName) => {
    setCalendarFilters(prev => ({
        ...prev,
        [filterName]: !prev[filterName]
    }));
};

return (
    <Box 
        sx={{ 
            flexGrow: 1, 
            p: 3,
            position: 'relative',
            minHeight: 'calc(100vh - 64px)',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${classesBackground})`,
                backgroundSize: '50%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                opacity: 0.1,
                zIndex: -1,
            }
        }}
    >
        <Box sx={{
            backgroundColor: 'rgba(255, 87, 34, 0.1)',
            color: '#FF5722',
            padding: '16px',
            borderRadius: '8px',
            textAlign: 'center',
            mb: 4
        }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Página en Construcción
            </Typography>
            <Typography variant="body1">
                Estamos trabajando para terminar la funcionalidad de esta página. ¡Gracias por tu paciencia!
            </Typography>
        </Box>
        <Box sx={{ marginBottom: '120px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Clases y Reservas
                </Typography>
                <Tooltip title={viewMode === 'list' ? 'Ver como calendario' : 'Ver como lista'}>
                    <IconButton 
                        onClick={handleToggleViewMode} 
                        sx={{
                            backgroundColor: '#FF5722',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#E64A19',
                            }
                        }}
                    >
                        {viewMode === 'list' ? <CalendarMonthIcon /> : <ViewListIcon />}
                    </IconButton>
                </Tooltip>
            </Box>
            <Typography variant="subtitle1" gutterBottom sx={{ mb: 4 }}>
                Gestiona tus clases grupales e individuales
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <ToggleButtonGroup
                    value={classType}
                    exclusive
                    onChange={handleClassTypeChange}
                    aria-label="class type"
                >
                    <ToggleButton value="group" aria-label="group classes">
                        <GroupIcon sx={{ mr: 1 }} /> Clases Grupales
                    </ToggleButton>
                    <ToggleButton value="individual" aria-label="individual classes">
                        <PersonIcon sx={{ mr: 1 }} /> Clases Individuales
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {viewMode === 'list' ? (
                <Paper elevation={3} sx={{ p: 3, borderRadius: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        {classType === 'group' ? 'Clases Grupales' : 'Clases Individuales'}
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Hora</TableCell>
                                    <TableCell>Duración</TableCell>
                                    {classType === 'group' ? (
                                        <>
                                            <TableCell>Capacidad</TableCell>
                                            <TableCell>Dificultad</TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>Cliente</TableCell>
                                            <TableCell>Modalidad</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(classType === 'group' ? groupClasses : individualClasses).map((clase, index) => {
                                    // Verificación de seguridad para evitar errores en el render
                                    if (!clase || !clase.id) {
                                        console.error('Clase inválida encontrada en posición', index, clase);
                                        return null;
                                    }
                                    
                                    // Crear una versión segura de la fecha y hora para el renderizado
                                    let fechaDisplay = 'N/A';
                                    let horaDisplay = 'N/A';
                                    
                                    try {
                                        if (clase.Tipo === 'periodica' && clase.Dia) {
                                            fechaDisplay = clase.Dia;
                                            } else if (clase.Tipo === 'sesion_periodica') {
                                                // Para sesiones de clases periódicas, mostrar la fecha específica
                                                if (clase.Fecha && clase.Fecha instanceof Date && !isNaN(clase.Fecha.getTime())) {
                                                    fechaDisplay = format(clase.Fecha, 'dd/MM/yyyy', { locale: es });
                                                    if (clase.Dia) {
                                                        fechaDisplay += ` (${clase.Dia})`;
                                                    }
                                                }
                                        } else if (clase.Fecha && clase.Fecha instanceof Date && !isNaN(clase.Fecha.getTime())) {
                                            fechaDisplay = format(clase.Fecha, 'dd/MM/yyyy', { locale: es });
                                        }
                                        
                                        if (clase.Hora && clase.Hora instanceof Date && !isNaN(clase.Hora.getTime())) {
                                            horaDisplay = format(clase.Hora, 'HH:mm');
                                        }
                                    } catch (error) {
                                        console.error('Error al formatear fecha/hora:', error, clase);
                                    }
                                    
                                    return (
                                        <TableRow 
                                            key={clase.id} 
                                            onClick={() => classType === 'individual' ? handleOpenSummaryModal(clase) : handleOpenGroupSummaryModal(clase)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell>{clase.nombreClase || 'Sin nombre'}</TableCell>
                                            <TableCell>{fechaDisplay}</TableCell>
                                            <TableCell>{horaDisplay}</TableCell>
                                            <TableCell>{clase.Duracion || 0} min</TableCell>
                                            {classType === 'group' ? (
                                                <>
                                                    <TableCell>{
                                                        clase.Tipo === 'sesion_periodica' 
                                                            ? `${clase.aforoActual || 0}/${clase.CapacidadMaxima || 0}`
                                                            : `${clase.clientes ? clase.clientes.length : 0}/${clase.CapacidadMaxima || 0}`
                                                    }</TableCell>
                                                    <TableCell>{clase.Dificultad || 'N/A'}</TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell>{clase.clientes && clase.clientes[0]?.nombre || 'N/A'}</TableCell>
                                                    <TableCell>{clase.modalidad || 'N/A'}</TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <Paper elevation={3} sx={{ p: 3, borderRadius: 4, minHeight: '600px' }}>
                    <Typography variant="h6" gutterBottom>
                        Calendario de {classType === 'group' ? 'Clases Grupales' : 'Clases Individuales'}
                    </Typography>
                    
                    {/* Filtros del calendario */}
                    <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
                        <Typography variant="body2" sx={{ my: 'auto' }}>
                            Filtros:
                        </Typography>
                        
                        {classType === 'group' ? (
                            <>
                                <Button 
                                    variant={calendarFilters.puntual ? "contained" : "outlined"}
                                    size="small"
                                    onClick={() => handleFilterChange('puntual')}
                                    sx={{ 
                                        backgroundColor: calendarFilters.puntual ? '#FF5722' : 'transparent',
                                        borderColor: '#FF5722',
                                        color: calendarFilters.puntual ? 'white' : '#FF5722',
                                        '&:hover': {
                                            backgroundColor: calendarFilters.puntual ? '#E64A19' : 'rgba(255, 87, 34, 0.1)',
                                        }
                                    }}
                                >
                                    Clases Puntuales
                                </Button>
                                <Button 
                                    variant={calendarFilters.periodica ? "contained" : "outlined"}
                                    size="small"
                                    onClick={() => handleFilterChange('periodica')}
                                    sx={{ 
                                        backgroundColor: calendarFilters.periodica ? '#FFA000' : 'transparent',
                                        borderColor: '#FFA000',
                                        color: calendarFilters.periodica ? 'white' : '#FFA000',
                                        '&:hover': {
                                            backgroundColor: calendarFilters.periodica ? '#FF8F00' : 'rgba(255, 160, 0, 0.1)',
                                        }
                                    }}
                                >
                                    Clases Periódicas
                                </Button>
                                <Button 
                                    variant={calendarFilters.sesion_periodica ? "contained" : "outlined"}
                                    size="small"
                                    onClick={() => handleFilterChange('sesion_periodica')}
                                    sx={{ 
                                        backgroundColor: calendarFilters.sesion_periodica ? '#FFA000' : 'transparent',
                                        borderColor: '#FFA000',
                                        color: calendarFilters.sesion_periodica ? 'white' : '#FFA000',
                                        '&:hover': {
                                            backgroundColor: calendarFilters.sesion_periodica ? '#FF8F00' : 'rgba(255, 160, 0, 0.1)',
                                        }
                                    }}
                                >
                                    Sesiones
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button 
                                    variant={calendarFilters.pendientes ? "contained" : "outlined"}
                                    size="small"
                                    onClick={() => handleFilterChange('pendientes')}
                                    sx={{ 
                                        backgroundColor: calendarFilters.pendientes ? '#FFB74D' : 'transparent',
                                        borderColor: '#FFB74D',
                                        color: calendarFilters.pendientes ? 'white' : '#FFB74D',
                                        '&:hover': {
                                            backgroundColor: calendarFilters.pendientes ? '#FFA726' : 'rgba(255, 183, 77, 0.1)',
                                        }
                                    }}
                                >
                                    Pendientes
                                </Button>
                            </>
                        )}
                    </Box>
                    
                    {/* Leyenda */}
                    <Box sx={{ display: 'flex', gap: 3, mb: 2, flexWrap: 'wrap' }}>
                        <Typography variant="body2" sx={{ my: 'auto' }}>
                            Leyenda:
                        </Typography>
                        {classType === 'group' ? (
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 16, height: 16, backgroundColor: '#FF5722', borderRadius: '50%', mr: 1 }}></Box>
                                    <Typography variant="body2">Clases Puntuales</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 16, height: 16, backgroundColor: '#FFA000', borderRadius: '50%', mr: 1 }}></Box>
                                    <Typography variant="body2">Clases Periódicas</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 16, height: 16, backgroundColor: '#FFA000', border: '1px dashed white', borderRadius: '50%', mr: 1 }}></Box>
                                    <Typography variant="body2">Sesiones Proyectadas</Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 16, height: 16, backgroundColor: '#4CAF50', borderRadius: '50%', mr: 1 }}></Box>
                                    <Typography variant="body2">Clases Confirmadas</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 16, height: 16, backgroundColor: '#FFB74D', borderRadius: '50%', mr: 1 }}></Box>
                                    <Typography variant="body2">Clases Pendientes</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                    
                    <Box sx={{ height: '600px', mt: 2 }}>
                        <Calendar
                            localizer={localizer}
                            events={getFilteredCalendarEvents()}
                            startAccessor="start"
                            endAccessor="end"
                            onSelectEvent={handleCalendarEventClick}
                            style={{ height: '100%' }}
                            views={['month', 'week', 'day']}
                            messages={{
                                today: 'Hoy',
                                previous: 'Anterior',
                                next: 'Siguiente',
                                month: 'Mes',
                                week: 'Semana',
                                day: 'Día',
                                agenda: 'Agenda',
                                date: 'Fecha',
                                time: 'Hora',
                                event: 'Evento',
                                noEventsInRange: 'No hay clases en este período'
                            }}
                            components={calendarComponents}
                            culture='es'
                            defaultView='week'
                            popup={true}
                            selectable={true}
                            onSelectSlot={(slotInfo) => {
                                console.log('Slot seleccionado:', slotInfo);
                                // Opcional: Puedes agregar la lógica para crear una clase en esta fecha/hora
                            }}
                        />
                    </Box>
                </Paper>
            )}

            <Container maxWidth="lg" sx={{ width: '100%' }}>
                <Box sx={{ 
                    mt: 4, 
                    display: 'flex', 
                    justifyContent: 'center',
                    gap: 2,
                }}>
                    <Button 
                        variant="contained" 
                        startIcon={<GroupIcon />}
                        onClick={handleCreateGroupClass}
                        sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            px: 3,
                            py: 1.5,
                            fontSize: '1rem',
                            fontWeight: 500,
                            background: 'linear-gradient(45deg, #FF5722, #FF1493)',
                            boxShadow: '0 2px 8px rgba(255, 87, 34, 0.25)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF1493, #FF5722)',
                                boxShadow: '0 4px 12px rgba(255, 87, 34, 0.35)',
                                transform: 'translateY(-1px)'
                            },
                            '&:active': {
                                transform: 'translateY(0)',
                            },
                            '&.Mui-disabled': {
                                background: 'rgba(0, 0, 0, 0.12)',
                                boxShadow: 'none'
                            },
                            transition: 'all 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2
                        }}
                    >
                        Crear Clase Grupal
                    </Button>
                    <Button 
                        variant="outlined" 
                        startIcon={<PersonIcon />}
                        onClick={handleScheduleIndividualClass}
                        sx={{
                            color: '#FF5722',
                            borderColor: '#FF5722',
                            padding: '10px 20px',
                            borderRadius: '25px',
                            textTransform: 'none',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                borderColor: '#E64A19',
                                boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                                transform: 'translateY(-2px)',
                            }
                        }}
                    >
                        Programar Clase Individual
                    </Button>
                </Box>
            </Container>

            {classType === 'individual' && viewMode === 'list' && (
                <Paper elevation={3} sx={{ p: 3, borderRadius: 4, mt: 4, mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Solicitudes Pendientes de Clases Individuales
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Hora</TableCell>
                                    <TableCell>Duración</TableCell>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Modalidad</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pendingRequests.map((request, index) => {
                                    // Verificación de seguridad para evitar errores en el render
                                    if (!request || !request.id) {
                                        console.error('Solicitud inválida encontrada en posición', index, request);
                                        return null;
                                    }
                                    
                                    // Crear una versión segura de la fecha y hora para el renderizado
                                    let fechaDisplay = 'N/A';
                                    let horaDisplay = 'N/A';
                                    
                                    try {
                                        if (request.Fecha && request.Fecha instanceof Date && !isNaN(request.Fecha.getTime())) {
                                            fechaDisplay = format(request.Fecha, 'dd/MM/yyyy', { locale: es });
                                        }
                                        
                                        if (request.Hora && request.Hora instanceof Date && !isNaN(request.Hora.getTime())) {
                                            horaDisplay = format(request.Hora, 'HH:mm');
                                        }
                                    } catch (error) {
                                        console.error('Error al formatear fecha/hora en solicitud:', error, request);
                                    }
                                    
                                    return (
                                        <TableRow key={request.id}>
                                            <TableCell>{request.nombreClase || 'Sin nombre'}</TableCell>
                                            <TableCell>{fechaDisplay}</TableCell>
                                            <TableCell>{horaDisplay}</TableCell>
                                            <TableCell>{request.Duracion || 0} min</TableCell>
                                            <TableCell>{request.clientes && request.clientes[0]?.nombre || 'N/A'}</TableCell>
                                            <TableCell>{request.modalidad || 'N/A'}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Button 
                                                        onClick={() => handleAcceptRequest(request.id)} 
                                                        variant="contained"
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#4CAF50',
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: '#45a049',
                                                            },
                                                        }}
                                                    >
                                                        Aceptar
                                                    </Button>
                                                    <Button 
                                                        onClick={() => handleRejectRequest(request.id)} 
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            borderColor: '#FF5722',
                                                            color: '#FF5722',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(255, 87, 34, 0.04)',
                                                                borderColor: '#E64A19',
                                                            },
                                                        }}
                                                    >
                                                        Rechazar
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}

            <Dialog 
                open={openCreateClassModal} 
                onClose={handleCloseModal} 
                maxWidth="md" 
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '20px',
                        padding: '20px',
                        backgroundColor: '#FFFFFF',
                    },
                }}
            >
                <DialogTitle sx={{ color: '#333', fontWeight: 'normal' }}>Crear Clase Grupal</DialogTitle>
                <DialogContent>
                    <CreateGroupClassForm onSubmit={handleSubmitClass} onCancel={handleCloseModal} />
                </DialogContent>
            </Dialog>

            <Dialog 
                open={openCreateIndividualClassModal} 
                onClose={handleCloseIndividualClassModal} 
                maxWidth="md" 
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: '20px',
                        padding: '20px',
                        backgroundColor: '#FFFFFF',
                    },
                }}
            >
                <DialogTitle sx={{ color: '#333', fontWeight: 'normal' }}>Crear Clase Individual</DialogTitle>
                <DialogContent>
                    <CreateIndividualClassForm onSubmit={handleSubmitIndividualClass} onCancel={handleCloseIndividualClassModal} />
                </DialogContent>
            </Dialog>

            <IndividualClassSummaryModal
                open={openSummaryModal}
                onClose={handleCloseSummaryModal}
                classData={selectedClass}
            />

            <GroupClassSummaryModal
                open={openGroupSummaryModal}
                onClose={handleCloseGroupSummaryModal}
                classData={selectedGroupClass}
            />
        </Box>
    </Box>
);
};

export default Classes;
