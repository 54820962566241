import React, { useState, useContext, useRef, useEffect } from 'react';
import { 
    Box, 
    Paper, 
    Typography, 
    Grid, 
    Button, 
    TextField, 
    Divider, 
    Avatar, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    List, 
    ListItem, 
    ListItemAvatar, 
    ListItemText,
    IconButton,
    ListItemIcon,
    Switch,
    FormControlLabel,
    FormGroup
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import CampaignIcon from '@mui/icons-material/Campaign';
import StarIcon from '@mui/icons-material/Star';
import { AuthContext } from '../../contexts/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from '../../config/firebaseConfig';
import { FiSettings, FiUserPlus, FiStar, FiCpu } from 'react-icons/fi';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import IA from './IA';
import CloseIcon from '@mui/icons-material/Close';
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";

const InfoWidget = ({ title, value, icon }) => (
    <Paper 
        elevation={2} 
        sx={{ 
            p: 2.5, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: 3,
            }
        }}
    >
        <Box>
            <Typography variant="subtitle1" component="div" color="text.secondary">
                {title}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mt: 1, fontWeight: 'bold' }}>
                {value}
            </Typography>
        </Box>
        <Box sx={{ color: 'primary.main', opacity: 0.7 }}>
            {icon}
        </Box>
    </Paper>
);

// Función auxiliar para traducir el tipo de profesional
const translateProfessionalType = (type) => {
    switch(type) {
        case 'entrenador_independiente':
            return 'Profesional Particular';
        case 'entrenador_centro':
            return 'Entrenador de Centro';
        case 'propietario_centro':
            return 'Propietario de Centro';
        default:
            return 'Profesional';
    }
};

const Settings = () => {
    const storage = getStorage();
    const { userDetails, updateUserDetails } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailChangePassword, setEmailChangePassword] = useState('');
    const fileInputRef = useRef(null);

    const [specialty, setSpecialty] = useState(userDetails?.especialidad || '');
    const [description, setDescription] = useState(userDetails?.descripcionProfesional || '');
    const [funFacts, setFunFacts] = useState(userDetails?.datosInteresantes || '');
    const [displayName, setDisplayName] = useState(userDetails?.display_name || '');

    const [centroNombre, setCentroNombre] = useState('');
    const [centroDescripcion, setCentroDescripcion] = useState(userDetails?.centroDescripcion || '');

    const [isLoading, setIsLoading] = useState(false);
    const userPhotoInputRef = useRef(null);
    const centroPhotoInputRef = useRef(null);

    const [centroPictureUrl, setCentroPictureUrl] = useState(userDetails?.centro_photo_url || '');
    const [businessLogoUrl, setBusinessLogoUrl] = useState(userDetails?.business_logo_url || '');
    const [centroEmail, setCentroEmail] = useState('');
    const [clientCount, setClientCount] = useState(0);
    const [profesionalesAdscritos, setProfesionalesAdscritos] = useState([]);
    const [profileImage, setProfileImage] = useState(null);

    const location = useLocation();

    const [isDashboardSubscribed, setIsDashboardSubscribed] = useState(false);
    const [openIASettings, setOpenIASettings] = useState(false);
    const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);

    const [trabajadores, setTrabajadores] = useState([]);

    const [primaryColor, setPrimaryColor] = useState('#FF5722');
    const [secondaryColor, setSecondaryColor] = useState('#FFA000');
    const [useCustomColors, setUseCustomColors] = useState(false);
    const [showBusinessLogo, setShowBusinessLogo] = useState(true);
    
    // Nuevo estado para el plan actual
    const [currentPlan, setCurrentPlan] = useState('starter');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleEmailChangePasswordChange = (e) => {
        setEmailChangePassword(e.target.value);
    };

    const handleSpecialtyChange = (e) => {
        setSpecialty(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleFunFactsChange = (e) => {
        setFunFacts(e.target.value);
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        // Aquí iría la lógica para cambiar el email
        console.log('Cambiar email a:', email, 'con contraseña:', emailChangePassword);
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert('Las contraseñas nuevas no coinciden');
            return;
        }

        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                alert('No hay usuario autenticado');
                return;
            }

            // Reautenticar al usuario
            const credential = EmailAuthProvider.credential(
                user.email,
                currentPassword
            );

            await reauthenticateWithCredential(user, credential);

            // Actualizar la contraseña
            await updatePassword(user, newPassword);

            // Limpiar los campos
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');

            alert('Contraseña actualizada con éxito');
        } catch (error) {
            console.error("Error al actualizar la contraseña:", error);
            if (error.code === 'auth/wrong-password') {
                alert('La contraseña actual es incorrecta');
            } else if (error.code === 'auth/weak-password') {
                alert('La nueva contraseña es demasiado débil. Debe tener al menos 6 caracteres');
            } else {
                alert('Error al actualizar la contraseña: ' + error.message);
            }
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        const sessionId = queryParams.get('session_id');

        if (status === 'success') {
            // La suscripción fue exitosa
            console.log('Suscripción exitosa, session ID:', sessionId);
            // Aquí puedes actualizar el estado de la UI o hacer una llamada a tu backend
            // para verificar y actualizar el estado de la suscripción
        } else if (status === 'canceled') {
            // El usuario canceló la suscripción
            console.log('Suscripción cancelada');
        }
    }, [location]);

    useEffect(() => {
        const fetchClientCount = async () => {
            if (!userDetails) return;

            try {
                let count = 0;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    const userRef = doc(db, 'userDetails', userDetails.uid);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = userSnap.data();
                        count = userData.clientes?.length || 0;
                    }
                } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional)) {
                    if (userDetails.IDBO) {
                        const centroRef = doc(db, 'Centros', userDetails.IDBO);
                        const centroSnap = await getDoc(centroRef);
                        if (centroSnap.exists()) {
                            const centroData = centroSnap.data();
                            count = centroData.clientes?.length || 0;
                        }
                    }
                }
                setClientCount(count);
            } catch (error) {
                console.error("Error al obtener el número de clientes:", error);
            }
        };

        fetchClientCount();
    }, [userDetails]);

    useEffect(() => {
        const fetchCentroData = async () => {
            if (userDetails?.IDBO && userDetails?.tipo_profesional === 'propietario_centro') {
                try {
                    const centroRef = doc(db, 'Centros', userDetails.IDBO);
                    const centroSnap = await getDoc(centroRef);
                    if (centroSnap.exists()) {
                        const centroData = centroSnap.data();
                        setCentroPictureUrl(centroData.photo_url || '');
                        setBusinessLogoUrl(centroData.business_logo_url || '');
                        setCentroNombre(centroData.nombreCentro || '');
                        setCentroDescripcion(centroData.descripcion || '');
                        setCentroEmail(centroData.email || '');
                        setProfesionalesAdscritos(centroData.profesionales || []);
                        setTrabajadores(centroData.trabajadores || []);
                    }
                } catch (error) {
                    console.error("Error al obtener datos del centro:", error);
                }
            }
        };

        fetchCentroData();
    }, [userDetails?.IDBO, userDetails?.tipo_profesional]);

    useEffect(() => {
        // Actualiza el estado de suscripción basado en dashboardAccess
        if (userDetails) {
            setIsDashboardSubscribed(userDetails.dashboardAccess === true);
            // Actualizar el plan actual basado en los datos de Firestore
            setCurrentPlan(userDetails.plan || 'starter');
        }
    }, [userDetails]);

    const handleDashboardSubscription = async () => {
        setIsLoading(true);
        try {
            const functions = getFunctions();
            const createCustomerPortalSession = httpsCallable(functions, 'createCustomerPortalSession');
            
            const result = await createCustomerPortalSession({
                returnUrl: window.location.origin
            });

            // Redirige al usuario al portal de cliente de Stripe
            if (result.data && result.data.url) {
                window.location.href = result.data.url;
            } else {
                throw new Error('No se recibió una URL válida del portal');
            }
        } catch (error) {
            console.error('Error al crear la sesión del portal de cliente:', error);
            alert('Hubo un error al procesar tu solicitud. Por favor, intenta de nuevo.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSubscriptionDialog = () => {
        setOpenSubscriptionDialog(false);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                if (!userDetails || !userDetails.uid) {
                    throw new Error('Detalles de usuario no disponibles. Por favor, inicia sesión de nuevo.');
                }

                const storage = getStorage();
                const storageRef = ref(storage, `pt_profile_pictures/${userDetails.uid}`);
                
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
                
                await updateDoc(doc(db, 'userDetails', userDetails.uid), {
                    photo_url: downloadURL
                });
                
                setProfileImage(downloadURL);
                
                // Actualizar el contexto de autenticación
                if (updateUserDetails) {
                    updateUserDetails({ ...userDetails, photo_url: downloadURL });
                }

                alert('Imagen de perfil actualizada con éxito');
            } catch (error) {
                console.error("Error detallado al subir la imagen:", error);
                alert(`Error al subir la imagen: ${error.message}`);
            }
        }
    };

    const handleAboutYouSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!userDetails || !userDetails.uid) {
                throw new Error('Detalles de usuario no disponibles. Por favor, inicia sesión de nuevo.');
            }

            const userRef = doc(db, 'userDetails', userDetails.uid);
            await updateDoc(userRef, {
                especialidad: specialty,
                descripcionProfesional: description,
                datosInteresantes: funFacts,
                display_name: displayName
            });

            // Actualizar el contexto de autenticación si es necesario
            if (updateUserDetails) {
                updateUserDetails({
                    ...userDetails,
                    especialidad: specialty,
                    descripcionProfesional: description,
                    datosInteresantes: funFacts,
                    display_name: displayName
                });
            }

            alert('Información actualizada con éxito');
        } catch (error) {
            console.error("Error al actualizar la información:", error);
            alert(`Error al actualizar la información: ${error.message}`);
        }
    };

    const handleCentroPictureChange = async (e) => {
        // Implementar lógica similar a handleImageChange pero para la imagen del centro
    };

    const handleCentroSubmit = async (e) => {
        e.preventDefault();
        if (userDetails?.IDBO) {
            try {
                const centroRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centroRef, { 
                    nombreCentro: centroNombre,
                    descripcion: centroDescripcion,
                    email: centroEmail
                });
                alert('Información del centro actualizada con éxito');
            } catch (error) {
                console.error("Error al actualizar la información del centro:", error);
                alert(`Error al actualizar la información del centro: ${error.message}`);
            }
        }
    };

    const handleUserPhotoChange = async (event) => {
        const file = event.target.files[0];
        if (file && userDetails.uid) {
            try {
                const storage = getStorage();
                const storageRef = ref(storage, `pt_profile_pictures/${userDetails.uid}`);
                
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
                
                // Actualizar Firestore para el usuario
                const userRef = doc(db, 'userDetails', userDetails.uid);
                await updateDoc(userRef, { photo_url: downloadURL });
                
                // Actualizar el contexto de autenticación
                if (updateUserDetails) {
                    updateUserDetails({ ...userDetails, photo_url: downloadURL });
                }

                alert('Imagen de perfil actualizada con éxito');
            } catch (error) {
                console.error("Error detallado al subir la imagen de perfil:", error);
                alert(`Error al subir la imagen de perfil: ${error.message}`);
            }
        }
    };

    const handleCentroPhotoChange = async (event) => {
        const file = event.target.files[0];
        if (file && userDetails.IDBO) {
            try {
                const storage = getStorage();
                const storageRef = ref(storage, `centro_profile_pictures/${userDetails.IDBO}`);
                
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
                
                // Actualizar Firestore para el centro
                const centroRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centroRef, { photo_url: downloadURL });
                
                // Actualizar el estado local
                setCentroPictureUrl(downloadURL);

                alert('Imagen del centro actualizada con éxito');
            } catch (error) {
                console.error("Error detallado al subir la imagen del centro:", error);
                alert(`Error al subir la imagen del centro: ${error.message}`);
            }
        }
    };

    const handleBusinessLogoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const storage = getStorage();
                // Determinar si es un entrenador independiente o un centro
                const storagePath = userDetails?.tipo_profesional === 'entrenador_independiente'
                    ? `business_logos/trainers/${userDetails.uid}`
                    : `business_logos/centers/${userDetails.IDBO}`;
                
                const storageRef = ref(storage, storagePath);
                
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
                
                // Actualizar Firestore según el tipo de profesional
                if (userDetails?.tipo_profesional === 'entrenador_independiente') {
                    const userRef = doc(db, 'userDetails', userDetails.uid);
                    await updateDoc(userRef, { business_logo_url: downloadURL });
                    if (updateUserDetails) {
                        updateUserDetails({ ...userDetails, business_logo_url: downloadURL });
                    }
                } else if (userDetails?.IDBO) {
                    const centroRef = doc(db, 'Centros', userDetails.IDBO);
                    await updateDoc(centroRef, { business_logo_url: downloadURL });
                }
                
                setBusinessLogoUrl(downloadURL);
                alert('Logo actualizado con éxito');
            } catch (error) {
                console.error("Error al subir el logo:", error);
                alert(`Error al subir el logo: ${error.message}`);
            }
        }
    };

    const textFieldSx = {
        '& .MuiOutlinedInput-root': {
            borderRadius: 4, // Aumenta este valor para más curvatura
        }
    };

    const planCardStyle = {
        p: 3,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: 'linear-gradient(90deg, #FF5722, #FFA000)',
        },
    };

    const buttonStyle = {
        mt: 2,
        backgroundColor: '#f5f5f5',
        color: '#333',
        borderRadius: '8px',
        padding: '8px 16px',
        fontSize: '0.9rem',
        textTransform: 'none',
        boxShadow: 'none',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s ease',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(120deg, transparent, rgba(255, 87, 34, 0.2), transparent)',
            transform: 'translateX(-100%)',
            transition: 'all 0.6s ease',
        },
        '&:hover': {
            backgroundColor: '#e0e0e0',
            color: '#FF5722',
            '&::before': {
                transform: 'translateX(100%)',
            },
        },
        '&:active': {
            transform: 'scale(0.98)',
        },
    };

    const updateButtonStyle = {
        mt: 2,
        borderRadius: '25px',
        padding: '10px 20px',
        textTransform: 'none',
        fontSize: '16px',
        color: 'white',
        backgroundColor: '#FF5722',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#F4511E',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-2px)',
        }
    };

    const colorPickerStyle = {
        width: '100%',
        height: '40px',
        padding: '0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&::-webkit-color-swatch-wrapper': {
            padding: 0,
        },
        '&::-webkit-color-swatch': {
            border: 'none',
            borderRadius: '4px',
        }
    };

    useEffect(() => {
        const loadBrandingConfig = async () => {
            try {
                if (userDetails?.tipo_profesional === 'entrenador_independiente') {
                    const userRef = doc(db, 'userDetails', userDetails.uid);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const data = userSnap.data();
                        if (data.branding) {
                            setPrimaryColor(data.branding.primaryColor || '#FF5722');
                            setSecondaryColor(data.branding.secondaryColor || '#FFA000');
                            setUseCustomColors(data.branding.appConfig?.useCustomColors || false);
                            setShowBusinessLogo(data.branding.appConfig?.showBusinessLogo || true);
                        }
                    }
                } else if (userDetails?.IDBO) {
                    const centroRef = doc(db, 'Centros', userDetails.IDBO);
                    const centroSnap = await getDoc(centroRef);
                    if (centroSnap.exists()) {
                        const data = centroSnap.data();
                        if (data.branding) {
                            setPrimaryColor(data.branding.primaryColor || '#FF5722');
                            setSecondaryColor(data.branding.secondaryColor || '#FFA000');
                            setUseCustomColors(data.branding.appConfig?.useCustomColors || false);
                            setShowBusinessLogo(data.branding.appConfig?.showBusinessLogo || true);
                        }
                    }
                }
            } catch (error) {
                console.error("Error al cargar la configuración de marca:", error);
            }
        };

        if (userDetails) {
            loadBrandingConfig();
        }
    }, [userDetails]);

    const handleSaveBrandingConfig = async () => {
        try {
            setIsLoading(true);
            const brandingData = {
                branding: {
                    primaryColor,
                    secondaryColor,
                    appConfig: {
                        useCustomColors,
                        showBusinessLogo
                    }
                }
            };

            if (userDetails?.tipo_profesional === 'entrenador_independiente') {
                const userRef = doc(db, 'userDetails', userDetails.uid);
                await updateDoc(userRef, brandingData);
            } else if (userDetails?.IDBO) {
                const centroRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centroRef, brandingData);
            }

            // Actualizar el contexto si es necesario
            if (updateUserDetails) {
                updateUserDetails({
                    ...userDetails,
                    ...brandingData
                });
            }

            alert('Configuración guardada con éxito');
        } catch (error) {
            console.error("Error al guardar la configuración:", error);
            alert('Error al guardar la configuración');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Configuración
            </Typography>

            <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="center">
                            <Avatar
                                src={userDetails?.photo_url || '/path/to/default/avatar.png'}
                                sx={{ 
                                    width: 100, 
                                    height: 100, 
                                    mr: 2,
                                    cursor: 'pointer',
                                    '&:hover': { opacity: 0.8 }
                                }}
                                onClick={() => userPhotoInputRef.current.click()}
                            />
                            <input
                                type="file"
                                ref={userPhotoInputRef}
                                style={{ display: 'none' }}
                                onChange={handleUserPhotoChange}
                                accept="image/*"
                            />
                            <Box>
                                <Typography variant="h6">{userDetails?.display_name}</Typography>
                                <Typography variant="body1">{userDetails?.email}</Typography>
                                <Typography variant="body2" color="orange" fontWeight="bold">
                                    IDPT: {userDetails?.IDPT}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {(userDetails?.tipo_profesional === 'propietario_centro' || userDetails?.IDBO) && (
                        <>
                            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                            <Grid item xs={12} md={5}>
                                <Box display="flex" alignItems="center">
                                    {userDetails?.IDBO && (
                                        <>
                                            <Avatar
                                                src={centroPictureUrl || '/path/to/default/center.png'}
                                                sx={{ 
                                                    width: 100, 
                                                    height: 100, 
                                                    mr: 2,
                                                    cursor: 'pointer',
                                                    '&:hover': { opacity: 0.8 }
                                                }}
                                                onClick={() => centroPhotoInputRef.current.click()}
                                            />
                                            <input 
                                                type="file" 
                                                ref={centroPhotoInputRef} 
                                                style={{ display: 'none' }} 
                                                onChange={handleCentroPhotoChange}
                                                accept="image/*"
                                            />
                                        </>
                                    )}
                                    <Box>
                                        <Typography variant="h6">{userDetails?.nombre_business}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {centroEmail}
                                        </Typography>
                                        <Typography variant="body2" color="orange" fontWeight="bold">
                                            IDBO: {userDetails?.IDBO}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Paper>

            {(userDetails?.tipo_profesional === 'propietario_centro' || userDetails?.tipo_profesional === 'entrenador_independiente') && (
                <>
                    <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Imagen Corporativa
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    Logo del Negocio
                                </Typography>
                                <Box
                                    sx={{
                                        width: 200,
                                        height: 200,
                                        border: '2px dashed #ccc',
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        '&:hover': {
                                            borderColor: 'primary.main',
                                        }
                                    }}
                                    onClick={() => document.getElementById('business-logo-input').click()}
                                >
                                    {businessLogoUrl ? (
                                        <img 
                                            src={businessLogoUrl} 
                                            alt="Logo del negocio"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    ) : (
                                        <Typography color="textSecondary">
                                            Haz clic para subir tu logo
                                        </Typography>
                                    )}
                                </Box>
                                <input
                                    id="business-logo-input"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleBusinessLogoChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    Colores Corporativos
                                </Typography>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Color Principal
                                    </Typography>
                                    <input
                                        type="color"
                                        value={primaryColor}
                                        onChange={(e) => setPrimaryColor(e.target.value)}
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            padding: '0',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Color Secundario
                                    </Typography>
                                    <input
                                        type="color"
                                        value={secondaryColor}
                                        onChange={(e) => setSecondaryColor(e.target.value)}
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            padding: '0',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        Estos colores se utilizarán en tu marca personal y aplicación de clientes si lo configuras.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Configuración App de Clientes
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
                            Personaliza cómo tus clientes ven tu marca en la aplicación
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch 
                                        checked={useCustomColors}
                                        onChange={(e) => setUseCustomColors(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Usar colores corporativos en la app de clientes"
                            />
                            <Box sx={{ ml: 4, mt: 1, mb: 2 }}>
                                <Typography variant="body2" color="textSecondary">
                                    Al activar esta opción, la app de tus clientes utilizará los colores que has definido en tu imagen corporativa
                                </Typography>
                            </Box>
                            
                            <FormControlLabel
                                control={
                                    <Switch 
                                        checked={showBusinessLogo}
                                        onChange={(e) => setShowBusinessLogo(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Mostrar logo en la app de clientes"
                            />
                            <Box sx={{ ml: 4, mt: 1 }}>
                                <Typography variant="body2" color="textSecondary">
                                    Tu logo aparecerá en la cabecera y en elementos clave de la aplicación
                                </Typography>
                            </Box>
                        </FormGroup>

                        <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="body2" color="textSecondary">
                                Vista previa de los colores seleccionados:
                            </Typography>
                            <Box sx={{ 
                                width: 30, 
                                height: 30, 
                                borderRadius: '50%', 
                                backgroundColor: primaryColor 
                            }} />
                            <Box sx={{ 
                                width: 30, 
                                height: 30, 
                                borderRadius: '50%', 
                                backgroundColor: secondaryColor 
                            }} />
                        </Box>

                        <Button
                            variant="contained"
                            onClick={handleSaveBrandingConfig}
                            disabled={isLoading}
                            sx={{
                                ...updateButtonStyle,
                                mt: 3
                            }}
                        >
                            {isLoading ? 'Guardando...' : 'Guardar Configuración'}
                        </Button>
                    </Paper>
                </>
            )}

            <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Gestión de Planes
                </Typography>
                <Grid container spacing={3}>
                    {/* Plan Starter */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{
                            ...planCardStyle,
                            border: currentPlan === 'starter' ? '2px solid #FF5722' : 'none',
                            position: 'relative'
                        }}>
                            {currentPlan === 'starter' && (
                                <Box sx={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    backgroundColor: '#FF5722',
                                    color: 'white',
                                    borderRadius: '12px',
                                    padding: '4px 8px',
                                    fontSize: '0.7rem',
                                    fontWeight: 'bold'
                                }}>
                                    PLAN ACTUAL
                                </Box>
                            )}
                            <Box>
                                <Typography variant="h6" sx={{ mb: 1, color: '#333' }}>Plan Starter</Typography>
                                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                                    Ideal para profesionales que están comenzando o tienen pocos clientes.
                                </Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <StarIcon sx={{ fontSize: 16, mr: 1, color: '#FFA000' }} />
                                        Hasta 5 clientes
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <StarIcon sx={{ fontSize: 16, mr: 1, color: '#FFA000' }} />
                                        Generación de rutinas para clientes específicos
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <StarIcon sx={{ fontSize: 16, mr: 1, color: '#FFA000' }} />
                                        Funcionalidades básicas de gestión
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                    <Typography variant="body2" color="text.secondary">Precio:</Typography>
                                    <Typography variant="body1" sx={{ color: '#FF5722' }}>25€/mes</Typography>
                                </Box>
                            </Box>
                            <Button 
                                variant="outlined" 
                                startIcon={<FiSettings size={16} />}
                                onClick={handleDashboardSubscription}
                                disabled={isLoading || currentPlan === 'starter'}
                                sx={{
                                    ...buttonStyle,
                                    backgroundColor: currentPlan === 'starter' ? '#f5f5f5' : '#fff',
                                }}
                            >
                                {isLoading ? "Cargando..." : (currentPlan === 'starter' ? "Plan Actual" : "Cambiar a Starter")}
                            </Button>
                        </Paper>
                    </Grid>
                    
                    {/* Plan Grow */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{
                            ...planCardStyle,
                            border: currentPlan === 'grow' ? '2px solid #FF5722' : 'none',
                            position: 'relative'
                        }}>
                            {currentPlan === 'grow' && (
                                <Box sx={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    backgroundColor: '#FF5722',
                                    color: 'white',
                                    borderRadius: '12px',
                                    padding: '4px 8px',
                                    fontSize: '0.7rem',
                                    fontWeight: 'bold'
                                }}>
                                    PLAN ACTUAL
                                </Box>
                            )}
                            <Box>
                                <Typography variant="h6" sx={{ mb: 1, color: '#333' }}>Plan Grow</Typography>
                                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                                    Para profesionales que buscan escalar su negocio sin limitaciones.
                                </Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <StarIcon sx={{ fontSize: 16, mr: 1, color: '#FFA000' }} />
                                        Clientes ilimitados
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <StarIcon sx={{ fontSize: 16, mr: 1, color: '#FFA000' }} />
                                        Generación general de rutinas y dietas
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <StarIcon sx={{ fontSize: 16, mr: 1, color: '#FFA000' }} />
                                        Todas las funcionalidades avanzadas
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                    <Typography variant="body2" color="text.secondary">Precio:</Typography>
                                    <Typography variant="body1" sx={{ color: '#FF5722' }}>75€/mes</Typography>
                                </Box>
                            </Box>
                            <Button 
                                variant="outlined" 
                                startIcon={<FiSettings size={16} />}
                                onClick={handleDashboardSubscription}
                                disabled={isLoading || currentPlan === 'grow'}
                                sx={{
                                    ...buttonStyle,
                                    backgroundColor: currentPlan === 'grow' ? '#f5f5f5' : '#fff',
                                }}
                            >
                                {isLoading ? "Cargando..." : (currentPlan === 'grow' ? "Plan Actual" : "Cambiar a Grow")}
                            </Button>
                        </Paper>
                    </Grid>
                    
                    {/* Configuración de IA */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={planCardStyle}>
                            <Box>
                                <Typography variant="h6" sx={{ mb: 1, color: '#333' }}>
                                    Configuración de IA
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                                    Personaliza el comportamiento y estilo de tu asistente virtual.
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="body2" color="text.secondary">Estado:</Typography>
                                    <Typography variant="body2" color="success.main">
                                        Disponible
                                    </Typography>
                                </Box>
                            </Box>
                            <Button 
                                variant="outlined" 
                                startIcon={<FiCpu size={16} />}
                                onClick={() => setOpenIASettings(true)}
                                sx={buttonStyle}
                            >
                                Configurar IA
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                
                {/* Información adicional sobre los planes */}
                <Paper elevation={1} sx={{ p: 2, mt: 2, borderRadius: 2, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <Typography variant="body2" color="text.secondary">
                        <strong>Nota:</strong> El plan Starter está limitado a 5 clientes. Si tienes más de 5 clientes, te recomendamos actualizar al plan Grow para disfrutar de todas las funcionalidades sin restricciones.
                        {clientCount > 5 && currentPlan === 'starter' && (
                            <Box component="span" sx={{ color: 'error.main', fontWeight: 'bold', ml: 1 }}>
                                Actualmente tienes {clientCount} clientes. Considera actualizar tu plan.
                            </Box>
                        )}
                    </Typography>
                </Paper>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Configuración de la Cuenta
                </Typography>
                <Paper elevation={2} sx={{ 
                    p: 3, 
                    mt: 2, 
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: 3,
                    }
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Cambiar Correo Electrónico
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Correo actual: {userDetails?.email || 'No disponible'}
                            </Typography>
                            <form onSubmit={handleEmailSubmit}>
                                <TextField
                                    fullWidth
                                    label="Nuevo Correo Electrónico"
                                    variant="outlined"
                                    value={email}
                                    onChange={handleEmailChange}
                                    margin="normal"
                                    type="email"
                                    required
                                    sx={textFieldSx}
                                />
                                <TextField
                                    fullWidth
                                    label="Contraseña Actual"
                                    variant="outlined"
                                    value={emailChangePassword}
                                    onChange={handleEmailChangePasswordChange}
                                    margin="normal"
                                    type="password"
                                    required
                                    sx={textFieldSx}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={updateButtonStyle}
                                >
                                    Actualizar Correo
                                </Button>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Cambiar Contraseña
                            </Typography>
                            <form onSubmit={handlePasswordSubmit}>
                                <TextField
                                    fullWidth
                                    label="Contraseña Actual"
                                    variant="outlined"
                                    value={currentPassword}
                                    onChange={handleCurrentPasswordChange}
                                    margin="normal"
                                    type="password"
                                    required
                                    sx={textFieldSx}
                                />
                                <TextField
                                    fullWidth
                                    label="Nueva Contraseña"
                                    variant="outlined"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    margin="normal"
                                    type="password"
                                    required
                                    sx={textFieldSx}
                                />
                                <TextField
                                    fullWidth
                                    label="Confirmar Nueva Contraseña"
                                    variant="outlined"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    margin="normal"
                                    type="password"
                                    required
                                    sx={textFieldSx}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={updateButtonStyle}
                                >
                                    Actualizar Contraseña
                                </Button>
                            </form>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Sobre ti
                </Typography>
                <Paper elevation={2} sx={{ 
                    p: 3, 
                    mt: 2, 
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: 3,
                    }
                }}>
                    <form onSubmit={handleAboutYouSubmit}>
                        <Typography variant="subtitle1" gutterBottom>
                            Nombre del negocio
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            margin="normal"
                            placeholder="Ej: FitPro Training, Centro Deportivo Elite, Tu Nombre Personal Training"
                            sx={textFieldSx}
                        />
                        <Typography variant="subtitle1" gutterBottom>
                            Especialidad
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={specialty}
                            onChange={handleSpecialtyChange}
                            margin="normal"
                            placeholder="Ej: Entrenamiento funcional, Nutrición deportiva, Experto en levantar pesas con el meñique"
                            sx={textFieldSx}
                        />
                        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                            Descripción
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={description}
                            onChange={handleDescriptionChange}
                            margin="normal"
                            multiline
                            rows={4}
                            placeholder="Ej: Soy un apasionado entrenador con 10 años de experiencia. Mi enfoque se centra en ayudar a mis clientes a alcanzar sus metas de forma divertida y sostenible. ¡Prepárate para sudar y reír en mis sesiones!"
                            sx={textFieldSx}
                        />
                        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                            Curiosidades sobre ti
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={funFacts}
                            onChange={handleFunFactsChange}
                            margin="normal"
                            multiline
                            rows={3}
                            placeholder="Ej: Puedo hacer 100 burpees mientras recito el alfabeto al revés. Una vez gané una maratón corriendo hacia atrás. Mi récord de plancha es de 10 minutos... con un gato dormido en mi espalda."
                            sx={textFieldSx}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={updateButtonStyle}
                        >
                            Actualizar información
                        </Button>
                    </form>
                </Paper>
            </Box>

            {userDetails?.tipo_profesional === 'propietario_centro' && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Configuración del Centro
                    </Typography>
                    <Paper elevation={2} sx={{ 
                        p: 3, 
                        mt: 2, 
                        borderRadius: 4,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    }}>
                        <form onSubmit={handleCentroSubmit}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar 
                                        src={centroPictureUrl || '/path/to/default/center.png'} 
                                        sx={{ 
                                            width: 100, 
                                            height: 100, 
                                            cursor: 'pointer',
                                            '&:hover': {
                                                opacity: 0.8,
                                            }
                                        }}
                                        onClick={() => centroPhotoInputRef.current.click()}
                                    />
                                    <input 
                                        type="file" 
                                        ref={centroPhotoInputRef} 
                                        style={{ display: 'none' }} 
                                        onChange={handleCentroPhotoChange}
                                        accept="image/*"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        label="Nombre del Centro"
                                        value={centroNombre}
                                        onChange={(e) => setCentroNombre(e.target.value)}
                                        sx={textFieldSx}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                fullWidth
                                label="Correo Electrónico del Centro"
                                value={centroEmail}
                                onChange={(e) => setCentroEmail(e.target.value)}
                                sx={{ ...textFieldSx, mt: 2 }}
                            />
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Descripción del Centro"
                                value={centroDescripcion}
                                onChange={(e) => setCentroDescripcion(e.target.value)}
                                sx={{ ...textFieldSx, mt: 2 }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={updateButtonStyle}
                            >
                                Actualizar información del centro
                            </Button>
                        </form>
                        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                            Trabajadores del Centro
                        </Typography>
                        {trabajadores.length > 0 ? (
                            <List>
                                {trabajadores.map((trabajador, index) => (
                                    <ListItem key={index} alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar src={trabajador.photo_url || '/path/to/default/avatar.png'} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={trabajador.nombre}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {trabajador.email}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body1">No hay trabajadores registrados en este centro.</Typography>
                        )}
                    </Paper>
                </Box>
            )}

            {/* Diálogo para IA */}
            <Dialog
                open={openIASettings}
                onClose={() => setOpenIASettings(false)}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        minHeight: '80vh',
                        maxHeight: '90vh',
                        borderRadius: 2
                    }
                }}
            >
                <DialogTitle>
                    Configuración de IA
                    <IconButton
                        onClick={() => setOpenIASettings(false)}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <IA />
                </DialogContent>
            </Dialog>

            {/* Diálogo para Gestión de Suscripción */}
            <Dialog open={openSubscriptionDialog} onClose={handleCloseSubscriptionDialog}>
                <DialogTitle>Gestionar Suscripción</DialogTitle>
                <DialogContent>
                    {isDashboardSubscribed ? (
                        <>
                            <Typography gutterBottom>
                                Aquí puedes gestionar tu suscripción actual:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <MonetizationOnIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Ver facturas" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Actualizar método de pago" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CampaignIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Cancelar suscripción" />
                                </ListItem>
                            </List>
                        </>
                    ) : (
                        <Typography>
                            ¿Quieres acceder a todas las funcionalidades premium? Suscríbete al plan completo.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSubscriptionDialog}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleDashboardSubscription} 
                        variant="contained" 
                        color="primary"
                        disabled={isLoading}
                    >
                        {isLoading ? "Cargando..." : (isDashboardSubscribed ? "Ir al Portal de Gestión" : "Suscribirse")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Settings;