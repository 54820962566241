import React, { useState, useEffect, useContext, useRef, useCallback, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
    Box,
    Paper,
    TextField,
    IconButton,
    Typography,
    Avatar,
    CircularProgress,
    Button,
    Skeleton,
} from '@mui/material';
import { PaperPlaneTilt, Lightning, User, Plus } from "@phosphor-icons/react";
import { AuthContext } from '../../contexts/AuthContext';
import { 
    collection, 
    addDoc,
    doc,
    query, 
    where, 
    orderBy, 
    onSnapshot,
    serverTimestamp,
    getDocs,
    setDoc,
    getDoc
} from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';
import { useSnackbar } from 'notistack';

// Verificar que la variable de entorno está cargada
console.log('Variables de entorno disponibles:', {
    ASSISTANT_ID: process.env.REACT_APP_OPENAI_ASSISTANT_ID,
    ALL_ENV: process.env
});

const ASSISTANT_ID = process.env.REACT_APP_OPENAI_ASSISTANT_ID;
const CHATBOT_URL = 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/chatbot-dashboard';

if (!ASSISTANT_ID) {
    console.error('ASSISTANT_ID no está definido en las variables de entorno. Asegúrate de que existe REACT_APP_OPENAI_ASSISTANT_ID en el archivo .env y que has reiniciado el servidor de desarrollo.');
}

const Chatbot = () => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [currentChatId, setCurrentChatId] = useState(null);
    // Guardamos los IDs de los mensajes animados para no volver a animarlos
    const animatedMessagesRef = useRef(new Set());
    const { userDetails } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Efecto para el autoscroll cuando cambian los mensajes
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Verificar la configuración al montar el componente
    useEffect(() => {
        if (!ASSISTANT_ID) {
            enqueueSnackbar('Error de configuración: Assistant ID no disponible. Por favor, contacta con soporte.', {
                variant: 'error',
                persist: true
            });
        }
    }, [enqueueSnackbar]);

    // Inicializar o cargar el chat actual
    useEffect(() => {
        const initializeChat = async () => {
            if (!userDetails?.uid) return;

            try {
                // Buscar el chat activo más reciente del usuario
                const chatsRef = collection(db, 'chatbot');
                const q = query(
                    chatsRef,
                    where('userId', '==', userDetails.uid),
                    where('status', '==', 'active'),
                    orderBy('createdAt', 'desc')
                );

                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    // Usar el chat activo más reciente
                    setCurrentChatId(querySnapshot.docs[0].id);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error al inicializar el chat:", error);
                setLoading(false);
            }
        };

        initializeChat();
    }, [userDetails]);

    // Cargar mensajes del chat actual
    useEffect(() => {
        if (!currentChatId) return;

        const messagesRef = collection(db, 'chatbot', currentChatId, 'messages');
        const q = query(messagesRef, orderBy('timestamp', 'asc'));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const loadedMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(loadedMessages);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [currentChatId]);

    // Utilizamos useEffect para rastrear los mensajes que ya se han animado
    useEffect(() => {
        if (!loading && messages.length > 0) {
            // Actualizamos el conjunto con todos los IDs actuales
            const currentIds = new Set(messages.map(msg => msg.id));
            animatedMessagesRef.current = currentIds;
        }
    }, [loading, messages]);

    const shouldAnimate = useCallback((messageId) => {
        // Si el ID no está en el conjunto de mensajes animados, debemos animarlo
        const shouldAnimate = messageId && !animatedMessagesRef.current.has(messageId);
        // Si lo vamos a animar, lo añadimos al conjunto para futuros renderizados
        if (shouldAnimate && messageId) {
            animatedMessagesRef.current.add(messageId);
        }
        return shouldAnimate;
    }, []);

    const handleSubmit = async (e) => {
        e?.preventDefault();
        
        if (!ASSISTANT_ID) {
            enqueueSnackbar('Error de configuración: Assistant ID no disponible. Por favor, contacta con soporte.', {
                variant: 'error'
            });
            return;
        }

        if (!message.trim() || !userDetails?.uid) {
            console.log('Condiciones no cumplidas:', {
                message: !message.trim(),
                userId: !userDetails?.uid
            });
            return;
        }

        const userMessage = message;
        setMessage('');
        setSending(true);

        try {
            console.log('Iniciando proceso de envío');
            let chatId = currentChatId;
            let threadId = null;

            // Si no existe un chat, crear uno nuevo
            if (!chatId) {
                console.log('Creando nuevo chat');
                const chatsRef = collection(db, 'chatbot');
                const newChatDoc = {
                    userId: userDetails.uid,
                    status: 'active',
                    createdAt: serverTimestamp(),
                    lastMessageAt: serverTimestamp(),
                    title: 'Nuevo Chat',
                    threadId: null
                };
                console.log('Documento a crear:', newChatDoc);
                
                const newChatRef = await addDoc(chatsRef, newChatDoc);
                console.log('Chat creado con ID:', newChatRef.id);
                chatId = newChatRef.id;
                setCurrentChatId(chatId);
            } else {
                console.log('Usando chat existente:', chatId);
                const chatDoc = await getDoc(doc(db, 'chatbot', chatId));
                threadId = chatDoc.data()?.threadId;
                console.log('Thread ID existente:', threadId);
            }

            // Referencia a la subcolección de mensajes
            console.log('Guardando mensaje del usuario');
            const messagesRef = collection(db, 'chatbot', chatId, 'messages');
            await addDoc(messagesRef, {
                content: userMessage,
                type: 'user',
                timestamp: serverTimestamp()
            });

            // Actualizar lastMessageAt
            await setDoc(doc(db, 'chatbot', chatId), {
                lastMessageAt: serverTimestamp()
            }, { merge: true });

            // Preparar el payload para la cloud function
            const payload = {
                question: userMessage,
                assistant_id: ASSISTANT_ID
            };

            if (threadId) {
                payload.thread_id = threadId;
            }
            
            console.log('Enviando payload a cloud function:', payload);

            // Llamar a la cloud function
            const response = await fetch(CHATBOT_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error('Error en la respuesta del chatbot');
            }

            const data = await response.json();
            console.log('Respuesta recibida:', data);

            // Si es una conversación nueva, guardar el thread_id
            if (!threadId && data.thread_id) {
                console.log('Guardando nuevo thread_id:', data.thread_id);
                await setDoc(doc(db, 'chatbot', chatId), {
                    threadId: data.thread_id
                }, { merge: true });
            }

            // Guardar la respuesta del asistente
            console.log('Guardando respuesta del asistente');
            await addDoc(messagesRef, {
                content: data.respuesta,
                type: 'assistant',
                timestamp: serverTimestamp()
            });

        } catch (error) {
            console.error("Error detallado:", error);
            enqueueSnackbar('Error al procesar el mensaje. Por favor, intenta de nuevo.', { 
                variant: 'error' 
            });
        } finally {
            setSending(false);
        }
    };

    const handleNewChat = () => {
        setCurrentChatId(null);
        setMessages([]);
        // Enfocar el campo de entrada después de crear un nuevo chat
        setTimeout(() => {
            inputRef.current?.focus();
        }, 100);
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp || !timestamp.seconds) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    // Memoizando el manejador del cambio de mensaje para evitar recreaciones innecesarias
    const handleMessageChange = useCallback((e) => {
        setMessage(e.target.value);
    }, []);

    const MessageBubble = memo(({ message, isLastMessage }) => {
        // Determinamos si este mensaje debe animarse (solo si es nuevo y tiene ID)
        const animate = message.id ? shouldAnimate(message.id) : isLastMessage;

        return (
            <Box sx={{ 
                width: '100%',
                display: 'flex', 
                flexDirection: 'column',
                px: { xs: 2, sm: 3 },
                py: 2,
                backgroundColor: message.type === 'assistant' ? 'rgba(255, 250, 245, 0.7)' : 'transparent',
                backdropFilter: message.type === 'assistant' ? 'blur(10px)' : 'none',
                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                transition: 'all 0.3s ease',
                // Solo animamos si es un mensaje nuevo
                animation: animate ? 'fadeIn 0.5s ease' : 'none',
                '@keyframes fadeIn': {
                    '0%': { opacity: 0, transform: 'translateY(10px)' },
                    '100%': { opacity: 1, transform: 'translateY(0)' }
                }
            }}>
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1
                }}>
                    {message.type === 'assistant' ? (
                        <Avatar 
                            sx={{ 
                                bgcolor: 'primary.main',
                                width: 28,
                                height: 28,
                                mr: 1.5,
                                boxShadow: '0 2px 10px rgba(255, 87, 34, 0.2)'
                            }}
                        >
                            <Lightning size={16} weight="fill" />
                        </Avatar>
                    ) : (
                        <Avatar 
                            src={userDetails?.photoURL || ''}
                            sx={{ 
                                bgcolor: 'grey.200',
                                width: 28,
                                height: 28,
                                mr: 1.5,
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <User size={16} weight="fill" />
                        </Avatar>
                    )}
                    <Typography variant="body2" sx={{ 
                        fontWeight: 500,
                        color: message.type === 'assistant' ? 'primary.dark' : 'text.primary',
                        fontSize: '0.85rem'
                    }}>
                        {message.type === 'assistant' ? 'FitAI Assistant' : 'Tú'}
                    </Typography>
                    
                    {message.timestamp && (
                        <Typography variant="caption" sx={{ 
                            ml: 'auto', 
                            color: 'text.secondary',
                            fontSize: '0.7rem'
                        }}>
                            {formatTimestamp(message.timestamp)}
                        </Typography>
                    )}
                </Box>
                
                <Box sx={{ 
                    pl: 5.5,
                    pr: 1,
                    '& p': { 
                        my: 0.5,
                        lineHeight: 1.6
                    },
                    '& code': {
                        backgroundColor: message.type === 'assistant' ? 'rgba(255, 87, 34, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                        px: 0.8,
                        py: 0.2,
                        borderRadius: '4px',
                        fontFamily: 'monospace',
                        fontSize: '0.85rem'
                    },
                    '& pre': {
                        backgroundColor: message.type === 'assistant' ? 'rgba(255, 87, 34, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                        p: 1.5,
                        borderRadius: '8px',
                        overflowX: 'auto',
                        mt: 1,
                        mb: 1.5,
                        border: '1px solid rgba(0, 0, 0, 0.05)'
                    },
                    '& a': {
                        color: message.type === 'assistant' ? 'primary.main' : 'primary.dark',
                        textDecoration: 'underline',
                        fontWeight: 500
                    },
                    '& ul, & ol': {
                        pl: 2.5,
                        mb: 1.5
                    },
                    '& li': {
                        mb: 0.5
                    },
                    '& blockquote': {
                        borderLeft: '3px solid',
                        borderColor: message.type === 'assistant' ? 'primary.main' : 'divider',
                        pl: 2,
                        py: 0.5,
                        ml: 1,
                        my: 1.5,
                        fontStyle: 'italic',
                        color: 'text.secondary'
                    }
                }}>
                    <ReactMarkdown 
                        remarkPlugins={[remarkGfm]}
                        components={{
                            p: ({node, ...props}) => <Typography variant="body2" color={message.type === 'assistant' ? 'text.primary' : 'text.secondary'} {...props} />,
                            a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" {...props} />
                        }}
                    >
                        {message.content}
                    </ReactMarkdown>
                </Box>
            </Box>
        );
    }, (prevProps, nextProps) => {
        // Optimizamos la comparación para el memoizado
        return prevProps.message.id === nextProps.message.id && 
               prevProps.isLastMessage === nextProps.isLastMessage;
    });

    const TypingIndicator = memo(() => (
        <Box sx={{ 
            width: '100%',
            px: { xs: 2, sm: 3 },
            py: 2,
            backgroundColor: 'rgba(255, 250, 245, 0.7)',
            backdropFilter: 'blur(10px)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}>
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                mb: 1
            }}>
                <Avatar 
                    sx={{ 
                        bgcolor: 'primary.main',
                        width: 28,
                        height: 28,
                        mr: 1.5,
                        boxShadow: '0 2px 10px rgba(255, 87, 34, 0.2)'
                    }}
                >
                    <Lightning size={16} weight="fill" />
                </Avatar>
                <Typography variant="body2" sx={{ 
                    fontWeight: 500,
                    color: 'primary.dark',
                    fontSize: '0.85rem'
                }}>
                    FitAI Assistant
                </Typography>
            </Box>
            
            <Box sx={{ pl: 5.5, pr: 1 }}>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 0.5,
                    color: 'text.secondary'
                }}>
                    <div className="typing-dot" style={{ 
                        width: '6px', 
                        height: '6px', 
                        backgroundColor: '#FF5722',
                        borderRadius: '50%',
                        animation: 'typingAnimation 1.4s infinite ease-in-out',
                        animationDelay: '0s',
                    }} />
                    <div className="typing-dot" style={{ 
                        width: '6px', 
                        height: '6px', 
                        backgroundColor: '#FF5722',
                        borderRadius: '50%',
                        animation: 'typingAnimation 1.4s infinite ease-in-out',
                        animationDelay: '0.2s',
                        opacity: 0.8
                    }} />
                    <div className="typing-dot" style={{ 
                        width: '6px', 
                        height: '6px', 
                        backgroundColor: '#FF5722',
                        borderRadius: '50%',
                        animation: 'typingAnimation 1.4s infinite ease-in-out',
                        animationDelay: '0.4s',
                        opacity: 0.5
                    }} />
                    
                    <style jsx>{`
                        @keyframes typingAnimation {
                            0%, 100% { transform: translateY(0); }
                            50% { transform: translateY(-5px); }
                        }
                    `}</style>
                </Box>
            </Box>
        </Box>
    ));

    // Optimizamos el componente principal para evitar renderizados innecesarios
    return (
        <Box sx={{ 
            height: 'calc(100vh - 64px)',
            display: 'flex', 
            flexDirection: 'column',
            maxWidth: '1200px',
            margin: '0 auto',
            position: 'relative'
        }}>
            <Paper elevation={0} sx={{ 
                borderRadius: 0,
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(8px)',
                zIndex: 10,
                py: 1.5,
                px: { xs: 2, sm: 3 },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar 
                        sx={{ 
                            bgcolor: 'primary.main',
                            width: 36,
                            height: 36,
                            mr: 1.5,
                            boxShadow: '0 4px 12px rgba(255, 87, 34, 0.25)'
                        }}
                    >
                        <Lightning size={20} weight="fill" />
                    </Avatar>
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                            FitAI Assistant
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>
                            Asistente inteligente para entrenadores
                        </Typography>
                    </Box>
                </Box>

                <Button
                    variant="text"
                    onClick={handleNewChat}
                    startIcon={<Plus weight="bold" />}
                    size="small"
                    sx={{ 
                        borderRadius: '8px',
                        textTransform: 'none',
                        fontWeight: 500,
                        px: 2,
                        py: 0.75,
                        color: 'primary.main',
                        bgcolor: 'rgba(255, 87, 34, 0.08)',
                        '&:hover': {
                            bgcolor: 'rgba(255, 87, 34, 0.15)'
                        }
                    }}
                >
                    Nuevo Chat
                </Button>
            </Paper>

            {/* Área de mensajes */}
            <Box 
                sx={{ 
                    flex: 1, 
                    overflowY: 'auto',
                    bgcolor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#ddd',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#ccc',
                    },
                }}
            >
                {loading ? (
                    <Box sx={{ p: { xs: 2, sm: 3 }, display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                            <Skeleton variant="circular" width={28} height={28} />
                            <Skeleton variant="rounded" width={100} height={16} />
                        </Box>
                        <Skeleton variant="rounded" height={80} sx={{ ml: 5.5 }} />
                        
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 2 }}>
                            <Skeleton variant="circular" width={28} height={28} />
                            <Skeleton variant="rounded" width={100} height={16} />
                        </Box>
                        <Skeleton variant="rounded" height={60} sx={{ ml: 5.5 }} />
                    </Box>
                ) : (
                    <>
                        {!currentChatId || messages.length === 0 ? (
                            <MessageBubble 
                                message={{
                                    type: 'assistant',
                                    content: "¡Hola! Soy tu asistente personal de FitAI para la gestión de tu negocio. Estoy aquí para ayudarte a optimizar tu trabajo como entrenador personal. Puedo asistirte con estrategias de marketing, gestión de clientes, planificación de servicios, análisis de métricas de negocio, y cualquier otra consulta relacionada con el crecimiento de tu empresa. ¿En qué puedo ayudarte hoy?"
                                }}
                                isLastMessage={true}
                            />
                        ) : (
                            messages.map((msg, index) => (
                                <MessageBubble 
                                    key={msg.id || `temp-${index}`} 
                                    message={msg} 
                                    isLastMessage={index === messages.length - 1} 
                                />
                            ))
                        )}
                        {sending && <TypingIndicator />}
                        <div ref={messagesEndRef} />
                    </>
                )}
            </Box>

            {/* Área de mensajes predefinidos */}
            <Box sx={{ 
                display: 'flex', 
                gap: 1, 
                p: 2,
                pt: 0.5,
                overflowX: 'auto',
                flexWrap: 'nowrap',
                '&::-webkit-scrollbar': {
                    height: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#ddd',
                    borderRadius: '10px',
                },
                backgroundColor: 'rgba(250, 250, 250, 0.9)',
                backdropFilter: 'blur(8px)',
            }}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setMessage("¿Qué estrategias me recomiendas para conseguir nuevos clientes?");
                        setTimeout(() => inputRef.current?.focus(), 100);
                    }}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.3)',
                        color: '#FF5722',
                        fontSize: '0.75rem',
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                        px: 1.5,
                        py: 0.5,
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Conseguir nuevos clientes
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setMessage("¿Cómo puedo establecer precios competitivos para mis servicios?");
                        setTimeout(() => inputRef.current?.focus(), 100);
                    }}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.3)',
                        color: '#FF5722',
                        fontSize: '0.75rem',
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                        px: 1.5,
                        py: 0.5,
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Estrategia de precios
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setMessage("¿Qué estrategias me recomiendas para fidelizar a mis clientes?");
                        setTimeout(() => inputRef.current?.focus(), 100);
                    }}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.3)',
                        color: '#FF5722',
                        fontSize: '0.75rem',
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                        px: 1.5,
                        py: 0.5,
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Fidelización de clientes
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setMessage("¿Cómo puedo mejorar mi presencia en redes sociales?");
                        setTimeout(() => inputRef.current?.focus(), 100);
                    }}
                    sx={{ 
                        borderRadius: '20px',
                        textTransform: 'none',
                        borderColor: 'rgba(255, 87, 34, 0.3)',
                        color: '#FF5722',
                        fontSize: '0.75rem',
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                        px: 1.5,
                        py: 0.5,
                        '&:hover': {
                            borderColor: '#FF5722',
                            backgroundColor: 'rgba(255, 87, 34, 0.04)'
                        }
                    }}
                >
                    Marketing en redes sociales
                </Button>
            </Box>

            {/* Área de input */}
            <Box sx={{ 
                p: 2,
                backgroundColor: 'rgba(250, 250, 250, 0.9)',
                backdropFilter: 'blur(8px)',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)'
            }}>
                <Typography 
                    variant="caption" 
                    color="text.secondary" 
                    sx={{ 
                        display: 'block',
                        textAlign: 'center', 
                        mb: 1,
                        fontStyle: 'italic',
                        fontSize: '0.7rem'
                    }}
                >
                    FitAI proporciona información general. Verifica todos los datos importantes.
                </Typography>
                
                <Paper 
                    component="form" 
                    onSubmit={handleSubmit}
                    elevation={0}
                    sx={{ 
                        p: 1,
                        pl: 2,
                        display: 'flex', 
                        gap: 1,
                        alignItems: 'center',
                        borderRadius: '12px',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        bgcolor: 'white',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.05)'
                        },
                        '&:focus-within': {
                            boxShadow: '0 0 0 2px rgba(255, 87, 34, 0.2)',
                            borderColor: 'rgba(255, 87, 34, 0.5)'
                        }
                    }}
                >
                    <TextField
                        fullWidth
                        inputRef={inputRef}
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="Escribe un mensaje..."
                        variant="standard"
                        disabled={sending}
                        multiline
                        maxRows={3}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        sx={{ 
                            '& .MuiInputBase-root': {
                                padding: 0,
                                fontSize: '0.95rem'
                            }
                        }}
                    />
                    <IconButton 
                        type="submit" 
                        color="primary"
                        disabled={!message.trim() || sending}
                        sx={{ 
                            bgcolor: message.trim() ? 'primary.main' : 'grey.300',
                            color: 'white',
                            '&:hover': {
                                bgcolor: message.trim() ? 'primary.dark' : 'grey.300'
                            },
                            '&.Mui-disabled': {
                                bgcolor: 'grey.200',
                                color: 'grey.400'
                            },
                            width: 38,
                            height: 38,
                            transition: 'all 0.2s ease',
                            transform: message.trim() ? 'scale(1)' : 'scale(0.95)'
                        }}
                    >
                        {sending ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <PaperPlaneTilt size={18} weight="fill" />
                        )}
                    </IconButton>
                </Paper>
            </Box>
        </Box>
    );
};

export default Chatbot; 