import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Paper,
    List,
    ListItem,
    ListItemText,
    Chip,
    Divider,
    IconButton,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    AccessTime as AccessTimeIcon,
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon,
    Pending as PendingIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, where, orderBy, onSnapshot, limit } from 'firebase/firestore';

const FormResponses = ({ clientId, formId, activityId }) => {
    const [loading, setLoading] = useState(true);
    const [responses, setResponses] = useState([]);
    const [expandedResponse, setExpandedResponse] = useState(null);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [form, setForm] = useState(null);

    useEffect(() => {
        const fetchResponses = () => {
            const db = getFirestore();
            const formsRef = collection(db, 'forms');
            
            // Construir la consulta base
            let queryConstraints = [
                where('clientId', '==', clientId),
                where('formId', '==', formId),
                orderBy('createdAt', 'desc'),
                limit(1)
            ];
            
            // Si se proporciona activityId, añadir esa restricción
            if (activityId) {
                queryConstraints = [
                    where('activityId', '==', activityId),
                    orderBy('createdAt', 'desc'),
                    limit(1)
                ];
            }
            
            const q = query(formsRef, ...queryConstraints);

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const responsesData = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.status !== 'deleted') {
                        responsesData.push({
                            id: doc.id,
                            ...data
                        });
                    }
                });
                setResponses(responsesData);
                setLoading(false);
            }, (error) => {
                console.error('Error al obtener respuestas:', error);
                setLoading(false);
            });

            return unsubscribe;
        };

        if (clientId && (formId || activityId)) {
            const unsubscribe = fetchResponses();
            return () => unsubscribe();
        }
    }, [clientId, formId, activityId]);

    useEffect(() => {
        const fetchForm = () => {
            const db = getFirestore();
            const formsRef = collection(db, 'forms');
            
            // Construir la consulta base
            let queryConstraints = [
                where('clientId', '==', clientId),
                where('formId', '==', formId),
                limit(1)
            ];
            
            // Si se proporciona activityId, añadir esa restricción
            if (activityId) {
                queryConstraints = [
                    where('activityId', '==', activityId),
                    limit(1)
                ];
            }
            
            const q = query(formsRef, ...queryConstraints);

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const formData = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.status !== 'deleted') {
                        formData.push({
                            id: doc.id,
                            ...data
                        });
                    }
                });
                setForm(formData[0]);
            }, (error) => {
                console.error('Error al obtener formulario:', error);
            });

            return unsubscribe;
        };

        if (clientId && (formId || activityId)) {
            const unsubscribe = fetchForm();
            return () => unsubscribe();
        }
    }, [clientId, formId, activityId]);

    const handleExpandClick = (responseId) => {
        setExpandedResponse(expandedResponse === responseId ? null : responseId);
    };

    const handleResponseClick = (response) => {
        setSelectedResponse(response);
        setOpenDialog(true);
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'completed':
                return <CheckCircleIcon color="success" />;
            case 'expired':
                return <ErrorIcon color="error" />;
            case 'active':
                return <PendingIcon color="primary" />;
            default:
                return null;
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 'completed':
                return 'Completado';
            case 'expired':
                return 'Expirado';
            case 'active':
                return form?.frequency === 'once' ? 'Pendiente (Una vez)' : 'Pendiente';
            default:
                return status;
        }
    };

    const getStatusColor = (status, frequency) => {
        if (status === 'completed') return 'success.main';
        if (status === 'expired') return 'error.main';
        if (status === 'active' && frequency === 'once') return 'info.main';
        return 'primary.main';
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <List>
                {responses.map((response) => (
                    <Paper 
                        key={response.id} 
                        elevation={1} 
                        sx={{ mb: 2, overflow: 'hidden' }}
                    >
                        <ListItem
                            button
                            onClick={() => handleResponseClick(response)}
                            sx={{ 
                                borderLeft: '4px solid',
                                borderColor: getStatusColor(response.status, form?.frequency)
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {getStatusIcon(response.status)}
                                        <Typography variant="subtitle1">
                                            {response.title}
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                                        <AccessTimeIcon fontSize="small" />
                                        <Typography variant="body2">
                                            {response.createdAt?.toDate().toLocaleDateString()}
                                        </Typography>
                                        <Chip
                                            size="small"
                                            label={getStatusText(response.status)}
                                            color={
                                                response.status === 'completed' ? 'success' :
                                                response.status === 'expired' ? 'error' : 'primary'
                                            }
                                        />
                                    </Box>
                                }
                            />
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                handleExpandClick(response.id);
                            }}>
                                {expandedResponse === response.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </ListItem>
                        <Collapse in={expandedResponse === response.id}>
                            <Box sx={{ p: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Resumen de Respuestas
                                </Typography>
                                <List dense>
                                    {response.questions?.map((questionData, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={questionData.question}
                                                secondary={questionData.answer || 'Sin respuesta'}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Collapse>
                    </Paper>
                ))}
            </List>

            {/* Diálogo de detalles de respuesta */}
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="md"
                fullWidth
            >
                {selectedResponse && (
                    <>
                        <DialogTitle>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                {getStatusIcon(selectedResponse.status)}
                                {selectedResponse.title}
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Enviado el {selectedResponse.createdAt?.toDate().toLocaleDateString()}
                                </Typography>
                                <Chip
                                    size="small"
                                    label={getStatusText(selectedResponse.status)}
                                    color={
                                        selectedResponse.status === 'completed' ? 'success' :
                                        selectedResponse.status === 'expired' ? 'error' : 'primary'
                                    }
                                    sx={{ mt: 1 }}
                                />
                            </Box>
                            <Divider sx={{ my: 2 }} />
                            <List>
                                {selectedResponse.questions?.map((questionData, index) => (
                                    <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {questionData.question}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            {questionData.answer || 'Sin respuesta'}
                                        </Typography>
                                        {index < selectedResponse.questions.length - 1 && (
                                            <Divider sx={{ my: 2, width: '100%' }} />
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)}>
                                Cerrar
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Box>
    );
};

export default FormResponses; 